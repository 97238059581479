import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Logger } from '../log/logger';
import { UiTab } from '../model/ui-tab';
import { DataRecord } from '../model/data-record';
import { AUtil } from '../utils/autil';
import { DataColumn, DataType } from '../model/data-column';
import { ModelUtil } from '../utils/model-util';
import { Trl } from '../utils/trl';
import { DataPickOption } from '../model/data-pick-option';
import { FormFocus } from './form-focus';
import { AccortoService } from '../accorto.service';

/**
 * Form / Record Manager
 */
export class FormManager {

  /** Form Group */
  formGroup: FormGroup;

  /** Form valid */
  valid: boolean = true;
  /** Form changes */
  changed: boolean = false;
  /** Form changes (property names) */
  changes: string[] = [];
  /** Form changes (labels) */
  changeLabels: string[] = [];
  /** Form errors (propertyNames) */
  errors: string[] = [];

  /** Form Manager Name */
  name: string;

  /** Initial Change */
  private initialChangeMap: { [ key: string ]: string } = {};
  /** Last Change */
  private lastChangeMap: { [ key: string ]: string } = {};

  private focusElements: { [ key: string ]: FormFocus } = {};
  private focusElementName: string;

  private log: Logger = new Logger('FormManager');

  private calculatedColumns: { [ key: string ]: DataColumn } = {};
  private handleChange: boolean = true;
  private statusSubscription: Subscription;
  private changeSubscription: Subscription;

  /**
   * Form Record Manager
   */
  constructor(private fb: FormBuilder,
              public ui: UiTab,
              public record: DataRecord,
              private tableEditMode: string, // y/n
              private conf: AccortoService,
              private saveRecord: EventEmitter<DataRecord>,
              private statusUpdate: EventEmitter<string>,
              private statusChange: EventEmitter<FormManager>) {
    this.name = ui.name + '#' + record.rowNo;
    this.log.setSubName(this.name);
    this.initialChangeMap = Object.assign({}, record.changeMap);
    this.createFormGroup();
    if (this.formGroup) {
      this.setToRecord();
    } else { // empty
      this.formGroup = new FormGroup({});
    }
    // this.log.debug('<> table=' + this.isTable(), ui)();
  } // constructor

  /**
   * Form/Record Change Info
   */
  get changeInfo(): string {
    if (this.changeLabels.length > 0) {
      return 'Undo changes: ' + this.changeLabels.join('; ');
    }
    return '';
  }

  /**
   * Form/Record Changed (might be lagging) - see changed
   */
  get isChanged(): boolean {
    return this.formGroup.dirty;
  }

  /**
   * No Need to Save
   * @return form/record not changed
   */
  get recordUnchanged() {
    return !this.formGroup.valid || this.formGroup.pristine;
  }

  /**
   * @return form/record is valid
   */
  get recordValid(): boolean {
    return this.formGroup.valid;
  }

  /**
   * Form/Record Error Info
   * @return string or empty
   */
  get statusInfo(): string {
    let msg = '';
    if (this.formGroup.errors) {
      msg = AUtil.getValidationErrors(this.formGroup.errors);
    }
    Object.keys(this.formGroup.controls).forEach(((key) => {
      const errors = this.formGroup.controls[ key ].errors;
      if (errors) {
        const dataColumn = this.ui.dataTable.columnListMap[ key.toLowerCase() ];
        if (msg) {
          msg += '; ' + AUtil.getValidationErrors(errors, key, dataColumn);
        } else {
          msg = AUtil.getValidationErrors(errors, key, dataColumn);
        }
      }
    }));
    // this.log.log('statusInfo', msg)();
    return msg;
  } // statusInfo

  /**
   * FormGroup Status Info
   * @return info (no values)
   */
  get statusInfoX(): string {
    let msg = 'valid=' + this.formGroup.valid
      + ' dirty=' + this.formGroup.dirty;
    if (this.formGroup.errors) {
      msg += ' error=' + AUtil.getValidationErrors(this.formGroup.errors);
    }
    Object.keys(this.formGroup.controls).forEach(((key) => {
      const ctrl = this.formGroup.controls[ key ];
      msg += ' | ' + key + (ctrl.valid ? '' : ' NotValid') + (ctrl.dirty ? ' dirty' : '');
      const errors = ctrl.errors;
      if (errors) {
        const dataColumn = this.ui.dataTable.columnListMap[ key.toLowerCase() ];
        msg += ' e=' + AUtil.getValidationErrors(errors, key, dataColumn);
      }
    }));
    // this.log.log('statusInfo', msg)();
    return msg;
  } // statusInfoX

  /**
   * clear subscription
   */
  destroy() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
    this.statusSubscription = null;
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
    this.changeSubscription = null;
  }

  /**
   * get error message of control with propertyName
   */
  errorMsg(propertyName: string): string | undefined {
    const fc = this.formGroup.controls[ propertyName ];
    if (!fc.valid) {
      return AUtil.getValidationErrors(fc.errors); // don't add label
    }
    return undefined;
  }

  /**
   * Evaluate Boolean value
   * @param name column name
   * @param logic readonlyLogic
   * @return value or false
   */
  evaluateBoolean(name: string, logic: string): boolean {
    const original = this.record.valueMap;
    const row = this.formGroup.value; // current context
    const env = this.conf.env;
    try {
      /* tslint:disable-next-line */
      const vv = eval(logic);
      const readOnly = vv === true;
      if (this.conf.isDebug) { // debug info
        let index = logic.indexOf('!=');
        if (index === -1) {
          index = logic.indexOf('=');
        }
        const xLogic: string = logic.substr(0, index).trim();
        let x: any;
        try {
          /* tslint:disable-next-line */
          x = eval(xLogic);
        } catch (xx) {
        }
        this.log.log('evaluateBoolean_' + name, logic + ' => ' + vv + ' (' + (vv === true) + ') ' + xLogic + '~' + x)();
      } else {
        this.log.log('evaluateBoolean_' + name, logic + ' => ' + vv + ' (' + (vv === true) + ')')();
      }
      return readOnly;
    } catch (ex) {
      this.log.warn('evaluateBoolean_' + name, logic, ex)();
      return false;
    }
  } // evaluateBoolean

  /**
   * Evaluate value
   * @param name column name
   * @param logic readonlyLogic
   */
  evaluateValue(name: string, logic: string) {
    const original = this.record.valueMap ? this.record.valueMap : {};
    const changed = this.record.changeMap ? this.record.changeMap : {};
    const row = this.formGroup.value ? this.formGroup.value : {}; // current form context
    const env = this.conf.env ? this.conf.env : {};
    try {
      /* tslint:disable-next-line */
      const theValue = eval(logic);
      // this.log.log('evaluateValue_' + name, logic + ' => ' + theValue, theValue)();
      return theValue;
    } catch (ex) {
      this.log.warn('evaluateValue_' + name, logic, ex)();
      return undefined;
    }
  } // evaluateValue

  /**
   * File Uploaded
   */
  fileUploaded() {
    this.log.debug('fileUploaded', this.formGroup);
    this.formGroup.markAsDirty();
  }

  /**
   * @return r0-propertyName
   */
  id(propertyName: string): string {
    if (this.record.rowNo || this.record.rowNo === 0) {
      return 'r' + this.record.rowNo + '-' + propertyName;
    }
    return 'r' + '-' + propertyName;
  }

  /**
   * is the control with propertyName not valid
   */
  isError(propertyName: string): boolean {
    const fc = this.formGroup.controls[ propertyName ];
    return fc && !fc.valid;
  }

  /**
   * Column readOnly
   */
  isReadOnly(col: DataColumn): boolean {
    if (this.record.isReadOnly || this.record.isReadOnlyEval) {
      return true;
    }
    if (this.tableEditMode === 'n') {
      return true;
    }
    if (col.isReadOnly || col.isAutoIncrement) {
      return true;
    }
    if (col.readOnlyLogic) {
      return this.evaluateBoolean(col.name, col.readOnlyLogic);
    }
    return false;
  } // isReadOnly

  isTextarea(dataColumn: DataColumn) {
    return dataColumn
      && (dataColumn.length > 60
        || dataColumn.controlType === 'textarea'
        || dataColumn.dataType === DataType.CLOB);
  }

  /**
   * Focus shifted - called from element
   * = inform all elements
   */
  onFocus(propertyName: string) {
    this.log.log('onFocus ' + propertyName)();
    for (const fe of Object.values(this.focusElements)) {
      fe.onFocusChangedTo(propertyName);
    }
  }

  /**
   * Form Reset
   */
  onReset() {
    this.log.log('onReset')();
    this.onFocus('onReset');
    this.record.changeMap = Object.assign({}, this.initialChangeMap);
    this.record.sourceMap = null; // attachments
    this.setToRecord();
    //
    this.valid = this.formGroup.valid;
    this.changes = [];
    this.changeLabels = [];
    this.changed = false;

    if (this.statusUpdate) {
      this.statusUpdate.emit('Reset');
    }
    if (this.statusChange) {
      this.statusChange.emit(this);
    }
  } // reset

  /**
   * Save Form
   */
  onSave() {
    // const value = this.formGroup.value;
    this.log.info('onSave valid=' + this.formGroup.valid, 'changes=' + this.changes, this.record)();
    this.onFocus('onSave');
    if (this.changes) { // busy handled by RecordHome
      this.saveRecord.emit(this.record); // changeMap -> RecordHome.saveRecord()
    } else {
      this.statusUpdate.emit('Nothing changed');
    }
  } // save

  onTextareaBlur(event: Event) {
    const ta = event.target as HTMLTextAreaElement; // 2x7
    ta.style.height = '2rem';
    ta.style.minHeight = null;
    ta.style.minWidth = null;
    if (this.isTable()) {
      const ec = ta.parentElement; // element__control
      const fe = ec.parentElement; // form-element
      const div = fe.parentElement;
      div.style.maxWidth = '7rem';
    }
  }

  onTextareaFocus(event: Event) {
    const ta = event.target as HTMLTextAreaElement; // 7x15
    ta.style.height = null;
    ta.style.minHeight = '7rem';
    ta.style.minWidth = '15rem';
    if (this.isTable()) {
      const ec = ta.parentElement; // element__control
      const fe = ec.parentElement; // form-element
      const div = fe.parentElement;
      div.style.maxWidth = null;
    }
  }

  pickOptions(propertyName: string, col: DataColumn): DataPickOption[] {
    const retValue: DataPickOption[] = [];
    if (col) {
      if (!col.isUiRequired) {
        retValue.push(new DataPickOption());
      }
      for (const oo of col.pickOptionList) {
        retValue.push(oo);
      }
    }
    return retValue;
  } // pickOptions

  /**
   * Register Focus element - called multiple times
   * @return true if first for focus
   */
  registerFocus(propertyName: string, formFocus: FormFocus): boolean {
    // this.log.info('registerFocus', propertyName)();
    if (!this.focusElementName) {
      this.focusElementName = propertyName;
    }
    this.focusElements[ propertyName ] = formFocus;
    return this.focusElementName === propertyName;
  }

  render(propertyName: string, col: DataColumn): string {
    // this.log.debug('render', value, col)();
    return col.dataType + ': ' + this.record.value(propertyName);
  }

  renderBool(propertyName: string) {
    return Trl.formatBoolean(this.record.value(propertyName));
  }

  renderDate(propertyName: string) {
    return Trl.formatDate(this.record.value(propertyName));
  }

  renderHours(propertyName: string) {
    const value = this.record.value(propertyName);
    if (value) {
      return Trl.formatNumber(value, 2);
    }
    return '';
  }

  renderInt(propertyName: string) {
    return Trl.formatInt(this.record.value(propertyName));
  }

  renderNumber(propertyName: string, decimals: number = 2) {
    return Trl.formatNumber(this.record.value(propertyName), decimals);
  }

  renderPick(propertyName: string, col: DataColumn): string {
    const value = this.record.value(propertyName);
    if (value) {
      if (col.pickOptionList) {
        for (const oo of col.pickOptionList) {
          if (value === oo.name) {
            return oo.label;
          }
        }
      } else {
        return col.dataType + ': ' + value;
      }
    }
    return '';
  } // renderPick

  renderTime(propertyName: string): string {
    return Trl.formatTime(this.record.value(propertyName));
  }

  renderTimestamp(propertyName: string): string {
    return Trl.formatTimestamp(this.record.value(propertyName));
  }

  /**
   * Set Record Value
   * @param record record change/original as is
   */
  setRecord(record: DataRecord) {
    this.initialChangeMap = Object.assign({}, record.changeMap);
    this.record = record;
    this.setToRecord();
  }

  /**
   * Set Values (patch) -- are reset on Reset
   * @param values values to be patched
   */
  setValues(values: { [ key: string ]: any; }) {
    Object.keys(values)
      .forEach((key: string) => {
        const ctrl = this.formGroup.controls[ key ];
        if (ctrl) {
          ctrl.setValue(values[ key ], {
            onlySelf: false, // update formGroup
            emitValue: false
          });
          ctrl.markAsDirty();
          ctrl.markAsTouched();
        }
      });
  } // setValues

  /**
   * Get Field Value
   */
  value(propertyName: string): string {
    return this.record.value(propertyName);
  }

  /**
   * Create Form Group if there is a form section
   */
  private createFormGroup() {
    // create FormGroup
    const group: { [ key: string ]: AbstractControl } = {};
    if (this.isTable()) {
      for (const gf of this.ui.gridFieldList) {
        const propertyName = gf.name;
        group[ propertyName ] = ModelUtil.createControl(gf.name, gf.dataColumn, this.record);
        if (gf.dataColumn.isAutoIncrement && gf.dataColumn.columnLogic) {
          this.calculatedColumns[ gf.name ] = gf.dataColumn;
        }
      }
    } else if (this.ui.formSectionList && this.ui.formSectionList.length > 0) {
      for (const section of this.ui.formSectionList) {
        for (const ff of section.uiFormFieldList) {
          const propertyName = ff.name;
          if (ff.dataColumn) { // optional for empty column
            group[ propertyName ] = ModelUtil.createControl(ff.name, ff.dataColumn, this.record, ff.isRequired());
            if (ff.dataColumn.isAutoIncrement && ff.dataColumn.columnLogic) {
              this.calculatedColumns[ ff.name ] = ff.dataColumn;
            }
            // this.log.debug('createFormGroup', propertyName, ff.isRequired(), group[ propertyName ])();
          }
        }
      }
    }
    this.formGroup = this.fb.group(group);

    // Status
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
    this.statusSubscription = this.formGroup.statusChanges
      .subscribe((value) => {
        this.valid = value === 'VALID';
        this.errors = [];
        if (this.handleChange) {
          if (!this.valid) {
            Object.keys(this.formGroup.controls)
              .forEach((key: string) => {
                const control: AbstractControl = this.formGroup.controls[ key ];
                if (control.errors) {
                  this.log.log('StatusChanges', 'error key=' + key, control.errors)();
                  this.errors.push(key + ': ' + Object.keys(control.errors));
                }
              });
          }
          this.log.debug('StatusChanges', 'valid=' + this.valid, this.errors)();
        }
      });

    // Changes
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
    this.changeSubscription = this.formGroup.valueChanges
      .subscribe((newValues) => {
        // this.log.debug('ValueChanges', newValues, this.record)();

        if (this.handleChange) { // not when setToRecord...
          this.setChange(newValues);
          this.log.debug('ValueChanges', this.changes, this.record)();
          if (this.statusChange) {
            this.statusChange.emit(this);
          }
          /*
          if (changes) {
            this.statusUpdate.emit('Changed: ' + changes);
            this.recordChanged.emit(this.record); // updates record + footer
          } else {
            this.statusUpdate.emit(''); // nothing changed
          } */
        }
      });
  } // createFormGroup

  /**
   * In Table/Grid
   */
  private isTable(): boolean {
    return this.tableEditMode === 'y' || this.tableEditMode === 'n';
  }

  /**
   * Check if data is changed and reset dependent values
   * - called from formGroup.ValueChanges subscription
   * @param formValues map with new form values
   */
  private setChange(formValues: { [ key: string ]: any }) {
    if (this.handleChange) {
      this.handleChange = false;
      this.log.info('setChange', formValues)(); // map

      this.changes = []; // compared to original value
      this.changeLabels = [];
      const deltas: string[] = [];
      for (const key of Object.keys(formValues)) {
        const formValue = formValues[ key ];
        const formValueC = formValue === null || formValue === undefined ? '' : formValue;
        const changedValue = this.record.changeMap[ key ];
        const originalValue = this.record.valueMap[ key ];
        const originalValueC = originalValue === undefined || originalValue === null ? '' : originalValue;
        const formControl = this.formGroup.controls[ key ];
        const col = this.ui.dataTable.columnListMap[ key.toLowerCase() ];
        const label = col ? col.label : key; // attachments are not in columnList

        if (!AUtil.isSame(formValueC, originalValueC)) { // undefined/null/empty
          this.log.debug('setChange - ' + key + '=' + formValue, 'valid=' + formControl.valid,
            'oldValue=' + originalValue, 'oldChange=' + changedValue)();
          this.changes.push(key);
          this.changeLabels.push(label);
          deltas.push(key);
          if (formControl.valid) {
            this.record.changeMap[ key ] = formValue === undefined ? null : formValue; // update record
          }
        } else if (changedValue !== undefined) { // null could be override
          deltas.push(key);
          if (formControl.valid) {
            delete this.record.changeMap[ key ]; // original value
          }
        }
      }

      for (const delta of deltas) { // keys
        this.setChangeDependents(delta);
      }
      // update calculated values
      for (const propertyName of Object.keys(this.calculatedColumns)) {
        // this.log.debug('setChange updateCalculated ' + key)();
        this.setToRecordValueCalc(propertyName, this.calculatedColumns[ propertyName ]);
      }
      // this.log.debug('setChange', this.changes)();
      this.changed = this.changes.length > 0;
      this.handleChange = true;
      this.lastChangeMap = Object.assign({}, this.record.changeMap);
    }
  } // setChange

  /**
   * Update Dependents
   */
  private setChangeDependents(changedProperty: string) {
    const deps: string[] = ModelUtil.dependentProperties(changedProperty, this.ui.dataTable);
    if (deps.length > 0) {
      const previousValue = this.lastChangeMap[ changedProperty ];
      const changedValue = this.record.changeMap[ changedProperty ];
      this.log.debug('setChangeDependents ' + changedProperty,
        'from=' + previousValue, 'to=' + changedValue,
        'isSame=' + AUtil.isSame(previousValue, changedValue))();

      if (!AUtil.isSame(previousValue, changedValue)) { // undefined/null/empty
        for (const dep of deps) {
          const formControl = this.formGroup.controls[ dep ];
          if (formControl) {
            const formValue = formControl.value;
            this.log.debug('setChangeDependents ' + changedProperty,
              '- dep=' + dep, 'current=' + formValue)();
            formControl.patchValue(null, {});
            //
            this.record.changeMap[ dep ] = null; // update record
            this.changes.push(dep);
            const col = this.ui.dataTable.columnListMap[ dep.toLowerCase() ];
            this.changeLabels.push(col ? col.label : dep); // attachment not in columnList
          }
        } // dependent
      } // changed
    } // dependents
  } // setChangeDependents

  /**
   * Set Form to record
   * - called from constructor, reset
   */
  private setToRecord() {
    this.handleChange = false;
    this.log.debug('setToRecord', this.record, this.initialChangeMap, this.formGroup.controls)();

    if (this.isTable()) {
      for (const gf of this.ui.gridFieldList) {
        if (gf.name) {
          if (gf.dataColumn && gf.dataColumn.isAutoIncrement) {
            this.setToRecordValueCalc(gf.name, gf.dataColumn);
          } else {
            this.setToRecordValue(gf.name);
          }
        }
      } // field
    } else {
      // const table = this.ui.dataTable;
      for (const section of this.ui.formSectionList) {
        for (const ff of section.uiFormFieldList) {
          if (ff.name) {
            if (ff.dataColumn && ff.dataColumn.isAutoIncrement) {
              this.setToRecordValueCalc(ff.name, ff.dataColumn);
            } else {
              this.setToRecordValue(ff.name);
            }
          }
        } // field
      } // section
    }
    this.handleChange = true;
    this.lastChangeMap = Object.assign({}, this.record.changeMap);
  } // setToRecord

  /**
   * Set Form Field
   * @param propertyName key
   */
  private setToRecordValue(propertyName: string) {
    const changedValue = this.record.changeMap[ propertyName ];
    const originalValue = this.record.valueMap[ propertyName ];
    const value = changedValue !== undefined ? changedValue : originalValue; // null could be override
    const initialValue = this.initialChangeMap[ propertyName ];

    const ctrl = this.formGroup.controls[ propertyName ];
    if (ctrl) {
      ctrl.setValue(value, {
        onlySelf: false, // update formGroup
        emitValue: false
      });
      if (changedValue === initialValue) {
        ctrl.markAsPristine();
        ctrl.markAsUntouched();
        //  this.log.debug('setToRecordValue', propertyName + '=' + value, 'original=' + originalValue, 'initial=' + initialValue)();
      } else {
        ctrl.markAsDirty();
        ctrl.markAsTouched();
        //  this.log.debug('setToRecordValue', propertyName + '=' + value, 'changed=' + changedValue, 'initial=' + initialValue)();
      }
    } else {
      this.log.debug('setToRecordValue', propertyName + '=' + value, 'NoCtrl')();
    }
  } // setToRecordValue

  /**
   * Set Calculated Value
   * @param propertyName property name
   * @param dataColumn data column
   */
  private setToRecordValueCalc(propertyName: string, dataColumn: DataColumn) {
    const changedValue = this.record.changeMap[ propertyName ];
    const originalValue = this.record.valueMap[ propertyName ];
    const value = changedValue !== undefined ? changedValue : originalValue; // null could be override
    if (dataColumn.columnLogic) {
      const vv = this.evaluateValue(dataColumn.name, dataColumn.columnLogic);
      if (vv) {
        this.record.changeMap[ propertyName ] = String(vv);
      } else {
        this.record.changeMap[ propertyName ] = '0';
      }
    }
    const ctrl = this.formGroup.controls[ propertyName ];
    if (ctrl) {
      ctrl.setValue(value, {
        onlySelf: false, // update formGroup
        emitValue: false
      });
    }
  } // setToRecordValueCalc

} // FormManager
