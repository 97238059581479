import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataColumn } from '../model/data-column';
import { Logger } from '../log/logger';
import { UiFormField } from '../model/ui-form-field';
import { UiGridField } from '../model/ui-grid-field';
import { FormManager } from '../form/form-manager';
import { FormFocus } from '../form/form-focus';

@Component({
  selector: 'acc-form-hours',
  templateUrl: './form-hours.component.html',
  styleUrls: [ './form-hours.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class FormHoursComponent implements OnChanges, OnDestroy, FormFocus {

  /** Grid Field */
  @Input() gf: UiGridField;
  /** Form Field */
  @Input() ff: UiFormField;
  /** Form Manager */
  @Input() fm: FormManager;
  /** Base Id */
  @Input() theId: string;
  /** ReadOnly Overwrite */
  @Input() readonly: boolean;

  /** Property Name */
  propertyName: string;
  /** Field Label */
  label: string;
  /** Data Column */
  dataColumn: DataColumn;

  /** The Hours Value */
  value: number;

  /** Form Element Control */
  control: FormControl;

  @ViewChild('inp', { static: true })
  inputElement: ElementRef;

  errorMessage: string;

  private subscription: Subscription;
  private log: Logger = new Logger('FormHours');

  constructor() {
  }

  get valid(): boolean {
    if (this.errorMessage) {
      return false;
    }
    if (this.control) {
      // this.log.log('valid ' + this.control.valid, this.control.errors)();
      if (this.control.valid) {
        return true;
      } else {
        return this.control.pristine; // true if untouched
      }
    }
    return false;
  }

  get inputValue(): string {
    if (this.inputElement) {
      return this.inputElement.nativeElement.value;
    }
    return '';
  }

  /**
   * Required
   */
  get isUiRequired(): boolean {
    if (this.ff) {
      return this.ff.isRequired();
    }
    if (this.gf && this.gf.dataColumn) {
      return this.gf.dataColumn.isUiRequired;
    }
    return false;
  }

  get isReadOnly(): boolean {
    if (this.readonly) {
      return true;
    }
    if (this.gf && this.gf.dataColumn && this.fm.isReadOnly(this.gf.dataColumn)) {
      return true;
    }
    return false;
  }

  /**
   * Initialize
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.ff) {
      this.propertyName = this.ff.name;
      this.label = this.ff.label;
      this.dataColumn = this.ff.dataColumn;
    } else if (this.gf) {
      this.propertyName = this.gf.name;
      this.label = this.gf.label;
      this.dataColumn = this.gf.dataColumn;
    }
    if (!this.control) { // setup
      this.log.setSubName(this.propertyName);
      if (this.fm.registerFocus(this.propertyName, this)) {
        setTimeout(() => {
          if (this.inputElement) {
            this.log.debug('ngOnChanges.focus')();
            this.inputElement.nativeElement.focus();
          }
        }, 500);
      }
      this.control = this.fm.formGroup.controls[ this.propertyName ] as FormControl;
      this.subscription = this.control.valueChanges.subscribe((value) => {
        this.log.debug('ValueChanges error=' + this.errorMessage,
          (this.control.dirty ? 'dirty ' : '') + (this.control.valid ? '' : 'NotValid ') + 'value=' + value)();
        this.displayValue(value);
      });
    }
    if (this.control) {
      const value = this.control.value;
      // this.log.debug('ngOnChanges', changes,
      //   (this.control.dirty ? 'dirty ' : '') + (this.control.valid ? '' : 'NotValid ') + 'value=' + value)();
      this.displayValue(this.control.value);
    } else {
      this.log.info('ngOnChanges NoControl ' + this.propertyName, this.fm.formGroup)();
    }
  } // ngOnChanges

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Blur - set/display value
   */
  onBlur(event: Event) {
    this.log.debug('onBlur', this.value)();
    // this.setValue(Str this.value);
    if (this.value) {
      this.control.setValue(String(this.value.toFixed(2))); // ***
      this.control.markAsDirty();
    } else {
      this.control.setValue(undefined);
      this.control.markAsDirty();
    }
    this.errorMessage = undefined;
  }

  onFocus() {
    this.fm.onFocus(this.propertyName);
  }

  onFocusChangedTo(propertyName: string) {
  }

  /**
   * KeyUp - parse + set value
   */
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Clear') {
      this.displayValue(undefined);
      this.errorMessage = undefined;
    } else {
      this.value = this.parse(this.inputValue);
      this.log.debug('onKeyUp ' + event.key, this.inputValue, this.value)();
      if (isNaN(this.value)) {
        this.errorMessage = 'invalid';
        this.value = undefined;
      } else {
        this.errorMessage = undefined;
      }
    }
  } // onKeyUp

  /**
   * Parse Hours
   * Format options:
   * (a) hh.ff - decimal hours - 1.5
   * -- if hh > 15 - in minutes - 90
   * (b) hh:mm - hours with minutes - 1:30
   * (c) hh,ff - decimal comma - 1,5
   * (d) (d)ay (h)ours (m)minutes - 1d1h1m
   *
   * @param inpValue string input
   * @returns number (hours) or NaN (error) or null (no input)
   */
  parse(inpValue: string): number {
    if (inpValue == null || inpValue === '') {
      return null;
    }
    // (a) hh.ff - hours with fraction or minutes (>= 15)
    let hours: number = Number(inpValue);
    if (!isNaN(hours)) { // valid
      if (hours >= 15 && inpValue.indexOf('.') === -1) {
        return hours / 60; // minutes
      }
      return hours;
    }

    // (b) hh:mm - hours with minutes
    let index: number = inpValue.indexOf(':');
    if (index >= 0) {
      hours = 0;
      if (index > 0) {
        const hoursString: string = inpValue.substring(0, index);
        hours = Number(hoursString);
        if (isNaN(hours)) {
          return hours; // invalid
        }
      }
      const minString: string = inpValue.substring(index + 1);
      if (minString.length > 0) {
        const min: number = Number(minString);
        if (isNaN(min)) {
          return min; // invalid
        }
        hours += (min / 60);
      }
      return hours;
    }

    // (c) hh,ff (decimal comma)
    index = inpValue.indexOf(',');
    if (index >= 0) {
      const pointString: string = inpValue.replace(',', '.');
      hours = Number(pointString);
      return hours; // might be invalid
    }

    // (d) (d)ay (h)ours (m)minutes
    hours = 0;
    let remainingString: string = inpValue;
    let hasDHM: boolean = false;
    index = remainingString.indexOf('d');
    if (index !== -1) {
      const valueString: string = remainingString.substring(0, index);
      if (valueString.length > 0) {
        const valueNum: number = Number(valueString);
        if (isNaN(valueNum)) {
          return valueNum; // invalid
        }
        hours = valueNum * 8;
        hasDHM = true;
      }
      remainingString = remainingString.substring(index + 1);
      // this.log.debug('parse d', hours, remainingString)();
    }
    index = remainingString.indexOf('h');
    if (index !== -1) {
      const valueString: string = remainingString.substring(0, index);
      if (valueString.length > 0) {
        const valueNum: number = Number(valueString);
        if (isNaN(valueNum)) {
          return valueNum; // invalid
        }
        hours += valueNum;
        hasDHM = true;
      }
      remainingString = remainingString.substring(index + 1);
      // this.log.debug('parse h', hours, remainingString)();
    }
    index = remainingString.indexOf('m');
    if (index !== -1) {
      const valueString: string = remainingString.substring(0, index);
      if (valueString.length > 0) {
        const valueNum: number = Number(valueString);
        if (isNaN(valueNum)) {
          return valueNum; // invalid
        }
        hours += valueNum / 60;
        hasDHM = true;
      }
      remainingString = remainingString.substring(index + 1);
      // this.log.debug('parse m', hours, remainingString)();
    } else if (remainingString.length > 0) { // minutes directly
      const valueNum: number = Number(remainingString);
      if (isNaN(valueNum)) {
        return valueNum; // invalid
      }
      hours += valueNum / 60;
      hasDHM = true;
    }
    if (hasDHM) {
      return hours;
    }
    return NaN; // cannot parse
  } // parse

  /**
   * update display
   */
  private displayValue(numberValue: string) {
    if (numberValue && numberValue.length > 0) {
      this.value = Number(numberValue);
      if (Number.isNaN(this.value)) {
        this.value = undefined;
      }
      if (this.value) {
        this.inputElement.nativeElement.value = String(this.value);
        return;
      }
    }
    // none
    this.value = 0;
    this.inputElement.nativeElement.value = '';
  }  // displayValue

} // FormHoursComponent
