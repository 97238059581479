import { ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as i0 from "@angular/core";
/**
 * Global Error Handler
 */
export class LogService {
    /**
     * Global Error Handler
     */
    constructor(injector) {
        this.injector = injector;
    }
    /**
     * Global ErrorHandler call
     *
     * console.info('--------');
     * let xx: string = undefined;
     * const dd: number = xx.length; // TypeError
     * console.info('--------');
     *
     * throw new Error('TheError');
     * @param error error
     */
    handleError(error) {
        const theMsg = this.handle(error);
        console.error('LogService.handleError', theMsg);
    } // handleError
    /**
     * Handle Error
     * @param error error
     * @return string info
     */
    handle(error) {
        // console.error('LogService.handle', error); // stringify(error));
        const message = error.message ? error.message : error.toString();
        if (error instanceof HttpErrorResponse) {
            const err = error;
            /*
            err.name;
            err.message;
            err.error;
            err.status;
            */
        }
        else if (error instanceof TypeError) {
            const err = error;
            return 'name=' + err.name + ' \nmsg=' + err.message + ' \nstack=' + err.stack;
        }
        else if (error instanceof Error) {
            const err = error;
            return 'name=' + err.name + ' \nmsg=' + err.message + ' \nstack=' + err.stack;
        }
        else {
        }
        return message;
    } // handleError
    send(error) {
        // https://medium.com/@aleixsuau/error-handling-angular-859d529fa53a
        //  const notificationService = this.injector.get(NotificationService);
        //  if (navigator.onLine) {
        //  }
    }
} // LogService
/** @nocollapse */ LogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogService_Factory() { return new LogService(i0.ɵɵinject(i0.INJECTOR)); }, token: LogService, providedIn: "root" });
