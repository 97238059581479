import {
  AfterContentChecked,
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

import {
  AccortoService,
  appStatus,
  CResponseData,
  DataRecordF,
  DataRecordI,
  DataType,
  ModelUtil,
  NotificationService,
  UiTab
} from 'accorto';
import { projectAllocationsLoadResultAction, projectLinesLoadResultAction, projectSelectedUpdateAction, } from '../project/project.actions';
import { AppState } from '../reducers';
import { ProjectService } from '../project/project.service';
import { selectCurrentProjectAllocations, selectCurrentProjectLines, selectCurrentProjectPhases, } from '../project/project.selectors';
import { ProjectAllocation } from './project-allocation';
import { resourceLoadAllRequestAction } from '../resource/resource.actions';
import { PsaBase } from '../psa-base';
import { ResourceSearchRestrictions } from '../resource-search/resource-search-restrictions';

/**
 * https://support.accorto.com/support/solutions/articles/1000283138-project-assignments-and-allocations
 * performance:
 * = TestX50  - size=2940   ms=17,629 - resourceList=10,538 - individual= 4,302
 * = TestX170 - size=10140 ms=113,308 - resourceList=87,308 - individual=10,554
 */
@Component({
  selector: 'psa-project-allocation',
  templateUrl: './project-allocation.component.html',
  styleUrls: [ './project-allocation.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProjectAllocationComponent
  extends PsaBase implements OnInit, OnDestroy, AfterContentChecked, AfterViewChecked {

  /** Resource Tab */
  isResourceTabActive: boolean = true;
  /** tab by PL */
  showLinesByResource: boolean = false;
  /** tab by Resource (rotate) */
  showResourceByLines: boolean = false;

  // Project Ui
  projectUi: UiTab = new UiTab();
  /** Project Lines - dimension */
  projectLineAList: ProjectAllocation[] = [];
  projectLineAList1: ProjectAllocation[] = [];
  projectLineAList2: ProjectAllocation[] = [];
  projectLineAList3: ProjectAllocation[] = [];
  projectLineAList4: ProjectAllocation[] = [];
  /** Resource Lines - dimension - */
  resourceList: DataRecordI[] = [];
  resourceList1: DataRecordI[] = [];
  resourceList2: DataRecordI[] = [];
  resourceList3: DataRecordI[] = [];
  resourceList4: DataRecordI[] = [];

  /** Project Level */
  formGroupProject: FormGroup = new FormGroup({
    isShareAllLines: new FormControl() // , managerId: new FormControl()
  });

  /** projectLineId: resourceId */
  formGroupResource: FormGroup = new FormGroup({});
  formGroupResourceSub: Subscription;
  /** projectLineId_resourceId: value */
  formGroupAllocation: FormGroup = new FormGroup({});
  formGroupAllocationSub: Subscription;

  projectLineAllocations: DataRecordI[] = [];
  projectLineAllocationsMap: { [ key: string ]: DataRecordI } = {};

  isSaveDisabled: boolean = true;

  /** by Line or Resource (rotate) */
  private tableByProjectLine: boolean = true;
  private startPaint: number;
  private busyResetLayout: boolean = false;

  /**
   * Project Allocation
   */
  constructor(route: ActivatedRoute,
              router: Router,
              store: Store<AppState>,
              private projectService: ProjectService,
              private notificationService: NotificationService,
              conf: AccortoService) {
    super('ProjectAllocation', 'P', '/project-alloc', 'Project Allocation',
      route, router, store, conf);
    // this.startTime = Date.now();
    // console.debug('ProjectAllocation <>');
    // this.store.dispatch(uiRequestAction({ uiName: 'Project' }));
    // this.store.dispatch(uiRequestAction({ uiName: 'ProjectLine' }));

    // layout
    let col = ModelUtil.createColumn('Manager', 'Manager', DataType.STRING);
    ModelUtil.addFormField(this.projectUi, col);
    col = ModelUtil.createColumn('IsShareAllLines', 'Share All Lines', DataType.BOOLEAN);
    ModelUtil.addFormField(this.projectUi, col);
  } // constructor

  /** background of allocation */
  background(projectLineId: string, resourceId: string): string {
    /** this.formGroupAllocation: projectLineId_resourceId: value */
    if (resourceId) {
      const acR = this.formGroupAllocation.controls[ resourceId ];
      if (acR && acR.value) {
        return 'lightblue';
      }
    }
    if (projectLineId) {
      const acPL = this.formGroupAllocation.controls[ projectLineId ];
      if (acPL && acPL.value) {
        return 'limegreen';
      }
    }
    if (projectLineId && resourceId) { // center values
      const acP = this.formGroupProject.controls.isShareAllLines;
      if (acP.value) {
        return 'lightgreen';
      }
    }
    return 'white';
  } // background

  /**
   * ReQuery
   */
  doRefresh() {
    this.log.debug('doRefresh', (this.project ? this.project.id : ''))();
    if (this.project != null && this.project.id) {
      this.loadProject(this.project.id, true);
    }
  } // doRefresh

  /**
   * Content paint starting
   */
  public ngAfterContentChecked(): void {
    //  this.log.debug('tableLayout ngAfterContentChecked')();
    this.startPaint = Date.now();
  } // ngAfterContentChecked

  /**
   * Content paint finished
   * - show time + reset busy
   */
  public ngAfterViewChecked(): void {
    const paintMs = Date.now() - this.startPaint;
    if (paintMs > 500 || this.busyResetLayout) {
      this.log.debug('tableLayout ngAfterViewChecked busyReset=' + this.busyResetLayout,
        'ms=' + paintMs)();
    }
    if (this.busyResetLayout) {
      setTimeout(() => {
        this.busy = false; // otherwise causes: Expression has changed after it was checked
      }, 50);
      this.busyReset = true;
      this.busyResetLayout = false;
    }
  } // ngAfterContentChecked

  public ngOnDestroy(): void {
    super.ngDestroy();
    if (this.formGroupResourceSub) {
      this.formGroupResourceSub.unsubscribe();
      this.formGroupResourceSub = undefined;
    }
    if (this.formGroupAllocationSub) {
      this.formGroupAllocationSub.unsubscribe();
      this.formGroupAllocationSub = undefined;
    }
  } // ngOnDestroy

  ngOnInit(): void {
    // console.debug('ProjectAllocation ngInit = ' + (Date.now() - this.startTime) + ' =');
    // this.startTime = Date.now();
    super.ngInit(); // projectAll currentProject resourceMap resourceAll currentResource

    // lines of selected project
    this.busyPlus('pl');
    this.subscriptions.push(this.store.pipe(select(selectCurrentProjectLines))
      .subscribe((projectLines) => {
        // this.log.debug('selectCurrentProjectLines', projectLines)();
        this.setProjectLines(projectLines); // updateNodes + layout
        this.busyMinus('pl');
      }));

    // phases of selected project
    this.busyPlus('pp');
    this.subscriptions.push(this.store.pipe(select(selectCurrentProjectPhases))
      .subscribe((projectPhases) => {
        // this.log.debug('selectCurrentProjectPhases', projectPhases)();
        this.busyMinus('pp');
      }));

    // allocations of selected project
    this.busyPlus('pa');
    this.subscriptions.push(this.store.pipe(select(selectCurrentProjectAllocations))
      .subscribe((projectAllocations) => {
        // this.log.debug('selectCurrentProjectAllocations', projectAllocations)();
        this.setProjectAllocations(projectAllocations);
        this.busyMinus('pa');
      }));

    // user entry
    this.subscriptions.push(this.formGroupProject.valueChanges.subscribe((chg) => {
      // console.log('formGroupProject.valueChanges', chg);
      this.message = this.getSummary();
    }));
    this.store.dispatch(appStatus({ status: 'project-allocation' }));
  } // ngOnInit

  /**
   * Select Project
   * @param project project to load [close(true)=undefined clear(false)=null]
   */
  onProjectSelected(project: DataRecordI) {
    super.onProjectSelected(project, false);
  }

  /**
   * Set All values from source
   */
  onReset() {
    this.log.debug('onReset',
      'projectLines=' + this.projectLineAList.length
      + ' resources=' + this.resourceRecords.length
      + ' (#' + (this.projectLineAList.length * this.resourceRecords.length) + ')',
      'allocations=' + this.projectLineAllocations.length)();
    // console.debug('ProjectAllocation onReset = ' + (Date.now() - this.startTime) + ' ---');
    // this.log.debug('onReset', this.project, this.projectLineAList, this.resourceList, this.projectLineAllocations)();

    if (this.project) {
      const isShareAllLines = DataRecordF.valueBoolean(this.project, 'isShareAllLines');
      const managerId = DataRecordF.value(this.project, 'resourceSfId');
      if (this.formGroupProject) { // not initialized yet
        this.formGroupProject.setValue({
          isShareAllLines // managerId
        });
      }
    }

    const resourceValues = {};
    const allocationValues = {};
    // project lines
    this.projectLineAList.forEach((pla) => {
      const projectLineId = pla.record.id;
      const resourceId = DataRecordF.value(pla.record, 'resourceSfId');
      /** projectLineId: resourceId */
      resourceValues[ projectLineId ] = resourceId;

      const isShareAll = DataRecordF.valueBoolean(pla.record, 'isShareAll');
      /** projectLineId_resourceId: value */
      allocationValues[ projectLineId ] = isShareAll;

      // reset all
      this.resourceRecords.forEach((res) => {
        allocationValues[ pla.projectLineId + '_' + res.id ] = false;
      });
    });
    // resource isAccess
    this.resourceRecords.forEach((res) => {
      const isAccessAllProjectLines = DataRecordF.valueBoolean(res, 'isAccessAllProjectLines');
      allocationValues[ res.id ] = isAccessAllProjectLines;
    });
    // set allocation lines
    this.projectLineAllocations.forEach((alloc) => {
      const projectLineId = DataRecordF.value(alloc, 'projectLineSfId');
      const resourceId = DataRecordF.value(alloc, 'resourceSfId');
      const isActive = DataRecordF.valueBoolean(alloc, 'isActive');
      if (projectLineId && resourceId) {
        allocationValues[ projectLineId + '_' + resourceId ] = isActive;
      }
    });

    /** projectLineId: resourceId */
    if (this.formGroupResource) { // not initialized yet
      this.formGroupResource.patchValue(resourceValues);
    }
    /** projectLineId_resourceId: value */
    if (this.formGroupAllocation) { // not initialized yet
      this.formGroupAllocation.patchValue(allocationValues);
    }
    //
    this.message = this.getSummary();
    this.tableLayout('reset');
  } // onReset

  /**
   * Restrict Resource Records
   * @param restrict restriction
   */
  onResourceRestriction(restrict: ResourceSearchRestrictions) {
    this.resourceRecords.forEach((res: DataRecordI) => {
      res.isSelected = restrict.matches(res);
    });
    this.tableLayout('resource');
  } // onResourceRestriction

  /**
   * On Save
   */
  onSave() {
    this.log.info('onSave')();

    if (!this.project) {
      return '';
    }
    // project
    const isShareAllLines = DataRecordF.valueBoolean(this.project, 'isShareAllLines');
    // const managerId = DataRecordF.valueNumber(this.project, ('resourceSfId');
    const isShareAllLinesNew = this.formGroupProject.controls.isShareAllLines.value;
    if (!this.isSameBool(isShareAllLines, isShareAllLinesNew)) {
      DataRecordF.setValue(this.project, 'isShareAllLines', isShareAllLinesNew ? 'true' : 'false');
      this.busyPlus('save pj');
      // send to server
      this.projectService.save(this.project)
        .subscribe((response: CResponseData) => {
          this.notificationService.addResponse('Update Project', response);
          this.log.debug('onSave.project', response.records)();
          if (response.records && response.records.length > 0) {
            this.store.dispatch(projectSelectedUpdateAction({ project: response.records[ 0 ] })); // updates all projects
          }
          this.busyMinus('save pj');
        });
    }

    // project lines
    const projectLines: DataRecordI[] = [];
    const allocations: DataRecordI[] = [];
    this.projectLineAList.forEach((pl: ProjectAllocation) => {
      let projectLine: DataRecordI;
      // projectLineId: resourceId -- formGroupResource
      const acR: AbstractControl = this.formGroupResource.controls[ pl.projectLineId ];
      if (acR) {
        const resourceId = DataRecordF.value(pl.record, 'resourceSfId');
        const resourceIdNew = acR.value;
        if (resourceId !== resourceIdNew) {
          projectLine = pl.record;
          DataRecordF.setValue(projectLine, 'resourceSfId', resourceIdNew);
        }
      }
      // projectLineId_resourceId: value -- formGroupAllocation
      const acA: AbstractControl = this.formGroupAllocation.controls[ pl.projectLineId ];
      if (acA) {
        const isShareAll = DataRecordF.valueBoolean(pl.record, 'isShareAll');
        const isShareAllNew = acA.value;
        if (!this.isSameBool(isShareAll, isShareAllNew)) {
          if (!projectLine) {
            projectLine = pl.record;
          }
          DataRecordF.setValue(projectLine, 'isShareAll', isShareAllNew ? 'true' : 'false');
        }
      }
      if (projectLine) {
        projectLines.push(projectLine);
      }

      // projectLineId_resourceId: value -- formGroupAllocation
      this.resourceRecords.forEach((res: DataRecordI) => {
        const key = pl.projectLineId + '_' + res.id;
        const acAlloc: AbstractControl = this.formGroupAllocation.controls[ key ];
        if (acAlloc) {
          const isActiveNew = acAlloc.value;
          const alloc = this.projectLineAllocationsMap[ key ];
          if (alloc) {
            const isActive = DataRecordF.valueBoolean(alloc, 'isActive');
            if (!this.isSameBool(isActive, isActiveNew)) {
              DataRecordF.setValue(alloc, 'isActive', isActiveNew ? 'true' : 'false');
              allocations.push(alloc);
            }
          } else if (isActiveNew === true) {
            const newAlloc: DataRecordI = {
              recordType: 'ProjectLineSharing'
            };
            DataRecordF.setValue(newAlloc, 'projectLineSfId', pl.projectLineId);
            DataRecordF.setValue(newAlloc, 'resourceSfId', res.id);
            DataRecordF.setValue(newAlloc, 'isActive', isActiveNew ? 'true' : 'false');
            allocations.push(newAlloc);
          }
        }
      }); // projectLine
    });
    if (projectLines.length > 0) {
      this.busyPlus('update pl');
      // send to server
      this.projectService.saveList(projectLines)
        .subscribe((response: CResponseData) => {
          this.notificationService.addResponse('Update Project Lines', response);
          this.projectService.loadProjectLines(this.project.id) // reload
            .subscribe((projectLines2: DataRecordI[]) => {
              this.store.dispatch(projectLinesLoadResultAction({ projectLines: projectLines2 }));
            });
          this.busyMinus('update pl');
        });
    }
    // allocations
    const allocationsDelete: DataRecordI[] = [];
    this.projectLineAllocations.forEach((alloc) => {
      const projectLineId = DataRecordF.value(alloc, 'projectLineSfId');
      const resourceId = DataRecordF.value(alloc, 'resourceSfId');
      if (!(projectLineId && resourceId)) {
        allocationsDelete.push(alloc);
      }
    });
    if (allocationsDelete.length > 0) {
      this.busyPlus('alloc del');
      // send to server
      this.projectService.deleteList(allocationsDelete)
        .subscribe((response: CResponseData) => {
          this.notificationService.addResponse('Delete Allocations', response);
          // delete from list ignored - refreshed below
          this.busyMinus('alloc del');
        });
    }
    if (allocations.length > 0) {
      this.busyPlus('alloc save');
      // send to server
      this.projectService.saveList(allocations)
        .subscribe((response: CResponseData) => {
          this.notificationService.addResponse('Save Allocations', response);
          this.projectService.loadProjectAllocations(this.project.id) // reload
            .subscribe((projectAllocations: DataRecordI[]) => {
              this.store.dispatch(projectAllocationsLoadResultAction({ projectAllocations }));
            });
          this.busyMinus('alloc save');
        });
    }

    // resources
    const resources: DataRecordI[] = [];
    this.resourceRecords.forEach((res: DataRecordI) => {
      const acR: AbstractControl = this.formGroupAllocation.controls[ res.id ];
      if (acR) {
        const isAccessAllProjectLines = DataRecordF.valueBoolean(res, 'isAccessAllProjectLines');
        const isAccessAllProjectLinesNew = acR.value;
        if (!this.isSameBool(isAccessAllProjectLines, isAccessAllProjectLinesNew)) {
          DataRecordF.setValue(res, 'isAccessAllProjectLines', isAccessAllProjectLinesNew ? 'true' : 'false');
          resources.push(res);
        }
      }
    });
    if (resources.length > 0) {
      this.busyPlus('res update');
      // send to server
      this.projectService.saveList(resources)
        .subscribe((response: CResponseData) => {
          this.notificationService.addResponse('Update Resources', response);
          this.log.debug('onSave.resource', response)();
          this.store.dispatch(resourceLoadAllRequestAction()); // reload all resources
          this.busyMinus('res update');
        });
    }
  } // onSave

  onTabClick(name: string) {
    this.isResourceTabActive = (name === 'Resource');
    this.tableLayout('tab');
  }

  onTableRotate() {
    // this.startTime = Date.now();
    this.tableByProjectLine = !this.tableByProjectLine;
    this.tableLayout('rotate');
  }

  /**
   * Source: projectLineAList
   * @see setProjectAllocations()
   * Source: resourceRecords filtered by r.isSelected
   * @see PsaBase.setResources()
   * @see onResourceRestriction()
   * after rendering
   * @see ngAfterViewChecked()
   */
  tableLayout(why: string) {
    this.resourceList = this.resourceRecords.filter((record) => {
      return record.isSelected;
    });
    const size = this.resourceList.length * this.projectLineAList.length;
    if (size > 2000) {
      this.busy = true;
      this.busyReset = false;
      this.busyResetLayout = false;
      this.notificationService.addInfo('Loading Delay',
        'Many Resources(' + this.resourceList.length
        + ') or Project lines(' + this.projectLineAList.length + ')',
        undefined, 5);
      setTimeout(() => {
        this.tableLayout2(why, size);
        this.busyReset = true;
        this.busyResetLayout = true;
      }, 100); // show busy
    } else {
      this.tableLayout2(why, size);
    }
  } // tableLayout
  tableLayout2(why: string, size: number) {
    this.resourceList1 = [];
    this.resourceList2 = [];
    this.resourceList3 = [];
    this.resourceList4 = [];
    this.projectLineAList1 = [];
    this.projectLineAList2 = [];
    this.projectLineAList3 = [];
    this.projectLineAList4 = [];
    if (size > 0) {
      this.log.debug('tableLayout', why,
        'resource=' + this.isResourceTabActive
        + ' (' + this.resourceRecords.length + '/' + this.resourceList.length + ')',
        'projectLine=' + this.tableByProjectLine
        + ' (' + this.projectLineAList.length + ')',
        size)();
    }
    this.showLinesByResource = this.tableByProjectLine
      && this.projectLineAList.length > 0;
    this.showResourceByLines = !this.tableByProjectLine
      && this.projectLineAList.length > 0;


    if (this.isResourceTabActive && this.showLinesByResource) {
      //  resourceRecords(cols)
      //  projectLineAList(rows)
      this.resourceList1 = this.resourceList;
      this.projectLineAList1 = this.projectLineAList;
    }
    if (this.isResourceTabActive && this.showResourceByLines) {
      //  projectLineAList(cols)
      //  resourceRecords(rows)
      this.resourceList2 = this.resourceList;
      this.projectLineAList2 = this.projectLineAList;
    }

    if (!this.isResourceTabActive && this.showLinesByResource) {
      //  resourceRecords(cols)
      //  projectLineAList(rows)
      this.resourceList3 = this.resourceList;
      this.projectLineAList3 = this.projectLineAList;
    }
    if (!this.isResourceTabActive && this.showResourceByLines) {
      //  projectLineAList(cols)
      //  resourceRecords(rows)
      this.resourceList4 = this.resourceList;
      this.projectLineAList4 = this.projectLineAList;
    }
  } // tableLayout2

  trackByAllocation(index, pla: ProjectAllocation): string {
    return pla.id;
  }

  trackByRecord(index, record: DataRecordI): string {
    return record.id;
  }


  protected reset() {
    super.reset();
    this.projectLineAList = [];
    this.projectLineAllocations = [];
    this.projectLineAllocationsMap = {};
  }

  /**
   * Set Project
   * @param project new project called via effect - selectCurrentProject
   */
  protected setProject(project: DataRecordI) {
    super.setProject(project, true);
    this.onReset(); // set values
  } // setProject

  /**
   * Get Changes
   * @return empty or change summary
   */
  private getChanges(): string {
    if (!this.project) {
      this.isSaveDisabled = true;
      return '';
    }
    let info = '';
    // project
    const isShareAllLines = DataRecordF.valueBoolean(this.project, 'isShareAllLines');
    // const managerId = DataRecordF.valueNumber(this.project, ('resourceSfId');
    const isShareAllLinesNew = this.formGroupProject.controls.isShareAllLines.value;
    if (this.isSameBool(isShareAllLines, isShareAllLinesNew)) {
      info = isShareAllLines ? '; share all lines' : '; sharing per line';
    } else {
      info = isShareAllLinesNew ? '; share All lines' : '; sharing Per line';
    }

    // project lines
    this.projectLineAList.forEach((pl: ProjectAllocation) => {
      let plInfo = '';
      // projectLineId: resourceId -- formGroupResource
      const acR: AbstractControl = this.formGroupResource.controls[ pl.projectLineId ];
      if (acR) {
        const resourceId = DataRecordF.value(pl.record, 'resourceSfId');
        const resourceIdNew = acR.value;
        if (resourceId !== resourceIdNew) {
          const rr = this.resourceMap[ resourceIdNew ];
          // console.debug('-rr', rr, this.resourceMap);
          plInfo = 'resource=' + (rr === undefined ? resourceIdNew : rr.name); // resourceId
        }
      }
      // projectLineId_resourceId: value -- formGroupAllocation
      const acA: AbstractControl = this.formGroupAllocation.controls[ pl.projectLineId ];
      if (acA) {
        const isShareAll = DataRecordF.valueBoolean(pl.record, 'isShareAll');
        const isShareAllNew = acA.value;
        if (!this.isSameBool(isShareAll, isShareAllNew)) {
          plInfo += 'shareAll=' + isShareAllNew;  // + '|' + isShareAll;
        }
      }

      // projectLineId_resourceId: value -- formGroupAllocation
      this.resourceRecords.forEach((res: DataRecordI) => {
        const key = pl.projectLineId + '_' + res.id;
        const acAlloc: AbstractControl = this.formGroupAllocation.controls[ key ];
        if (acAlloc) {
          const isActiveNew = acAlloc.value;
          const alloc = this.projectLineAllocationsMap[ key ];
          if (alloc) {
            const isActive = DataRecordF.valueBoolean(alloc, 'isActive');
            if (!this.isSameBool(isActive, isActiveNew)) {
              plInfo += ' ' + res.name + ' Active=' + isActiveNew; // + '|' + isActive;
            }
          } else if (isActiveNew === true) {
            plInfo += ' ' + res.name + ' Active=' + isActiveNew;
          }
        }
      }); // projectLine

      if (plInfo.length > 0) {
        info += ' -- Line(' + pl.projectLineName + ') ' + plInfo;
      }
    });

    this.resourceRecords.forEach((res: DataRecordI) => {
      const acR: AbstractControl = this.formGroupAllocation.controls[ res.id ];
      if (acR) {
        const isAccessAllProjectLines = DataRecordF.valueBoolean(res, 'isAccessAllProjectLines');
        const isAccessAllProjectLinesNew = acR.value;
        if (!this.isSameBool(isAccessAllProjectLines, isAccessAllProjectLinesNew)) {
          info += ' -- Resource(' + res.name + ') accessAll=' + isAccessAllProjectLinesNew; // + '|' + isAccessAllProjectLines;
        }
      }
    });
    this.isSaveDisabled = info.length === 0;
    return info;
  } // getChanges

  /**
   * ProjectLines, Allocations
   */
  private getSummary(): string {
    let info = '';
    if (this.resourceRecords.length > 0) {
      info = String(this.resourceRecords.length) + ' Resources';
    }
    if (this.project === null || this.project === undefined) {
      return info;
    }
    // lines
    if (this.projectLineAList.length === 0) {
      return info + '; No Lines';
    }
    if (this.projectLineAList.length === 1) {
      info += '; 1 Line';
    } else {
      info += '; ' + String(this.projectLineAList.length) + ' Lines';
    }
    // allocation
    if (this.projectLineAllocations.length === 0) {
      info += '; No Allocations';
    } else if (this.projectLineAllocations.length === 1) {
      info += '; 1 Allocation';
    } else {
      info += '; ' + String(this.projectLineAllocations.length) + ' Allocations';
    }
    //
    info += this.getChanges();
    return info;
  } // getSummary

  private isSameBool(oldV, newV): boolean {
    if (Object.is(oldV, newV)) {
      return true;
    }
    if (newV === null || newV === undefined) {
      return oldV === false;
    }
    if (typeof oldV === typeof newV) {
      return oldV === newV;
    }

    const oldVString = String(oldV);
    const newVString = String(newV);
    // console.debug('isSameBool', oldV, newV, typeof oldV, typeof newV,
    //   '=' + Object.is(oldV, newV) + '|' + (oldV === newV) + '|' + (oldVString === newVString) );
    return (oldVString === newVString);
  } // isSameBool

  /**
   * @param records new project line allocations
   */
  private setProjectAllocations(records: DataRecordI[]) {
    this.log.debug('setProjectAllocations', records)();
    this.projectLineAllocations = [];
    this.projectLineAllocationsMap = {};
    if (records) {
      this.projectLineAllocations = DataRecordF.cloneArray(records);
      this.projectLineAllocations.forEach((alloc) => {
        const projectLineId = DataRecordF.value(alloc, 'projectLineSfId');
        const resourceId = DataRecordF.value(alloc, 'resourceSfId');
        if (projectLineId && resourceId) {
          this.projectLineAllocationsMap[ projectLineId + '_' + resourceId ] = alloc;
        }
      });
    }
    this.log.debug('setProjectAllocations',
      'allocations=' + Object.keys(this.projectLineAllocationsMap).length)();
    this.onReset(); // sets values
  } // setProjectAllocations

  /**
   * Project Lines - dimension - projectLineAList
   * @param projectLines new project lines
   */
  private setProjectLines(projectLines: DataRecordI[]) {
    this.log.debug('setProjectLines', projectLines)();
    // this.data.resetKey1Plan(this.projectId);
    if (projectLines) {
      // create controls
      const controlsResource = {};
      const controlsAllocation = {};
      this.projectLineAList = [];
      projectLines.forEach((record: DataRecordI) => {
        if (record.name !== ProjectService.PROJECT) {
          const projectLineId = record.id;
          const resourceId = DataRecordF.value(record, 'resourceSfId');
          //
          this.projectLineAList.push(new ProjectAllocation(Object.assign({}, record), this.resourceRecords));
          controlsResource[ projectLineId ] = new FormControl(resourceId);
          // allocations
          const isShareAll = DataRecordF.valueBoolean(record, 'isShareAll');
          controlsAllocation[ projectLineId ] = new FormControl(isShareAll);
          this.resourceRecords.forEach((res) => {
            controlsAllocation[ projectLineId + '_' + res.id ] = new FormControl();
          });
        }
      });
      this.resourceRecords.forEach((res) => {
        const isAccessAllProjectLines = DataRecordF.valueBoolean(res, 'isAccessAllProjectLines');
        controlsAllocation[ res.id ] = new FormControl();
      });
      // Resource - accessAll
      if (this.formGroupResourceSub) {
        this.formGroupResourceSub.unsubscribe();
      }
      this.formGroupResource = new FormGroup(controlsResource);
      this.formGroupResourceSub = this.formGroupResource.valueChanges.subscribe((chg) => {
        // console.log('formGroupResource.valueChanges', chg);
        this.message = this.getSummary();
      });
      // Allocation
      if (this.formGroupAllocationSub) {
        this.formGroupAllocationSub.unsubscribe();
      }
      this.formGroupAllocation = new FormGroup(controlsAllocation);
      this.formGroupAllocationSub = this.formGroupAllocation.valueChanges.subscribe((chg) => {
        // console.debug('formGroupAllocation.valueChanges', chg);
        this.message = this.getSummary();
      });
      this.log.debug('setProjectLines',
        'resources=' + Object.keys(controlsResource).length,
        'allocations=' + Object.keys(controlsAllocation).length)();
    }
    this.onReset(); // set values
    this.store.dispatch(appStatus({ status: 'project-allocation-lines-' + projectLines.length }));
  } // setProjectLines

} // ProjectAllocationComponent
