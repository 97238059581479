import { Project } from './project';
import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { Resource } from './resource';
import { ActivityType } from './activity-type';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { ResourcePriceI, ResourcePriceD } from './resource-price-i';
import { DataRecordF } from '../utils/data-record-f';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: ResourcePrice
 */
export class ResourcePrice {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id: number|string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created: number;

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy: TenantUser;

  public createdById: number;

  /**
   * label: Updated Time
   */
  public updated: number;

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy: TenantUser;

  public updatedById: number;

  /**
   * label: External Id
   */
  public externalId: string;

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant: Tenant;

  public tenantId: number;

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner: TenantUser;

  public ownerId: number;

  /**
   * label: Record Version
   */
  public version: number;

  /**
   * label: Salesforce Id
   */
  public sfId: string;

  /**
   * parent fk Resource
   * label: Resource
   */
  public resource: Resource;

  public resourceId: number;

  /**
   * label: Resource
   */
  public resourceSfId: string;

  /**
   * fk Project
   * label: Project
   */
  public project: Project;

  public projectId: number;

  /**
   * label: Project
   */
  public projectSfId: string;

  /**
   * fk ActivityType
   * label: Activity Type
   */
  public activityType: ActivityType;

  public activityTypeId: number;

  /**
   * label: Activity Type
   */
  public activityTypeSfId: string;

  /**
   * label: Effective from
   */
  public effectiveFrom: number;

  /**
   * label: Cost Rate
   */
  public costRate: number;

  /**
   * label: Billing Rate
   */
  public unitPrice: number;


  /**
   * @return ResourcePrice record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'ResourcePrice';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key) => {
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): ResourcePrice {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        const value = record.valueMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, ResourcePriceD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        const value = record.changeMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, ResourcePriceD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: ResourcePriceI): ResourcePrice {
    Object.keys(ResourcePriceD).forEach((key) => {
      const value = record[ key ];
      if (value) {
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'project') { // fk
          this.project = new Project();
          this.project.setFromI(value);
        } else if (key === 'activityType') { // fk
          this.activityType = new ActivityType();
          this.activityType.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // ResourcePrice
