import { InvoiceI } from './invoice-i';
import { ProjectI } from './project-i';
import { ActivityTypeI } from './activity-type-i';
import { TenantUserI } from './tenant-user-i';
import { ProjectPhaseI } from './project-phase-i';
import { ResourceI } from './resource-i';
import { ProjectLineI } from './project-line-i';
import { TenantI } from './tenant-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: InvoiceLine
 */
export interface InvoiceLineI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name?: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * parent fk InvoiceI
   * label: Invoice
   */
  invoice?: InvoiceI;

  /**
   * parent fk InvoiceI key
   * label: Invoice
   */
  invoiceId?: number|string;

  /**
   * label: Invoice
   */
  invoiceSfId?: string;

  /**
   * fk ProjectI
   * label: Project
   */
  project?: ProjectI;

  /**
   * fk ProjectI key
   * label: Project
   */
  projectId?: number|string;

  /**
   * label: Project
   */
  projectSfId?: string;

  /**
   * fk ProjectPhaseI
   * label: Project Phase
   */
  projectPhase?: ProjectPhaseI;

  /**
   * fk ProjectPhaseI key
   * label: Project Phase
   */
  projectPhaseId?: number|string;

  /**
   * label: Project Phase
   */
  projectPhaseSfId?: string;

  /**
   * fk ProjectLineI
   * label: Project Line
   */
  projectLine?: ProjectLineI;

  /**
   * fk ProjectLineI key
   * label: Project Line
   */
  projectLineId?: number|string;

  /**
   * label: Project Line
   */
  projectLineSfId?: string;

  /**
   * fk ResourceI
   * label: Resource
   */
  resource?: ResourceI;

  /**
   * fk ResourceI key
   * label: Resource
   */
  resourceId?: number|string;

  /**
   * label: Resource
   */
  resourceSfId?: string;

  /**
   * fk ActivityTypeI
   * label: Activity Type
   */
  activityType?: ActivityTypeI;

  /**
   * fk ActivityTypeI key
   * label: Activity Type
   */
  activityTypeId?: number|string;

  /**
   * label: Activity Type
   */
  activityTypeSfId?: string;

  quantity?: number;

  hours?: number;

  listPrice?: number;

  unitPrice?: number;

} // InvoiceLine

/** InvoiceLine Definition */
export const InvoiceLineD = {
  recordTypeName: 'InvoiceLine',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  invoice: {
    n: 'invoice',
    t: 'InvoiceI'
  },
  invoiceId: {
    n: 'invoiceId',
    t: 'number|string'
  },
  invoiceSfId: {
    n: 'invoiceSfId',
    t: 'string'
  },
  project: {
    n: 'project',
    t: 'ProjectI'
  },
  projectId: {
    n: 'projectId',
    t: 'number|string'
  },
  projectSfId: {
    n: 'projectSfId',
    t: 'string'
  },
  projectPhase: {
    n: 'projectPhase',
    t: 'ProjectPhaseI'
  },
  projectPhaseId: {
    n: 'projectPhaseId',
    t: 'number|string'
  },
  projectPhaseSfId: {
    n: 'projectPhaseSfId',
    t: 'string'
  },
  projectLine: {
    n: 'projectLine',
    t: 'ProjectLineI'
  },
  projectLineId: {
    n: 'projectLineId',
    t: 'number|string'
  },
  projectLineSfId: {
    n: 'projectLineSfId',
    t: 'string'
  },
  resource: {
    n: 'resource',
    t: 'ResourceI'
  },
  resourceId: {
    n: 'resourceId',
    t: 'number|string'
  },
  resourceSfId: {
    n: 'resourceSfId',
    t: 'string'
  },
  activityType: {
    n: 'activityType',
    t: 'ActivityTypeI'
  },
  activityTypeId: {
    n: 'activityTypeId',
    t: 'number|string'
  },
  activityTypeSfId: {
    n: 'activityTypeSfId',
    t: 'string'
  },
  quantity: {
    n: 'quantity',
    t: 'number'
  },
  hours: {
    n: 'hours',
    t: 'number'
  },
  listPrice: {
    n: 'listPrice',
    t: 'number'
  },
  unitPrice: {
    n: 'unitPrice',
    t: 'number'
  },
}; // InvoiceLine
