import { Pipe, PipeTransform } from '@angular/core';
import { Trl } from '../utils/trl';

/**
 * Date Pipe with value epoch milliseconds
 * usage:
 *  xx | dateMs
 *  xx | dateMs:'date'    // default
 *  xx | dateMs:'dateTime'
 *  xx | dateMs:'time'
 */
@Pipe({
  name: 'dateMs'
})
export class DateMsPipe implements PipeTransform {

  /**
   * @param value string or number with epoch ms
   * @param args optional type - date (default) - dateTime - time
   */
  transform(value: any, ...args: any[]): any {
    if (value) {
      try {
        const ms = Number(value);
        const theDate = new Date(ms);
        if (args && args.length === 1) {
          if (args[ 0 ] === 'dateTime') {
            return Trl.formatTimestamp(theDate);
          }
          if (args[ 0 ] === 'time') {
            return Trl.formatTime(theDate);
          }
        }
        return Trl.formatDateUtc(theDate);
      } catch (ex) {
        return value;
      }
    }
    return undefined;
  }

}
