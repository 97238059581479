/**
 * Translation
 */
import { AccortoService } from '../accorto.service';

export class Trl {

  public static readonly T_SENDING_REQUEST = 'Sending Request';
  public static readonly T_SENDING_QUERY = 'Sending Query';

  public static readonly T_ERROR_NO_TAB = 'No Tab found - Check URL';
  public static readonly T_ERROR_NO_TAB_ID = 'No Tab Id found - Check URL';

  public static readonly T_ERROR_INCOMPLETE = 'Incomplete Info';

  public static readonly T_PROCESS = 'Process';
  public static readonly T_SAVE = 'Save';
  public static readonly T_CLOSE = 'Close';

  public static readonly P_RECORDS = [ 'No records', 'One record', '{0} records' ];

  public static readonly V_YES = 'Yes';
  public static readonly V_NO = 'No';

  public static readonly V_TRUE = '\u2714'; // 2611 http://www.fileformat.info/info/unicode/char/2611/index.htm
  public static readonly V_FALSE = '\u20DE'; // 2610 http://www.fileformat.info/info/unicode/char/2610/index.htm


  // dot-dot-dot
  public static readonly DDD = ' \u2026 ';
  public static readonly DOT = ' \u00B7 ';
  // delta
  public static readonly DELTA = ' \u0394 ';

  /**
   * Format Boolean
   * @param value the value
   * @param yesNo or true/false (default)
   * @return boolean value
   */
  static formatBoolean(value: any, yesNo: boolean = false): string {
    if (Boolean(value) && value !== 'false' && value !== 'N' && value !== 'No') {
      if (yesNo) {
        return Trl.term(Trl.V_YES);
      }
      return Trl.V_TRUE;
    } else { // false
      if (yesNo) {
        return Trl.term(Trl.V_NO);
      }
      return Trl.V_FALSE;
    }
  } // formatBoolean

  /**
   * Format bytes
   * @param value bytes
   * @return info
   */
  static formatBytes(value: number) {
    if (value === undefined || value === null) {
      return '';
    }
    if (value > -1024 && value < 1024) {
      return Trl.formatInt(value) + 'b';
    }
    const kb = value / 1024;
    if (kb > -1024 && kb < 1024) {
      return Trl.formatNumber(kb, 1) + 'kb';
    }
    const mb = kb / 1024;
    if (mb > -124 && mb < 1024) {
      return Trl.formatNumber(mb, 1) + 'Mb';
    }
    const gb = mb / 1024;
    return Trl.formatNumber(gb, 1) + 'Gb';
  }

  /**
   * format local/utc date 02/1/2017 - mm/d/yyyy
   * @param value date or number
   * @param useUtc date is utc (true)
   * @param options localeString options
   */
  static formatDate(value: any, useUtc: boolean = true,
                    options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: 'numeric' }): string {
    let local: Date;
    if (this.isEmpty(value)) {
      return '';
    } else if (value instanceof Date) {
      local = value;
    } else {
      try {
        if (isNaN(value)) {
          local = new Date(value);
        } else {
          const time = Number(value);
          local = new Date(time);
        }
      } catch (e) {
        console.warn('Trl.formatDate =' + value, e);
        return value.toString();
      }
    }
    if (useUtc) {
      local.setMinutes(local.getMinutes() + local.getTimezoneOffset());
    }
    return local.toLocaleString(AccortoService.locale, options);
  } // formatDate

  /**
   * format utc date: day, 02/1
   */
  static formatDateDayUtc(utcDate: Date): string {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
    if (!utcDate) {
      return '';
    }
    const local = new Date(utcDate.getTime());
    local.setMinutes(local.getMinutes() + local.getTimezoneOffset());
    return local.toLocaleString(AccortoService.locale, { weekday: 'short', month: '2-digit', day: 'numeric' });
  } // formatDateDayUtc

  /**
   * format utc date: January 2019
   * @param utcDate the date
   * @param monthFormat long|short|narrow|2-digit|numeric
   * @param yearFormat numeric|2-digit
   */
  static formatMonthUtc(utcDate: Date, monthFormat: string = 'long', yearFormat: string = 'numeric'): string {
    if (!utcDate) {
      return '';
    }
    const local = new Date(utcDate.getTime());
    local.setMinutes(local.getMinutes() + local.getTimezoneOffset());
    return local.toLocaleString(AccortoService.locale, { month: monthFormat, year: yearFormat });
  } // formatMonthUtc

  /**
   * format utc date: January 2019
   * @param utcDate the date
   * @param width width in px
   */
  static formatMonthUtcWidth(utcDate: Date, width: number): string {
    // console.log('---width=' + width);
    let monthFormat: string = 'long';
    let yearFormat: string = 'numeric';
    if (width < 30) {
      monthFormat = 'narrow';
      yearFormat = '2-digit';
    } else if (width < 40) {
      monthFormat = 'numeric';
      yearFormat = '2-digit';
    } else if (width < 57) {
      monthFormat = 'short';
      yearFormat = '2-digit';
    } else if (width < 99) {
      monthFormat = 'short';
    } else if (width < 100) {
      yearFormat = '2-digit';
    }
    return this.formatMonthUtc(utcDate, monthFormat, yearFormat);
  } // formatMonthUtcWidth

  /**
   * format utc date: 02/1 mm/d
   */
  static formatWeekUtc(utcDate: Date): string {
    if (!utcDate) {
      return '';
    }
    const local = new Date(utcDate.getTime());
    local.setMinutes(local.getMinutes() + local.getTimezoneOffset());
    return local.toLocaleString(AccortoService.locale, { month: '2-digit', day: 'numeric' });
  }

  /**
   * format local/utc date 2017-02-01 - yyyy-MM-dd
   */
  static formatDateJdbc(value: any, useUtc: boolean = true): string {
    let local: Date;
    if (this.isEmpty(value)) {
      return '';
    } else if (value instanceof Date) {
      local = value;
    } else {
      try {
        if (isNaN(value)) {
          local = new Date(value);
        } else {
          const time = Number(value);
          local = new Date(time);
        }
      } catch (e) {
        console.warn('Trl.formatDateJdbc =' + value, e);
        return value.toString();
      }
    }
    // console.log('Trl.formatDateJdbc =' + value, value, local);
    if (useUtc) {
      local.setMinutes(local.getMinutes() + local.getTimezoneOffset());
    }
    const month = local.getUTCMonth() + 1;
    const day = local.getUTCDate();
    let jdbc = '' + local.getUTCFullYear() + '-';
    if (month < 10) {
      jdbc += '0';
    }
    jdbc += month + '-';
    if (day < 10) {
      jdbc += '0';
    }
    jdbc += day;
    return jdbc;
  } // formatDateJdbc

  /**
   * format utc date: 02/1/2017
   */
  static formatDateUtc(utcDate: Date): string {
    if (utcDate == null) {
      return '';
    }
    return this.formatDateUtcMs(utcDate.getTime());
  }

  /**
   * format utc date: 02/1/2017
   */
  static formatDateUtcMs(ms: number): string {
    if (!ms || ms === 0) {
      return '';
    }
    const local = new Date(ms);
    local.setMinutes(local.getMinutes() + local.getTimezoneOffset());
    return local.toLocaleString(AccortoService.locale, { year: 'numeric', month: '2-digit', day: 'numeric' });
  }

  /**
   * Format Number
   * @param value the value
   * @param decimals default 4
   * @return 1,234.5679
   */
  static formatFloat(value: any, decimals: number = 4): string {
    if (this.isEmpty(value)) {
      return '';
    }
    try {
      const num = Number(value.toString());
      return num.toLocaleString(AccortoService.locale, { maximumFractionDigits: decimals });
    } catch (e) {
      console.warn('Trl.format Float =' + value, e);
      return value.toString();
    }
  }

  /**
   * Format Integer
   * @param value the value
   * @return 1,234 (rounds)
   */
  static formatInt(value: any): string {
    if (this.isEmpty(value)) {
      return '';
    }
    try {
      const num = Number(value.toString());
      return num.toLocaleString(AccortoService.locale, { maximumFractionDigits: 0 });
    } catch (e) {
      console.warn('Trl.format Int =' + value, e);
      return value.toString();
    }
  }

  /**
   * Format Number
   * @param value the value
   * @param decimals default 2
   * @return 1,234.56
   */
  static formatNumber(value: any, decimals: number = 2): string {
    if (this.isEmpty(value)) {
      return '';
    }
    try {
      const num = Number(value.toString());
      return num.toLocaleString(AccortoService.locale, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
    } catch (e) {
      console.warn('Trl.format Number =' + value, e);
      return value.toString();
    }
  }

  /**
   * Format String
   * @param value string
   * @param maxLength max length
   * @return string with max length
   */
  static formatString(value: any, maxLength: number = 0): string {
    if (this.isEmpty(value)) {
      return '';
    }
    const str = value.toString();
    if (maxLength > 0 && str.length > maxLength) {
      return str.substr(0, maxLength - 1) + '\u2026'; // http://www.fileformat.info/info/unicode/char/2026/index.htm
    }
    return str;
  }

  /**
   * format local time 10:40 PM PST
   */
  static formatTime(value: any): string {
    let date: Date;
    if (this.isEmpty(value)) {
      return '';
    } else if (value instanceof Date) {
      date = value;
    } else {
      try {
        const time = Number(value);
        date = new Date(time);
      } catch (e) {
        console.warn('Trl.formatTime =' + value, e);
        return value.toString();
      }
    }
    return date.toLocaleString(AccortoService.locale, { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' });
  }

  /**
   * format local date+time 2/1/2017, 10:40:37 PM
   */
  static formatTimestamp(value: any): string {
    let date: Date;
    if (this.isEmpty(value)) {
      return '';
    } else if (value instanceof Date) {
      date = value;
    } else {
      try {
        const time = Number(value);
        date = new Date(time);
      } catch (e) {
        console.warn('Trl.formatTs =' + value, e);
        return value.toString();
      }
    }
    return date.toLocaleString(AccortoService.locale);
  }

  static isEmpty(value: any): boolean {
    return (value == null || value === '');
  }

  /**
   * Translate Plurals
   * @param definedTerm list of terms
   * @param count count
   * @return formatted string
   */
  public static plural(definedTerm: string[], count: number): string {
    let index = count;
    if (count < 0 || count > 1) {
      index = 2;
    }
    let term = definedTerm[ index ];
    term = term.replace('{0}', count.toLocaleString(AccortoService.locale));
    return term;
  }

  /**
   * Translate Fixed Term
   * @param definedTerm the term
   * @return translation
   */
  public static term(definedTerm: string): string {
    return definedTerm;
  }

} // Trl
