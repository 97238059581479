import { Action, createReducer, on } from '@ngrx/store';
import * as ProjectActions from './project.actions';
import { DataRecordI } from 'accorto';


export const projectsFeatureKey = 'projects';

/**
 * Project State
 */
export interface ProjectState {
  // projectId -> DataRecordI
  allProjectsMap: { [ key: string ]: DataRecordI };
  //
  currentProject: DataRecordI;
  currentProjectPhases: DataRecordI[];
  currentProjectLines: DataRecordI[];
  currentProjectAllocations: DataRecordI[];
}

export const initialState: ProjectState = {
  allProjectsMap: {},

  currentProject: undefined,
  currentProjectPhases: [],
  currentProjectLines: [],
  currentProjectAllocations: []
};

export function sortByName(one: DataRecordI, two: DataRecordI): number {
  const o = one.label ? one.label : one.name ? one.name : '';
  const t = two.label ? two.label : two.name ? two.name : '';
  return o.localeCompare(t);
}

const projectReducer0 = createReducer(
  initialState,

  // All Projects
  on(ProjectActions.projectLoadAllResultAction,
    (state, action) => {
      const allProjects: DataRecordI[] = action.projects;
      const allProjectsMap: { [ key: string ]: DataRecordI } = {};
      allProjects.forEach((project: DataRecordI) => {
        allProjectsMap[ project.id ] = project;
      });
      return {
        ...state,
        allProjectsMap
      };
    }
  ),
  on(ProjectActions.projectAddAction,
    (state, action) => {
      const allProjectsMap: { [ key: string ]: DataRecordI } = {
        ...state.allProjectsMap,
      };
      allProjectsMap[ action.project.id ] = action.project;
      return {
        ...state,
        allProjectsMap
      };
    }
  ),
  on(ProjectActions.projectUpdateAction,
    (state, action) => {
      const allProjectsMap: { [ key: string ]: DataRecordI } = {
        ...state.allProjectsMap,
      };
      allProjectsMap[ action.project.id ] = action.project;
      return {
        ...state,
        allProjectsMap
      };
    }
  ),
  on(ProjectActions.projectDeleteAction,
    (state, action) => {
      const allProjectsMap: { [ key: string ]: DataRecordI } = {
        ...state.allProjectsMap,
      };
      delete allProjectsMap[ action.id ];
      return {
        ...state,
        allProjectsMap,
        currentProject: undefined,
        currentProjectPhases: [],
        currentProjectLines: [],
        currentProjectAllocations: []
      };
    }
  ),
  on(ProjectActions.projectsClearAction,
    (state, action) => {
      return { // initial state
        ...state,
        allProjectsMap: {},
        currentProject: undefined,
        currentProjectPhases: [],
        currentProjectLines: [],
        currentProjectAllocations: []
      };
    }
  ),


  // Selected Project
  on(ProjectActions.projectSelectedAction,
    (state, action) => {
      return {
        ...state,
        currentProject: action.project,
        currentProjectPhases: [],
        currentProjectLines: [],
        currentProjectAllocations: []
      };
    }
  ),

  on(ProjectActions.projectSelectedUpdateAction,
    (state, action) => {
      const allProjectsMap: { [ key: string ]: DataRecordI } = {
        ...state.allProjectsMap,
      };
      allProjectsMap[ action.project.id ] = action.project;
      return {
        ...state,
        currentProject: action.project,
        allProjectsMap
      };
    }
  ),

  on(ProjectActions.projectLineSaveResultAction,
    (state, action) => {
      const currentProjectLines: DataRecordI[] = [];
      if (action.responseData.records && action.responseData.records.length > 0) {
        const record = action.responseData.records[ 0 ];
        let updated = false;
        for (const pl of state.currentProjectLines) {
          if (pl.id === record.id) {
            currentProjectLines.push(record);
            updated = true;
          } else {
            currentProjectLines.push(pl);
          }
        }
        console.log('----updated=' + updated, record);
        if (!updated) {
          currentProjectLines.push(record);
        }
      }
      return {
        ...state,
        currentProjectLines
      };
    }
  ),


  on(ProjectActions.projectPhasesLoadResultAction,
    (state, action) => {
      return {
        ...state,
        currentProjectPhases: action.projectPhases,
      };
    }
  ),

  // lines loaded
  on(ProjectActions.projectLinesLoadResultAction,
    (state, action) => {
      return {
        ...state,
        currentProjectLines: action.projectLines
      };
    }
  ),

  // allocations loaded
  on(ProjectActions.projectAllocationsLoadResultAction,
    (state, action) => {
      return {
        ...state,
        currentProjectAllocations: action.projectAllocations
      };
    }
  ),
);

// Selected Project
export const getCurrentProject = (state: ProjectState) => state.currentProject;


export function projectReducer(state: ProjectState | undefined, action: Action): ProjectState {
  return projectReducer0(state, action);
}

