import { TenantUserI } from './tenant-user-i';
import { TenantI } from './tenant-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Account
 */
export interface AccountI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name?: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * label: Account Phone
   */
  phone?: string;

  /**
   * label: Account Fax
   */
  fax?: string;

  /**
   * label: Street
   */
  addrStreet?: string;

  /**
   * label: City
   */
  addrCity?: string;

  /**
   * label: Zip/Postal Code
   */
  addrPostalCode?: string;

  /**
   * label: State/Province
   */
  addrState?: string;

  /**
   * label: Country
   */
  addrCountry?: string;

  /**
   * label: Account Number
   */
  accountNumber?: string;

  /**
   * label: Website
   */
  website?: string;

  /**
   * label: SIC Code
   */
  sic?: string;

  /**
   * Account Type
   * label: Type
   */
  type?: string;

  /**
   * label: Employees
   */
  employees?: number;

  /**
   * label: Annual Revenue
   */
  annualRevenue?: number;

  /**
   * label: Last Modified Date
   */
  sfLastModifiedDate?: number;

} // Account

/** Account Definition */
export const AccountD = {
  recordTypeName: 'Account',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  phone: {
    n: 'phone',
    t: 'string'
  },
  fax: {
    n: 'fax',
    t: 'string'
  },
  addrStreet: {
    n: 'addrStreet',
    t: 'string'
  },
  addrCity: {
    n: 'addrCity',
    t: 'string'
  },
  addrPostalCode: {
    n: 'addrPostalCode',
    t: 'string'
  },
  addrState: {
    n: 'addrState',
    t: 'string'
  },
  addrCountry: {
    n: 'addrCountry',
    t: 'string'
  },
  accountNumber: {
    n: 'accountNumber',
    t: 'string'
  },
  website: {
    n: 'website',
    t: 'string'
  },
  sic: {
    n: 'sic',
    t: 'string'
  },
  type: {
    n: 'type',
    t: 'string'
  },
  employees: {
    n: 'employees',
    t: 'number'
  },
  annualRevenue: {
    n: 'annualRevenue',
    t: 'number'
  },
  sfLastModifiedDate: {
    n: 'sfLastModifiedDate',
    t: 'number'
  },
}; // Account
