import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Logger } from '../log/logger';
import { AccortoService } from '../accorto.service';
import { CResponseFk } from '../model/c-response-fk';
import { CRequestFk } from '../model/c-request-fk';
import { AccortoCUtil } from '../model/accorto-c-util';
import { ServiceBase } from '../utils/service-base';
import { selectFkCaches, selectFkIdRequested } from './fk.selectors';
import { FkCache } from './fk-cache';
import { FkInfo } from '../model/fk-info';
import { logoutRequestAction } from '../login/login.actions';
import { FkRequests } from './fk-requests';

/**
 * FK Service
 */
@Injectable({
  providedIn: 'root'
})
export class FkService extends ServiceBase {

  private log: Logger = new Logger('FkService');
  private fkIdRequested: Set<string>;

  /** Cache copy */
  private caches: { [ key: string ]: FkCache } = {};

  /**
   * FK Service
   */
  constructor(private http: HttpClient,
              private config: AccortoService,
              private store: Store<any>) {
    super();
    this.store.pipe(select(selectFkIdRequested))
      .subscribe((fkIdRequested) => {
        this.fkIdRequested = fkIdRequested;
      });
    this.store.pipe(select(selectFkCaches))
      .subscribe((caches: { [ key: string ]: FkCache }) => {
        if (caches) {
          this.caches = caches;
        }
      });
  } // FkService

  /**
   * Get Label (assumes fk loaded/complete
   * @param fkTable table name
   * @param fkValue value
   */
  public getLabel(fkTable: string, fkValue: string): string {
    const cache: FkCache = this.caches[ fkTable ];
    if (cache) {
      const fkInfo: FkInfo = cache.fkInfoMap[ fkValue ];
      if (fkInfo) {
        return fkInfo.label;
      }
      return '<' + fkValue + '>'; // value not found
    }
    return '?' + fkValue + '?'; // no table
  } // getLabel

  /**
   * Send FK request
   * @param request fk request
   */
  send(request: CRequestFk): Observable<CResponseFk> {
    const start = new Date();
    const url = this.config.server + '/fk';
    this.config.setCRequest(request);
    // request.limit = 50; // test

    this.log.debug('send ' + url, request.fkTable, request.fkId,
      request.fkIds, request.parentMap)();
    const body = JSON.stringify(request);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    FkRequests.requestSent(request);

    return this.http.post<CResponseFk>(url, body, httpOptions)
      .pipe(
        tap(response => {
          if (response.error) {
            this.log.info('send.response', response)();
            if (response.isLoggedOut) {
              this.store.dispatch(logoutRequestAction());
            }
          } else {
            this.log.debug('send.response', response.message)();
          }
        }),
        map(response => AccortoCUtil.createCResponseFk(response)),
        tap((response) => {
          FkRequests.requestReceived(request);
          if (response.error) {
            this.markError(start);
          } else {
            this.markSuccess(start);
          }
        })
      );
  } // send

  /**
   * Send FK Id request
   * @param fkTable table name
   * @param fkId optional fk id
   * @param fkIds optional list of ids
   */
  sendFkIds(fkTable: string, fkId?: string, fkIds?: string[]): Observable<CResponseFk> {
    if (this.fkIdRequested && fkId && this.fkIdRequested.has(fkId)) {
      return EMPTY;
    }
    const request = new CRequestFk();
    request.fkTable = fkTable;
    request.fkId = fkId;
    request.fkIds = fkIds;
    return this.send(request);
  }

  /**
   * Send FK options Search request
   * @param fkTable table name
   * @param parentMap optional parent propertyName->value
   * @param searchTerm optional search term
   */
  sendFkSearch(fkTable: string, parentMap: { [ key: string ]: string }, searchTerm?: string): Observable<CResponseFk> {
    const request = new CRequestFk();
    request.fkTable = fkTable;
    request.parentMap = parentMap;
    request.searchTerm = searchTerm;
    return this.send(request);
  }

} // FkService
