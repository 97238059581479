import { Action, createReducer, on } from '@ngrx/store';
import * as DataActions from './data.actions';

export interface DataState {
  status: string;
}

export const initialState: DataState = {
  status: undefined
};


const dataReducer0 = createReducer(
  initialState,

  on(DataActions.loadDataAction,
    (state, action) => {
      return {
        ...state
      };
    }),
);

export function dataReducer(state: DataState, action: Action): DataState {
  return dataReducer0(state, action);
}
