const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** LogRecord Definition */
export const LogRecordD = {
    recordTypeName: 'LogRecord',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    requestUrl: {
        n: 'requestUrl',
        t: 'string'
    },
    remoteAddr: {
        n: 'remoteAddr',
        t: 'string'
    },
    userAgent: {
        n: 'userAgent',
        t: 'string'
    },
    cid: {
        n: 'cid',
        t: 'string'
    },
    sid: {
        n: 'sid',
        t: 'string'
    },
    userName: {
        n: 'userName',
        t: 'string'
    },
    userId: {
        n: 'userId',
        t: 'string'
    },
    orgName: {
        n: 'orgName',
        t: 'string'
    },
    orgId: {
        n: 'orgId',
        t: 'string'
    },
    product: {
        n: 'product',
        t: 'string'
    },
    productVersion: {
        n: 'productVersion',
        t: 'string'
    },
    userInterface: {
        n: 'userInterface',
        t: 'string'
    },
    loggerName: {
        n: 'loggerName',
        t: 'string'
    },
    methodName: {
        n: 'methodName',
        t: 'string'
    },
    logLevel: {
        n: 'logLevel',
        t: 'string'
    },
    message: {
        n: 'message',
        t: 'string'
    },
    params: {
        array: true,
        n: 'params',
        t: 'string'
    },
    exception: {
        n: 'exception',
        t: 'string'
    },
    statistics: {
        n: 'statistics',
        t: 'string'
    },
    statisticsValue: {
        n: 'statisticsValue',
        t: 'number'
    },
    durationMs: {
        n: 'durationMs',
        t: 'number'
    },
}; // LogRecord
export { ɵ0 };
