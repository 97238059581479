import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { selectAppHeading, selectLoginSession } from '../login/login.selectors';
import { logoutRequestAction } from '../login/login.actions';
import { Session } from '../model/session';

@Component({
  selector: 'acc-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: [ './global-header.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class GlobalHeaderComponent implements OnInit {

  // application name
  @Input() appName: SafeHtml;
  @Input() appTitle: string = 'Apps';
  // sub-title
  hdrTitle: string = '';

  initials: string;
  userName: string;
  userLabel: string;
  userCompany: string;
  showUserMenu: boolean = false;

  private session: Session;

  constructor(private store: Store<any>,
              private router: Router) {
    this.setSession(undefined);
  }

  ngOnInit() {
    this.store.pipe(select(selectLoginSession))
      .subscribe((session) => {
        this.setSession(session);
      });
    this.store.pipe(select(selectAppHeading))
      .subscribe((heading) => {
        this.hdrTitle = heading;
      });
  }

  onClickLogo() {
    console.log('onClickLogo');
    this.router.navigateByUrl('/');
  }

  onClickLogout() {
    this.store.dispatch(logoutRequestAction());
    this.showUserMenu = false;
  }

  private setSession(session: Session) {
    this.session = session;
    if (this.session) {
      this.userName = this.session.tenantUser.name;
      this.userLabel = this.session.tenantUser.label;
      this.initials = this.userLabel
        .split(' ')
        .map((n) => n[ 0 ]).join('');
      this.userCompany = this.session.tenant.name;

    } else {
      this.initials = '??';
      this.userName = 'Please Log In';
    }
  } // setSession

} // GlobalHeaderComponent
