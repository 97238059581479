import { FkInfo } from './fk-info';
import { CResponse } from './c-response';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: CResponseFk
 */
export class CResponseFk extends CResponse {

  /**
   * label: All FKs Loaded for FkTable
   */
  public isComplete: boolean;

  /**
   * label: Fk Table Name
   */
  public fkTable: string;

  /**
   * label: Limit used
   */
  public limit: number;

  /**
   * label: Fk Infos
   */
  public fkInfos: FkInfo[] = [];

  /**
   * label: Optional Parent Map (AND) used - propertyName->value
   */
  public parentMap: {[key: string]: string} = {};

  /**
   * label: Optional Search Term used
   */
  public searchTerm: string;

  /**
   * label: total count without restrictions
   */
  public totalCount: number;

} // CResponseFk
