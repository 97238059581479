import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { DataRecordI, Logger } from 'accorto';
import { SheetCol } from './sheet-col';
import { SheetRow } from './sheet-row';

/**
 * Sheet
 */
export class Sheet {

  /** The Columns */
  columns: SheetCol[] = [];
  /** The Rows */
  rows: SheetRow[] = [];
  /** Form Group */
  formGroup: FormGroup = new FormGroup({});

  /** Change Flag */
  isChanged: boolean = false;

  private log: Logger = new Logger('Sheet');

  get columnsDisplay(): SheetCol[] {
    return this.columns.filter(col => col.show);
  }

  get rowCount(): number {
    return this.rows.length;
  }

  /** add Column */
  addColumn(col: SheetCol) {
    this.columns.push(col);
  } // addColumn

  /** add Row */
  addRow(record: DataRecordI) {
    this.rows.push(new SheetRow(record));
  } // addColumn

  /** Initialize */
  createFormGroup(cause: string) {
    const controls: { [ key: string ]: FormControl } = {};
    this.columns.forEach((col: SheetCol) => {
      col.init();
      controls[ col.keyTop() ] = new FormControl(col.getTopFormatted());
      controls[ col.keyDelta() ] = new FormControl({ value: '', disabled: true });
      this.rows.forEach((row) => {
        controls[ row.key(col) ] = new FormControl(col.getRecordValueRowFormatted(row));
      });
    });
    this.formGroup = new FormGroup(controls);
    this.log.debug('createFormGroup ' + cause, 'cols=' + this.columns.length,
      'rows=' + this.rows.length, 'controls=' + Object.keys(this.formGroup.controls).length)();
  } // createFormGroup

  /**
   * Get column with property name
   * @param propertyName property name
   */
  getColumn(propertyName: string): SheetCol | undefined {
    return this.columns.find((col) => {
      return col.propertyName === propertyName;
    });
  }

  /**
   * Get Row with id
   * @param rowId row id
   */
  getRow(rowId: string): SheetRow | undefined {
    return this.rows.find((row) => {
      return row.record.id === rowId;
    });
  }

  /**
   * Get Form Value
   * @param key row_column
   */
  getValue(key: string): string | undefined {
    const ac: AbstractControl = this.formGroup.controls[ key ];
    return ac ? ac.value : undefined;
  }

  /**
   * Process user cell value change
   * @param key row_col
   * @param col column
   */
  onChange(key: string, col: SheetCol) {
    const index = key.indexOf('_');
    const rowKey = key.substring(0, index);
    // const colKey = key.substring(index + 1);
    // value
    const ac: AbstractControl = this.formGroup.controls[ key ];
    const stringValue = ac ? ac.value : '';
    const newValue = Number(stringValue);
    //
    const row = this.rows.find((rr) => {
      return rr.projectLineId === rowKey;
    });
    this.log.debug('onChange', row, col, '"' + stringValue + '"', newValue)();
    //
    col.changedDetail(newValue, row, ac, this.formGroup);

    // update dependents
    this.columns.forEach((other1) => {
      if (other1.rpn && other1.rpn.includes(col.propertyName)) {
        this.log.debug('onChange (1)', col.propertyName + '<-' + other1.propertyName)();
        other1.changedDependent(row, this.formGroup); // first level
        this.columns.forEach((other2) => {
          if (other2.rpn && other2.rpn.includes(other1.propertyName)) {
            this.log.debug('onChange (2)', other1.propertyName + '<-' + other2.propertyName)();
            other2.changedDependent(row, this.formGroup); // second level
          }
        });
      }
    });
    this.isChanged = true;
  } // onChange

  /**
   * Process top value Change
   * @param key row_col
   * @param col column
   */
  onChangeTop(key: string, col: SheetCol) {
    const ac: AbstractControl = this.formGroup.controls[ key ];
    const stringValue = ac ? ac.value : '';
    const newValue = Number(stringValue);
    this.log.debug('onChangeTop', col, '"' + stringValue + '"', newValue)();
    //
    col.changedTop(newValue, ac, this.formGroup);
  } // onChangeTop

  /**
   * Adjust
   * @param top too row or detail rows
   * @param col column
   */
  onClick(top: boolean, col: SheetCol): string {
    this.log.debug('onClick', 'top=' + top, col)();
    if (top) {
      return col.adjustTop(this.formGroup);
    } else {
      const info = col.adjustDetails(this.formGroup);
      // update dependents
      this.columns.forEach((other1) => {
        if (other1.rpn && other1.rpn.includes(col.propertyName)) {
          this.log.debug('onChange (1)', col.propertyName + '<-' + other1.propertyName)();
          this.rows.forEach((row) => {
            other1.changedDependent(row, this.formGroup); // first level
          });
          this.columns.forEach((other2) => {
            if (other2.rpn && other2.rpn.includes(other1.propertyName)) {
              this.log.debug('onChange (2)', other1.propertyName + '<-' + other2.propertyName)();
              this.rows.forEach((row) => {
                other2.changedDependent(row, this.formGroup); // second level
              });
            }
          });
        }
      });
      return info;
    }
  } // onClick

  /** reset rows/formGroup */
  resetRows() {
    this.rows = [];
    this.formGroup = new FormGroup({});
    this.isChanged = false;
  }

  /** Reset record + recalculate */
  resetValues() {
    // reset changes
    this.rows.forEach((row) => {
      row.record.changeMap = {};
    });
    //
    const values: { [ key: string ]: string } = {};
    this.columns.forEach((col: SheetCol) => {
      col.init(); // calculates
      values[ col.keyTop() ] = col.getTopFormatted();
      values[ col.keyDelta() ] = '';
      this.rows.forEach((row) => {
        values[ row.key(col) ] = col.getRecordValueRowFormatted(row);
      });
    });
    this.formGroup.setValue(values);
    this.isChanged = false;
  } // resetValues

  /**
   * Set Form Value
   * @param key the key
   * @param value new value
   */
  setValue(key: string, value: string): boolean {
    const ac: AbstractControl = this.formGroup.controls[ key ];
    if (ac) {
      ac.setValue(value);
    }
    return !!ac;
  }

} // Sheet
