import { ProjectI } from './project-i';
import { TenantUserI } from './tenant-user-i';
import { ProjectPhaseI } from './project-phase-i';
import { ResourceI } from './resource-i';
import { ProjectLineI } from './project-line-i';
import { TenantI } from './tenant-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: ProjectLine
 */
export interface ProjectLineI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name?: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * parent fk ProjectI
   * label: Project
   */
  project?: ProjectI;

  /**
   * parent fk ProjectI key
   * label: Project
   */
  projectId?: number|string;

  /**
   * label: Project
   */
  projectSfId?: string;

  /**
   * parent fk ProjectPhaseI
   * label: Project Phase
   */
  projectPhase?: ProjectPhaseI;

  /**
   * parent fk ProjectPhaseI key
   * label: Project Phase
   */
  projectPhaseId?: number|string;

  /**
   * label: Project Phase
   */
  projectPhaseSfId?: string;

  /**
   * Project Line WBS Code
   * label: WBS
   */
  wbs?: string;

  /**
   * fk ResourceI
   * label: Resource
   */
  resource?: ResourceI;

  /**
   * fk ResourceI key
   * label: Resource
   */
  resourceId?: number|string;

  /**
   * label: Resource
   */
  resourceSfId?: string;

  /**
   * label: Line Type
   */
  lineType?: string;

  /**
   * label: Priority
   */
  priority?: string;

  /**
   * label: Invoiced
   */
  invoiced?: string;

  /**
   * Give access to this Line for all who have access to the Project (no assignments necessary)
   * label: Share all
   */
  isShareAll?: boolean;

  /**
   * in hours
   * label: Planned Effort
   */
  plannedEffort?: number;

  /**
   * label: Planned Start
   */
  plannedStart?: number;

  /**
   * in hours
   * label: Planned Effort Billable
   */
  plannedEffortBillable?: number;

  /**
   * label: Planned End
   */
  plannedEnd?: number;

  /**
   * Overwrites Resource Price
   * label: Unit Price (Billing)
   */
  unitPrice?: number;

  /**
   * Amount charged regardless of effort
   * label: Fixed Amount
   */
  fixedAmount?: number;

  /**
   * used with estimated effort
   * label: Planned Cost Rate
   */
  plannedCostRate?: number;

  /**
   * Amount budgeted regardless of effort
   * label: Budget Cost Amount
   */
  plannedCostBudget?: number;

  /**
   * used with estimated effort
   * label: Planned Rate (Average Price)
   */
  plannedRate?: number;

  /**
   * Amount budgeted regardless of effort
   * label: Budget Revenue Amount
   */
  budgetAmount?: number;

  /**
   * label: Status
   */
  status?: string;

  /**
   * label: Percent Complete
   */
  percentComplete?: number;

  /**
   * label: Progress
   */
  progress?: string;

  /**
   * fk ProjectLineI
   * label: Prerequisite
   */
  prerequisite?: ProjectLineI;

  /**
   * fk ProjectLineI key
   * label: Prerequisite
   */
  prerequisiteId?: number|string;

  /**
   * Prerequisite line to be completed before starting this
   * label: Prerequisite
   */
  prerequisiteSfId?: string;

  /**
   * Internal list of prerequisite item Ids
   * label: Prerequisite Id List
   */
  prerequisiteIdList?: string;

  /**
   * If selected, to start the Line, ALL prior Lines need to be completed (the lines this line depends on).
   * label: All Complete
   */
  isAllComplete?: boolean;

} // ProjectLine

/** ProjectLine Definition */
export const ProjectLineD = {
  recordTypeName: 'ProjectLine',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  project: {
    n: 'project',
    t: 'ProjectI'
  },
  projectId: {
    n: 'projectId',
    t: 'number|string'
  },
  projectSfId: {
    n: 'projectSfId',
    t: 'string'
  },
  projectPhase: {
    n: 'projectPhase',
    t: 'ProjectPhaseI'
  },
  projectPhaseId: {
    n: 'projectPhaseId',
    t: 'number|string'
  },
  projectPhaseSfId: {
    n: 'projectPhaseSfId',
    t: 'string'
  },
  wbs: {
    n: 'wbs',
    t: 'string'
  },
  resource: {
    n: 'resource',
    t: 'ResourceI'
  },
  resourceId: {
    n: 'resourceId',
    t: 'number|string'
  },
  resourceSfId: {
    n: 'resourceSfId',
    t: 'string'
  },
  lineType: {
    n: 'lineType',
    t: 'string'
  },
  priority: {
    n: 'priority',
    t: 'string'
  },
  invoiced: {
    n: 'invoiced',
    t: 'string'
  },
  isShareAll: {
    n: 'isShareAll',
    t: 'boolean'
  },
  plannedEffort: {
    n: 'plannedEffort',
    t: 'number'
  },
  plannedStart: {
    n: 'plannedStart',
    t: 'number'
  },
  plannedEffortBillable: {
    n: 'plannedEffortBillable',
    t: 'number'
  },
  plannedEnd: {
    n: 'plannedEnd',
    t: 'number'
  },
  unitPrice: {
    n: 'unitPrice',
    t: 'number'
  },
  fixedAmount: {
    n: 'fixedAmount',
    t: 'number'
  },
  plannedCostRate: {
    n: 'plannedCostRate',
    t: 'number'
  },
  plannedCostBudget: {
    n: 'plannedCostBudget',
    t: 'number'
  },
  plannedRate: {
    n: 'plannedRate',
    t: 'number'
  },
  budgetAmount: {
    n: 'budgetAmount',
    t: 'number'
  },
  status: {
    n: 'status',
    t: 'string'
  },
  percentComplete: {
    n: 'percentComplete',
    t: 'number'
  },
  progress: {
    n: 'progress',
    t: 'string'
  },
  prerequisite: {
    n: 'prerequisite',
    t: 'ProjectLineI'
  },
  prerequisiteId: {
    n: 'prerequisiteId',
    t: 'number|string'
  },
  prerequisiteSfId: {
    n: 'prerequisiteSfId',
    t: 'string'
  },
  prerequisiteIdList: {
    n: 'prerequisiteIdList',
    t: 'string'
  },
  isAllComplete: {
    n: 'isAllComplete',
    t: 'boolean'
  },
}; // ProjectLine
