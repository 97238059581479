import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Point2d } from '../draw/point2d';
import { Logger } from '../log/logger';

@Component({
  selector: 'acc-modal-float',
  templateUrl: './modal-float.component.html',
  styleUrls: [ './modal-float.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ModalFloatComponent implements OnInit {

  /** Modal Title */
  @Input() title: string = 'the title';
  /** Icon or svg */
  @Input() iconRef: string = '/assets/icons/utility-sprite/svg/symbols.svg#search';
  /** Border style */
  @Input() borderStyle: string = 'solid 2px blue';
  /** Show Footer with buttons */
  @Input() showFooter: boolean = true;
  /** Show Clear button */
  @Input() showClear: boolean = true;

  /** css with - 30rem(476px) - two form columns: 36rem(572px) */
  @Input() width: string = '30rem';

  /** Callback close clicked (true=close false=clear) */
  @Output() closing = new EventEmitter<boolean>();

  @ViewChild('dialog', { static: true }) dialogElementRef: ElementRef;
  mouseOffset: Point2d;

  private log: Logger = new Logger('ModalFloat');

  constructor() {
  }

  ngOnInit() {
  }

  onClear() {
    this.closing.emit(false);
  }

  onClose() {
    this.closing.emit(true);
  }

  onHeaderMouseDown(event: MouseEvent) {
    // event.preventDefault();
    const div = this.dialogElementRef.nativeElement as HTMLDivElement;
    const mousePos = new Point2d(event.clientX, event.clientY);
    const divPos = new Point2d(div.offsetLeft, div.offsetTop);
    this.mouseOffset = divPos.minus(mousePos);
    this.log.debug('onHeaderHouseDown', mousePos, divPos, this.mouseOffset)();
  }

  onHeaderMouseMove(event: MouseEvent) {
    // event.preventDefault();
    if (this.mouseOffset) {
      const ref = this.dialogElementRef.nativeElement;
      const mouse = new Point2d(event.clientX, event.clientY);
      const newPos = mouse.add(this.mouseOffset);
      // this.log.debug('onHeaderHouseMove', mouse, newPos)();
      ref.style.left = (newPos.x) + 'px';
      ref.style.top = (newPos.y) + 'px';
    }
  }

  onHeaderMouseUp() {
    this.mouseOffset = undefined;
  }


} // ModalFloating
