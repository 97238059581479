import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MenuItem } from '../model/menu-item';
import { selectLoginMenuItems } from '../login/login.selectors';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccortoService } from '../accorto.service';

/**
 * Global Navigation
 * assumes router path /ui/menuItemName
 */
@Component({
  selector: 'acc-global-navigation',
  templateUrl: './global-navigation.component.html',
  styleUrls: [ './global-navigation.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class GlobalNavigationComponent implements OnChanges, OnDestroy {

  /** Local Menu Items */
  @Input() localItems: MenuItem[] = [];
  /** Main entries only Local */
  @Input() localOnlyMain: boolean = false;
  /** Get Menu Items from Session */
  @Input() fromSession: boolean = false;

  /** Session Items */
  sessionItems: MenuItem[] = [];

  /** All Menu Items for user */
  menuItems: MenuItem[] = [];
  /** Main Menu Items for user */
  mainMenuItems: MenuItem[] = [];
  /** Show Menu */
  showAppLauncher: boolean = false;
  /** Apps in IFrame */
  isInIFrame: boolean = false;

  /** Subscriptions */
  private menuSubscription: Subscription;


  constructor(private store: Store<any>,
              private router: Router,
              config: AccortoService) {
    this.isInIFrame = config.isInIFrame();
  }

  doShowAppLauncher() {
    this.showAppLauncher = true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // console.debug('GlobalNavigation ' + this.fromSession, changes);
    if (this.fromSession && this.menuSubscription === undefined) {
      this.menuSubscription = this.store.pipe(select(selectLoginMenuItems))
        .subscribe((menuItems: MenuItem[]) => {
          if (menuItems) {
            this.sessionItems = menuItems;
            this.setMenuItems();
          }
        });
    }
    this.setMenuItems();
  }

  public ngOnDestroy(): void {
    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
    }
  }

  /**
   * Menu Item selected - called from AppLauncher
   * @param menuItem optional menu item
   */
  onMenuSelected(menuItem: MenuItem) {
    this.showAppLauncher = false;
    if (menuItem) {
      // add to menu
      let found = false;
      for (const mi of this.mainMenuItems) {
        if (mi.name === menuItem.name) {
          found = true;
          break;
        }
      }
      if (!found) { // add to main menu
        this.mainMenuItems.push(menuItem);
      }

      // go to url
      if (menuItem.routerLink() && menuItem.routerLink().length > 0) {
        this.router.navigate(menuItem.routerLink());
      } else {
        this.router.navigate([ '/ui', menuItem.name ]);
      }
    }
  } // onMenuSelected

  /**
   * @param menuItems set menu items
   */
  private setMenuItems() {
    // console.log('GlobalNavigation',
    //   'local #' + (this.localItems ? this.localItems.length : -1) + ' only=' + this.localOnlyMain,
    //   'sess #' + (this.sessionItems ? this.sessionItems.length : -1) + ' add=' + this.fromSession);

    // all menu items
    this.menuItems = [];
    const home = new MenuItem()
      .setName('home', 'Home', 'Dashboard',
        '/assets/icons/utility-sprite/svg/symbols.svg#home')
      .setNav('/');
    this.menuItems.push(home);

    // Main Menu
    this.mainMenuItems = [];
    for (const mi of this.localItems) { // local items
      if (mi.isMainMenu !== undefined) {
        this.menuItems.push(mi); // all
        // console.debug('GlobalNavigation (al) ' + mi.name);
        if (mi.isMainMenu) {
          this.mainMenuItems.push(mi);
          // console.debug('GlobalNavigation (ml) ' + mi.name);
        }
      }
    }
    if (this.fromSession) {
      for (const mi of this.sessionItems) {
        this.menuItems.push(mi); // all
        // console.debug('GlobalNavigation (a)' + mi.name);
        if (mi.isMainMenu !== undefined && mi.isMainMenu
          && !this.localOnlyMain) {
          this.mainMenuItems.push(mi);
          // console.debug('GlobalNavigation (m)' + mi.name);
        }
      }
    }
    // all items - sort alpha
    this.menuItems.sort((a, b) => {
      const s1 = a.label;
      const s2 = b.label;
      if (s1 < s2) {
        return -1;
      }
      if (s1 > s2) {
        return 1;
      }
      return 0;
    });
  } // setMenuItems

} // GlobalNavigation
