import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DataRecordI } from 'accorto';
import { projectsFeatureKey, ProjectState, sortByName } from './project.reducer';

// the State
export const selectProjectState = createFeatureSelector<ProjectState>(projectsFeatureKey);

// projects dictionary
export const selectProjectsMap = createSelector(
  selectProjectState,
  (state): { [ key: string ]: DataRecordI } => state.allProjectsMap
);

// projects array - DataRecord[]
export const selectProjectsAll = createSelector(
  selectProjectsMap,
  (stateMap): DataRecordI[] => Object.values(stateMap).sort(sortByName)
);


// current project
export const selectCurrentProject = createSelector(
  selectProjectState,
  (state): DataRecordI => state.currentProject
);
// current project - phases
export const selectCurrentProjectPhases = createSelector(
  selectProjectState,
  (state): DataRecordI[] => state.currentProjectPhases
);
// current project - lines
export const selectCurrentProjectLines = createSelector(
  selectProjectState,
  (state): DataRecordI[] => state.currentProjectLines
);
// current project - allocations
export const selectCurrentProjectAllocations = createSelector(
  selectProjectState,
  (state): DataRecordI[] => state.currentProjectAllocations
);



/*
export const selectCurrentPj = createSelector(
  selectProjectEntities,
  selectCurrentPjId,
  (projectEntities, projectId) => projectEntities[ projectId ]
); */
