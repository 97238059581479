import { DataRecord } from '../model/data-record';
import { DataService } from './data.service';
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
/**
 * Data Resolver
 * - loads record into snapshot data
 *
 * route:
 *  path: 'ui/:uiTab/:id',
 *  component: RecordHomeComponent,
 *  canActivate: [ LoginGuard ],
 *  resolve: {
 *    uiTab: UiResolver,
 *    record: DataResolver
 *  }
 */
export class DataResolver {
    constructor(service) {
        this.service = service;
    }
    resolve(route, state) {
        const uiName = route.params.uiTab;
        const recordId = route.params.id;
        console.log('DataResolver ' + uiName + '_' + recordId);
        if ('' + recordId === '-1') {
            return new DataRecord(); // new
        }
        return this.service.queryId(uiName, recordId);
    }
} // DataResolver
/** @nocollapse */ DataResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataResolver_Factory() { return new DataResolver(i0.ɵɵinject(i1.DataService)); }, token: DataResolver, providedIn: "root" });
