import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { DataColumn } from './data-column';
import { DataRecordI } from './data-record-i';
import { UiFormFieldI, UiFormFieldD } from './ui-form-field-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';
import { UiFormSection } from './ui-form-section';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: UiFormField
 */
export class UiFormField {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id: number|string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created: number;

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy: TenantUser;

  public createdById: number;

  /**
   * label: Updated Time
   */
  public updated: number;

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy: TenantUser;

  public updatedById: number;

  /**
   * label: External Id
   */
  public externalId: string;

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant: Tenant;

  public tenantId: number;

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner: TenantUser;

  public ownerId: number;

  /**
   * label: Record Version
   */
  public version: number;

  /**
   * label: Salesforce Id
   */
  public sfId: string;

  /**
   * parent fk UiFormSection
   * parent
   * label: Form Section
   */
  public uiFormSection: UiFormSection;

  public uiFormSectionId: number;

  /**
   * fk DataColumn
   * label: Data Column
   */
  public dataColumn: DataColumn;

  public dataColumnId: number;

  /**
   * label: Sequence in form/section
   */
  public seqNo: number;

  /**
   * label: Displayed
   */
  public isDisplay: boolean = true;

  /**
   * Overwrites Column value
   * label: Required (UI) overwrite
   */
  public isUiRequired: boolean;

  /**
   * label: html id
   */
  public uiId: string;


  /**
   * @return UiFormField record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'UiFormField';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key) => {
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * is the field required?
   */
  public isRequired(): boolean {
    if (this.isUiRequired === undefined) {
      if (this.dataColumn) {
        return this.dataColumn.isUiRequired;
      }
      return false;
    }
    return this.isUiRequired;
  } // isRequired


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): UiFormField {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        const value = record.valueMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, UiFormFieldD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        const value = record.changeMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, UiFormFieldD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: UiFormFieldI): UiFormField {
    Object.keys(UiFormFieldD).forEach((key) => {
      const value = record[ key ];
      if (value) {
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'dataColumn') { // fk
          this.dataColumn = new DataColumn();
          this.dataColumn.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // UiFormField
