import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalInfo } from './modal-info';

/**
 * Modal Service
 */
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private subject = new Subject<ModalInfo>();

  constructor() {
  }

  /**
   * Show Confirmation - yes-no
   * @param modalInfo modal details
   * @param yesFn business function
   * @param noFn business function - also cancel/close
   */
  doConfirm(modalInfo: ModalInfo, yesFn: () => void, noFn: () => void) {
    modalInfo.showCancel = false;
    modalInfo.yesFn = () => {
      this.subject.next(); // closes modal
      yesFn(); // business callback
    };
    modalInfo.noFn = () => {
      this.subject.next();
      noFn();
    };
    modalInfo.cancelFn = () => {
      this.subject.next();
      noFn();
    };
    this.subject.next(modalInfo); // publish to component
  } // doConfirm

  /**
   * Show Confirmation - yes-no-cancel
   * @param modalInfo modal details
   * @param yesFn business function
   * @param noFn business function
   * @param cancelFn business function - also close
   */
  doConfirmCancel(modalInfo: ModalInfo, yesFn: () => void, noFn: () => void, cancelFn: () => void) {
    modalInfo.showCancel = true;
    modalInfo.yesFn = () => {
      this.subject.next(); // closes modal
      yesFn(); // business callback
    };
    modalInfo.noFn = () => {
      this.subject.next();
      noFn();
    };
    modalInfo.cancelFn = () => {
      this.subject.next();
      cancelFn();
    };
    this.subject.next(modalInfo); // publish to component
  } // doConfirm


  /**
   * Modal Info for Component
   */
  getModalInfo(): Observable<ModalInfo> {
    return this.subject.asObservable();
  }

} // ModalService
