const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** UiGridField Definition */
export const UiGridFieldD = {
    recordTypeName: 'UiGridField',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    uiTab: {
        n: 'uiTab',
        t: 'UiTabI'
    },
    uiTabId: {
        n: 'uiTabId',
        t: 'number|string'
    },
    dataColumn: {
        n: 'dataColumn',
        t: 'DataColumnI'
    },
    dataColumnId: {
        n: 'dataColumnId',
        t: 'number|string'
    },
    seqNo: {
        n: 'seqNo',
        t: 'number'
    },
    isDisplay: {
        n: 'isDisplay',
        t: 'boolean',
        d: true
    },
    footerInfo: {
        n: 'footerInfo',
        t: 'string'
    },
    headerInfo: {
        n: 'headerInfo',
        t: 'string'
    },
    nameFooter: {
        n: 'nameFooter',
        t: 'string'
    },
    sortUp: {
        n: 'sortUp',
        t: 'boolean'
    },
    total: {
        n: 'total',
        t: 'number'
    },
    uiId: {
        n: 'uiId',
        t: 'string'
    },
}; // UiGridField
export { ɵ0 };
