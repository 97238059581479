import { Account } from './account';
import { ContactI, ContactD } from './contact-i';
import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Contact
 */
export class Contact {

  /**
   * label: Last Modified Date
   */
  public lastModifiedDate: number;

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id: number|string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created: number;

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy: TenantUser;

  public createdById: number;

  /**
   * label: Updated Time
   */
  public updated: number;

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy: TenantUser;

  public updatedById: number;

  /**
   * label: External Id
   */
  public externalId: string;

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant: Tenant;

  public tenantId: number;

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner: TenantUser;

  public ownerId: number;

  /**
   * label: Record Version
   */
  public version: number;

  /**
   * label: Salesforce Id
   */
  public sfId: string;

  /**
   * parent fk Account
   * label: Account
   */
  public account: Account;

  public accountId: number;

  public accountSfId: string;

  /**
   * label: Email
   */
  public email: string;

  /**
   * label: Business Fax
   */
  public fax: string;

  /**
   * label: First Name
   */
  public firstName: string;

  /**
   * label: Home Phone
   */
  public homePhone: string;

  /**
   * label: Business Phone
   */
  public phone: string;

  /**
   * label: Last Name
   */
  public lastName: string;

  /**
   * label: Mobile Phone
   */
  public mobilePhone: string;

  /**
   * label: Other Phone
   */
  public otherPhone: string;

  /**
   * label: Mailing Address
   */
  public mailingAddress: string;

  /**
   * label: Mailing City
   */
  public mailingCity: string;

  /**
   * label: Mailing Street
   */
  public mailingStreet: string;

  /**
   * label: Mailing Zip/Postal Code
   */
  public mailingPostalCode: string;

  /**
   * label: Mailing State/Province
   */
  public mailingState: string;

  /**
   * label: Title
   */
  public title: string;

  /**
   * label: Mailing Country
   */
  public mailingCountry: string;

  /**
   * fk Contact
   * label: Reports To
   */
  public reportsTo: Contact;

  public reportsToId: number;

  public reportsToSfId: string;

  /**
   * label: Department
   */
  public department: string;

  /**
   * label: Birthdate
   */
  public birthdate: number;

  /**
   * label: Assistant's Name
   */
  public assistantName: string;

  /**
   * label: Asst. Phone
   */
  public assistantPhone: string;

  /**
   * label: Salutation
   */
  public salutation: string;


  /**
   * @return Contact record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'Contact';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key) => {
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): Contact {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        const value = record.valueMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, ContactD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        const value = record.changeMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, ContactD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: ContactI): Contact {
    Object.keys(ContactD).forEach((key) => {
      const value = record[ key ];
      if (value) {
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'reportsTo') { // fk
          this.reportsTo = new Contact();
          this.reportsTo.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // Contact
