import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IconService } from './icon.service';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

/**
 * Icon
 * IE - https://github.com/arkon/ng-inline-svg
 * https://github.com/jonathantneal/svg4everybody
 */
@Component({
  selector: 'acc-icon',
  templateUrl: './icon.component.html',
  styleUrls: [ './icon.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnChanges {

  /** Use Path */
  @Input() href: string;
  /** Title/Help text */
  @Input() description: string;
  /** Icon is in Button (true) */
  @Input() isButton = 'true';

  /** (white) - default - success - warning - error - light */
  @Input() color: string = 'white';
  /** xx-small - (x-small) - small - large */
  @Input() size: string;

  /** additional container classes */
  @Input() containerClasses: string;

  /** All Container Classes */
  containerCls: string = '';

  /** Icon Name */
  iconName: string;
  readonly known: string[] = [ 'chevronleft', 'chevronright', 'search', 'clock', 'close', 'check', 'date_time', 'logout' ];


  constructor(private service: IconService,
              private sanitizer: DomSanitizer,
              @Inject(PLATFORM_ID) private platform) {
  }

  /** Icon Classes */
  get iconClasses(): string[] {
    const classes: string[] = [];
    if (this.isButton === 'true') {
      classes.push('slds-button__icon');
      if (this.size) {
        classes.push('slds-button__icon_' + this.size);
      }
    } else {
      classes.push('slds-icon');
      if (this.color !== 'white') {
        classes.push('slds-icon-text-' + this.color);
      }
      if (this.size) {
        classes.push('slds-icon_' + this.size);
      } else {
        classes.push('slds-icon_x-small');
      }
    }
    return classes;
  }

  /**
   * Set classes, load icons
   * @param changes ignored
   */
  public ngOnChanges(changes: SimpleChanges): void {
    this.containerCls = '';
    // background color - .slds-icon-[sprite name]-[icon name]
    // /assets/icons/utility-sprite/svg/symbols.svg#announcement -> slds-icon-utility-announcement
    if (this.href && this.href.length > 0) {
      if (!this.href.includes('#')) {
        if (!this.description) {
          this.description = this.href; // no description
        }
        this.href = '/assets/icons/utility-sprite/svg/symbols.svg#' + this.href;
      }
      const parts = this.href.split('#');
      if (parts.length === 2) {
        const url = parts[ 0 ];
        this.iconName = parts[ 1 ];
        let index = url.indexOf('-sprite');
        if (index !== -1) {
          // background color class
          let spriteName = url.substring(0, index);
          index = spriteName.lastIndexOf('/');
          spriteName = spriteName.substring(index + 1);
          this.containerCls += 'slds-icon-' + spriteName + '-' + this.iconName.replace('_', '-') + ' ';
          // console.log(this.containerCls, spriteName);
        }
      }
    }
    if (this.containerClasses) {
      this.containerCls = this.containerClasses;
    }

    // icons
    if (this.known.includes(this.iconName)) {
      return;
    }
    if (isPlatformBrowser(this.platform)
      && this.href) {
      this.service.get(this.href).subscribe((data) => {
          if (data) {
            // console.log('icon=' + this.iconName, data);
          }
        },
        (err) => {
          console.warn('icon=' + this.iconName, err);
        });
    }
  } // ngOnChanges

} // IconComponent
