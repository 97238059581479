import { CResponse } from './c-response';
/* tslint:disable max-line-length no-inferrable-types */
/**
 * extends: CResponse
 * name: LoginResponse
 */
export class LoginResponse extends CResponse {
    constructor() {
        super(...arguments);
        /**
         * label: Menu Items
         */
        this.menuItems = [];
    }
} // LoginResponse
