import { Box, DataRecordF, DataRecordI, GraphContainer, Margin, Trl } from 'accorto';
import { PalletLayout } from './pallet-layout';
import { Carton } from './carton';

/**
 * Crate (summary) managing Cartons containing Box Lines containing Boxes,
 * collapsible, e.g. Phase
 * contained in pallets
 * types = PP | PJ
 */
export class Crate extends Box {

  /** footer info */
  public footer: string;
  /** footer info */
  public subFooter: string;

  /** Expanded */
  public isExpanded: boolean = true;
  /** Show Header info */
  public showHdr: boolean = false;

  /** List of Cartons */
  private pCartons: Carton[] = [];

  /**
   * Crate
   * @param parent Graph Container
   * @param x crate x
   * @param y crate y
   * @param w crate width
   * @param h crate height
   * @param margin crate margin
   */
  constructor(parent: GraphContainer, x: number = 0, y: number = 0, w: number = 0, h: number = 0, public margin?: Margin) {
    super(x, y, w, h, margin, 2); // radius
    this.parent = parent;
    this.fill = 'aliceblue';
    this.stroke = 'gray';
  }

  get arrowLink(): string {
    return this.isExpanded ? '#a-down' : '#a-right';
  }

  /** cartons */
  public get cartons(): Carton[] {
    const cc = this.pCartons.filter((c) => {
      return c.show;
    });
    // sort cartons by startMs, label, name
    cc.sort((one, two) => {
      // let cmp = one.type.localeCompare(two.type); // PL
      let cmp = one.startMs ? one.startMs : 0 - two.startMs ? two.startMs : 0;
      if (cmp === 0) {
        cmp = (one.label ? one.label : '').localeCompare(two.label ? two.label : '');
        if (cmp === 0) {
          cmp = (one.name ? one.name : '').localeCompare(two.name ? two.name : '');
        }
      }
      return cmp;
    });
    return cc;
  } // cartons

  /** date info plan | current */
  get dateInfo(): string {
    return 'Plan ' + this.getDateInfo(PalletLayout.ShowPlan)
      + ' | Current ' + this.getDateInfo(PalletLayout.ShowCurrent);
  }

  /** effort info */
  get effortInfo(): string {
    return this.formatValue(this.getValue(PalletLayout.ShowPlan)) + 'h'
      + ' » ' + this.formatValue(this.getValue(PalletLayout.ShowCurrent)) + 'h';
  } // effortInfo

  /** Collapsed */
  get isCollapsed(): boolean {
    return !this.isExpanded;
  }

  /**
   * Show Header if there is something to show
   */
  get showHeader(): boolean {
    let kidsDisplayed = false;
    for (const cc of this.pCartons) {
      if (cc.show) {
        kidsDisplayed = true;
        break;
      }
    }
    // console.debug('showHeader ' + this.label, this.pCartons);
    return kidsDisplayed && this.label && this.showHdr;
  } // showHeader

  /**
   * Show Header if there is an id
   */
  get showToggle(): boolean {
    return false;
  }

  /**
   * Find / create Carton with type/id
   * @param type the type
   * @param id the id (can be undefined)
   */
  findCreateCarton(type: string, id: string, record: DataRecordI): Carton {
    const theId = id ? id : undefined;
    let cc = this.pCartons.find((c) => {
      return c.type === type && c.id === theId;
    });
    if (!cc) {
      cc = new Carton(this.parent, this).setTypeId(type, theId);
      this.pCartons.push(cc);
      this.parent.addNode(cc);
    }
    if (record && !cc.record) {
      cc.record = DataRecordF.clone(record); // record
      cc.name = record.name;
      cc.label = DataRecordF.codeLabel(record);
    }
    return cc;
  } // findCreateCarton

  /**
   * Date info for type
   * @param type line type P/C
   */
  getDateInfo(type: string): string {
    const start = this.getStartMs(type);
    const end = this.getEndMs(type);
    let info = '-';
    if (start && start !== 0) {
      info = Trl.formatDateUtcMs(start);
    }
    if (end && end !== 0) {
      info += ' → ';
      info += Trl.formatDateUtcMs(end);
    }
    return info;
  }

  /**
   * End Info
   * @param type type P/C
   */
  getEndMs(type: string): number {
    if (this.pCartons.length === 0) {
      return this.endMs;
    }
    let endMs;
    this.pCartons.forEach((carton) => {
      const end = carton.getEndMs(type);
      if (endMs === undefined || endMs < end) {
        endMs = end;
      }
    });
    return endMs;
  } // getEndMs

  /**
   * Start Info
   * @param type type P/C
   */
  getStartMs(type: string): number {
    if (this.pCartons.length === 0) {
      return this.startMs;
    }
    let startMs;
    this.pCartons.forEach((carton) => {
      const start = carton.getStartMs(type);
      if (startMs === undefined || startMs > start) {
        startMs = start;
      }
    });
    return startMs;
  } // getStartMs

  /**
   * Get Value
   * @param type line type
   */
  getValue(type: string): number {
    if (this.pCartons.length === 0) {
      return this.value ? this.value : 0;
    }
    let v = 0;
    this.pCartons.forEach((carton) => {
      v += carton.getValue(type);
    });
    return v;
  } // getValue

  /**
   * Calculate startMs/endMs, duration, footer (effort)
   * @param showPlanCurrent (P|C|B)
   * @return value min, max, sum
   */
  initStartEnd(showPlanCurrent: string) {
    this.startMs = undefined;
    this.endMs = undefined;
    const retValue = [ 0, 0, 0 ]; // min,max,sum
    this.pCartons.forEach((carton) => {
      const values = carton.initStartEnd(showPlanCurrent);
      if (carton.startMs) {
        if (this.startMs === undefined || this.startMs > carton.startMs) {
          this.startMs = carton.startMs;
        }
      }
      if (carton.endMs) {
        if (this.endMs === undefined || this.endMs < carton.endMs) {
          this.endMs = carton.endMs;
        }
      }
    });
    this.setDurationDays();
    this.subFooter = String(this.durationDays);
    //
    this.footer = this.effortInfo;
    if (showPlanCurrent === PalletLayout.ShowCurrent) {
      this.footer += ' - ' + this.getDateInfo(PalletLayout.ShowCurrent);
    } else if (showPlanCurrent === PalletLayout.ShowPlan) {
      this.footer += ' - ' + this.getDateInfo(PalletLayout.ShowPlan);
    } else {
      this.footer += ' - ' + this.dateInfo;
    }
    // console.debug('crate-initStartEnd(' + showPlanCurrent + ')=' + this, this.effortInfo, retValue);
    return retValue;
  } // initStartEnd

  /**
   * Create summary info
   * - startMs/endMs durationDays
   * @param pLayout parameters
   */
  initialize(pLayout: PalletLayout) {
    this.pCartons.forEach((carton) => {
      carton.initialize(pLayout);
    });
  } // initialize

  /**
   * Layout Crate (e.g. Phase)
   * @param startY start y position
   * @param pLayout parameters
   * @return last y position
   */
  layout(startY: number, pLayout: PalletLayout): number {
    this.margin = pLayout.marginCarton;
    this.setXW(pLayout.startMs, pLayout.xDayFactor);
    //
    this.y = startY;
    if (this.show) {
      if (this.showHeader) {
        this.h = 30;
        //
        startY += pLayout.lineGap;
      } else {
        this.h = 0;
      }

      // content
      startY += this.h;
      if (this.isExpanded) {
        this.pCartons.forEach((carton) => {
          if (this.isExpanded) {
            // hide NoLine in plan
            carton.show = !(!carton.id && pLayout.showPlanCurrent === PalletLayout.ShowPlan);
            //
            startY = carton.layout(startY, pLayout);
            startY += 6; // v gap between crates
          } else {
            carton.y = startY;
            carton.h = 0;
          }
        });
      } // expanded
    }
    return startY;
  } // layout

  onClickToggle() {
    this.isExpanded = !this.isExpanded;
    // TODO rerender
  }

  /**
   * Set Type and Id
   * @param type the type
   * @param id the id
   * @return this
   */
  setTypeId(type: string, id: string): Crate {
    this.type = type;
    this.id = id;
    return this;
  }

} // Crate
