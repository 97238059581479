import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '../log/logger';
import { UiTab } from '../model/ui-tab';
import { DataService } from '../data/data.service';
import { DataView } from '../model/data-view';
import { DataRecord } from '../model/data-record';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { AccortoService } from '../accorto.service';

/**
 * Object Home
 */
@Component({
  selector: 'acc-object-home',
  templateUrl: './object-home.component.html',
  styleUrls: [ './object-home.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ObjectHomeComponent implements OnInit, OnDestroy {

  /** UiTab */
  ui: UiTab = new UiTab();
  /** Data */
  records: DataRecord[] = [];

  message: string;
  error: string;
  busy: boolean;

  filterName: string = 'Recent';
  dataView: DataView;

  private log: Logger = new Logger('ObjectHome');
  private subscriptions: Subscription[] = [];


  constructor(private route: ActivatedRoute,
              private router: Router,
              private dataService: DataService,
              private pageTitle: Title,
              private pageMeta: Meta,
              private config: AccortoService) {
    this.dataView = new DataView();
  }

  ngOnInit() {
    // this.initUi(this.route.snapshot.data[ 'uiTab' ]); // ui.resolver
    this.subscriptions.push(this.route.data.subscribe((data => {
      this.initUi(data.uiTab);
    })));

  } // ngOnInit

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  onClickFilterSelect() {
    this.log.debug('onClickFilterSelect')();
  }

  onClickFilterEdit() {
    this.log.debug('onClickFilterEdit')();
  }

  onClickRequery() {
    this.log.debug('onClickRequery')();
    this.doQuery();
  }


  onActionNew() {
    this.log.debug('onActionNew')();
    this.router.navigate([ '/ui', this.ui.name, '-1' ]);
  }

  /**
   * Do Query
   */
  doQuery() {
    this.busy = true;
    this.dataService.query(this.ui.dataTable.name, this.dataView)
      .subscribe((result) => {
        this.log.info('doQuery', result)();
        this.error = result.error;
        this.message = result.message;
        this.busy = false;
        if (result.records) {
          this.records = result.records;
        } else {
          this.records = [];
        }
      });
  } // doQuery

  private initUi(ui: UiTab) {
    this.log.debug('initUi', ui)();
    if (ui) {
      this.ui = ui;
      this.doQuery();
      this.pageTitle.setTitle(ui.labelPlural + ' | ' + this.config.productTitle);
    }
  } // initUi


}
