import { UiTabI } from './ui-tab-i';
import { TenantUserI } from './tenant-user-i';
import { UiFormFieldI } from './ui-form-field-i';
import { TenantI } from './tenant-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: UiFormSection
 */
export interface UiFormSectionI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name?: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * parent fk UiTabI
   */
  uiTab?: UiTabI;

  /**
   * parent fk UiTabI key
   */
  uiTabId?: number|string;

  seqNo?: number;

  isCollapsible?: boolean;

  isCollapsed?: boolean;

  uiFormFieldList?: UiFormFieldI[];

  /**
   * label: html id
   */
  uiId?: string;

} // UiFormSection

/** UiFormSection Definition */
export const UiFormSectionD = {
  recordTypeName: 'UiFormSection',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  uiTab: {
    n: 'uiTab',
    t: 'UiTabI'
  },
  uiTabId: {
    n: 'uiTabId',
    t: 'number|string'
  },
  seqNo: {
    n: 'seqNo',
    t: 'number'
  },
  isCollapsible: {
    n: 'isCollapsible',
    t: 'boolean'
  },
  isCollapsed: {
    n: 'isCollapsed',
    t: 'boolean'
  },
  uiFormFieldList: {
    array: true,
    n: 'uiFormFieldList',
    t: 'UiFormFieldI'
  },
  uiId: {
    n: 'uiId',
    t: 'string'
  },
}; // UiFormSection
