import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { selectResourcesAll } from './resource.selector';
import { resourceLoadAllRequestAction } from './resource.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ResourceResolver {
    constructor(store) {
        this.store = store;
        this.loading = false;
    }
    resolve(route, state) {
        return this.store
            .pipe(select(selectResourcesAll), tap((resources) => {
            // console.log('ResourceResolver ' + this.loading, resources);
            if (!this.loading && (!resources || resources.length === 0)) {
                this.loading = true;
                // console.log('ResourceResolver request');
                this.store.dispatch(resourceLoadAllRequestAction());
            }
        }), filter(resources => !!resources), first(), finalize(() => {
            // console.log('ResourceResolver fini');
            this.loading = false;
        }));
    }
} // ResourceResolver
/** @nocollapse */ ResourceResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResourceResolver_Factory() { return new ResourceResolver(i0.ɵɵinject(i1.Store)); }, token: ResourceResolver, providedIn: "root" });
