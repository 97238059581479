const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** UiFormSection Definition */
export const UiFormSectionD = {
    recordTypeName: 'UiFormSection',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    uiTab: {
        n: 'uiTab',
        t: 'UiTabI'
    },
    uiTabId: {
        n: 'uiTabId',
        t: 'number|string'
    },
    seqNo: {
        n: 'seqNo',
        t: 'number'
    },
    isCollapsible: {
        n: 'isCollapsible',
        t: 'boolean'
    },
    isCollapsed: {
        n: 'isCollapsed',
        t: 'boolean'
    },
    uiFormFieldList: {
        array: true,
        n: 'uiFormFieldList',
        t: 'UiFormFieldI'
    },
    uiId: {
        n: 'uiId',
        t: 'string'
    },
}; // UiFormSection
export { ɵ0 };
