import { DataView } from './data-view';
import { CRequest } from './c-request';
import { DataRecord } from './data-record';

/* tslint:disable max-line-length no-inferrable-types */

export enum DataOperation {
  DELETE = 'DELETE',
  QUERY = 'QUERY',
  SAVE = 'SAVE'
}

/**
 * extends: CRequest
 * name: CRequestData
 */
export class CRequestData extends CRequest {

  /**
   * SELECT ... WHERE ... ORDER
   * label: Query Data View
   */
  public dataView: DataView;

  /**
   * label: Count only
   */
  public isCount: boolean;

  /**
   * label: Query Limit: Number of records
   */
  public limit: number;

  /**
   * label: Query Offset: Start
   */
  public offset: number;

  /**
   * label: Operation
   */
  public operation: DataOperation;

  /**
   * label: Table Name
   */
  public tableName: string;

  /**
   * save, delete
   * label: Data Record
   */
  public record: DataRecord;

  /**
   * query, delete
   * label: Record Id
   */
  public recordId: string;

  /**
   * query, delete
   * label: Record Ids
   */
  public recordIds: string[] = [];

  /**
   * save, delete
   * label: Data Records
   */
  public records: DataRecord[] = [];

  /**
   * label: Query Table Id
   */
  public tableId: number;

} // CRequestData
