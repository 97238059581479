import { CResponse } from './c-response';
/* tslint:disable max-line-length no-inferrable-types */
/**
 * extends: CResponse
 * name: TzInfoResponse
 */
export class TzInfoResponse extends CResponse {
    constructor() {
        super(...arguments);
        this.infos = [];
    }
} // TzInfoResponse
