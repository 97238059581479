import { UiTab } from '../model/ui-tab';
import { DataTable } from '../model/data-table';
import { DataColumn, DataDisplay, DataType } from '../model/data-column';
import { UiGridField } from '../model/ui-grid-field';
import { UiFormField } from '../model/ui-form-field';
import { DataRecord } from '../model/data-record';
import { DataPickOption } from '../model/data-pick-option';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { UiFormSection } from '../model/ui-form-section';
import { UiTabI } from '../model/ui-tab-i';

/**
 * Model Utilities
 */
export class ModelUtil {

  /**
   * Add Form Field to UiTab
   * @param uiTab ui tab
   * @param column data column
   */
  static addFormField(uiTab: UiTab, column: DataColumn) {
    // add to table
    if (!uiTab.dataTable) {
      uiTab.dataTable = new DataTable();
    }
    uiTab.dataTable.columnList.push(column);
    this.processDataColumn(column, uiTab.dataTable);

    // section
    if (uiTab.formSectionList.length === 0) {
      const s: UiFormSection = new UiFormSection();
      s.name = 'Default';
      s.isCollapsible = false;
      s.uiTab = uiTab; // parent ref
      uiTab.formSectionList.push(s);
    }
    const section = uiTab.formSectionList[ 0 ];

    // field
    const ff = new UiFormField();
    ff.name = this.nameToProperty(column.name); // property name;
    ff.dataColumn = column;
    ff.label = column.label;
    ff.description = column.description;
    this.processUiFormField(ff);
    section.uiFormFieldList.push(ff);
  } // addFormField

  /**
   * Clone Data Column with nameLC from Data Table
   * @param dataTable data table
   * @param name original column name
   * @param newDataTable new data table to add to
   * @param newName new name
   * @param newLabel new label
   * @return cloned Data Column
   */
  static cloneDataColumn(dataTable: DataTable, name: string,
                         newDataTable: DataTable, newName: string, newLabel?: string): DataColumn {
    const column: DataColumn = dataTable.columnListMap[ name.toLowerCase() ];
    const newColumn: DataColumn = new DataColumn();
    Object.assign(newColumn, column);
    newColumn.name = newName;
    if (newLabel) {
      newColumn.label = newLabel;
    }
    newColumn.dataTable = newDataTable;
    newDataTable.columnList.push(newColumn);
    newDataTable.columnListMap[ newName.toLowerCase() ] = newColumn;
    return newColumn;
  } // cloneDataColumn

  /**
   * Clone Ui
   * @param ui user interface
   */
  static cloneUi(ui: UiTab): UiTab {
    const rv = new UiTab(); // clone
    rv.id = ui.id;
    rv.uiId = ui.uiId;
    rv.name = ui.name;
    rv.label = ui.label;
    rv.labelPlural = ui.labelPlural;
    rv.description = ui.description;
    rv.iconClass = ui.iconClass;
    rv.iconHref = ui.iconHref;
    rv.isActive = ui.isActive;
    rv.isChildTab = ui.isChildTab;
    rv.tenantId = ui.tenantId;
    //
    rv.dataTable = ui.dataTable;
    rv.relatedLinkColumnList = ui.relatedLinkColumnList;
    //
    return rv;
  } // cloneUi

  /**
   * Create Form Field from Data Column
   * @param dataTable data table
   * @param name column name
   * @param label optional label
   * @param required ui required
   * @return Form Field
   */
  static cloneUiFormField(dataTable: DataTable, name: string, label?: string, required?): UiFormField {
    const column: DataColumn = dataTable.columnListMap[ name.toLowerCase() ];
    const ff = new UiFormField();
    ff.dataColumn = column;
    ff.isActive = true;
    ff.isDisplay = true;
    if (required !== undefined) {
      ff.isUiRequired = required;
    }
    ModelUtil.processUiFormField(ff); // sets (property) name
    if (label) { // overwrite
      ff.label = label;
    }
    return ff;
  } // cloneUiFormField

  /**
   * Create Grid Field
   * @param dataTable data table
   * @param name column name
   * @param label optional label
   * @return Grid Field
   */
  static cloneUiGridField(dataTable: DataTable, name: string, label?: string): UiGridField {
    const column: DataColumn = dataTable.columnListMap[ name.toLowerCase() ];
    const gf = new UiGridField();
    gf.dataColumn = column;
    gf.isActive = true;
    gf.isDisplay = true;
    ModelUtil.processUiGridField(gf); // sets (property) name
    if (label) { // overwrite
      gf.label = label;
    }
    return gf;
  } // cloneUiGridField

  /**
   * @param name column name
   * @param label column label
   * @param dataType data type
   * @return data column
   */
  static createColumn(name: string, label: string, dataType: DataType) {
    const col = new DataColumn();
    col.name = name;
    col.label = label;
    col.dataType = dataType;

    return col;
  }

  /**
   * Create Form Control from Grid/Form Field
   * @param propertyName property name
   * @param column data column
   * @param record data record
   * @param required optional overwrite
   * @return FormControl for field
   */
  public static createControl(propertyName: string, column: DataColumn, record: DataRecord, required?: boolean): FormControl {
    const vals: ValidatorFn[] = [];

    if (required === undefined) {
      if (column.isUiRequired) {
        vals.push(Validators.required);
      }
    } else if (required) {
      vals.push(Validators.required);
    }
    if (column.lengthMin) {
      vals.push(Validators.minLength(column.lengthMin));
    }
    if (column.length) {
      vals.push(Validators.maxLength(column.length));
    }
    if (column.inputRegex) {
      vals.push(Validators.pattern(column.inputRegex));
    }
    if (column.inputType === 'email') {
      vals.push(Validators.email);
    }

    let initialValue = record.value(propertyName); // value might be undefined
    if (initialValue === undefined || initialValue === null) {
      initialValue = '';
    }
    return new FormControl({
        value: initialValue,
        disabled: false // formField.isReadOnly // prevents update
      },
      vals);
  } // createControl

  /**
   * Create Form Field from column
   * @param col DataColumn
   * @return UiFormField
   */
  public static createFormField(col: DataColumn): UiFormField {
    const field: UiFormField = new UiFormField();
    field.name = this.nameToProperty(col.name); // property name
    field.dataColumn = col;
    //
    ModelUtil.processUiFormField(field);
    return field;
  }

  /**
   * Create Option value
   * @param name name/value
   * @param label optional display value
   * @param isDefault is default (false)
   * @param isActive is active (true)
   */
  static createOption(name: string, label?: string, isDefault: boolean = false, isActive: boolean = true): DataPickOption {
    const op = new DataPickOption();
    op.name = name;
    op.label = label ? label : name;
    op.isDefaultValue = isDefault;
    op.isActive = isActive;
    return op;
  } // createOption

  /**
   * Get Dependent Properties
   * @param name property or column name, e.g. projectId
   * @param table the table
   * @return string[] list of dependent
   */
  static dependentProperties(name: string, table: DataTable): string[] {
    const column = table.columnListMap[ name.toLowerCase() ];
    if (column) {
      return column.dependentPropertyList;
    }
    console.warn('ModelUtil.dependentProperties NotFound: ' + table.name + '.' + name, table);
    return [];
  } // dependentProperties

  /**
   * Check that record is active and status not completed
   * @param record DataRecord
   * @return true if ok.
   */
  public static filterActiveRecords(record: DataRecord): boolean {
    if (!record.isActive) {
      return false;
    }
    const active = record.valueMap.isActive;
    if (active === 'false') {
      return false;
    }
    // hardcoded status
    const status = record.valueMap.status;
    // console.log('status=' + status);
    if (status === 'Completed') {
      return false; // skip
    }
    return true;
  } // filterActiveRecords

  /**
   * Filter Records
   * @param table data table
   * @param parentLink optional parent link, e.g. record.propertyName=='xx'
   * @param record data record
   * @return true if matches criteria
   */
  public static filterFkRecords(table: DataTable, parentLink: string, record: DataRecord): boolean {
    if (!this.filterActiveRecords(record)) {
      console.log('filterFkRecords NotActive', record.name);
      return false;
    }
    /*
     if (table.fkSelect || parentLink) {
      let code = table.fkSelect;
      if (!code) {
        code = '';
      }
      if (parentLink) {
        code += '&&' + parentLink;
      }
      // context (record,original,parent,env)
      let record = record.valueMap;
      if (record && record.changeMap) {
        record = Object.assign(record, record.changeMap);
      }
      const parent = {};
      const env = {};

      try {
        /* tslint:disable-next-line *
        const vv = eval(code);
        if (vv) {
          //  console.debug('filterFkRecords OK ' + code + ': ' + vv, record.name);
          return true;
        }
        // Sconsole.debug('filterFkRecords NO ' + code + ': ' + vv, (vv === true), record);
      } catch (ex) {
        console.warn('filterFkRecords ' + code, ex, record);
      }
      return false;
    } */
    return true;
  } // filterFkRecords

  /*
   * Get Control Type for Parameter
   * @param param parameter
   * @return control type (default: input)
   *
   static getControlTypeParam(param: DataProcessParameter): string {
    if (param.controlType && param.controlType.length > 0) {
      if (param.controlType === 'yn') {
        param.pickOptionList = ModelUtil.getPickListYN(param.isUiRequired);
        param.inputType = 'yn';
        return 'select';
      }
      if (param.controlType === 'tf') {
        param.pickOptionList = ModelUtil.getPickListTF(param.isUiRequired);
        param.inputType = 'tf';
        return 'select';
      }
      return param.controlType;
    }
    if (param.dataType === DataType.BOOLEAN) {
      return 'checkbox';
    }
    if (param.fkTable && param.fkTable.length > 0) {
      return 'fk';
    }
    if (param.isPick) {
      return 'select';
    }
    if (param.length && param.length > 60) {
      return 'textarea';
    }
    if (param.dataType === DataType.CLOB) {
      return 'textarea';
    }
    return 'input';
  } // getControlTypeParam

  /*
   * Input type - text, email, ..
   * @param param parameter
   * @return input type (default: text)
   *
   public static getInputTypeParam(param: DataProcessParameter): string {
    if (param.inputType && param.inputType.length > 0) {
      return param.inputType;
    }
    if (param.controlType !== 'input') {
      return '';
    }
    return ModelUtil.getInputTypeDataType(param.dataType);
  } // getInputTypeParam
   */

  /**
   * Control type - input, checkbox, select (yn), fk, hours
   * @param col data column
   * @return control type (default: input)
   */
  static getControlType(col: DataColumn): string {
    if (col.controlType && col.controlType.length > 0) { // defined
      if (col.controlType === 'yn') {
        col.pickOptionList = ModelUtil.getPickListYN(col.isUiRequired);
        col.inputType = 'yn';
        return 'select';
      }
      if (col.controlType === 'tf') {
        col.pickOptionList = ModelUtil.getPickListTF(col.isUiRequired);
        col.inputType = 'tf';
        return 'select';
      }
      return col.controlType;
    }
    // derive
    if (col.dataType === DataType.BOOLEAN) {
      return 'checkbox';
    }
    if (col.fkTable && col.fkTable.length > 0) {
      return 'fk';
    }
    if (col.isPick) {
      return 'select';
    }
    if (col.length && col.length > 60) {
      return 'textarea';
    }
    if (col.dataType === DataType.CLOB) {
      return 'textarea';
    }
    return 'input';
  } // getControlType

  /**
   * Get CSS class from icon href
   * @param href svg use href
   * @return slds-icon class
   */
  static getCssClassFromIconHref(href: string): string {
    let css = 'slds-icon-';
    if (href) {
      const regExp = /\/|#/;
      const parts = href.split(regExp);
      for (const part of parts) {
        if (part.endsWith('-sprite')) {
          css += part.substr(0, part.length - 7);
        }
      }
      const last = parts[ parts.length - 1 ];
      return css + '-' + last.replace('_', '-');
    }
    return css + 'unknown';
  } // getCssClassFromIconHref

  /**
   * Error Tab
   * @return UiTab
   */
  static getErrorTab(msg: any): UiTab {
    const tab = new UiTab();
    tab.name = 'error';
    tab.label = 'Error';
    tab.labelPlural = 'Error';
    tab.description = ModelUtil.toString(msg);
    // tab.iconHref = '/assets/icons/standard-sprite/svg/symbols.svg#default'; // gray cloud
    tab.iconHref = '/assets/icons/custom-sprite/svg/symbols.svg#custom34'; // bug
    tab.iconClass = ModelUtil.getCssClassFromIconHref(tab.iconHref); // 'slds-icon-custom-custom34';
    return tab;
  } // getLoadingTab

  /**
   * Input type based on data type
   * @param col data column
   * @return input type (default: text)
   */
  public static getInputType(col: DataColumn): string {
    // console.log('inputType ' + col.name + ' ' + col.inputType, col);
    if (col.inputType && col.inputType.length > 0) {
      return col.inputType;
    }
    if (col.controlType && col.controlType !== 'input') {
      return undefined;
    }
    // derive
    const dataType = col.dataType;
    if (dataType === DataType.BOOLEAN) {
      return 'checkbox';
    }
    if (dataType === DataType.EMAIL) {
      return 'email';
    }
    if (dataType === DataType.PHONE) {
      return 'tel';
    }
    if (dataType === DataType.URL) {
      return 'url';
    }
    if (dataType === DataType.DATE) {
      return 'date';
    }
    if (dataType === DataType.TIMESTAMP) {
      return 'datetime-local';
    }
    if (dataType === DataType.TIME) {
      return 'time';
    }
    if (dataType === DataType.INTEGER || dataType === DataType.LONG
      || dataType === DataType.DECIMAL || dataType === DataType.DOUBLE) {
      return 'number';
    }
    return 'text';
  } // getInputTypeDataType

  /**
   * Loading Tab
   * @return UiTab
   */
  static getLoadingTab(): UiTab {
    const tab = new UiTab();
    tab.name = 'loading';
    tab.label = '..loading..';
    tab.labelPlural = '..loading ..';
    tab.iconHref = '/assets/icons/standard-sprite/svg/symbols.svg#generic_loading';
    tab.iconClass = ModelUtil.getCssClassFromIconHref(tab.iconHref); // 'slds-icon-standard-generic-loading';
    return tab;
  } // getLoadingTab

  /**
   * Get Pick True/False Options (optional boolean)
   * @param required not optional
   * @return DataPickOption[]
   */
  static getPickListTF(required: boolean): DataPickOption[] {
    const options: DataPickOption[] = [];
    let pick: DataPickOption = new DataPickOption();
    if (!required) {
      pick.name = null;
      pick.label = '';
      options.push(pick);
    }
    pick = new DataPickOption();
    pick.name = 'true';
    pick.label = 'Yes';
    options.push(pick);
    pick = new DataPickOption();
    pick.name = 'false';
    pick.label = 'No';
    options.push(pick);
    return options;
  } // getPickListYn

  /**
   * Get Pick Yes/No Options (optional)
   * @param required not optional
   * @return DataPickOption[]
   */
  static getPickListYN(required: boolean): DataPickOption[] {
    const options: DataPickOption[] = [];
    let pick: DataPickOption = new DataPickOption();
    if (!required) {
      pick.name = null;
      pick.label = '';
      options.push(pick);
    }
    pick = new DataPickOption();
    pick.name = 'Yes';
    pick.label = 'Yes';
    options.push(pick);
    pick = new DataPickOption();
    pick.name = 'No';
    pick.label = 'No';
    options.push(pick);
    return options;
  } // getPickListYn

  /**
   * Get Column
   * @param table DataTable
   * @param name columnName or property name
   * @return DataColumn
   */
  public static getTableColumn(table: DataTable, name: string): DataColumn | undefined {
    if (table && name) {
      const nameLC = name.toLowerCase();
      const column: DataColumn = table.columnListMap[ nameLC ];
      if (column) {
        return column;
      }
      for (const col of table.columnList) {
        if (nameLC === col.name.toLowerCase()) {
          return col;
        }
      }
    } else {
      console.warn('ModelUtil.getTableColumn NotFound=' + name, table);
    }
    return undefined;
  } // getTableColumn

  /**
   * Get String Value
   * @param record DataRecord
   * @param key property name
   * @return string or undefined (if not exists)
   */
  static getValue(record: DataRecord, key: string): string | undefined {
    let stringValue: string;
    if (record.changeMap) {
      stringValue = record.changeMap[ key ];
    }
    if (stringValue === undefined) {
      stringValue = record.valueMap[ key ];
    }
    return stringValue;
  } // getValue

  /**
   * Get Float Value
   * @param record DataRecord
   * @param key property name
   * @return float or undefined (if not exists) or null if parse error
   */
  static getValueFloat(record: DataRecord, key: string): number | undefined | null {
    const stringValue: string = ModelUtil.getValue(record, key);
    if (stringValue === undefined || stringValue === '') {
      return undefined;
    }
    try {
      const nn = Number(stringValue);
      if (Number.isFinite(nn)) {
        return nn;
      }
    } catch (error) {
    }
    return null;
  } // getValueFloat

  /**
   * Get Int Value
   * @param record DataRecord
   * @param key property name
   * @return int or undefined (if not exists) or null if parse error
   */
  static getValueInt(record: DataRecord, key: string): number | undefined | null {
    const stringValue: string = ModelUtil.getValue(record, key);
    if (stringValue === undefined || stringValue === '') {
      return undefined;
    }
    try {
      const nn = Number(stringValue);
      if (Number.isInteger(nn)) {
        return nn;
      }
    } catch (error) {
    }
    return null;
  } // getValueInt

  /**
   * @param name CamelCase
   * @param fallback if there is no name
   * @return camel_case
   */
  static nameToId(name: string, fallback: string = '_id'): string {
    let retValue: string = fallback;
    if (name) {
      retValue = '';
      let lastChar: string = '';
      for (const char of name) {
        if (char === ' ' || char === '_') {
          if (lastChar === '_') {
            continue;
          }
          lastChar = '_';
        } else if (char === char.toLocaleUpperCase()) {
          if (retValue.length > 0 && lastChar !== '_') {
            retValue += '_';
          }
          lastChar = char.toLocaleLowerCase();
        } else {
          lastChar = char;
        }
        retValue += lastChar;
      }
    }
    return retValue;
  } // nameToId

  /**
   * @param name CamelCase
   * @return Camel Case
   */
  static nameToLabel(name: string): string {
    if (!name) {
      return 'NoName';
    }
    let retValue = name;
    if (retValue) {
      if (retValue.startsWith('Is') && retValue.length > 2) {
        const third = retValue.charAt(2);
        if (third === third.toLocaleUpperCase()) {
          retValue = retValue.substr(2);
        }
      }
      const temp = retValue;
      retValue = '';
      let lastChar: string = '';
      for (const char of temp) {
        if (retValue.length > 0 && char !== '_'
          && char === char.toLocaleUpperCase()
          && lastChar !== ' ') {
          retValue += ' ';
        }
        if (char === '_') {
          lastChar = ' ';
        } else {
          lastChar = char;
        }
        retValue += lastChar;
      }
    }
    return retValue;
  } // nameToLabel

  /**
   * @param name SomeName
   * @return someName
   */
  static nameToProperty(name: string): string {
    let retValue: string = 'none';
    if (name) {
      retValue = '';
      for (const char of name) {
        if (char === ' ' || char === '_') {

        } else if (retValue === '') {
          retValue = char.toLocaleLowerCase();
        } else {
          retValue += char;
        }
      }
    }
    return retValue;
  } // nameToProperty

  /**
   * Create/add column to table
   * @param dataTable table
   * @param name column name
   * @param dataType data type
   * @param label optional label (name)
   * @param required optional uiRequired (false)
   */
  static newDataColumn(dataTable: DataTable, name: string, dataType: DataType, label?: string, required?: boolean): DataColumn {
    const col = new DataColumn();
    col.name = name;
    col.dataType = dataType;
    col.label = label ? label : name;
    col.isUiRequired = required ? required : false;
    //
    dataTable.columnList.push(col);
    dataTable.columnListMap[ name.toLowerCase() ] = col;
    return col;
  }

  /**
   * Create New Record from Tab
   * @param tab UiTab
   * @param rowNo row number (default -1)
   * @return DataRecord
   */
  public static newDataRecord(tab: UiTab, rowNo: number = -1): DataRecord {
    const record = new DataRecord();
    record.label = 'New ' + tab.label;
    record.rowNo = rowNo; // new are negative
    record.recordType = tab.name;
    record.info = record.label;
    record.valueMap = {};
    // set default values
    if (tab.dataTable) {
      for (const col of tab.dataTable.columnList) {
        if (col.defaultValue && !col.isReadOnly) {
          const propName = col.propertyName;
          if (col.defaultValue
            && col.defaultValue.startsWith('\'') && col.defaultValue.endsWith('\'')) {
            record.valueMap[ propName ] = col.defaultValue.substring(1, col.defaultValue.length - 1);
          } else {
            record.valueMap[ propName ] = col.defaultValue;
          }
        }
      }
    }
    if (record.isActive === undefined) {
      record.isActive = true;
    }
    if (record.isReadOnly === undefined) {
      record.isReadOnly = false;
    }
    return record;
  } // newDataRecord

  /**
   * Process Data Column (2)
   * @param col column to be processed
   * @param table data table
   */
  static processDataColumn(col: DataColumn, table?: DataTable) {
    // set parent info
    if (table) {
      col.dataTable = table;
      if (col.fkTable) {
        if (table.isSystemTable && col.fkTable !== 'Tenant') {
          if (col.isFkParent) {
            table.parentTable = col.fkTable;
            table.parentIdColumn = col.fkIdColumn;
            table.parentLinkColumn = col.name; // DataConnectionId -> DataConnection.Id
            // console.debug('parent: ' + column.name + ' -> ' + column.fkTable + '.' + column.fkIdColumn);
          }
        }
      } // fkTable
    }
    const name = col.name;
    // FK
    if (name === 'TenantId') {
      col.fkTable = 'Tenant';
    } else if (name === 'OwnerId' || name === 'CreatedById' || name === 'UpdatedById') {
      col.fkTable = 'TenantUser';
    }
    col.isFk = (col.fkTable && col.fkTable.length > 0);

    //
    if (col.isNullable === undefined) {
      col.isNullable = true;
    }
    if (col.isUiRequired === undefined) {
      col.isUiRequired = !col.isNullable;
    }

    // picklist
    if (col.isPick) {
      if (!col.isUiRequired) { // optional
        col.pickOptionList.unshift(new DataPickOption()); // first
      }
    } // pickList


    if (!col.dataType) {
      if (col.name.startsWith('Is')) {
        col.dataType = DataType.BOOLEAN;
      } else if (col.name.includes('Email')) {
        col.dataType = DataType.EMAIL;
      } else if (col.name.includes('Phone')) {
        col.dataType = DataType.PHONE;
      } else if (col.name.includes('Time')) {
        col.dataType = DataType.TIME;
      } else if (col.name.includes('Date')) {
        col.dataType = DataType.DATE;
      } else if (col.name.endsWith('Url')) {
        col.dataType = DataType.URL;
      } else if (col.name.endsWith('Id')) {
        col.dataType = DataType.FKID;
      } else {
        col.dataType = DataType.STRING;
      }
    } // dataType

    col.controlType = this.getControlType(col);
    col.inputType = this.getInputType(col);
    if (col.length === undefined) {
      col.length = 60; // text
    }
    //
    if (col.isReadOnly === undefined || col.isReadOnly) {
      col.isReadOnly = col.name === 'Id' || col.name === 'Version'
        || col.name.startsWith('Created') || col.name.startsWith('Updated')
        || col.name.startsWith('Tenant');
    }
    if (!col.dataDisplay) {
      col.dataDisplay = DataDisplay.BOTH;
    }
    //
    if (col.autocomplete === undefined) {
      col.autocomplete = 'off';
    }
  } // processDataColumn

  /**
   * Process Form Field (4)
   * @param field form field
   */
  static processUiFormField(field: UiFormField): void {
    const col: DataColumn = field.dataColumn;
    if (col) {
      field.name = this.nameToProperty(col.name); // property name
      field.uiId = ModelUtil.nameToId(field.name, 'ffield');

      if (!field.label) { // field.name = propertyName
        if (col.label) {
          field.label = col.label;
        } else {
          field.label = ModelUtil.nameToLabel(col.name); // CamelCase
        }
      }
      if (col.fkTable && field.label.endsWith('Id')) {
        field.label = field.label.substr(0, field.label.length - 3);
      }

      if (!field.description && col.description) {
        field.description = col.description;
      }

      // don't show fields with display logic
      if (field.isDisplay) { // default true
        if (col.displayLogic) {
          field.isDisplay = false;
        }
      } else {
        console.log('ModelUtil.processUiFormField noColumn', field);
      }
    }
  } // processUiForm

  /**
   * Process Grid Field (3)
   * @param field grid field
   */
  static processUiGridField(field: UiGridField): void {
    const col: DataColumn = field.dataColumn;
    if (col) {
      field.name = this.nameToProperty(col.name); // property name
      field.uiId = ModelUtil.nameToId(field.name, 'gfield');

      if (!field.label) {
        if (col.label) {
          field.label = col.label;
        } else {
          field.label = ModelUtil.nameToLabel(col.name); // CamelCase
          if (col.isFk && field.label.endsWith('Id')) {
            field.label = field.label.substr(0, field.label.length - 3);
          }
        }
      }
      if (!field.description && col.description) {
        field.description = col.description;
      }
      // don't show fields with display logic
      if (field.isDisplay) { // default true
        if (col.displayLogic) {
          field.isDisplay = false;
        }
      }
    } else {
      console.log('ModelUtil.processUiGridField noColumn', field);
    }
  } // processUiGrid

  /**
   * Process UiTab (1)
   * @param ui ui tab
   */
  public static processUiTab(uiInt: UiTabI): UiTab {
    if (!uiInt) {
      return undefined;
    }
    const ui = new UiTab();
    ui.setFromI(uiInt);

    // ----- table -----
    const table: DataTable = ui.dataTable;
    const colIdMap: Map<number | string, DataColumn> = new Map();
    // - table-columns -
    for (const col of table.columnList) {
      this.processDataColumn(col, table);
      colIdMap.set(col.id, col);
    } // table-columns

    if (!ui.label) {
      ui.label = table.label;
      ui.labelPlural = table.labelPlural;
      if (!ui.labelPlural) {
        ui.labelPlural = table.label + 's';
      }
    }

    // - table-column dependencies
    for (const col of table.columnList) {
      col.dependentPropertyList = [];
      const selector = 'row.' + col.propertyName;
      for (const col2 of table.columnList) {
        if (col2.fkRestrictColumnValues) {
          const values = col2.fkRestrictColumnValues.split(',');
          for (const value of values) {
            // console.log('-' + col.name + ' - ' + col2.name + ' ' + value + ' selector=' + selector);
            if (value.indexOf(selector) >= 0) {
              col.dependentPropertyList.push(col2.propertyName);
              // console.log('---=' + col.name + ' ' + col2.name, col2.propertyName);
            }
          }
        }
      }
    } // table-columns

    /* - table processes -
     for (const process of table.processList) {
      if (!process.label) {
        process.label = ModelUtil.nameToLabel(process.name);
      }
      process.dataTable = table;
      for (const param of process.parameterList) {
        if (!param.label) {
          param.label = ModelUtil.nameToLabel(param.name);
        }
        param.controlType = ModelUtil.getControlTypeParam(param);
        param.inputType = ModelUtil.getInputTypeParam(param);
      }
    } // table processes */


    // ----- ui -----
    if (!ui.label && table.label) {
      ui.label = table.label;
    }
    if (!ui.labelPlural && table.labelPlural) {
      ui.labelPlural = table.labelPlural;
    }
    if (!ui.description && table.description) {
      ui.description = table.description;
    }
    if (!ui.help && table.help) {
      ui.help = table.help;
    }
    //
    if (!ui.uiId) {
      ui.uiId = ModelUtil.nameToId(ui.name, 'ui');
    }
    if (!ui.iconHref && table.iconRef) {
      ui.iconHref = table.iconRef; // use or svg (img)
    }
    if (!ui.iconHref) { // default icon
      ui.iconHref = '/assets/icons/custom-sprite/svg/symbols.svg#custom63'; // chip
    }
    if (!ui.iconClass && !ui.iconHref.endsWith('.svg')) {
      ui.iconClass = ModelUtil.getCssClassFromIconHref(ui.iconHref);
    }

    if (ui.dataTable.parentTable) {
      ui.isChildTab = true;
    }

    // ----- grid -----
    for (const gf of ui.gridFieldList) {
      gf.dataColumn = colIdMap.get(gf.dataColumnId);
      this.processUiGridField(gf);
    }

    // ------ section -----
    for (const section of ui.formSectionList) {
      section.uiId = ModelUtil.nameToId(section.name, 'section');
      section.uiTab = ui;

      // - form -
      for (const ff of section.uiFormFieldList) {
        ff.dataColumn = colIdMap.get(ff.dataColumnId);
        ModelUtil.processUiFormField(ff);
      }
    }

    /*  -- related-tabs --
     for (const relTab of ui.relatedList) {
      const relTable: DataTable = tableMap.get(relTab.dataTableId);
      console.debug('processUiTable ui=' + ui.tableName + ' related ' + relTab.tableName, relTab, relTable);
      if (relTable) {
        ModelUtil.processUiTable(relTab, tableMap); // recursive
        for (const relatedColumn of relTable.columnList) {
          if (relatedColumn.fkTable === table.name) {
            // console.log('ui=' + ui.tableName + ' related ' + relatedTable.name + ': ' + relatedColumn.name
              + ' for ' + relatedColumn.fkTable + ' parent=' + relatedColumn.isFkParent);
            if (!relTab.relatedLinkColumnList) {
              relTab.relatedLinkColumnList = [];
            }
            relTab.relatedLinkColumnList.push(relatedColumn.name);
          }
        }
      }
    } // related-tabs */

    // console.log('ModelUtil.processUiTab i', uiInt);
    // console.log('ModelUtil.processUiTab o', ui);
    return ui;
  } // processUiTab


  /**
   * Get Sort direction for column
   * @param queryOrderBy Name ASC|DESC,Description ASC|DESC
   * @param columnName column name, e.g. Name
   * @return asc|desc|undefined
   */
  public static sortColumn(queryOrderBy: string, columnName: string): string {
    if (queryOrderBy && columnName) {
      const orderBy: string = queryOrderBy.toLowerCase();
      let index: number = orderBy.indexOf(columnName.toLowerCase());
      if (index !== -1) {
        let retValue = orderBy.substr(index + columnName.length + 1);
        index = retValue.indexOf(',');
        if (index !== -1) {
          retValue = retValue.substr(0, index);
        }
        // console.log(queryOrderBy, columnName, retValue);
        return retValue;
      }
    }
    return undefined;
  } // sortColumn

  /**
   * Sort info as text
   * @param queryOrderBy Name ASC|DESC,Description ASC|DESC
   * @param ui ui def
   * @return sort info
   */
  public static sortInfo(queryOrderBy: string, ui: UiTab): string {
    let sortInfo = queryOrderBy;
    const names: string[] = queryOrderBy.replace(' ASC', '').replace(' DESC', '').split(' ,');
    // console.log(names);
    for (const name of names) {
      for (const gfield of ui.gridFieldList) {
        if (gfield.name === name) {
          sortInfo = sortInfo.replace(name, gfield.label);
          break;
        }
      }
    }
    sortInfo = sortInfo.replace(' ASC', ' \u2191\u2070'); // &uarr; supercript Zero
    sortInfo = sortInfo.replace(' DESC', ' \u2193\u2079'); // &darr; supercript 9
    return sortInfo;
  }

  /**
   * Split to sentences .?!;:
   * @param text paragraph
   */
  static toSentences(text: string): string[] {
    const parts: string[] = [];
    if (text) {
      const DELIMS = '.?!;:';
      let sentence: string = '';
      let lastChar: string = '';
      for (let x = 0; x < text.length; x++) {
        const cc = text.charAt(x);
        if (cc === '\n' || cc === ' ') {
          if (cc === '\n' || DELIMS.includes(lastChar)) {
            parts.push(sentence);
            sentence = '';
            if (cc === ' ') {
              continue;
            }
          }
        }
        sentence += cc;
        lastChar = cc;
      }
      if (sentence) {
        parts.push(sentence);
      }
    }
    return parts;
  } // toSentence

  /**
   * ToString
   * @param msg error
   * @return string
   */
  public static toString(msg: any): string {
    if (msg === undefined || msg == null) {
      return '';
    } else if (typeof msg === 'string') {
      return msg;
    } else if (msg instanceof String) {
      // tslint:disable:ban-types
      return (msg as String).valueOf();
    } else if (msg.message) {
      return msg.message;
    } else {
      return JSON.stringify(msg);
    }
  }

} // ModelUtil
