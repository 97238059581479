/**
 * Color Utilities
 * https://github.com/d3/d3-color/blob/master/src/color.js
 * https://github.com/d3/d3-interpolate
 * https://codepen.io/njmcode/pen/axoyD/
 */
export class Colors {

  /**
   * Get foreground color based on background (black|white)
   * @param rgb background
   */
  static contrastYiq(rgb): string {
    // https://en.wikipedia.org/wiki/YIQ
    const yiq = ((rgb[ 0 ] * 299) + (rgb[ 1 ] * 587) + (rgb[ 2 ] * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  /**
   * Hex To RGB array
   * @param hex #ffffff
   * @return [r,g,b]
   */
  static hex2rgb(hex: string): number[] {
    const match = hex.replace(/#/, '').match(/.{1,2}/g);
    return [
      parseInt(match[ 0 ], 16),
      parseInt(match[ 1 ], 16),
      parseInt(match[ 2 ], 16)
    ];
  } // hex2Rgb

  /**
   * Hex To RGB array
   * @param hex #ffffff
   * @return [r,g,b]
   */
  static hex2rgb2(hex: string): number[] {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      return [
        parseInt(result[ 1 ], 16),
        parseInt(result[ 2 ], 16),
        parseInt(result[ 3 ], 16)
      ];
    }
    return undefined;
  } // hex2Rgb

  /**
   * HSL to rgb array
   * @param hsl hsl array
   */
  static hsl2rgb2(hsl: number[]): number[] {
    let l = hsl[ 2 ];
    if (hsl[ 1 ] === 0) {
      l = Math.round(l * 255);
      return [ l, l, l ];
    }
    const s = hsl[ 1 ];
    const q = (l < 0.5 ? l * (1 + s) : l + s - l * s);
    const p = 2 * l - q;
    const r = Colors.hue2rgb(p, q, hsl[ 0 ] + 1 / 3);
    const g = Colors.hue2rgb(p, q, hsl[ 0 ]);
    const b = Colors.hue2rgb(p, q, hsl[ 0 ] - 1 / 3);
    return [ Math.round(r * 255), Math.round(g * 255), Math.round(b * 255) ];
  }

  static hue2rgb(p, q, t) {
    if (t < 0) {
      t += 1;
    }
    if (t > 1) {
      t -= 1;
    }
    if (t < 1 / 6) {
      return p + (q - p) * 6 * t;
    }
    if (t < 1 / 2) {
      return q;
    }
    if (t < 2 / 3) {
      return p + (q - p) * (2 / 3 - t) * 6;
    }
    return p;
  }

  /**
   * Interpolate (gray-ish colors)
   * @param rgb1 low value color
   * @param rgb2 high value color
   * @param factor 0..1
   * @return rgb
   */
  static interpolateHsl(rgb1: number[], rgb2: number[], factor: number): number[] {
    const hsl1 = Colors.rgb2hsl(rgb1);
    const hsl2 = Colors.rgb2hsl(rgb2);
    for (let i = 0; i < 3; i++) {
      hsl1[ i ] += factor * (hsl2[ i ] - hsl1[ i ]);
    }
    return Colors.hsl2rgb2(hsl1);
  }

  /**
   * Interpolate (stays in color)
   * @param rgb1 low value color
   * @param rgb2 high value color
   * @param factor 0..1
   * @return rgb
   */
  static interpolateRgb(rgb1: number[], rgb2: number[], factor: number): number[] {
    const result = rgb1.slice();
    for (let i = 0; i < 3; i++) {
      result[ i ] = Math.round(result[ i ] + factor * (rgb2[ i ] - rgb1[ i ]));
    }
    return result;
  }

  /**
   * Convert rgb to hex string
   * @param rgb rgb array
   */
  static rgb2hex(rgb: number[]): string {
    /* tslint:disable no-bitwise */
    return '#' + ((1 << 24) + (rgb[ 0 ] << 16) + (rgb[ 1 ] << 8) + rgb[ 2 ])
      .toString(16)
      .slice(1);
  }

  /**
   * Convert rgb to shl
   * @param rgb rgb array
   */
  static rgb2hsl(rgb: number[]) {
    const r = rgb[ 0 ] / 255;
    const g = rgb[ 1 ] / 255;
    const b = rgb[ 2 ] / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h;
    let s;
    const l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = (l > 0.5 ? d / (2 - max - min) : d / (max + min));
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return [ h, s, l ];
  } // rgb2hsl

  /**
   * RGB CSS Function
   * @param rgb array
   * @return rgb(1,2,3)
   */
  static rgb2rgb(rgb: number[]): string {
    return 'rgb(' + rgb[ 0 ] + ',' + rgb[ 1 ] + ',' + rgb[ 2 ] + ')';
  }

  /**/

} // Colors
