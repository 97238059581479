import { HttpClient, HttpHeaders } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Logger } from '../log/logger';
import { AccortoService } from '../accorto.service';
import { CRequestFk } from '../model/c-request-fk';
import { AccortoCUtil } from '../model/accorto-c-util';
import { ServiceBase } from '../utils/service-base';
import { selectFkCaches, selectFkIdRequested } from './fk.selectors';
import { logoutRequestAction } from '../login/login.actions';
import { FkRequests } from './fk-requests';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../accorto.service";
import * as i3 from "@ngrx/store";
/**
 * FK Service
 */
export class FkService extends ServiceBase {
    /**
     * FK Service
     */
    constructor(http, config, store) {
        super();
        this.http = http;
        this.config = config;
        this.store = store;
        this.log = new Logger('FkService');
        /** Cache copy */
        this.caches = {};
        this.store.pipe(select(selectFkIdRequested))
            .subscribe((fkIdRequested) => {
            this.fkIdRequested = fkIdRequested;
        });
        this.store.pipe(select(selectFkCaches))
            .subscribe((caches) => {
            if (caches) {
                this.caches = caches;
            }
        });
    } // FkService
    /**
     * Get Label (assumes fk loaded/complete
     * @param fkTable table name
     * @param fkValue value
     */
    getLabel(fkTable, fkValue) {
        const cache = this.caches[fkTable];
        if (cache) {
            const fkInfo = cache.fkInfoMap[fkValue];
            if (fkInfo) {
                return fkInfo.label;
            }
            return '<' + fkValue + '>'; // value not found
        }
        return '?' + fkValue + '?'; // no table
    } // getLabel
    /**
     * Send FK request
     * @param request fk request
     */
    send(request) {
        const start = new Date();
        const url = this.config.server + '/fk';
        this.config.setCRequest(request);
        // request.limit = 50; // test
        this.log.debug('send ' + url, request.fkTable, request.fkId, request.fkIds, request.parentMap)();
        const body = JSON.stringify(request);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        FkRequests.requestSent(request);
        return this.http.post(url, body, httpOptions)
            .pipe(tap(response => {
            if (response.error) {
                this.log.info('send.response', response)();
                if (response.isLoggedOut) {
                    this.store.dispatch(logoutRequestAction());
                }
            }
            else {
                this.log.debug('send.response', response.message)();
            }
        }), map(response => AccortoCUtil.createCResponseFk(response)), tap((response) => {
            FkRequests.requestReceived(request);
            if (response.error) {
                this.markError(start);
            }
            else {
                this.markSuccess(start);
            }
        }));
    } // send
    /**
     * Send FK Id request
     * @param fkTable table name
     * @param fkId optional fk id
     * @param fkIds optional list of ids
     */
    sendFkIds(fkTable, fkId, fkIds) {
        if (this.fkIdRequested && fkId && this.fkIdRequested.has(fkId)) {
            return EMPTY;
        }
        const request = new CRequestFk();
        request.fkTable = fkTable;
        request.fkId = fkId;
        request.fkIds = fkIds;
        return this.send(request);
    }
    /**
     * Send FK options Search request
     * @param fkTable table name
     * @param parentMap optional parent propertyName->value
     * @param searchTerm optional search term
     */
    sendFkSearch(fkTable, parentMap, searchTerm) {
        const request = new CRequestFk();
        request.fkTable = fkTable;
        request.parentMap = parentMap;
        request.searchTerm = searchTerm;
        return this.send(request);
    }
} // FkService
/** @nocollapse */ FkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FkService_Factory() { return new FkService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AccortoService), i0.ɵɵinject(i3.Store)); }, token: FkService, providedIn: "root" });
