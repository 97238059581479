import { isPlatformBrowser } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { ClientStorage } from './utils/client-storage';
import { selectLoginSession } from './login/login.selectors';
import { Preference } from './utils/preference';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Accorto Configuration
 */
export class AccortoServiceConfig {
    constructor() {
        /** Server base url */
        this.server = '';
        /** Stage Server base url */
        this.serverStage = '';
        /** Production Flag */
        this.production = true;
        /** Google Analytics Code UA-... */
        this.gaCode = '';
        // Timestamp
        this.ts = '';
        // Product Code
        this.productCode = '';
        // Product Title
        this.productTitle = '';
    }
}
/**
 * Accorto Service + Configuration
 */
export class AccortoService {
    constructor(config, platform, store) {
        this.platform = platform;
        this.store = store;
        /** In IFrame and confirmed or not in IFrame */
        this.inIFrameConfirmed = false;
        /** Environment Info for Field eval and Parameters */
        this.env = {};
        /** Number of Requests */
        this.requestCount = 0;
        /** Memory Statistics */
        this.memStats = [];
        this.setStatics();
        if (config) {
            this.server = config.server;
            if (isPlatformBrowser(this.platform)) {
                const currentURL = window.location.href;
                if (currentURL && (currentURL.includes('localhost') || currentURL.includes('.us'))) {
                    this.server = config.serverStage;
                }
            }
            this.production = config.production;
            this.gaCode = config.gaCode;
            this.ts = config.ts;
            this.productCode = config.productCode;
            this.productTitle = config.productTitle;
        }
        //
        console.log(this.productCode + ' ' + AccortoService.cid
            + ' ' + this.ts // version
            + ' ' + this.server
            + ' ' + AccortoService.locale
            + ' ' + AccortoService.timezoneId
            + (this.isInIFrame() ? ' [' + document.referrer + ']' : '')
            + (AccortoService.isChrome ? ' chrome' : '')
            + (AccortoService.isIE ? ' ie' : ''), this.env);
        //
        this.store.pipe(select(selectLoginSession))
            .subscribe((session) => {
            this.session = session;
        });
    } // constructor
    /** Debug Flag */
    get isDebug() {
        return Preference.prefDebug.isValue;
    }
    /**
     * @param key environment key
     * @return environment (parameter) value
     */
    getEnv(key) {
        return this.env[key];
    }
    /**
     * @param key environment key
     * @param defaultValue default if none
     * @return "true" environment value or default
     */
    getEnvBoolean(key, defaultValue = false) {
        const ss = this.env[key];
        if (ss) {
            return ss === 'true';
        }
        return defaultValue;
    }
    /**
     * @param key environment key
     * @return environment value
     */
    getEnvOrStorage(key) {
        const env = this.env[key];
        if (env) {
            return env;
        }
        return ClientStorage.get(key);
    }
    /**
     * Get Memory Stats
     */
    getMemoryStats() {
        const memory = 'memory';
        try {
            if (window.performance && window.performance[memory]) {
                const mm = window.performance[memory];
                const stats = {
                    time: Date.now(),
                    usedJSHeapSize: mm.usedJSHeapSize,
                    totalJSHeapSize: mm.totalJSHeapSize,
                    jsHeapSizeLimit: mm.jsHeapSizeLimit
                };
                this.memStats.push(stats);
                return stats;
            }
        }
        catch (e) {
        }
        return undefined;
    } // getMemoryStats
    /** Salesforce Reference for id */
    getSfHref(id) {
        if (id && id.length > 10) {
            const url = this.getSfUrl();
            if (url) {
                return url + '/' + id;
            }
        }
        return undefined;
    } // getSfHref
    /** Salesforce instance */
    getSfUrl() {
        if (this.session) {
            if (this.session.tenant && this.session.tenant.externalId) {
                return this.session.tenant.externalId;
            }
            // if (this.session.requestReferrer) {
            //   return this.session.requestReferrer;
            // }
        }
        return undefined;
    }
    /**
     * @return user email if logged in
     */
    getUserEmail() {
        if (this.session) {
            if (this.session.email) {
                return this.session.email;
            }
            if (this.session.tenantUser) {
                if (this.session.tenantUser.email) {
                    return this.session.tenantUser.email;
                }
            }
        }
        return '';
    } // getUserEmail
    /**
     * Application running in IFrame
     */
    isInIFrame() {
        /*
        Salesforce
        - Blocked a frame with origin "https://wb.accorto.app" from accessing a cross-origin frame.
        - window.frameElement - returns null
        */
        let inIF = false;
        try {
            // const inIframe1 = window.location !== window.parent.location;
            // console.log('location=' + window.location + ' parent=' + window.parent.location + ' 1=' + inIframe1);
            if (window.location !== window.parent.location) {
                inIF = true;
            }
        }
        catch (e) { // Blocked a frame with origin
            // console.warn('#1', e);
            inIF = true;
        }
        return inIF;
    } // inIframe
    /**
     * Preference list
     */
    preferences() {
        return Preference.allPreferences;
    }
    /**
     * Populate Request
     * @param request Client Request
     */
    setCRequest(request) {
        request.cid = AccortoService.cid;
        if (this.session) {
            request.sid = this.session.name;
        }
        request.rid = this.requestCount++;
        // current url
        request.requestUrl = location.href;
        //
        const dd = new Date();
        request.timezoneOffsetMinutes = dd.getTimezoneOffset(); // 420
        request.timeMs = dd.getTime();
        // 3/27/2018, PDT
        request.timeString = dd.toLocaleDateString('en', { timeZoneName: 'short' });
        request.timezoneName = AccortoService.timezoneId;
        request.localeName = AccortoService.locale;
        request.referrer = document.referrer;
        this.getMemoryStats();
    } // setCRequest
    /**
     * Populate Request
     * @param request Client Request
     */
    setCRequestI(request) {
        request.cid = AccortoService.cid;
        if (this.session) {
            request.sid = this.session.name;
        }
        request.rid = this.requestCount++;
        // current url
        request.requestUrl = location.href;
        //
        const dd = new Date();
        request.timezoneOffsetMinutes = dd.getTimezoneOffset(); // 420
        request.timeMs = dd.getTime();
        // 3/27/2018, PDT
        request.timeString = dd.toLocaleDateString('en', { timeZoneName: 'short' });
        request.timezoneName = AccortoService.timezoneId;
        request.localeName = AccortoService.locale;
        request.referrer = document.referrer;
        this.getMemoryStats();
    } // setCRequestI
    /**
     * @param key environment key
     * @param value environment value
     */
    setEnv(key, value) {
        this.env[key] = value;
    }
    /**
     * find timezone (ie)
     */
    findTimezoneId() {
        const dd = new Date();
        const offsetMinutes = dd.getTimezoneOffset();
        const timeString = dd.toLocaleDateString('en', { timeZoneName: 'short' });
        console.log(timeString + ' ' + offsetMinutes);
        if (timeString.includes('PST') || timeString.includes('PDT')) { // Pacific Time
            return 'America/Los_Angeles';
        }
        if (timeString.includes('MST') || timeString.includes('MDT')) { // Mountain Time
            return 'America/Denver';
        }
        if (timeString.includes('CST') || timeString.includes('CDT')) { // Central Time
            return 'America/Chicago';
        }
        if (timeString.includes('EST') || timeString.includes('EDT')) { // Eastern Time
            return 'America/New_York';
        }
        // https://www.timeanddate.com/time/zone/uk
        if (timeString.includes('BST') || timeString.includes('GMT')) { // British Summer
            return 'Europe/London';
        }
        if (timeString.includes('CET') || timeString.includes('CEST')) { // Central European
            return 'Europe/Paris';
        }
        //
        if (offsetMinutes === 0) {
            return 'Zulu';
        }
        if (offsetMinutes === 360) { // Central Time
            return 'America/Chicago';
        }
        return '-';
    }
    /**
     *  Set static values + parameters
     */
    setStatics() {
        AccortoService.isChrome = navigator.vendor.includes('Google');
        // tslint:disable:no-string-literal
        AccortoService.isIE = document['documentMode'] !== undefined;
        // locale
        try {
            AccortoService.locale = Intl.DateTimeFormat().resolvedOptions().locale;
        }
        catch (e) {
            AccortoService.locale = navigator ? navigator.language : 'en';
        }
        // timezoneId
        try {
            AccortoService.timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        catch (e) {
        }
        if (!AccortoService.timezoneId || AccortoService.timezoneId === '-') {
            AccortoService.timezoneId = this.findTimezoneId();
        }
        // location/parameters
        const href = window.location.href; // https://track4d.us/a/b/c?aa=bb&cc
        const pathName = window.location.pathname; // /a/b/c
        const search = window.location.search; // ?aa=bb&cc
        if (search) {
            try {
                const pp = new URLSearchParams(search);
                pp.forEach((value, key) => {
                    this.env[key] = value;
                });
            }
            catch (e) {
                search.substr(1).split('&')
                    .forEach(item => {
                    const [key, value] = item.split('=');
                    if (value) {
                        this.env[key] = decodeURIComponent(value);
                    }
                    else {
                        this.env[key] = '';
                    }
                });
            }
        }
        if (pathName && !href.includes('sid=')) { // callback
            const redirect = (pathName === '/login' ? '/' : pathName) + search;
            ClientStorage.set('redirect', redirect);
            // console.log('redirect=' + redirect);
        }
        this.inIFrameConfirmed = !this.isInIFrame();
        //
        this.getMemoryStats();
    } // setStatics
} // AccortoService
/** Chrome Browser */
AccortoService.isChrome = false;
/** IE Browser */
AccortoService.isIE = false;
/** client id / state */
AccortoService.cid = Math.random().toString(36).substr(2);
/** Locale */
AccortoService.locale = 'en';
/** TimeZone Id = e.g. - America/Los_Angeles */
AccortoService.timezoneId = '-';
/** @nocollapse */ AccortoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccortoService_Factory() { return new AccortoService(i0.ɵɵinject(AccortoServiceConfig, 8), i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i1.Store)); }, token: AccortoService, providedIn: "root" });
