import { Action, createReducer, on } from '@ngrx/store';
import * as UiActions from './ui.actions';
import { UiTabI } from '../model/ui-tab-i';

export interface UiState {
  status: string;
  uis: { [ key: string ]: UiTabI };
}

export const initialState: UiState = {
  status: undefined,
  uis: {}
};

/**
 * UI Reducer
 */
const uiReducer0 = createReducer(
  initialState,

  on(UiActions.uiRequestAction,
    (state, action) => {
      return {
        ...state,
        status: 'requesting ' + action.uiName
      };
    }),
  on(UiActions.uiResponseAction,
    (state, action) => {
      const ui: UiTabI = action.uiTab;
      const uis = Object.assign({}, state.uis);
      uis[ ui.name ] = ui;
      return {
        status: 'ok',
        uis
      };
    }),
  on(UiActions.uiErrorAction,
    (state, action) => {
      return {
        ...state,
        status: 'error ' + action.err
      };
    }),
);  // uiReducer

export function uiReducer(state: UiState, action: Action): UiState {
  return uiReducer0(state, action);
}
