/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./record-value.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./record-value.component";
import * as i5 from "../accorto.service";
var styles_RecordValueComponent = [i0.styles];
var RenderType_RecordValueComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_RecordValueComponent, data: {} });
export { RenderType_RecordValueComponent as RenderType_RecordValueComponent };
function View_RecordValueComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["rel", "salesforce"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["In Salesforce "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sfHref; _ck(_v, 0, 0, currVal_0); }); }
function View_RecordValueComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], [[1, "data-value", 0], [8, "title", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecordValueComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.spanClass; _ck(_v, 2, 0, currVal_2); var currVal_4 = _co.sfHref; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recordValue; var currVal_1 = _co.title; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.displayValue; _ck(_v, 3, 0, currVal_3); }); }
function View_RecordValueComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [], [[1, "data-value", 0], [8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(3, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.cssClass; _ck(_v, 2, 0, currVal_4); var currVal_5 = _co.fkLink; _ck(_v, 3, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.recordValue); var currVal_1 = _co.title; var currVal_2 = i1.ɵnov(_v, 3).target; var currVal_3 = i1.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = _co.displayValueFk; _ck(_v, 5, 0, currVal_6); }); }
export function View_RecordValueComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecordValueComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecordValueComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isFkLink; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isFkLink; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RecordValueComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "acc-record-value", [], null, null, null, View_RecordValueComponent_0, RenderType_RecordValueComponent)), i1.ɵdid(1, 704512, null, 0, i4.RecordValueComponent, [i5.AccortoService], null, null)], null, null); }
var RecordValueComponentNgFactory = i1.ɵccf("acc-record-value", i4.RecordValueComponent, View_RecordValueComponent_Host_0, { column: "column", record: "record", cssClass: "cssClass" }, {}, []);
export { RecordValueComponentNgFactory as RecordValueComponentNgFactory };
