import { CRequest } from './c-request';
/* tslint:disable max-line-length no-inferrable-types */
/**
 * extends: CRequest
 * name: CRequestFk
 */
export class CRequestFk extends CRequest {
    constructor() {
        super(...arguments);
        /**
         * label: Optional List of Ids
         */
        this.fkIds = [];
        /**
         * label: Optional Parent Map - propertyName->value
         */
        this.parentMap = {};
    }
} // CRequestFk
