const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** Project Definition */
export const ProjectD = {
    recordTypeName: 'Project',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    resource: {
        n: 'resource',
        t: 'ResourceI'
    },
    resourceId: {
        n: 'resourceId',
        t: 'number|string'
    },
    managerSfId: {
        n: 'managerSfId',
        t: 'string'
    },
    code: {
        n: 'code',
        t: 'string'
    },
    invoiced: {
        n: 'invoiced',
        t: 'string'
    },
    account: {
        n: 'account',
        t: 'AccountI'
    },
    accountId: {
        n: 'accountId',
        t: 'number|string'
    },
    accountSfId: {
        n: 'accountSfId',
        t: 'string'
    },
    color: {
        n: 'color',
        t: 'string'
    },
    isShareAllLines: {
        n: 'isShareAllLines',
        t: 'boolean'
    },
    status: {
        n: 'status',
        t: 'string'
    },
    percentComplete: {
        n: 'percentComplete',
        t: 'number'
    },
    progress: {
        n: 'progress',
        t: 'string'
    },
}; // Project
export { ɵ0 };
