import { createAction, props } from '@ngrx/store';
import { LoginResponse } from '../model/login-response';
import { LoginRequest } from '../model/login-request';

export enum LoginActionTypes {
  LoginRequest = '[Login] Request',
  LoginResponse = '[Login] Response',
  LoginError = '[Login] Error',
  LogoutRequest = '[Logout] Request',
}

export const loginRequestAction = createAction(
  LoginActionTypes.LoginRequest,
  props<{ request: LoginRequest }>()
);

export const loginResponseAction = createAction(
  LoginActionTypes.LoginResponse,
  props<{ response: LoginResponse }>()
);

export const loginErrorAction = createAction(
  LoginActionTypes.LoginError,
  props<{ err: any }>()
);

export const appStatus = createAction(
  '[app] status',
  props<{ status: string }>()
);
export const appHeading = createAction(
  '[app] heading',
  props<{ heading: string }>()
);


export const logoutRequestAction = createAction(
  LoginActionTypes.LogoutRequest
);
