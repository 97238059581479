import { CResponseImportColumn } from './c-response-import-column';
import { CResponseImportRow } from './c-response-import-row';
import { CResponse } from './c-response';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: CResponseImport
 */
export class CResponseImport extends CResponse {

  /**
   * Mapping
   * label: Error Messages
   */
  public errors: string[] = [];

  /**
   * label: Headings
   */
  public headings: string[] = [];

  /**
   * label: Headings with Import Column Mappings
   */
  public headingColumns: CResponseImportColumn[] = [];

  /**
   * label: column(heading) -> target name
   */
  public mapping: {[key: string]: string} = {};

  /**
   * label: column(heading) -> sf target name
   */
  public mappingSf: {[key: string]: string} = {};

  /**
   * label: List of all Import Columns
   */
  public importColumns: CResponseImportColumn[] = [];

  /**
   * label: Mapping infos
   */
  public mappingInfos: string;

  /**
   * label: Task Messages
   */
  public messages: string[] = [];

  /**
   * label: Rows
   */
  public rows: CResponseImportRow[] = [];

} // CResponseImport
