const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** ProjectLineSharing Definition */
export const ProjectLineSharingD = {
    recordTypeName: 'ProjectLineSharing',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    projectLine: {
        n: 'projectLine',
        t: 'ProjectLineI'
    },
    projectLineId: {
        n: 'projectLineId',
        t: 'number|string'
    },
    projectLineSfId: {
        n: 'projectLineSfId',
        t: 'string'
    },
    resource: {
        n: 'resource',
        t: 'ResourceI'
    },
    resourceId: {
        n: 'resourceId',
        t: 'number|string'
    },
    resourceSfId: {
        n: 'resourceSfId',
        t: 'string'
    },
    projectSfId: {
        n: 'projectSfId',
        t: 'string'
    },
    isMandatory: {
        n: 'isMandatory',
        t: 'boolean'
    },
    maxEffort: {
        n: 'maxEffort',
        t: 'number'
    },
    percent: {
        n: 'percent',
        t: 'number'
    },
    resourcePriority: {
        n: 'resourcePriority',
        t: 'number'
    },
    plannedEffort: {
        n: 'plannedEffort',
        t: 'number'
    },
    plannedDetails: {
        n: 'plannedDetails',
        t: 'string'
    },
}; // ProjectLineSharing
export { ɵ0 };
