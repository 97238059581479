import { TenantUserI } from './tenant-user-i';
import { ResourceI } from './resource-i';
import { ProjectLineI } from './project-line-i';
import { TenantI } from './tenant-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: ProjectLineSharing
 */
export interface ProjectLineSharingI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name?: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * parent fk ProjectLineI
   * label: Project Line
   */
  projectLine?: ProjectLineI;

  /**
   * parent fk ProjectLineI key
   * label: Project Line
   */
  projectLineId?: number|string;

  /**
   * label: Project Line
   */
  projectLineSfId?: string;

  /**
   * fk ResourceI
   * label: Resource
   */
  resource?: ResourceI;

  /**
   * fk ResourceI key
   * label: Resource
   */
  resourceId?: number|string;

  /**
   * label: Resource
   */
  resourceSfId?: string;

  /**
   * 15 of 18 characters
   * label: Project
   */
  projectSfId?: string;

  /**
   * label: Mandatory
   */
  isMandatory?: boolean;

  /**
   * label: Max Effort (h) for this Resource
   */
  maxEffort?: number;

  /**
   * label: Percent for this Resource
   */
  percent?: number;

  /**
   * label: Resource Priority relative to other Resources
   */
  resourcePriority?: number;

  /**
   * in hours
   * label: Resource Planned Effort
   */
  plannedEffort?: number;

  /**
   * label: Resource Planned Effort Details
   */
  plannedDetails?: string;

} // ProjectLineSharing

/** ProjectLineSharing Definition */
export const ProjectLineSharingD = {
  recordTypeName: 'ProjectLineSharing',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  projectLine: {
    n: 'projectLine',
    t: 'ProjectLineI'
  },
  projectLineId: {
    n: 'projectLineId',
    t: 'number|string'
  },
  projectLineSfId: {
    n: 'projectLineSfId',
    t: 'string'
  },
  resource: {
    n: 'resource',
    t: 'ResourceI'
  },
  resourceId: {
    n: 'resourceId',
    t: 'number|string'
  },
  resourceSfId: {
    n: 'resourceSfId',
    t: 'string'
  },
  projectSfId: {
    n: 'projectSfId',
    t: 'string'
  },
  isMandatory: {
    n: 'isMandatory',
    t: 'boolean'
  },
  maxEffort: {
    n: 'maxEffort',
    t: 'number'
  },
  percent: {
    n: 'percent',
    t: 'number'
  },
  resourcePriority: {
    n: 'resourcePriority',
    t: 'number'
  },
  plannedEffort: {
    n: 'plannedEffort',
    t: 'number'
  },
  plannedDetails: {
    n: 'plannedDetails',
    t: 'string'
  },
}; // ProjectLineSharing
