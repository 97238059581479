import { createAction, props } from '@ngrx/store';
import { DataRecordI } from 'accorto';

/**
 * load all resources
 * - initiated: resolver, project-allocation - onSave
 * - effect: service.loadResources -> resourceLoadAllResultAction
 * -- reducer: state.allResourcesMap, .resourceTypes, .resourceGroups, .resourceLocations, .resourceSkills
 */
export const resourceLoadAllRequestAction = createAction(
  '[Resources] Load All Resources Request',
);
export const resourceLoadAllResultAction = createAction(
  '[Resources/api] Load All Resources Result',
  props<{ resources: DataRecordI[] }>()
);

/**
 * add/update/delete/clear resource
 * - initiated: - (not used)
 * - reducer ...
 */
export const resourceAddAction = createAction(
  '[Resource] Add Resource',
  props<{ resource: DataRecordI }>()
);
export const resourceUpdateAction = createAction(
  '[Resource] Update Resource',
  props<{ resource: DataRecordI }>()
);
export const resourceDeleteAction = createAction(
  '[Resource] Delete Resource',
  props<{ id: string }>()
);
export const resourcesClearAction = createAction(
  '[Resource] Clear Resources'
);


/**
 * set resource (load dependents)
 * - initiated: psa-base
 * - reducer: state.currentResource (clear dependents)
 * - effect
 * -- service.loadResourceProjectLines -> resourceProjectLineLoadResultAction
 * -- service.loadResourceProjectLines -> resourceProjectAllocationLoadResultAction
 * -- itemLoadResourceRequestAction
 */
export const resourceSelectedAction = createAction(
  '[Resource] Selected Resource',
  props<{ resource: DataRecordI }>()
);
// update resource only
export const resourceSelectedUpdateAction = createAction(
  '[Resource] Selected Resource Update',
  props<{ resource: DataRecordI }>()
);


export const resourceProjectLineLoadResultAction = createAction(
  '[Resource/api] Load Resource Project Line Results',
  props<{ projectLines: DataRecordI[] }>()
);
export const resourceProjectAllocationLoadResultAction = createAction(
  '[Resource/api] Load Resource Project Allocations Results',
  props<{ projectAllocations: DataRecordI[] }>()
);


/**
 * set date range
 * - initiated:
 * - reducer:
 */
export const resourceDateRangeAction = createAction(
  '[Resource] Date Range',
  props<{ fromMs: number, toMs: number }>()
);

