import { filter, first, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectUi } from './ui.selectors';
import { uiRequestAction } from './ui.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
/**
 * Ui Resolver
 * - loads uiTab into snapshot data
 *
 * route:
 *  path: 'ui/:uiTab',
 *  component: ObjectHomeComponent,
 *  canActivate: [ LoginGuard ],
 *  resolve: {
 *    uiTab: UiResolver
 *  }
 */
export class UiResolver {
    constructor(store) {
        this.store = store;
    }
    /**
     * Load UI from route param uiTab
     */
    resolve(route, state) {
        const uiName = route.params.uiTab;
        return this.store
            .pipe(select(selectUi(uiName)), tap(uiTab => {
            if (!uiTab) {
                this.store.dispatch(uiRequestAction({ uiName }));
            }
        }), filter(uiTab => !!uiTab), first());
    } // resolve
} // UiResolver
/** @nocollapse */ UiResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiResolver_Factory() { return new UiResolver(i0.ɵɵinject(i1.Store)); }, token: UiResolver, providedIn: "root" });
