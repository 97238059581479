import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HelpService {
    /**
     * Help Service
     */
    constructor(http) {
        this.http = http;
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + btoa(HelpService.apiKey + ':X')
        });
    }
    /**
     * @param term search term
     */
    search(term, fd) {
        const theUrl = HelpService.urlSearch + term;
        return this.http.get(theUrl, { headers: this.httpHeaders })
            .pipe(map((result) => {
            result.forEach((sr) => {
                // 'https://support.accorto.com/support/solutions/articles/' + this.id;
                sr.url = fd.url + '/support/solutions/articles/' + sr.id;
            });
            return result.filter((s) => {
                return fd.category === s.category_name // e.g. Accorto
                    && s.status === 2 // published
                    && !s.folder_name.startsWith('*'); // internal
            });
        }));
    } // search
    /**
     * Send Ticket
     * @param ticket freshdesk ticket
     * @return user message
     */
    sendTicket(ticket) {
        const body = JSON.stringify(ticket);
        return this.http.post(HelpService.urlTickets, body, { headers: this.httpHeaders })
            .pipe(map((response) => {
            console.log('sendTicket response', response);
            return `Ticket ${response.id} created - we will get back to you shortly`;
        }), catchError((err) => {
            console.log('sendTicket error', err);
            let msg = 'Error';
            if (err.error && err.error.description) {
                msg += ': ' + err.error.description;
            }
            else if (err.status) {
                msg += ': ' + err.status;
            }
            return of(msg);
        }));
    } // sendTicket
} // HelpService
HelpService.urlTickets = 'https://accorto.freshdesk.com/api/v2/tickets';
HelpService.urlSearch = 'https://accorto.freshdesk.com/api/v2/search/solutions?term=';
HelpService.apiKey = 'Z3Bw7hE7N1DwIHxHPUM';
/** @nocollapse */ HelpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelpService_Factory() { return new HelpService(i0.ɵɵinject(i1.HttpClient)); }, token: HelpService, providedIn: "root" });
