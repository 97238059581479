import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { AccortoCUtil, AccortoService, Logger } from 'accorto';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../projects/accorto/src/lib/accorto.service";
export class ProjectImportService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
        this.log = new Logger('ProjectImportService');
    }
    send(request) {
        this.config.setCRequest(request);
        request.importObject = 'Project;ProjectPhase;ProjectLine';
        const url = this.config.server + '/import';
        this.log.info('send ' + url, request)();
        const body = JSON.stringify(request);
        //
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(url, body, httpOptions)
            .pipe(map(response => AccortoCUtil.createCResponseImport(response)), tap(response => {
            this.log.info('send.response', response)();
        }));
    } // send
} // ProjectImportService
/** @nocollapse */ ProjectImportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectImportService_Factory() { return new ProjectImportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AccortoService)); }, token: ProjectImportService, providedIn: "root" });
