import { GraphNode } from './graph-node';
import { DataRecord } from './data-record';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Graph
 */
export class Graph {

  /**
   * client only absolute end
   * label: Client End Ms
   */
  public endMs: number;

  /**
   * label: Client Graph Height
   */
  public height: number;

  public info: string;

  /**
   * label: Client Info Map
   */
  public infoMap: {[key: string]: string} = {};

  /**
   * label: Client Line Height px
   */
  public lineHeight: number;

  /**
   * label: Client Width per day px
   */
  public xDayFactor: number;

  /**
   * label: Client Margin px
   */
  public xMargin: number;

  /**
   * label: Client Width per month px
   */
  public xMonthFactor: number;

  /**
   * label: Client Width per week px
   */
  public xWeekFactor: number;

  /**
   * label: Client ZoomLevel = D|W|M
   */
  public zoomLevel: string;

  /**
   * label: CheckList Items
   */
  public nodes: GraphNode[] = [];

  /**
   * label: Parent
   */
  public record: DataRecord;

  /**
   * label: CheckList SfId
   */
  public sfId: string;

  /**
   * client only absolute start
   * label: Client Start Ms
   */
  public startMs: number;

  /**
   * label: Client Graph Width
   */
  public width: number;


  /**
   * Create node for record
   */
  public addRecord(record: DataRecord): GraphNode {
    const node = new GraphNode();
    node.record = record;
    node.sfId = record.value('id');
    node.title = record.name;
    this.nodes.push(node);
    return node;
  } // addRecord


  /**
   * Get Node with SfId
   */
  public getNode(sfId: string): GraphNode | undefined {
    for (const node of this.nodes) {
      if (node.sfId === sfId) {
        return node;
      }
    }
    return undefined;
  } // getNode

} // Graph
