/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status-line.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./status-line.component";
var styles_StatusLineComponent = [i0.styles];
var RenderType_StatusLineComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_StatusLineComponent, data: {} });
export { RenderType_StatusLineComponent as RenderType_StatusLineComponent };
export function View_StatusLineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "status-line"]], [[2, "slds-text-color_error", null], [2, "slds-text-color_success", null], [8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isError; var currVal_1 = _co.isSuccess; var currVal_2 = _co.id; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.status; _ck(_v, 1, 0, currVal_3); }); }
export function View_StatusLineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "acc-status-line", [], null, null, null, View_StatusLineComponent_0, RenderType_StatusLineComponent)), i1.ɵdid(1, 573440, null, 0, i2.StatusLineComponent, [], null, null)], null, null); }
var StatusLineComponentNgFactory = i1.ɵccf("acc-status-line", i2.StatusLineComponent, View_StatusLineComponent_Host_0, { id: "id", message: "message", error: "error" }, {}, []);
export { StatusLineComponentNgFactory as StatusLineComponentNgFactory };
