/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-record-selector.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./data-record-selector.component";
var styles_DataRecordSelectorComponent = [i0.styles];
var RenderType_DataRecordSelectorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DataRecordSelectorComponent, data: {} });
export { RenderType_DataRecordSelectorComponent as RenderType_DataRecordSelectorComponent };
function View_DataRecordSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getValue(_v.parent.context.$implicit, _v.context.$implicit); _ck(_v, 2, 0, currVal_0); }); }
function View_DataRecordSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [["class", "slds-hint-parent"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["type", "checkbox"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataRecordSelectorComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.columnNames; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.isSelected ? "table-row" : "none"); _ck(_v, 0, 0, currVal_0); }); }
export function View_DataRecordSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "slds-form-element slds-form-element_horizontal slds-m-top_small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "slds-form-element__label"], ["for", "select-input"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Search"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "slds-form-element__control slds-input-has-fixed-addon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["class", "slds-input"], ["id", "select-input"]], null, [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.onKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "slds-form-element__addon"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["style", "max-height: 400px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "slds-scrollable_y"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "table", [["class", "slds-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataRecordSelectorComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.records; _ck(_v, 12, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.summary; _ck(_v, 6, 0, currVal_0); }); }
export function View_DataRecordSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "acc-data-record-selector", [], null, null, null, View_DataRecordSelectorComponent_0, RenderType_DataRecordSelectorComponent)), i1.ɵdid(1, 573440, null, 0, i3.DataRecordSelectorComponent, [], null, null)], null, null); }
var DataRecordSelectorComponentNgFactory = i1.ɵccf("acc-data-record-selector", i3.DataRecordSelectorComponent, View_DataRecordSelectorComponent_Host_0, { records: "records", columnNames: "columnNames" }, { recordSelected: "recordSelected" }, []);
export { DataRecordSelectorComponentNgFactory as DataRecordSelectorComponentNgFactory };
