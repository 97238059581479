import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
/**
 * Modal Service
 */
export class ModalService {
    constructor() {
        this.subject = new Subject();
    }
    /**
     * Show Confirmation - yes-no
     * @param modalInfo modal details
     * @param yesFn business function
     * @param noFn business function - also cancel/close
     */
    doConfirm(modalInfo, yesFn, noFn) {
        modalInfo.showCancel = false;
        modalInfo.yesFn = () => {
            this.subject.next(); // closes modal
            yesFn(); // business callback
        };
        modalInfo.noFn = () => {
            this.subject.next();
            noFn();
        };
        modalInfo.cancelFn = () => {
            this.subject.next();
            noFn();
        };
        this.subject.next(modalInfo); // publish to component
    } // doConfirm
    /**
     * Show Confirmation - yes-no-cancel
     * @param modalInfo modal details
     * @param yesFn business function
     * @param noFn business function
     * @param cancelFn business function - also close
     */
    doConfirmCancel(modalInfo, yesFn, noFn, cancelFn) {
        modalInfo.showCancel = true;
        modalInfo.yesFn = () => {
            this.subject.next(); // closes modal
            yesFn(); // business callback
        };
        modalInfo.noFn = () => {
            this.subject.next();
            noFn();
        };
        modalInfo.cancelFn = () => {
            this.subject.next();
            cancelFn();
        };
        this.subject.next(modalInfo); // publish to component
    } // doConfirm
    /**
     * Modal Info for Component
     */
    getModalInfo() {
        return this.subject.asObservable();
    }
} // ModalService
/** @nocollapse */ ModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalService_Factory() { return new ModalService(); }, token: ModalService, providedIn: "root" });
