import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AccortoService, appStatus, DataRecordF, DataRecordI, ModalInfo, ModalService, ProjectLineSharingD } from 'accorto';
import { Alloc } from '../alloc';
import { ResourceInfo } from '../resource-info';
import { AllocationInfoDay } from '../allocation-info-day';
import { ResourceAllocationInfo } from './resource-allocation-info';
import { AppState } from '../../reducers';
import { selectProjectTeItems } from '../../te-item/te-item.selectors';
import { ResourceService } from '../../resource/resource.service';
import { PsaUtil } from '../../psa-util';

@Component({
  selector: 'psa-alloc-resource',
  templateUrl: './alloc-resource.component.html',
  styleUrls: [ './alloc-resource.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class AllocResourceComponent
  extends Alloc implements OnInit, OnDestroy {

  resourceAllocations: DataRecordI[];
  //
  resourceInfo: ResourceInfo;
  allocationInfoList: ResourceAllocationInfo[] = [];

  constructor(route: ActivatedRoute,
              router: Router,
              store: Store<AppState>,
              conf: AccortoService,
              private service: ResourceService,
              private modalService: ModalService) {
    super('AllocResource', 'R', '/alloc-resource', 'Resource Detail Allocation',
      route, router, store, conf);
  }

  /**
   * ReQuery
   */
  doRefresh() {
    this.log.debug('doRefresh', (this.resource ? this.resource.id : ''))();
    if (this.resource != null && this.resource.id) {
      this.busyPlus('res alloc');
      this.service.loadResourceAllocations(this.resource.id, undefined, undefined)
        .subscribe((records: DataRecordI[]) => {
          this.setResourceAllocations(records); // active or inactive
          this.busyMinus('res alloc');
        });
    }
  } // doRefresh

  public ngOnDestroy(): void {
    super.ngDestroy();
  }

  public ngOnInit() {
    super.ngInit(); // projectAll currentProject resourceMap resourceAll currentResource

    /* allocations of selected project
    this.subscriptions.push(this.store.pipe(select(selectCurrentProjectAllocations))
      .subscribe((projectAllocations) => {
        // this.log.debug('selectCurrentProjectAllocations', projectAllocations)();
        this.setProjectAllocations(projectAllocations);
        this.busyCount--;
        this.busy = this.busyCount > 0;
      }));
    */

    // actuals (items)
    this.subscriptions.push(this.store.pipe(select(selectProjectTeItems))
      .subscribe((projectTeItems) => {
        this.log.debug('ngInit selectProjectTeItems', projectTeItems)();
      }));

    this.store.dispatch(appStatus({ status: 'alloc-resource' }));
  } // ngOnInit

  onChangeGranularity(event: Event) {
    super.onChangeGranularity(event); // buildDateRange
    this.buildResource();
    this.buildProjectLines();
  }

  onDateFromSelected(dateFrom: Date) {
    super.onDateFromSelected(dateFrom); // buildDateRange
    this.buildResource();
    this.buildProjectLines();
  }

  onDateToSelected(dateTo: Date) {
    super.onDateToSelected(dateTo); // buildDateRange
    this.buildResource();
    this.buildProjectLines();
  }

  /**
   * Allocation Input Change
   */
  onInputChange(event: Event, ra: ResourceAllocationInfo, rd: AllocationInfoDay) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const numValue = Number(value);
    target.value = ra.setValueDay(numValue, rd);
    ra.updateSum();
    this.updateResourceInfo(ra, rd);
    this.log.debug('onInputChange', value, numValue, target.value)();
    this.isSaveDisabled = false;
  } // onInputChange

  /**
   * Allocation DoubleClick
   */
  onInputDblClick(event: Event, ra: ResourceAllocationInfo, rd: AllocationInfoDay) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const numValue = Number(value);
    target.value = ra.toggleDay(rd);
    ra.updateSum();
    this.updateResourceInfo(ra, rd);
    this.log.debug('onInputDblClick', value, numValue, target.value)();
    this.isSaveDisabled = false;
  } // onInputDblClick

  /**
   * Reset - does not reset Date From/To, Granularity, Resource
   */
  onReset() {
    this.log.debug('onReset')();
    // changes
    for (const alloc of this.resourceAllocations) {
      DataRecordF.reset(alloc);
    }
    //
    this.buildDateRange();
    this.buildResource();
    this.buildProjectLines();
    //
    this.isSaveDisabled = true;
  } // onResource

  /**
   * Change Allocation Line
   * @param ra allocation info
   */
  onProjectLineResourceChange(ra: ResourceAllocationInfo) {
    const plannedEffort = DataRecordF.valueNumber(ra.allocation, PsaUtil.AllocProjectLineEffortPlan, null);
    this.allocationFormSet(ra.sum, this.resourceInfo.capacityDay, this.resourceInfo.daysWeek, plannedEffort);
    //
    const modal = new ModalInfo(this.resourceInfo.label, 'Planned Effort for ' + ra.projectName + ': ' + ra.projectLineName);
    this.modalService.doConfirm(modal, () => {
      this.allocationFormProcess(ra);
      ra.updateSum();
      this.updateResourceInfo(ra, undefined);
      this.isSaveDisabled = false;
    }, () => {
    });

  } // onProjectLineResourceChange

  /**
   * Delete Allocation Line
   * @param ra allocation info
   */
  onProjectLineResourceDelete(ra: ResourceAllocationInfo) {
    this.log.debug('onProjectLineResourceDelete', ra)();
    for (const day of ra.dayList) {
      day.setValue(0);
    }
    const allocId = ra.allocation.id;
    if (allocId) { // saved
      // find by id | pl/res as it could be changed
      for (const aa of this.resourceAllocations) {
        if (aa.id === allocId) {
          aa.isActive = false;
          aa.changeMap[ ProjectLineSharingD.isActive.n ] = 'false';
          aa.changeMap[ ProjectLineSharingD.plannedEffort.n ] = '0';
          aa.changeMap[ ProjectLineSharingD.plannedDetails.n ] = '{}';
          aa.details = {};
          break;
        }
      }
    }
    // remove
    const index = this.allocationInfoList.indexOf(ra);
    if (index !== -1) {
      this.allocationInfoList.splice(index, 1);
    }
    this.updateResourceInfo(ra, undefined);
    this.isSaveDisabled = false;
  } // onProjectLineResourceDelete


  /**
   * Save
   */
  onSave(event: Event) {
    // Allocations
    const projectAllocations: DataRecordI[] = [];
    for (const alloc of this.resourceAllocations) {
      if (alloc.isActive && DataRecordF.isChanged(alloc)) {
        const aa = DataRecordF.clone(alloc, true);
        projectAllocations.push(aa);
      }
    }
    this.log.debug('onSave - allocations', projectAllocations)();
    this.busyPlus('save');
    if (projectAllocations.length > 0) {
      // this.store.dispatch(resourceAllocationsSaveRequestAction({ projectAllocations }));
      this.service.saveList(projectAllocations)
        .subscribe((response => {
          this.busyMinus('save');
          if (!response.error) {
            this.doRefresh();
          }
        }));
    }
  } // onSave

  /**
   * Set dateFirstMs/dateLastMs from records
   * (called from buildDateRange)
   */
  protected buildDateRangeFromRecords() {
    for (const ra of this.resourceAllocations) {
      if (ra.isActive) {
        const ps = DataRecordF.valueNumber(ra, 'projectLine.startPlan');
        if (ps && (!this.dateFirstMs || this.dateFirstMs > ps)) {
          this.dateFirstMs = ps;
        }
        const pe = DataRecordF.valueNumber(ra, 'projectLine.endPlan');
        if (pe && (!this.dateLastMs || this.dateLastMs < pe)) {
          this.dateLastMs = pe;
        }
      }
    } // active
  } // buildDateRangeFromRecords

  protected setResource(resource: DataRecordI, updateRoute: boolean = true) {
    super.setResource(resource, updateRoute);
    this.doRefresh();
  }

  /**
   * Build Project Line Info = allocationInfoList
   * from resourceAllocations
   */
  private buildProjectLines() {
    // build info
    this.allocationInfoList = [];
    for (const ra of this.resourceAllocations) {
      if (ra.isActive) {
        this.allocationInfoList.push(new ResourceAllocationInfo(ra, this.resource));
      }
    }

    // build days
    const weekly = this.granularity === this.granularityWeek;
    for (const ai of this.allocationInfoList) {
      ai.buildDayList(weekly, this.headerInfoList);
    }
    // sort lines
    this.allocationInfoList.sort((one, two) => {
      const cmp = one.projectName.localeCompare(two.projectName);
      if (cmp === 0) {
        return one.projectLineName.localeCompare(two.projectLineName);
      }
      return cmp;
    });

    this.log.debug('buildProjectLines #' + this.allocationInfoList.length)();
  } // buildProjectLines

  /**
   * Build Resource Info = resourceInfo
   * from resourceAllocations
   */
  private buildResource() {
    this.resourceInfo = new ResourceInfo(this.resource);
    for (const ra of this.resourceAllocations) {
      if (ra.isActive) {
        this.resourceInfo.addAllocation(ra);
      }
    }

    // build days
    const weekly = this.granularity === this.granularityWeek;
    this.resourceInfo.buildDayList(weekly, this.headerInfoList);

    this.log.debug('buildResource')();
  } // buildResource

  private setResourceAllocations(records: DataRecordI[]) {
    this.log.debug('setResourceAllocations', records)();
    this.resourceAllocations = DataRecordF.cloneArray(records); // descending
    this.buildDateRange(); // calls buildDateRangeFromRecords, loadResourcesAllocations
    this.buildResource();
    this.buildProjectLines();
  } // setResourceAllocations

  /**
   * Update Resource sum/total
   * @param ra allocation
   * @param rd allocation day
   */
  private updateResourceInfo(ra: ResourceAllocationInfo, rd: AllocationInfoDay) {
    if (rd) {
      this.resourceInfo.update(rd.ms); // single day
    } else {
      this.resourceInfo.updateSum();
    }
  } // updateResourceInfo

} // AllocResource
