/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help-button.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../help/help.component.ngfactory";
import * as i3 from "../help/help.component";
import * as i4 from "../help/help.service";
import * as i5 from "../accorto.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./help-button.component";
var styles_HelpButtonComponent = [i0.styles];
var RenderType_HelpButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HelpButtonComponent, data: {} });
export { RenderType_HelpButtonComponent as RenderType_HelpButtonComponent };
function View_HelpButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "acc-help", [], null, [[null, "closing"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closing" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HelpComponent_0, i2.RenderType_HelpComponent)), i1.ɵdid(1, 573440, null, 0, i3.HelpComponent, [i4.HelpService, i5.AccortoService, i6.DomSanitizer], { title: [0, "title"], message: [1, "message"], vimeoId: [2, "vimeoId"], youtubeId: [3, "youtubeId"] }, { closing: "closing" }), i1.ɵncd(0, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.message; var currVal_2 = _co.vimeoId; var currVal_3 = _co.youtubeId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_HelpButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "slds-button slds-button_icon slds-button_icon-border-filled"], ["title", "Help"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["aria-hidden", "true"], ["class", "slds-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/icons/utility-sprite/svg/symbols.svg#help"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "slds-assistive-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Help"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpButtonComponent_1)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showHelp; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_HelpButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "acc-help-button", [], null, null, null, View_HelpButtonComponent_0, RenderType_HelpButtonComponent)), i1.ɵdid(1, 49152, null, 0, i8.HelpButtonComponent, [], null, null)], null, null); }
var HelpButtonComponentNgFactory = i1.ɵccf("acc-help-button", i8.HelpButtonComponent, View_HelpButtonComponent_Host_0, { title: "title", message: "message", vimeoId: "vimeoId", youtubeId: "youtubeId" }, {}, ["*"]);
export { HelpButtonComponentNgFactory as HelpButtonComponentNgFactory };
