import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AccortoService } from '../accorto.service';
import { isPlatformBrowser } from '@angular/common';
import { Logger } from '../log/logger';

/** global variable - reference to index js */
  // tslint:disable-next-line:ban-types
declare var ga: Function;

/**
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
 * https://github.com/googleanalytics/autotrack
 *
 * https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/google.analytics/index.d.ts
 *
 * Track4d - (UA-32129178-12)
 * Pick4d - (UA-32129178-15)
 * Quando - (UA-32129178-5)
 * Accorto - (UA-32129178-2)
 * Check4Sf - (UA-32129178-14)
 */
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private timeSincePageLoad: number;

  private log: Logger = new Logger('GA');

  /**
   * Setup
   * - Add script to index.html
   * - Add to constructor of app.component
   */
  constructor(router: Router,
              config: AccortoService,
              @Inject(PLATFORM_ID) platform) {
    const uaCode = config.gaCode ? config.gaCode : 'UA-32129178-2';
    if (isPlatformBrowser(platform)) {
      try {
        if (ga) {
          this.log.debug(uaCode)();
          ga('create', uaCode, 'auto');
          ga('send', 'pageview');
          router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
              this.pageView(evt.urlAfterRedirects);
            }
          });
        } else {
          this.log.warn('NotFound', uaCode)();
        }
      } catch (ex) {
        this.log.warn(uaCode, ex)();
      }
    }
  } // constructor

  /**
   * Set Session Info
   * @param userId user id
   * @param dim1 dimension 1 - org name
   */
  setSession(userId: string, dim1: string) {
    if (userId) {
      ga('set', 'userId', userId);
    }
    if (dim1) { // session org name
      ga('set', 'dimension1', dim1);
    }
  } // setSession

  /**
   * @param url current url
   */
  public pageView(url: string) {
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/pages
    ga('set', 'page', url);
    ga('send', 'pageview');
    this.log.debug('pageView', url)();

    if (window.performance && !this.timeSincePageLoad) {
      this.timeSincePageLoad = Math.round(performance.now());
      this.timing('initial', 'load', this.timeSincePageLoad);
    }
  } // pageView

  public timing(category: string, name: string, value: number) {
    ga('send', 'timing', category, name, value); // label
    this.log.debug('timing', name + '=' + value)();
  } // timing


  /**
   * @param action event action e.g. play
   * @param category event category e.g. videos
   * @param label event label e.g. video title
   * @param value integer value
   */
  public event(category: string, action: string, label: string, value: number) {
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
    ga('send', 'event', category, action, label, value);
    this.log.debug('event', action)();
  } // event


  public exception(description: string, fatal: boolean = false) {
    ga('send', 'exception', {
      exDescription: description,
      exFatal: fatal
    });
  } // exception

} // GoogleAnalyticsService
