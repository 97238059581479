import { CResponseImportColumn } from './c-response-import-column';
import { CRequest } from './c-request';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CRequest
 * name: CRequestImport
 */
export class CRequestImport extends CRequest {

  /**
   * label: Import Data
   */
  public importData: string;

  /**
   * label: Import First Line is Heading
   */
  public importIsFirstLineHeading: boolean;

  /**
   * e.g. Project,..
   * label: Import Type
   */
  public importObject: string;

  /**
   * label: Headings with Import Column Mappings
   */
  public headingColumns: CResponseImportColumn[] = [];

  /**
   * label: Mapping is Complete
   */
  public mappingIsComplete: boolean;

  /**
   * label: Mapping Name
   */
  public mappingName: string;

  /**
   * label: Mapping infos
   */
  public mappingInfos: string;

  /**
   * label: Test Only
   */
  public testMode: boolean;

} // CRequestImport
