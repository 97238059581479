import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Global Error Handler
 */
@Injectable({
  providedIn: 'root'
})
export class LogService implements ErrorHandler {

  /**
   * Global Error Handler
   */
  constructor(private injector: Injector) {
  }

  /**
   * Global ErrorHandler call
   *
   * console.info('--------');
   * let xx: string = undefined;
   * const dd: number = xx.length; // TypeError
   * console.info('--------');
   *
   * throw new Error('TheError');
   * @param error error
   */
  public handleError(error: any): void {
    const theMsg = this.handle(error);
    console.error('LogService.handleError', theMsg);
  } // handleError

  /**
   * Handle Error
   * @param error error
   * @return string info
   */
  public handle(error: any): string {
    // console.error('LogService.handle', error); // stringify(error));
    const message = error.message ? error.message : error.toString();

    if (error instanceof HttpErrorResponse) {
      const err = error as HttpErrorResponse;
      /*
      err.name;
      err.message;
      err.error;
      err.status;
      */
    } else if (error instanceof TypeError) {
      const err = error as TypeError;
      return 'name=' + err.name + ' \nmsg=' + err.message + ' \nstack=' + err.stack;

    } else if (error instanceof Error) {
      const err = error as Error;
      return 'name=' + err.name + ' \nmsg=' + err.message + ' \nstack=' + err.stack;
    } else {

    }
    return message;
  } // handleError

  private send(error: string) {
    // https://medium.com/@aleixsuau/error-handling-angular-859d529fa53a
    //  const notificationService = this.injector.get(NotificationService);
    //  if (navigator.onLine) {
    //  }
  }

} // LogService
