import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

/**
 * Busy Spinner
 * use slds-is-relative on parent to restrict busy area
 */
@Component({
  selector: 'acc-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: [ './spinner.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit {

  @Input() size: string = 'slds-spinner_medium';
  @Input() color: string = 'slds-spinner_brand';
  @Input() message: string = 'Loading ...';

  constructor() {
  }

  ngOnInit() {
  }

} // SpinnerComponent
