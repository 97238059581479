import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'acc-status-line',
  templateUrl: './status-line.component.html',
  styleUrls: [ './status-line.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class StatusLineComponent implements OnChanges {

  @Input() id: string = 'status-line';
  @Input() message: string;
  @Input() error: string;

  /** the actual status */
  status: string;
  isError: boolean = false;
  isSuccess: boolean = false;

  constructor() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.id) {
      this.id = 'status-message';
    }
    this.status = this.error;
    this.isError = this.error !== undefined && this.error.length > 0;
    this.isSuccess = false;
    if (this.message) {
      if (this.status === undefined) {
        this.status = this.message;
        const msg = this.message.toLowerCase();
        if (msg.startsWith('error') || msg.startsWith('warn')) {
          this.isError = true;
        } else if (msg.startsWith('ok') || msg.startsWith('success')) {
          this.isSuccess = true;
        }
      } else {
        this.status += ' - ' + this.message;
      }
    }
  } // ngOnChanges

} // StatusLineComponent
