/**
 * Date utilities
 */
export class DateUtil {

  /** One Day in MS */
  static readonly ONEDAY = 1000 * 60 * 60 * 24;
  /** One WEEK in MS */
  static readonly ONEWEEK = 1000 * 60 * 60 * 24 * 7;
  /** Months */
  static readonly MONTHS = [ 'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December' ];

  // default week Start Day 0..6 Sun..Sat
  static defaultWeekStartDay: number = 0;
  // default weekend days
  static defaultWeekendDays: string = 'Sa,Su';
  // default weekend days (0..6)
  static defaultWeekendList: number[] = [ 0, 6 ];

  /**
   * Get Day Only
   * @param theDate the date (utc)
   * @return UTC day only
   */
  static day(theDate: Date): Date {
    if (theDate) {
      return new Date(Date.UTC(theDate.getUTCFullYear(), theDate.getUTCMonth(), theDate.getUTCDate()));
    }
    return DateUtil.today();
  }

  // format YYYY-MM-DD
  static formatYYYYMMDD(utc: Date): string {
    const mm = utc.getUTCMonth() + 1;
    const dd = utc.getUTCDate();
    return utc.getUTCFullYear()
      + (mm < 10 ? '-0' + mm : '-' + mm)
      + (dd < 10 ? '-0' + dd : '-' + dd);
  } // formatYYYYMMDD

  static formatIso(ms: number): string {
    if (ms && ms > 0) {
      const ss = new Date(ms).toISOString();
      return ss.replace('T00:00:00.000Z', '');
    }
    return '-';
  }

  /**
   * Get List of days, e.g. [0, 6]
   * @param dayList e.g. Sa,Su
   */
  static getDayList(dayList: string): number[] {
    const l: number[] = [];
    if (dayList) {
      if (dayList.includes('Su')) {
        l.push(0);
      }
      if (dayList.includes('Mo')) {
        l.push(1);
      }
      if (dayList.includes('Tu')) {
        l.push(2);
      }
      if (dayList.includes('We')) {
        l.push(3);
      }
      if (dayList.includes('Th')) {
        l.push(4);
      }
      if (dayList.includes('Fr')) {
        l.push(5);
      }
      if (dayList.includes('Sa')) {
        l.push(6);
      }
    }
    return l;
  } // getDayList

  /**
   * @param value base string
   * @return formatted date string yyyy-mm-dd or today
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
   */
  static inputValue(value: string): string {
    if (value) {
      const dateInt = Number(value);
      if (!isNaN(dateInt) && dateInt > 1000000000000) {
        const dateDate = new Date(dateInt);
        return DateUtil.formatYYYYMMDD(dateDate);
      }
    }
    return DateUtil.formatYYYYMMDD(DateUtil.today());
  } // inputValue

  /**
   * @param date utc date
   * @param dateStart utc date
   * @param dateEnd utc date
   * @return true if between
   */
  static isBetween(date: Date, dateStart: Date, dateEnd: Date): boolean {
    if (date && dateStart && dateEnd) {
      const time = date.getUTCFullYear() * 10000 + date.getUTCMonth() * 100 + date.getUTCDate();
      const timeStart = dateStart.getUTCFullYear() * 10000 + dateStart.getUTCMonth() * 100 + dateStart.getUTCDate();
      const timeEnd = dateEnd.getUTCFullYear() * 10000 + dateEnd.getUTCMonth() * 100 + dateEnd.getUTCDate();
      return time >= timeStart && time <= timeEnd;
    }
    return false;
  }

  /**
   * @param date1 utc date
   * @param date2 utc date
   * @return true if same day
   */
  static isSameDay(date1: Date, date2: Date): boolean {
    if (date1 && date2) {
      return date1.getUTCFullYear() === date2.getUTCFullYear()
        && date1.getUTCMonth() === date2.getUTCMonth()
        && date1.getUTCDate() === date2.getUTCDate();
    }
    return false;
  }

  /**
   * @param date1 utc date
   * @return true if same day
   */
  static isToday(date1: Date): boolean {
    return DateUtil.isSameDay(date1, DateUtil.today());
  }

  /**
   * Is the date a weekend day
   * @param date the date
   * @param weekendDays optional list of days
   */
  static isWeekend(date: Date, weekendDays?: string): boolean {
    return DateUtil.isWeekendDay(date.getUTCDay(), weekendDays);
  }

  /**
   * Is this the first day in week
   * @param date the date
   * @param weekStartDay optional 0..6 Sun..Sat
   */
  static isWeekStartDay(date: Date, weekStartDay?: number): boolean {
    const startDay = DateUtil.defaultWeekStartDay;
    return date.getUTCDay() === startDay;
  }

  /**
   * Is the day a weekend day
   * @param utcDay the day 0..6
   * @param weekendDays optional list of days
   */
  static isWeekendDay(utcDay: number, weekendDays?: string): boolean {
    const dayList: number[] = weekendDays == null ? DateUtil.defaultWeekendList : DateUtil.getDayList(weekendDays);
    // console.debug('isWeekendDay=' + utcDay, weekendDays, dayList, 'default=' + DateUtil.defaultWeekendDays,
    //   DateUtil.defaultWeekendList, DateUtil.defaultWeekStartDay);
    return dayList.includes(utcDay); // Su..Sa 0..6
  }

  /**
   * @param month number 0..11
   * @return month name
   */
  static monthName(month: number) {
    return DateUtil.MONTHS[ month ];
  }

  /**
   * Parse Date to Utc
   * @param value date string
   * @return parsed Date
   */
  static parseDate(value: string): Date | undefined {
    // Date.parse('07/01/2017') = 1498892400000
    //  GMT: Saturday, July 1, 2017 7:00:00 AM
    //  Your time zone: Saturday, July 1, 2017 12:00:00 AM GMT-07:00 DST (X)

    // Date.parse('2017-07-01') = 1498867200000
    //  GMT: Saturday, July 1, 2017 12:00:00 AM (X)
    //  Your time zone: Friday, June 30, 2017 5:00:00 PM GMT-07:00 DST

    if (value) {
      const time = Date.parse(value);
      if (!Number.isNaN(time)) {
        const local = new Date(value);
        if (value.includes('-')) {
          return new Date(Date.UTC(local.getUTCFullYear(), local.getUTCMonth(), local.getUTCDate()));
        }
        return new Date(Date.UTC(local.getFullYear(), local.getMonth(), local.getDate()));
      }
    }
    return undefined;
  } // parseDate

  /**
   * Set Week/end info
   * - called from login.effect
   * @param firstDayOfWeek first day of week e.g. 1..7 Sun..Sat
   * @param weekendDays days e.g. Sa,Su
   */
  static setDefaultWeekInfo(firstDayOfWeek: number, weekendDays: string) {
    if (firstDayOfWeek != null && firstDayOfWeek > 0) {
      DateUtil.defaultWeekStartDay = firstDayOfWeek - 1;
    }
    if (weekendDays != null) {
      DateUtil.defaultWeekendDays = weekendDays;
      DateUtil.defaultWeekendList = DateUtil.getDayList(weekendDays);
    }
  } // setDefaultWeekInfo

  /**
   * Parse Input
   * @param inpValue user input
   * @return hours
   */
  static timeParse(inpValue: string): number {
    if (inpValue == null || inpValue === '') {
      return undefined;
    }
    // (a) hh.ff - hours with fraction or minutes (>= 15)
    let hours: number = Number(inpValue);
    if (!isNaN(hours)) { // valid
      if (hours >= 15 && inpValue.indexOf('.') === -1) {
        return hours / 60; // minutes
      }
      return hours;
    }
    // (b) hh:mm - hours with minutes
    let index: number = inpValue.indexOf(':');
    if (index >= 0) {
      hours = 0;
      if (index > 0) {
        const hoursString: string = inpValue.substring(0, index);
        hours = Number(hoursString);
        if (isNaN(hours)) {
          return hours; // invalid
        }
      }
      const minString: string = inpValue.substring(index + 1);
      if (minString.length > 0) {
        const min: number = Number(minString);
        if (isNaN(min)) {
          return min; // invalid
        }
        hours += (min / 60);
      }
      return hours;
    }
    // (c) hh,ff (decimal comma)
    index = inpValue.indexOf(',');
    if (index >= 0) {
      const pointString: string = inpValue.replace(',', '.');
      hours = Number(pointString);
      return hours; // might be invalid
    }
    // (d) (d)ay (h)ours (m)minutes
    hours = 0;
    let remainingString: string = inpValue;
    let hasDHM: boolean = false;
    index = remainingString.indexOf('d');
    if (index !== -1) {
      const valueString: string = remainingString.substring(0, index);
      if (valueString.length > 0) {
        const valueNum: number = Number(valueString);
        if (isNaN(valueNum)) {
          return valueNum; // invalid
        }
        hours = valueNum * 8;
        hasDHM = true;
      }
      remainingString = remainingString.substring(index + 1);
      // console.log('d', hours, remainingString);
    }
    index = remainingString.indexOf('h');
    let hasH = false;
    if (index !== -1) {
      hasH = true;
      const valueString: string = remainingString.substring(0, index);
      if (valueString.length > 0) {
        const valueNum: number = Number(valueString);
        if (isNaN(valueNum)) {
          return valueNum; // invalid
        }
        hours += valueNum;
        hasDHM = true;
      }
      remainingString = remainingString.substring(index + 1);
      // console.log('h', hours, remainingString);
    }
    index = remainingString.indexOf('m');
    if (index !== -1) {
      const valueString: string = remainingString.substring(0, index);
      if (valueString.length > 0) {
        const valueNum: number = Number(valueString);
        if (isNaN(valueNum)) {
          return valueNum; // invalid
        }
        hours += valueNum / 60;
        hasDHM = true;
      }
      remainingString = remainingString.substring(index + 1);
      // console.log('m', hours, remainingString);
    } else if (hasH && remainingString) {
      const valueString: string = remainingString;
      if (valueString.length > 0) {
        const valueNum: number = Number(valueString);
        if (isNaN(valueNum)) {
          return valueNum; // invalid
        }
        hours += valueNum / 60;
        hasDHM = true;
      }
    }
    if (hasDHM) {
      return hours;
    }
    return Number.NaN; // cannot parse
  } // timeParse

  /**
   * End of the Week
   * @param date the dat
   * @param weekStartDay 0..6 Sun..Sat  (firstDayOfWeek=1..7)
   * @return end of week
   */
  static toEndOfWeek(date: Date, weekStartDay?: number): Date {
    if (weekStartDay == null) {
      weekStartDay = DateUtil.defaultWeekStartDay;
    }
    let theDate = date;
    if (theDate.getUTCDay() === weekStartDay) {
      theDate = new Date(theDate.getTime() + 6 * DateUtil.ONEDAY);
      return theDate;
    }
    while (theDate.getUTCDay() !== weekStartDay) {
      theDate = new Date(theDate.getTime() + DateUtil.ONEDAY);
    }
    theDate = new Date(theDate.getTime() - DateUtil.ONEDAY);
    return theDate;
  }

  /**
   * Start of Week
   * @param date the day (date only)
   * @param weekStartDay 0..6 Sun..Sat  (firstDayOfWeek=1..7)
   * @return start of week
   */
  static toStartOfWeek(date: Date, weekStartDay?: number): Date {
    if (weekStartDay == null) {
      weekStartDay = DateUtil.defaultWeekStartDay;
    }
    let theDate = date;
    while (theDate.getUTCDay() !== weekStartDay) {
      // console.log('toStartOfWeek', theDate.toISOString(), theDate.getUTCDay(), weekStartDay);
      theDate = new Date(theDate.getTime() - DateUtil.ONEDAY);
    }
    // console.log('toStartOfWeek', date.toISOString(), theDate.toISOString(), theDate.getUTCDay(), weekStartDay);
    return theDate;
  }

  /**
   * Get Day Only
   * @return UTC today
   */
  static today(): Date {
    const dd = new Date();
    return new Date(Date.UTC(dd.getFullYear(), dd.getMonth(), dd.getDate()));
  }

} // DateUtil
