import { Action, createReducer, on } from '@ngrx/store';
import { DataRecordI } from 'accorto';
import * as TeItemActions from './te-item.actions';

export const teItemFeatureKey = 'teItem';

export interface TeItemState {
  projectItems: DataRecordI[];
  resourceItems: DataRecordI[];

  restrictProjectIds: string[];
  restrictResourceIds: string[];
}

export const initialState: TeItemState = {
  projectItems: [],
  resourceItems: [],
  restrictProjectIds: undefined,
  restrictResourceIds: undefined,
};


const teItemReducer0 = createReducer(
  initialState,

  on(TeItemActions.itemLoadProjectRequestAction, (state, action) => {
    return {
      ...state,
      restrictProjectIds: [ action.projectId ]
    };
  }),

  on(TeItemActions.itemLoadProjectResultAction, (state, action) => {
    return {
      ...state,
      projectItems: action.items
    };
  }),

  on(TeItemActions.itemLoadResourceResultAction, (state, action) => {
    return {
      ...state,
      resourceItems: action.items
    };
  })
);


export function teItemReducer(state: TeItemState, action: Action): TeItemState {
  return teItemReducer0(state, action);
}
