import { DomSanitizer } from '@angular/platform-browser';
import { SafeHtml } from '@angular/platform-browser';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: FkInfo
 */
export class FkInfo {

  /**
   * label: Optional Description
   */
  public description: string;

  /**
   * label: Html Display
   */
  public display: SafeHtml;

  /**
   * label: Optional Id
   */
  public fkId: string;

  /**
   * label: Fk Table Name
   */
  public fkTable: string;

  /**
   * label: Display - is matched
   */
  public isMatched: boolean;

  /**
   * label: Client only - selected
   */
  public isSelected: boolean;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Optional Parent Id
   */
  public parentId: string;

  /**
   * label: Optional 2md Parent Id
   */
  public parent2Id: string;

  /**
   * label: Owning Tenant Id
   */
  public tenantId: string;

  /**
   * label: Valid for FK selection
   */
  public validForSelection: boolean;

  /**
   * label: Value Map
   */
  public valueMap: {[key: string]: string} = {};


  /**
   * FkInfo Sort compare by label
   */
  public static compare(a: FkInfo, b: FkInfo): number {
    const al = a.label;
    const bl = b.label;
    if (al < bl) {
      return -1;
    }
    if (al > bl) {
      return +1;
    }
    return 0;
  } // compare


  /**
   * clone FkInfo
   */
  public clone(isSelected: boolean): FkInfo {
    const nv = new FkInfo();
    nv.fkTable = this.fkTable ? this.fkTable : '';
    nv.fkId = this.fkId ? this.fkId : '';
    nv.label = this.label ? this.label : '';
    nv.description = this.description ? this.description : '';
    nv.parentId = this.parentId;
    nv.parent2Id = this.parent2Id;
    nv.isSelected = isSelected;
    nv.validForSelection = this.validForSelection;
    return nv;
  } // clone


  /**
   * create empty FkInfo
   */
  public empty(fkTable?: string): FkInfo {
    this.label = '';
    this.display = '';
    this.fkId = '';
    this.fkTable = fkTable ? fkTable : '-';
    this.description = '';
    this.isSelected = false;
    return this;
  } // empty


  /**
   * initialize with label, id
   */
  public init(label: string, fkId?: string, fkTable?: string): FkInfo {
    this.label = label;
    this.display = label;
    this.fkId = fkId ? fkId : label;
    this.fkTable = fkTable ? fkTable : '-';
    this.description = '';
    this.isMatched = true;
    this.isSelected = false;
    this.validForSelection = true;
    return this;
  } // init


  /**
   * table+id
   */
  public key(): string {
    return this.fkTable + '_' + this.fkId;
  } // key


  /**
   * match term to the label and description - set isMatched and display
   */
  public match(term: string, sanitizer: DomSanitizer): boolean {
    let matchLabel = this.label;
    let matchDescription = this.description;
    this.isMatched = true;
    // match term
    if (term) {
      this.isMatched = false;
      const regexp = new RegExp(term, 'i');
      if (matchLabel.match(regexp)) {
        this.isMatched = true;
        const result: RegExpExecArray = regexp.exec(matchLabel);
        const term2 = result[ 0 ];
        // highlight first
        matchLabel = matchLabel.substring(0, result.index)
          + '<mark>' + matchLabel.substr(result.index, term.length) + '</mark>'
          + matchLabel.substring(result.index + term.length);
      }
      if (matchDescription) {
        if (matchDescription.match(regexp)) {
          this.isMatched = true;
          const result: RegExpExecArray = regexp.exec(matchDescription);
          const term2 = result[ 0 ];
          // highlight first
          matchDescription = matchDescription.substring(0, result.index)
            + '<mark>' + matchDescription.substr(result.index, term.length) + '</mark>'
            + matchDescription.substring(result.index + term.length);
        }
      }
    }
    // display
    if (matchDescription) {
      this.display = sanitizer.bypassSecurityTrustHtml(matchLabel
        + '<br/><small>' + matchDescription + '</small>');
    } else {
      this.display = sanitizer.bypassSecurityTrustHtml(matchLabel);
    }
    return this.isMatched;
  } // match

} // FkInfo
