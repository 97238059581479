
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CRequest
 */
export class CRequest {

  /**
   * label: Client Id
   */
  public cid: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Intl.DateTimeFormat().resolvedOptions().locale
   */
  public localeName: string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: request parameter
   */
  public params: {[key: string]: string} = {};

  /**
   * label: document.referrer
   */
  public referrer: string;

  /**
   * label: client location.href
   */
  public requestUrl: string;

  /**
   * label: request id
   */
  public rid: number;

  /**
   * label: accorto session id
   */
  public sid: string;

  /**
   * label: time in ms
   */
  public timeMs: number;

  /**
   * label: time string
   */
  public timeString: string;

  /**
   * label: timezone
   */
  public timezoneName: string;

  /**
   * label: time offset min
   */
  public timezoneOffsetMinutes: number;

} // CRequest
