import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { ClientStorage } from '../utils/client-storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class IconService {
    constructor(http) {
        this.http = http;
    }
    /**
     * Get symbol -> svg
     * @param href use href
     */
    get(href) {
        const cvalue = ClientStorage.get(href);
        if (cvalue && cvalue.length > 1) {
            //  return of(cvalue);
        }
        const parts = href.split('#');
        const url = parts[0];
        const name = parts[1];
        return this.http.get(url, { responseType: 'text' })
            .pipe(tap(data => {
            // console.log(data);
        }, error => console.error(error)), map(data => this.getSymbol(data, name)), tap((svgString) => {
            if (svgString && svgString.length > 1) {
                ClientStorage.set(href, svgString);
            }
        }));
    } // get
    /**
     * Get Symbol svg
     * @param svgText all data
     * @param name icon id
     */
    getSymbol(svgText, name) {
        try {
            const parser = new DOMParser();
            const svgXml = parser.parseFromString(svgText, 'image/svg+xml');
            const svgSymbol = svgXml.getElementById(name);
            // svgSymbol.setAttribute('height', '100%');
            // svgSymbol.setAttribute('width', '100%');
            // console.log('symbol', svgSymbol);
            // return svgSymbol.innerHTML; // does not work in IE11
            const retValue = new XMLSerializer().serializeToString(svgSymbol.lastChild); // skip Title
            if (retValue && retValue.length > 1) { // convert symbol to string
                return retValue;
            }
        }
        catch (e) {
            console.warn(name, e);
        }
        return undefined;
    }
}
/** @nocollapse */ IconService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IconService_Factory() { return new IconService(i0.ɵɵinject(i1.HttpClient)); }, token: IconService, providedIn: "root" });
