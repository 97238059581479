import { TenantUserI } from './tenant-user-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Tenant
 */
export interface TenantI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name?: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  currency?: string;

  isMultiCurrency?: boolean;

  isDomainUserCanSignup?: boolean;

  /**
   * label: Admin Email
   */
  adminEmail?: string;

  /**
   * label: Domain (Prefix)
   */
  domainName?: string;

  /**
   * label: Salesforce Domain
   */
  sfDomain?: string;

  lastLogin?: number;

  /**
   * fk TenantUserI
   * label: Last User
   */
  lastUser?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Last User
   */
  lastUserId?: number|string;

  /**
   * label: Tenant Settings
   */
  settings?: { [key: string]: string };

  /**
   * 1..7 = Sun..Sat
   * label: First Day of Week
   */
  firstDayOfWeek?: number;

} // Tenant

/** Tenant Definition */
export const TenantD = {
  recordTypeName: 'Tenant',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  currency: {
    n: 'currency',
    t: 'string'
  },
  isMultiCurrency: {
    n: 'isMultiCurrency',
    t: 'boolean'
  },
  isDomainUserCanSignup: {
    n: 'isDomainUserCanSignup',
    t: 'boolean'
  },
  adminEmail: {
    n: 'adminEmail',
    t: 'string'
  },
  domainName: {
    n: 'domainName',
    t: 'string'
  },
  sfDomain: {
    n: 'sfDomain',
    t: 'string'
  },
  lastLogin: {
    n: 'lastLogin',
    t: 'number'
  },
  lastUser: {
    n: 'lastUser',
    t: 'TenantUserI'
  },
  lastUserId: {
    n: 'lastUserId',
    t: 'number|string'
  },
  settings: {
    n: 'settings',
    t: '{ [key: string]: string }'
  },
  firstDayOfWeek: {
    n: 'firstDayOfWeek',
    t: 'number'
  },
}; // Tenant
