import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { FkService } from './fk.service';
import { fkErrorAction, fkIdRequestAction, fkInfoResponseAction, fkOptionsRequestAction } from './fk.actions';


@Injectable()
export class FkEffects {

  fkIdRequest$ = createEffect(() => this.actions$.pipe(
    ofType(fkIdRequestAction),
    mergeMap((action) => this.service.sendFkIds(action.fkTable, action.fkId, action.fkIds)
      .pipe(
        catchError(err => {
          console.log('fkIdRequest$.error', err);
          this.store.dispatch(fkErrorAction({ err }));
          return of(undefined);
        })
      )
    ),
    map(response => {
      // console.log('fkOptionsRequest$ response', response);
      if (response.error) {
        return fkErrorAction({ err: response.error });
      }
      return fkInfoResponseAction({ response });
    })
    ), {}
  ); // fkOptionsRequest$


  fkOptionsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(fkOptionsRequestAction),
    mergeMap((action) => this.service.sendFkSearch(action.fkTable, action.parentMap, action.searchTerm)
      .pipe(
        catchError(err => {
          console.log('fkOptionsRequest$.error', err);
          this.store.dispatch(fkErrorAction({ err }));
          return of(undefined);
        })
      )
    ),
    map(response => {
      // console.log('fkOptionsRequest$ response', response);
      if (response.error) {
        return fkErrorAction({ err: response.error });
      }
      return fkInfoResponseAction({ response });
    })
    ), {}
  ); // fkOptionsRequest$


  fkError$ = createEffect(() => this.actions$.pipe(
    ofType(fkErrorAction),
    tap((action) => {
      console.warn('fkError$', action);
    })
    ), { dispatch: false }
  );


  constructor(private actions$: Actions,
              private service: FkService,
              private store: Store<any>) {
  }

} // FkEffects
