import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectLoginLoggedIn } from './login.selectors';
import { ClientStorage } from '../utils/client-storage';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
/**
 * Navigate to /login of not logged in
 */
export class LoginGuard {
    constructor(store, router) {
        this.store = store;
        this.router = router;
        /** Logged In */
        this.loggedIn = false;
        this.store.pipe(select(selectLoginLoggedIn))
            .subscribe((val) => {
            this.loggedIn = val;
        });
    } // constructor
    canActivate(next, state) {
        if (this.loggedIn) {
            ClientStorage.set('redirect', undefined);
            return true;
        }
        this.router.navigateByUrl('/login');
        return false;
    } // canActivate
} // LoginGuard
/** @nocollapse */ LoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuard_Factory() { return new LoginGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Router)); }, token: LoginGuard, providedIn: "root" });
