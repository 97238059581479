import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Logger } from '../log/logger';
import { DataRecord } from '../model/data-record';

/**
 * File Input
 * - result is stored in the record.sourceMap
 */
@Component({
  selector: 'acc-form-file',
  templateUrl: './form-file.component.html',
  styleUrls: [ './form-file.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class FormFileComponent implements OnChanges {

  /** Property Name */
  @Input() propertyName: string = 'attachment';
  /** Input Accept (image) */
  @Input() accept: string = 'image/*';

  /** Label text */
  @Input() label: string = 'Attachment';
  /** Label text */
  @Input() labelButton: string = 'Upload Image';
  /** Id base */
  @Input() theId: string = 'file-upload';

  /** ReadOnly Overwrite */
  @Input() readonly: boolean;
  /** Data record */
  @Input() record: DataRecord = new DataRecord();
  /** File Data */
  @Output() fileUploaded = new EventEmitter<string>();

  @ViewChild('canvas', { static: false })
  canvasRef: ElementRef;

  /** Error Message */
  errorMsg: string;

  /** File Name */
  public fileName: string;
  /** data:image/jpeg;base64,/9j/4S... */
  private fileDataUrl: string;

  private log: Logger = new Logger('FormFile');


  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.log.setSubName(this.propertyName);
    for (const propName of Object.keys(changes)) { // in changes
      if (propName === 'record') {
        if (this.record.changeMap) { // try changeMap first
          this.fileDataUrl = this.record.valueMap[ this.propertyName ];
          this.fileName = this.record.valueMap[ this.propertyName + 'Name' ];
        }
        if (!this.fileDataUrl && this.record.sourceMap) {
          this.fileDataUrl = this.record.sourceMap[ this.propertyName ];
          this.fileName = this.record.sourceMap[ this.propertyName + 'Name' ];
        }
        // this.log.debug('ngOnChanges', this.fileDataUrl, this.fileName)();
        this.showImage();
      }
    }
  } // ngOnChanges

  /**
   * File Input Change
   * - FormElement.ValueChange also called
   */
  onChange(event: Event) {
    const fileInput: HTMLInputElement = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file: File = fileInput.files[ 0 ];
      this.log.debug('onChange', file)();
      this.saveFile(file);
    } else {
      this.saveFile(null);
    }
  } // onChange

  /**
   * Drag Over (#1)
   * - prepare for accept drop
   */
  onDragOver(event: DragEvent) {
    event.preventDefault(); // allow drop
    event.dataTransfer.dropEffect = 'link'; // copy | move | link | none (prevents drop)
    // this.log.debug('onDragOver', event.dataTransfer)();
  }

  /**
   * Drop file (#2)
   */
  onDrop(event) {
    const dt: DataTransfer = event.dataTransfer;
    event.preventDefault();
    let file: File;
    if (dt.items) {
      if (dt.files.length > 0) {
        const item: DataTransferItem = dt.items[ 0 ];
        if (item.kind === 'file') {
          file = item.getAsFile();
        }
      }
    } else {
      if (event.files && event.files.length > 0) {
        file = event.files[ 0 ];
      }
    }
    this.log.debug('onDrop', file)();
    if (this.onDropAccept(file)) {
      this.saveFile(file);
    }
  } // onDrop

  /**
   * Is this file valid to drop
   * = sets fileName, errorMsg
   * @param file the file
   */
  private onDropAccept(file: File): boolean {
    // https://www.lightningdesignsystem.com/components/file-selector/#site-main-content
    this.fileName = undefined;
    this.errorMsg = undefined;
    if (file) {
      this.fileName = file.name;
      const index = this.fileName.lastIndexOf('.');
      const ext = (index > 0 ? this.fileName.substr(index + 1) : this.fileName)
        .toLocaleLowerCase(); // e.g. csv
      if (this.accept) {
        if (this.accept.startsWith('text/csv')) {
          if (!(ext === 'csv' || ext === 'txt')) {
            this.errorMsg = 'Please select a CSV file - found: ' + this.fileName;
          }
        }
      }
    } // file
    return this.errorMsg === undefined; // default
  } // onDropAccept

  /**
   * Save and display file
   * = sets fileDataUrl
   * @param file the file
   */
  private saveFile(file: File) {
    this.fileDataUrl = undefined;
    if (file) {
      const reader = new FileReader();
      reader.onload = (event2: ProgressEvent) => {
        const fileReader2 = event2.target as FileReader;
        // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlgAAAC5CAIAAA
        // data:application/x-gzip;base64,H4sIAAAAAAAA/+y9WZPjyJmmO7czv
        // data:;base64,cGFja2FnZSBuZXQuc291cmNlZm9yZ2Uuc2l6ZW9mOwoKaW1
        // this.log.debug('saveFile', fileReader2.result)();
        const theResult: string | ArrayBuffer = fileReader2.result;
        if (theResult instanceof ArrayBuffer) {
          this.fileDataUrl = String.fromCharCode.apply(null, new Uint16Array(theResult));
        } else if (theResult) {
          this.fileDataUrl = theResult as string;
        }
        this.fileName = file.name;
        if (!this.record.sourceMap) {
          this.record.sourceMap = {};
        }
        this.record.sourceMap[ this.propertyName ] = this.fileDataUrl;
        this.record.sourceMap[ this.propertyName + 'Name' ] = this.fileName; // set in onDropAccept
        this.log.log('saveFile', this.fileName, this.fileDataUrl.substr(0, 60))();
        this.showImage();
        this.fileUploaded.emit(this.fileDataUrl); // send data to listeners
      }; // reader onload
      reader.readAsDataURL(file); // start reader load
    } else { // not a file
      this.record.sourceMap = undefined; // assumes just one
      this.showImage();
    }
  } // saveFile

  /**
   * Show Image in fileDataUrl
   */
  private showImage() {
    if (this.canvasRef === undefined) {
      return;
    }
    const imageMaxSize = 100;
    const canvas: HTMLCanvasElement = this.canvasRef.nativeElement as HTMLCanvasElement;
    //
    if (this.fileDataUrl && this.fileDataUrl.startsWith('data:image/')) {
      canvas.style.display = 'block';
      // create image + display
      const img: HTMLImageElement = new Image();
      img.onload = (event3) => {
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height); // clear
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > imageMaxSize) {
            height = height / width * imageMaxSize;
            width = imageMaxSize;
          }
        } else {
          if (height > imageMaxSize) {
            width = width / height * imageMaxSize;
            height = imageMaxSize;
          }
        }
        this.log.log('showImage ' + this.fileName + ' ' + img.width + 'x' + img.height, width + 'x' + height)();
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
      }; // image onload
      img.src = this.fileDataUrl; // start image load
    } else {
      canvas.style.display = 'none'; // no image
    }
  } // showImage

} // FormFileComponent
