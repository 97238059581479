
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CResponseImportColumn
 */
export class CResponseImportColumn {

  /**
   * label: Target Field Alias (Sf)
   */
  public alias: string;

  /**
   * label: Heading
   */
  public heading: string;

  /**
   * label: icon
   */
  public icon: string;

  /**
   * label: Target Field Label
   */
  public label: string;

  /**
   * label: Target Field Name
   */
  public name: string;

} // CResponseImportColumn
