import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProjectImportService } from './project-import.service';
import { Logger } from 'accorto';
import { CRequestImport } from '../../../projects/accorto/src/lib/model/c-request-import';
import { CResponseImport } from '../../../projects/accorto/src/lib/model/c-response-import';
import { FormControl } from '@angular/forms';
import { CResponseImportColumn } from '../../../projects/accorto/src/lib/model/c-response-import-column';

@Component({
  selector: 'psa-project-import',
  templateUrl: './project-import.component.html',
  styleUrls: [ './project-import.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ProjectImportComponent implements OnInit {

  /** Allow Upload */
  uploadDisabled: boolean = true;

  /** Import Request */
  request: CRequestImport = new CRequestImport();
  /** Import Response */
  response: CResponseImport = new CResponseImport();

  /** CSV Heading Columns */
  headingColumns: CResponseImportColumn[] = [];
  /** active source */
  activeSource: CResponseImportColumn;

  importIsTest: FormControl = new FormControl(true);
  importIsFirstLineHeading: FormControl = new FormControl(true);
  mappingName: FormControl = new FormControl();
  mappingInfos: FormControl = new FormControl();

  /** Uploading */
  busy: boolean = false;
  showTable: boolean = false;

  private log: Logger = new Logger('ProjectImport');


  constructor(private service: ProjectImportService) {
  }

  get showResults(): boolean {
    return this.response.message !== undefined || this.response.error !== undefined;
  }

  get showResultErrors(): boolean {
    return this.response.errors !== undefined && this.response.errors.length > 0;
  }

  get showResultMessages(): boolean {
    return this.response.messages !== undefined && this.response.messages.length > 0;
  }

  get colspan1(): number {
    if (this.response.headings) {
      return Math.max(1, Math.ceil(this.response.headings.length / 2));
    }
    return 1;
  }

  get colspan2(): number {
    if (this.response.headings) {
      return Math.max(1, Math.floor(this.response.headings.length / 2));
    }
    return 1;
  }

  cleanMsg(inp: string): string {
    if (inp) {
      const msg = inp.replace('ERROR=', '')
        .replace('INFO=', '')
        .replace('SUCCESS=', '');
      return msg; // .replace('accorto__', '');
    }
    return inp;
  }

  href(icon: string): string {
    return '/assets/icons/utility-sprite/svg/symbols.svg#'
      + (icon ? icon : 'steps');
  }

  /**
   * Click on Source
   * @param col column
   */
  onClickAvailable(col: CResponseImportColumn) {
    this.log.debug('onClickAvailable', col)();
    this.activeSource = col;
  }

  ngOnInit() {
  //  this.store.dispatch(appStatus({ status: 'project-import' }));
  }

  /**
   * Click on Target (drop if selected)
   * @param col column
   */
  onClickHeading(col: CResponseImportColumn) {
    this.log.debug('onClickHeading', col, this.activeSource)();
    if (this.activeSource) {
      col.label = this.activeSource.label;
      col.name = this.activeSource.name;
      col.alias = this.activeSource.alias;
      col.icon = this.activeSource.icon;
    }
    this.activeSource = undefined;
  } // onClickHeading

  /**
   * Drag+Drop (2) - map columns
   * @param event drag
   */
  onDragoverHeading(event: DragEvent) {
    // this.log.debug('onDragoverHeading', event, name)();
    event.dataTransfer.dropEffect = 'link';
    event.preventDefault(); // allow drop
    return false; // allow drop
  }

  /**
   * Drag+Drop (1) - map columns
   * @param event drag
   * @param col payload
   */
  onDragstartAvailable(event: DragEvent, col: CResponseImportColumn) {
    // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API
    // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations
    this.log.debug('onDragStartAvailable', event, col)();
    if (event.dataTransfer) {
      event.dataTransfer.setData('text/plain', col.name);
      event.dataTransfer.dropEffect = 'link';
      this.activeSource = col;
    }
  }

  /**
   * Drag+Drop (3) - map columns
   * @param event drag event
   * @param col target heading column
   */
  onDropHeading(event: DragEvent, col: CResponseImportColumn) {
    if (event.dataTransfer) {
      const name = event.dataTransfer.getData('text/plain');
      this.log.debug('onDropHeading: ' + name, event, col, this.activeSource)();
      event.preventDefault(); // allow drop
      for (const available of this.response.importColumns) {
        if (available.name === name) {
          col.label = this.activeSource.label;
          col.name = this.activeSource.name;
          col.alias = this.activeSource.alias;
          col.icon = this.activeSource.icon;
          break;
        }
      }
    }
  } // onDropHeading

  /**
   * @param data uploaded csv
   */
  onFileUploaded(data: string) {
    this.log.info('onFileUploaded', data)();
    this.request.importData = data;
    this.uploadDisabled = !(this.request.importData && this.request.importData.length > 100);
  }

  /**
   * Start Import
   */
  onImportClick() {
    this.busy = true;
    this.showTable = false; // collapse ui
    // parameters
    this.request.importIsFirstLineHeading = this.importIsFirstLineHeading.value;
    this.request.testMode = this.importIsTest.value;
    // mapping
    this.request.headingColumns = this.headingColumns;
    this.request.mappingInfos = this.mappingInfos.value;
    this.request.mappingName = this.mappingName.value;

    this.service.send(this.request).subscribe((response => {
      // this.log.info('onImportClick.response', response)();
      this.response = response;
      this.headingColumns = this.response.headingColumns;
      this.mappingInfos.setValue(this.response.mappingInfos);
      //
      this.showTable = true;
      this.busy = false;
    }));

  } // onImportClick

  // insert manual hyphen
  printAlias(alias: string): string {
    if (alias) {
      const shy = '\u00AD';
      return alias
        .replace(/accorto__/g, '') // 'accorto__' + shy)
        .replace('.', '.' + shy);
    }
    return alias;
  }

} // ProjectImportComponent
