import { of } from 'rxjs';
import { TzInfoResponse } from '../model/tz-info-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isPlatformServer } from '@angular/common';
import { Logger } from '../log/logger';
import { AccortoService } from '../accorto.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../accorto.service";
export class TimezoneService {
    constructor(http, config, platform) {
        this.http = http;
        this.config = config;
        this.platform = platform;
        this.log = new Logger('TimezoneService');
    }
    get() {
        if (isPlatformServer(this.platform)) {
            this.log.info('get(server)')();
            return of(new TzInfoResponse());
        }
        const dd = new Date();
        const timeString = dd.toLocaleDateString('en', { timeZoneName: 'short' });
        const timezoneName = AccortoService.timezoneId;
        const localeName = AccortoService.locale;
        const url = this.config.server
            + '/tz?id=' + encodeURIComponent(timezoneName)
            + '&offset=' + dd.getTimezoneOffset()
            + '&locale=' + encodeURIComponent(localeName)
            + '&time=' + encodeURIComponent(timeString);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this.log.info('get ' + url)();
        return this.http.get(url, httpOptions);
    }
}
/** @nocollapse */ TimezoneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TimezoneService_Factory() { return new TimezoneService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AccortoService), i0.ɵɵinject(i0.PLATFORM_ID)); }, token: TimezoneService, providedIn: "root" });
