import { DataTable } from './data-table';
import { Account } from './account';
import { Invoice } from './invoice';
import { Tenant } from './tenant';
import { DataViewWhere } from './data-view-where';
import { LogRecord } from './log-record';
import { ResourcePrice } from './resource-price';
import { DataColumn } from './data-column';
import { DataPickOption } from './data-pick-option';
import { DataRecord } from './data-record';
import { UiTab } from './ui-tab';
import { TenantUser } from './tenant-user';
import { ProjectLineSharing } from './project-line-sharing';
import { InvoiceLine } from './invoice-line';
import { Resource } from './resource';
import { ActivityType } from './activity-type';
import { ProjectLine } from './project-line';
import { UiFormSection } from './ui-form-section';
import { UiGridField } from './ui-grid-field';
import { Common } from './common';
import { Contact } from './contact';
import { DataView } from './data-view';
import { Project } from './project';
import { ProjectPhase } from './project-phase';
import { UiFormField } from './ui-form-field';
import { Session } from './session';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * Domain Utility for com.accorto.domain
 */
export class AccortoUtil {


  /**
   * Convert DataRecord to Account
   * @return populated Account
   * @param dr data record
   */
  static convertAccount(dr: DataRecord): Account {
    const result = this.createAccount(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createAccount(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertAccount


  /**
   * Convert DataRecord to ActivityType
   * @return populated ActivityType
   * @param dr data record
   */
  static convertActivityType(dr: DataRecord): ActivityType {
    const result = this.createActivityType(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createActivityType(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertActivityType


  /**
   * Convert DataRecord to Common
   * @return populated Common
   * @param dr data record
   */
  static convertCommon(dr: DataRecord): Common {
    const result = this.createCommon(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createCommon(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertCommon


  /**
   * Convert DataRecord to Contact
   * @return populated Contact
   * @param dr data record
   */
  static convertContact(dr: DataRecord): Contact {
    const result = this.createContact(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createContact(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertContact


  /**
   * Convert DataRecord to DataColumn
   * @return populated DataColumn
   * @param dr data record
   */
  static convertDataColumn(dr: DataRecord): DataColumn {
    const result = this.createDataColumn(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createDataColumn(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertDataColumn


  /**
   * Convert DataRecord to DataPickOption
   * @return populated DataPickOption
   * @param dr data record
   */
  static convertDataPickOption(dr: DataRecord): DataPickOption {
    const result = this.createDataPickOption(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createDataPickOption(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertDataPickOption


  /**
   * Convert DataRecord to DataTable
   * @return populated DataTable
   * @param dr data record
   */
  static convertDataTable(dr: DataRecord): DataTable {
    const result = this.createDataTable(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createDataTable(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertDataTable


  /**
   * Convert DataRecord to DataView
   * @return populated DataView
   * @param dr data record
   */
  static convertDataView(dr: DataRecord): DataView {
    const result = this.createDataView(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createDataView(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertDataView


  /**
   * Convert DataRecord to DataViewWhere
   * @return populated DataViewWhere
   * @param dr data record
   */
  static convertDataViewWhere(dr: DataRecord): DataViewWhere {
    const result = this.createDataViewWhere(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createDataViewWhere(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertDataViewWhere


  /**
   * Convert DataRecord to Invoice
   * @return populated Invoice
   * @param dr data record
   */
  static convertInvoice(dr: DataRecord): Invoice {
    const result = this.createInvoice(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createInvoice(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertInvoice


  /**
   * Convert DataRecord to InvoiceLine
   * @return populated InvoiceLine
   * @param dr data record
   */
  static convertInvoiceLine(dr: DataRecord): InvoiceLine {
    const result = this.createInvoiceLine(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createInvoiceLine(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertInvoiceLine


  /**
   * Convert DataRecord to LogRecord
   * @return populated LogRecord
   * @param dr data record
   */
  static convertLogRecord(dr: DataRecord): LogRecord {
    const result = this.createLogRecord(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createLogRecord(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertLogRecord


  /**
   * Convert DataRecord to Project
   * @return populated Project
   * @param dr data record
   */
  static convertProject(dr: DataRecord): Project {
    const result = this.createProject(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createProject(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertProject


  /**
   * Convert DataRecord to ProjectLine
   * @return populated ProjectLine
   * @param dr data record
   */
  static convertProjectLine(dr: DataRecord): ProjectLine {
    const result = this.createProjectLine(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createProjectLine(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertProjectLine


  /**
   * Convert DataRecord to ProjectLineSharing
   * @return populated ProjectLineSharing
   * @param dr data record
   */
  static convertProjectLineSharing(dr: DataRecord): ProjectLineSharing {
    const result = this.createProjectLineSharing(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createProjectLineSharing(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertProjectLineSharing


  /**
   * Convert DataRecord to ProjectPhase
   * @return populated ProjectPhase
   * @param dr data record
   */
  static convertProjectPhase(dr: DataRecord): ProjectPhase {
    const result = this.createProjectPhase(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createProjectPhase(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertProjectPhase


  /**
   * Convert DataRecord to Resource
   * @return populated Resource
   * @param dr data record
   */
  static convertResource(dr: DataRecord): Resource {
    const result = this.createResource(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createResource(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertResource


  /**
   * Convert DataRecord to ResourcePrice
   * @return populated ResourcePrice
   * @param dr data record
   */
  static convertResourcePrice(dr: DataRecord): ResourcePrice {
    const result = this.createResourcePrice(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createResourcePrice(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertResourcePrice


  /**
   * Convert DataRecord to Session
   * @return populated Session
   * @param dr data record
   */
  static convertSession(dr: DataRecord): Session {
    const result = this.createSession(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createSession(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertSession


  /**
   * Convert DataRecord to Tenant
   * @return populated Tenant
   * @param dr data record
   */
  static convertTenant(dr: DataRecord): Tenant {
    const result = this.createTenant(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createTenant(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertTenant


  /**
   * Convert DataRecord to TenantUser
   * @return populated TenantUser
   * @param dr data record
   */
  static convertTenantUser(dr: DataRecord): TenantUser {
    const result = this.createTenantUser(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createTenantUser(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertTenantUser


  /**
   * Convert DataRecord to UiFormField
   * @return populated UiFormField
   * @param dr data record
   */
  static convertUiFormField(dr: DataRecord): UiFormField {
    const result = this.createUiFormField(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createUiFormField(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertUiFormField


  /**
   * Convert DataRecord to UiFormSection
   * @return populated UiFormSection
   * @param dr data record
   */
  static convertUiFormSection(dr: DataRecord): UiFormSection {
    const result = this.createUiFormSection(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createUiFormSection(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertUiFormSection


  /**
   * Convert DataRecord to UiGridField
   * @return populated UiGridField
   * @param dr data record
   */
  static convertUiGridField(dr: DataRecord): UiGridField {
    const result = this.createUiGridField(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createUiGridField(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertUiGridField


  /**
   * Convert DataRecord to UiTab
   * @return populated UiTab
   * @param dr data record
   */
  static convertUiTab(dr: DataRecord): UiTab {
    const result = this.createUiTab(dr.valueMap);
    if (Object.keys(dr.changeMap).length > 0) {
      const result2 = this.createUiTab(dr.changeMap);
      Object.assign(result, result2);
    }
    return result;
  } // convertUiTab


  /**
   * Convert json to Account
   * @return populated Account
   * @param json json info
   */
  static createAccount(json: any): Account {
    const entity: Account = new Account();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'phone') {
        entity.phone = value;
      } else if (key === 'fax') {
        entity.fax = value;
      } else if (key === 'addrStreet') {
        entity.addrStreet = value;
      } else if (key === 'addrCity') {
        entity.addrCity = value;
      } else if (key === 'addrPostalCode') {
        entity.addrPostalCode = value;
      } else if (key === 'addrState') {
        entity.addrState = value;
      } else if (key === 'addrCountry') {
        entity.addrCountry = value;
      } else if (key === 'accountNumber') {
        entity.accountNumber = value;
      } else if (key === 'website') {
        entity.website = value;
      } else if (key === 'sic') {
        entity.sic = value;
      } else if (key === 'type') {
        entity.type = value;
      } else if (key === 'employees') {
        entity.employees = Number(value);
      } else if (key === 'annualRevenue') {
        entity.annualRevenue = Number(value);
      } else if (key === 'sfLastModifiedDate') {
        entity.sfLastModifiedDate = Number(value);
      }
    } // for loop
    return entity;
  } // createAccount


  /**
   * Convert json to ActivityType
   * @return populated ActivityType
   * @param json json info
   */
  static createActivityType(json: any): ActivityType {
    const entity: ActivityType = new ActivityType();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'color') {
        entity.color = value;
      }
    } // for loop
    return entity;
  } // createActivityType


  /**
   * Convert json to Common
   * @return populated Common
   * @param json json info
   */
  static createCommon(json: any): Common {
    const entity: Common = new Common();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      }
    } // for loop
    return entity;
  } // createCommon


  /**
   * Convert json to Contact
   * @return populated Contact
   * @param json json info
   */
  static createContact(json: any): Contact {
    const entity: Contact = new Contact();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'lastModifiedDate') {
        entity.lastModifiedDate = Number(value);
      } else if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'account') {
        entity.account = this.createAccount(value); // dm
      } else if (key === 'accountId') {
        entity.accountId = Number(value);
      } else if (key === 'accountSfId') {
        entity.accountSfId = value;
      } else if (key === 'email') {
        entity.email = value;
      } else if (key === 'fax') {
        entity.fax = value;
      } else if (key === 'firstName') {
        entity.firstName = value;
      } else if (key === 'homePhone') {
        entity.homePhone = value;
      } else if (key === 'phone') {
        entity.phone = value;
      } else if (key === 'lastName') {
        entity.lastName = value;
      } else if (key === 'mobilePhone') {
        entity.mobilePhone = value;
      } else if (key === 'otherPhone') {
        entity.otherPhone = value;
      } else if (key === 'mailingAddress') {
        entity.mailingAddress = value;
      } else if (key === 'mailingCity') {
        entity.mailingCity = value;
      } else if (key === 'mailingStreet') {
        entity.mailingStreet = value;
      } else if (key === 'mailingPostalCode') {
        entity.mailingPostalCode = value;
      } else if (key === 'mailingState') {
        entity.mailingState = value;
      } else if (key === 'title') {
        entity.title = value;
      } else if (key === 'mailingCountry') {
        entity.mailingCountry = value;
      } else if (key === 'reportsTo') {
        entity.reportsTo = this.createContact(value); // dm
      } else if (key === 'reportsToId') {
        entity.reportsToId = Number(value);
      } else if (key === 'reportsToSfId') {
        entity.reportsToSfId = value;
      } else if (key === 'department') {
        entity.department = value;
      } else if (key === 'birthdate') {
        entity.birthdate = Number(value);
      } else if (key === 'assistantName') {
        entity.assistantName = value;
      } else if (key === 'assistantPhone') {
        entity.assistantPhone = value;
      } else if (key === 'salutation') {
        entity.salutation = value;
      }
    } // for loop
    return entity;
  } // createContact


  /**
   * Convert json to DataColumn
   * @return populated DataColumn
   * @param json json info
   */
  static createDataColumn(json: any): DataColumn {
    const entity: DataColumn = new DataColumn();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'dataTable') {
        entity.dataTable = this.createDataTable(value); // dm
      } else if (key === 'dataTableId') {
        entity.dataTableId = Number(value);
      } else if (key === 'dataType') {
        entity.dataType = value;
      } else if (key === 'isNullable') {
        entity.isNullable = Boolean(value);
      } else if (key === 'length') {
        entity.length = Number(value);
      } else if (key === 'lengthMin') {
        entity.lengthMin = Number(value);
      } else if (key === 'precision') {
        entity.precision = Number(value);
      } else if (key === 'scale') {
        entity.scale = Number(value);
      } else if (key === 'defaultValue') {
        entity.defaultValue = value;
      } else if (key === 'isPk') {
        entity.isPk = Boolean(value);
      } else if (key === 'isReadOnly') {
        entity.isReadOnly = Boolean(value);
      } else if (key === 'isUnique') {
        entity.isUnique = Boolean(value);
      } else if (key === 'isAutoIncrement') {
        entity.isAutoIncrement = Boolean(value);
      } else if (key === 'isFk') {
        entity.isFk = Boolean(value);
      } else if (key === 'fkTable') {
        entity.fkTable = value;
      } else if (key === 'fkIdColumn') {
        entity.fkIdColumn = value;
      } else if (key === 'fkNameColumn') {
        entity.fkNameColumn = value;
      } else if (key === 'fkLabelColumn') {
        entity.fkLabelColumn = value;
      } else if (key === 'isFkParent') {
        entity.isFkParent = Boolean(value);
      } else if (key === 'fkMultiReference') {
        entity.fkMultiReference = value;
      } else if (key === 'fkRestrictColumnNames') {
        entity.fkRestrictColumnNames = value;
      } else if (key === 'fkRestrictColumnValues') {
        entity.fkRestrictColumnValues = value;
      } else if (key === 'isPick') {
        entity.isPick = Boolean(value);
      } else if (key === 'pickSource') {
        entity.pickSource = value;
      } else if (key === 'uniqueSequenceId') {
        entity.uniqueSequenceId = Number(value);
      } else if (key === 'isExternalId') {
        entity.isExternalId = Boolean(value);
      } else if (key === 'seqNo') {
        entity.seqNo = Number(value);
      } else if (key === 'dataDisplay') {
        entity.dataDisplay = value;
      } else if (key === 'isUiRequired') {
        entity.isUiRequired = Boolean(value);
      } else if (key === 'displayLogic') {
        entity.displayLogic = value;
      } else if (key === 'readOnlyLogic') {
        entity.readOnlyLogic = value;
      } else if (key === 'help') {
        entity.help = value;
      } else if (key === 'autocomplete') {
        entity.autocomplete = value;
      } else if (key === 'controlType') {
        entity.controlType = value;
      } else if (key === 'inputType') {
        entity.inputType = value;
      } else if (key === 'inputRegex') {
        entity.inputRegex = value;
      } else if (key === 'isServerOnly') {
        entity.isServerOnly = Boolean(value);
      } else if (key === 'tabSection') {
        entity.tabSection = value;
      } else if (key === 'displayAfter') {
        entity.displayAfter = value;
      } else if (key === 'columnLogic') {
        entity.columnLogic = value;
      } else if (key === 'dependentPropertyList') {
        entity.dependentPropertyList = [];
        for (const vll of value) {
          entity.dependentPropertyList.push(vll);
        }
      } else if (key === 'layout') {
        entity.layout = value;
      } else if (key === 'pickOptionList') {
        entity.pickOptionList = [];
        for (const vom of value) {
          entity.pickOptionList.push(this.createDataPickOption(vom));
        }
      } else if (key === 'pickOptionListMap') {
        entity.pickOptionListMap = {};
        for (const vmm of Object.keys(value)) {
          entity.pickOptionListMap[vmm] = value[vmm];
        }
      }
    } // for loop
    return entity;
  } // createDataColumn


  /**
   * Convert json to DataPickOption
   * @return populated DataPickOption
   * @param json json info
   */
  static createDataPickOption(json: any): DataPickOption {
    const entity: DataPickOption = new DataPickOption();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'dataColumn') {
        entity.dataColumn = this.createDataColumn(value); // dm
      } else if (key === 'dataColumnId') {
        entity.dataColumnId = Number(value);
      } else if (key === 'isDefaultValue') {
        entity.isDefaultValue = Boolean(value);
      }
    } // for loop
    return entity;
  } // createDataPickOption


  /**
   * Convert json to DataTable
   * @return populated DataTable
   * @param json json info
   */
  static createDataTable(json: any): DataTable {
    const entity: DataTable = new DataTable();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'isSystemTable') {
        entity.isSystemTable = Boolean(value);
      } else if (key === 'isDefinitionReadOnly') {
        entity.isDefinitionReadOnly = Boolean(value);
      } else if (key === 'isTransaction') {
        entity.isTransaction = Boolean(value);
      } else if (key === 'isRecordsReadOnly') {
        entity.isRecordsReadOnly = Boolean(value);
      } else if (key === 'readOnlyLogic') {
        entity.readOnlyLogic = value;
      } else if (key === 'parentTable') {
        entity.parentTable = value;
      } else if (key === 'parentIdColumn') {
        entity.parentIdColumn = value;
      } else if (key === 'parentLinkColumn') {
        entity.parentLinkColumn = value;
      } else if (key === 'defaultSort') {
        entity.defaultSort = value;
      } else if (key === 'isCsvFirstRowReader') {
        entity.isCsvFirstRowReader = Boolean(value);
      } else if (key === 'csvHeaderRow') {
        entity.csvHeaderRow = value;
      } else if (key === 'sfOrgId') {
        entity.sfOrgId = value;
      } else if (key === 'sfUserId') {
        entity.sfUserId = value;
      } else if (key === 'statRowCount') {
        entity.statRowCount = Number(value);
      } else if (key === 'statColCount') {
        entity.statColCount = Number(value);
      } else if (key === 'statKeyPrefix') {
        entity.statKeyPrefix = value;
      } else if (key === 'labelPlural') {
        entity.labelPlural = value;
      } else if (key === 'help') {
        entity.help = value;
      } else if (key === 'iconRef') {
        entity.iconRef = value;
      } else if (key === 'columnList') {
        entity.columnList = [];
        for (const vom of value) {
          entity.columnList.push(this.createDataColumn(vom));
        }
      } else if (key === 'columnListMap') {
        entity.columnListMap = {};
        for (const vmm of Object.keys(value)) {
          entity.columnListMap[vmm] = value[vmm];
        }
      } else if (key === 'dataQueryScope') {
        entity.dataQueryScope = value;
      }
    } // for loop
    return entity;
  } // createDataTable


  /**
   * Convert json to DataView
   * @return populated DataView
   * @param json json info
   */
  static createDataView(json: any): DataView {
    const entity: DataView = new DataView();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'dataTable') {
        entity.dataTable = this.createDataTable(value); // dm
      } else if (key === 'dataTableId') {
        entity.dataTableId = Number(value);
      } else if (key === 'isUserLevel') {
        entity.isUserLevel = Boolean(value);
      } else if (key === 'selectColumns') {
        entity.selectColumns = [];
        for (const vll of value) {
          entity.selectColumns.push(vll);
        }
      } else if (key === 'selectAddlColumns') {
        entity.selectAddlColumns = [];
        for (const vll of value) {
          entity.selectAddlColumns.push(vll);
        }
      } else if (key === 'groupBys') {
        entity.groupBys = [];
        for (const vll of value) {
          entity.groupBys.push(vll);
        }
      } else if (key === 'orderBys') {
        entity.orderBys = [];
        for (const vll of value) {
          entity.orderBys.push(vll);
        }
      } else if (key === 'whereList') {
        entity.whereList = [];
        for (const vll of value) {
          entity.whereList.push(this.createDataViewWhere(vll));
        }
      } else if (key === 'whereLogic') {
        entity.whereLogic = value;
      }
    } // for loop
    return entity;
  } // createDataView


  /**
   * Convert json to DataViewWhere
   * @return populated DataViewWhere
   * @param json json info
   */
  static createDataViewWhere(json: any): DataViewWhere {
    const entity: DataViewWhere = new DataViewWhere();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'columnName') {
        entity.columnName = value;
      } else if (key === 'op') {
        entity.op = value;
      } else if (key === 'value') {
        entity.value = value;
      } else if (key === 'valueLabel') {
        entity.valueLabel = value;
      } else if (key === 'valueList') {
        entity.valueList = [];
        for (const vll of value) {
          entity.valueList.push(vll);
        }
      } else if (key === 'whereDirect') {
        entity.whereDirect = value;
      }
    } // for loop
    return entity;
  } // createDataViewWhere


  /**
   * Convert json to Invoice
   * @return populated Invoice
   * @param json json info
   */
  static createInvoice(json: any): Invoice {
    const entity: Invoice = new Invoice();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'status') {
        entity.status = value;
      } else if (key === 'account') {
        entity.account = this.createAccount(value); // dm
      } else if (key === 'accountId') {
        entity.accountId = Number(value);
      } else if (key === 'accountSfId') {
        entity.accountSfId = value;
      } else if (key === 'invoiceDate') {
        entity.invoiceDate = Number(value);
      }
    } // for loop
    return entity;
  } // createInvoice


  /**
   * Convert json to InvoiceLine
   * @return populated InvoiceLine
   * @param json json info
   */
  static createInvoiceLine(json: any): InvoiceLine {
    const entity: InvoiceLine = new InvoiceLine();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'invoice') {
        entity.invoice = this.createInvoice(value); // dm
      } else if (key === 'invoiceId') {
        entity.invoiceId = Number(value);
      } else if (key === 'invoiceSfId') {
        entity.invoiceSfId = value;
      } else if (key === 'project') {
        entity.project = this.createProject(value); // dm
      } else if (key === 'projectId') {
        entity.projectId = Number(value);
      } else if (key === 'projectSfId') {
        entity.projectSfId = value;
      } else if (key === 'projectPhase') {
        entity.projectPhase = this.createProjectPhase(value); // dm
      } else if (key === 'projectPhaseId') {
        entity.projectPhaseId = Number(value);
      } else if (key === 'projectPhaseSfId') {
        entity.projectPhaseSfId = value;
      } else if (key === 'projectLine') {
        entity.projectLine = this.createProjectLine(value); // dm
      } else if (key === 'projectLineId') {
        entity.projectLineId = Number(value);
      } else if (key === 'projectLineSfId') {
        entity.projectLineSfId = value;
      } else if (key === 'resource') {
        entity.resource = this.createResource(value); // dm
      } else if (key === 'resourceId') {
        entity.resourceId = Number(value);
      } else if (key === 'resourceSfId') {
        entity.resourceSfId = value;
      } else if (key === 'activityType') {
        entity.activityType = this.createActivityType(value); // dm
      } else if (key === 'activityTypeId') {
        entity.activityTypeId = Number(value);
      } else if (key === 'activityTypeSfId') {
        entity.activityTypeSfId = value;
      } else if (key === 'quantity') {
        entity.quantity = Number(value);
      } else if (key === 'hours') {
        entity.hours = Number(value);
      } else if (key === 'listPrice') {
        entity.listPrice = Number(value);
      } else if (key === 'unitPrice') {
        entity.unitPrice = Number(value);
      }
    } // for loop
    return entity;
  } // createInvoiceLine


  /**
   * Convert json to LogRecord
   * @return populated LogRecord
   * @param json json info
   */
  static createLogRecord(json: any): LogRecord {
    const entity: LogRecord = new LogRecord();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'requestUrl') {
        entity.requestUrl = value;
      } else if (key === 'remoteAddr') {
        entity.remoteAddr = value;
      } else if (key === 'userAgent') {
        entity.userAgent = value;
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'sid') {
        entity.sid = value;
      } else if (key === 'userName') {
        entity.userName = value;
      } else if (key === 'userId') {
        entity.userId = value;
      } else if (key === 'orgName') {
        entity.orgName = value;
      } else if (key === 'orgId') {
        entity.orgId = value;
      } else if (key === 'product') {
        entity.product = value;
      } else if (key === 'productVersion') {
        entity.productVersion = value;
      } else if (key === 'userInterface') {
        entity.userInterface = value;
      } else if (key === 'loggerName') {
        entity.loggerName = value;
      } else if (key === 'methodName') {
        entity.methodName = value;
      } else if (key === 'logLevel') {
        entity.logLevel = value;
      } else if (key === 'message') {
        entity.message = value;
      } else if (key === 'params') {
        entity.params = [];
        for (const vll of value) {
          entity.params.push(vll);
        }
      } else if (key === 'exception') {
        entity.exception = value;
      } else if (key === 'statistics') {
        entity.statistics = value;
      } else if (key === 'statisticsValue') {
        entity.statisticsValue = Number(value);
      } else if (key === 'durationMs') {
        entity.durationMs = Number(value);
      }
    } // for loop
    return entity;
  } // createLogRecord


  /**
   * Convert json to Project
   * @return populated Project
   * @param json json info
   */
  static createProject(json: any): Project {
    const entity: Project = new Project();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'resource') {
        entity.resource = this.createResource(value); // dm
      } else if (key === 'resourceId') {
        entity.resourceId = Number(value);
      } else if (key === 'managerSfId') {
        entity.managerSfId = value;
      } else if (key === 'code') {
        entity.code = value;
      } else if (key === 'invoiced') {
        entity.invoiced = value;
      } else if (key === 'account') {
        entity.account = this.createAccount(value); // dm
      } else if (key === 'accountId') {
        entity.accountId = Number(value);
      } else if (key === 'accountSfId') {
        entity.accountSfId = value;
      } else if (key === 'color') {
        entity.color = value;
      } else if (key === 'isShareAllLines') {
        entity.isShareAllLines = Boolean(value);
      } else if (key === 'status') {
        entity.status = value;
      } else if (key === 'percentComplete') {
        entity.percentComplete = Number(value);
      } else if (key === 'progress') {
        entity.progress = value;
      }
    } // for loop
    return entity;
  } // createProject


  /**
   * Convert json to ProjectLine
   * @return populated ProjectLine
   * @param json json info
   */
  static createProjectLine(json: any): ProjectLine {
    const entity: ProjectLine = new ProjectLine();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'project') {
        entity.project = this.createProject(value); // dm
      } else if (key === 'projectId') {
        entity.projectId = Number(value);
      } else if (key === 'projectSfId') {
        entity.projectSfId = value;
      } else if (key === 'projectPhase') {
        entity.projectPhase = this.createProjectPhase(value); // dm
      } else if (key === 'projectPhaseId') {
        entity.projectPhaseId = Number(value);
      } else if (key === 'projectPhaseSfId') {
        entity.projectPhaseSfId = value;
      } else if (key === 'wbs') {
        entity.wbs = value;
      } else if (key === 'resource') {
        entity.resource = this.createResource(value); // dm
      } else if (key === 'resourceId') {
        entity.resourceId = Number(value);
      } else if (key === 'resourceSfId') {
        entity.resourceSfId = value;
      } else if (key === 'lineType') {
        entity.lineType = value;
      } else if (key === 'priority') {
        entity.priority = value;
      } else if (key === 'invoiced') {
        entity.invoiced = value;
      } else if (key === 'isShareAll') {
        entity.isShareAll = Boolean(value);
      } else if (key === 'plannedEffort') {
        entity.plannedEffort = Number(value);
      } else if (key === 'plannedStart') {
        entity.plannedStart = Number(value);
      } else if (key === 'plannedEffortBillable') {
        entity.plannedEffortBillable = Number(value);
      } else if (key === 'plannedEnd') {
        entity.plannedEnd = Number(value);
      } else if (key === 'unitPrice') {
        entity.unitPrice = Number(value);
      } else if (key === 'fixedAmount') {
        entity.fixedAmount = Number(value);
      } else if (key === 'plannedCostRate') {
        entity.plannedCostRate = Number(value);
      } else if (key === 'plannedCostBudget') {
        entity.plannedCostBudget = Number(value);
      } else if (key === 'plannedRate') {
        entity.plannedRate = Number(value);
      } else if (key === 'budgetAmount') {
        entity.budgetAmount = Number(value);
      } else if (key === 'status') {
        entity.status = value;
      } else if (key === 'percentComplete') {
        entity.percentComplete = Number(value);
      } else if (key === 'progress') {
        entity.progress = value;
      } else if (key === 'prerequisite') {
        entity.prerequisite = this.createProjectLine(value); // dm
      } else if (key === 'prerequisiteId') {
        entity.prerequisiteId = Number(value);
      } else if (key === 'prerequisiteSfId') {
        entity.prerequisiteSfId = value;
      } else if (key === 'prerequisiteIdList') {
        entity.prerequisiteIdList = value;
      } else if (key === 'isAllComplete') {
        entity.isAllComplete = Boolean(value);
      }
    } // for loop
    return entity;
  } // createProjectLine


  /**
   * Convert json to ProjectLineSharing
   * @return populated ProjectLineSharing
   * @param json json info
   */
  static createProjectLineSharing(json: any): ProjectLineSharing {
    const entity: ProjectLineSharing = new ProjectLineSharing();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'projectLine') {
        entity.projectLine = this.createProjectLine(value); // dm
      } else if (key === 'projectLineId') {
        entity.projectLineId = Number(value);
      } else if (key === 'projectLineSfId') {
        entity.projectLineSfId = value;
      } else if (key === 'resource') {
        entity.resource = this.createResource(value); // dm
      } else if (key === 'resourceId') {
        entity.resourceId = Number(value);
      } else if (key === 'resourceSfId') {
        entity.resourceSfId = value;
      } else if (key === 'projectSfId') {
        entity.projectSfId = value;
      } else if (key === 'isMandatory') {
        entity.isMandatory = Boolean(value);
      } else if (key === 'maxEffort') {
        entity.maxEffort = Number(value);
      } else if (key === 'percent') {
        entity.percent = Number(value);
      } else if (key === 'resourcePriority') {
        entity.resourcePriority = Number(value);
      } else if (key === 'plannedEffort') {
        entity.plannedEffort = Number(value);
      } else if (key === 'plannedDetails') {
        entity.plannedDetails = value;
      }
    } // for loop
    return entity;
  } // createProjectLineSharing


  /**
   * Convert json to ProjectPhase
   * @return populated ProjectPhase
   * @param json json info
   */
  static createProjectPhase(json: any): ProjectPhase {
    const entity: ProjectPhase = new ProjectPhase();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'project') {
        entity.project = this.createProject(value); // dm
      } else if (key === 'projectId') {
        entity.projectId = Number(value);
      } else if (key === 'projectSfId') {
        entity.projectSfId = value;
      } else if (key === 'wbs') {
        entity.wbs = value;
      }
    } // for loop
    return entity;
  } // createProjectPhase


  /**
   * Convert json to Resource
   * @return populated Resource
   * @param json json info
   */
  static createResource(json: any): Resource {
    const entity: Resource = new Resource();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'user') {
        entity.user = this.createTenantUser(value); // dm
      } else if (key === 'userId') {
        entity.userId = Number(value);
      } else if (key === 'userSfId') {
        entity.userSfId = value;
      } else if (key === 'isManager') {
        entity.isManager = Boolean(value);
      } else if (key === 'resourceType') {
        entity.resourceType = value;
      } else if (key === 'code') {
        entity.code = value;
      } else if (key === 'resourceGroup') {
        entity.resourceGroup = value;
      } else if (key === 'resourceLocation') {
        entity.resourceLocation = value;
      } else if (key === 'skillSet') {
        entity.skillSet = [];
        for (const vll of value) {
          entity.skillSet.push(vll);
        }
      } else if (key === 'isAccessAllProjectLines') {
        entity.isAccessAllProjectLines = Boolean(value);
      } else if (key === 'weekendDays') {
        entity.weekendDays = [];
        for (const vll of value) {
          entity.weekendDays.push(vll);
        }
      } else if (key === 'daysWeek') {
        entity.daysWeek = Number(value);
      } else if (key === 'capacityDay') {
        entity.capacityDay = Number(value);
      }
    } // for loop
    return entity;
  } // createResource


  /**
   * Convert json to ResourcePrice
   * @return populated ResourcePrice
   * @param json json info
   */
  static createResourcePrice(json: any): ResourcePrice {
    const entity: ResourcePrice = new ResourcePrice();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'resource') {
        entity.resource = this.createResource(value); // dm
      } else if (key === 'resourceId') {
        entity.resourceId = Number(value);
      } else if (key === 'resourceSfId') {
        entity.resourceSfId = value;
      } else if (key === 'project') {
        entity.project = this.createProject(value); // dm
      } else if (key === 'projectId') {
        entity.projectId = Number(value);
      } else if (key === 'projectSfId') {
        entity.projectSfId = value;
      } else if (key === 'activityType') {
        entity.activityType = this.createActivityType(value); // dm
      } else if (key === 'activityTypeId') {
        entity.activityTypeId = Number(value);
      } else if (key === 'activityTypeSfId') {
        entity.activityTypeSfId = value;
      } else if (key === 'effectiveFrom') {
        entity.effectiveFrom = Number(value);
      } else if (key === 'costRate') {
        entity.costRate = Number(value);
      } else if (key === 'unitPrice') {
        entity.unitPrice = Number(value);
      }
    } // for loop
    return entity;
  } // createResourcePrice


  /**
   * Convert json to Session
   * @return populated Session
   * @param json json info
   */
  static createSession(json: any): Session {
    const entity: Session = new Session();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'cid') {
        entity.cid = value;
      } else if (key === 'locale') {
        entity.locale = value;
      } else if (key === 'utcOffset') {
        entity.utcOffset = Number(value);
      } else if (key === 'timezone') {
        entity.timezone = value;
      } else if (key === 'language') {
        entity.language = value;
      } else if (key === 'tenantUser') {
        entity.tenantUser = this.createTenantUser(value); // dm
      } else if (key === 'tenantUserId') {
        entity.tenantUserId = Number(value);
      } else if (key === 'sfOrgId') {
        entity.sfOrgId = value;
      } else if (key === 'sfUserId') {
        entity.sfUserId = value;
      } else if (key === 'sfUserName') {
        entity.sfUserName = value;
      } else if (key === 'sfResourceId') {
        entity.sfResourceId = value;
      } else if (key === 'sfInstanceUrl') {
        entity.sfInstanceUrl = value;
      } else if (key === 'email') {
        entity.email = value;
      } else if (key === 'userName') {
        entity.userName = value;
      } else if (key === 'userInitials') {
        entity.userInitials = value;
      } else if (key === 'userPhotoUrl') {
        entity.userPhotoUrl = value;
      } else if (key === 'firstDayOfWeek') {
        entity.firstDayOfWeek = Number(value);
      } else if (key === 'settings') {
        entity.settings = {};
        for (const vmm of Object.keys(value)) {
          entity.settings[vmm] = value[vmm];
        }
      }
    } // for loop
    return entity;
  } // createSession


  /**
   * Convert json to Tenant
   * @return populated Tenant
   * @param json json info
   */
  static createTenant(json: any): Tenant {
    const entity: Tenant = new Tenant();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'currency') {
        entity.currency = value;
      } else if (key === 'isMultiCurrency') {
        entity.isMultiCurrency = Boolean(value);
      } else if (key === 'isDomainUserCanSignup') {
        entity.isDomainUserCanSignup = Boolean(value);
      } else if (key === 'adminEmail') {
        entity.adminEmail = value;
      } else if (key === 'domainName') {
        entity.domainName = value;
      } else if (key === 'sfDomain') {
        entity.sfDomain = value;
      } else if (key === 'lastLogin') {
        entity.lastLogin = Number(value);
      } else if (key === 'lastUser') {
        entity.lastUser = this.createTenantUser(value); // dm
      } else if (key === 'lastUserId') {
        entity.lastUserId = Number(value);
      } else if (key === 'settings') {
        entity.settings = {};
        for (const vmm of Object.keys(value)) {
          entity.settings[vmm] = value[vmm];
        }
      } else if (key === 'firstDayOfWeek') {
        entity.firstDayOfWeek = Number(value);
      }
    } // for loop
    return entity;
  } // createTenant


  /**
   * Convert json to TenantUser
   * @return populated TenantUser
   * @param json json info
   */
  static createTenantUser(json: any): TenantUser {
    const entity: TenantUser = new TenantUser();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'firstName') {
        entity.firstName = value;
      } else if (key === 'email') {
        entity.email = value;
      } else if (key === 'isEmailConfirmed') {
        entity.isEmailConfirmed = Boolean(value);
      } else if (key === 'isAdmin') {
        entity.isAdmin = Boolean(value);
      } else if (key === 'isManager') {
        entity.isManager = Boolean(value);
      } else if (key === 'ssoProvider') {
        entity.ssoProvider = value;
      } else if (key === 'photoUrl') {
        entity.photoUrl = value;
      } else if (key === 'sfUserName') {
        entity.sfUserName = value;
      } else if (key === 'sfOrgId') {
        entity.sfOrgId = value;
      } else if (key === 'sfResourceId') {
        entity.sfResourceId = value;
      } else if (key === 'sfThumbnail') {
        entity.sfThumbnail = value;
      } else if (key === 'addrStreet') {
        entity.addrStreet = value;
      } else if (key === 'addrCity') {
        entity.addrCity = value;
      } else if (key === 'addrState') {
        entity.addrState = value;
      } else if (key === 'addrZip') {
        entity.addrZip = value;
      } else if (key === 'addrCountry') {
        entity.addrCountry = value;
      } else if (key === 'phone') {
        entity.phone = value;
      } else if (key === 'mobilePhone') {
        entity.mobilePhone = value;
      } else if (key === 'firstDayOfWeek') {
        entity.firstDayOfWeek = Number(value);
      } else if (key === 'locale') {
        entity.locale = value;
      } else if (key === 'language') {
        entity.language = value;
      } else if (key === 'timezone') {
        entity.timezone = value;
      } else if (key === 'utcOffset') {
        entity.utcOffset = Number(value);
      }
    } // for loop
    return entity;
  } // createTenantUser


  /**
   * Convert json to UiFormField
   * @return populated UiFormField
   * @param json json info
   */
  static createUiFormField(json: any): UiFormField {
    const entity: UiFormField = new UiFormField();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'uiFormSection') {
        entity.uiFormSection = this.createUiFormSection(value); // dm
      } else if (key === 'uiFormSectionId') {
        entity.uiFormSectionId = Number(value);
      } else if (key === 'dataColumn') {
        entity.dataColumn = this.createDataColumn(value); // dm
      } else if (key === 'dataColumnId') {
        entity.dataColumnId = Number(value);
      } else if (key === 'seqNo') {
        entity.seqNo = Number(value);
      } else if (key === 'isDisplay') {
        entity.isDisplay = Boolean(value);
      } else if (key === 'isUiRequired') {
        entity.isUiRequired = Boolean(value);
      } else if (key === 'uiId') {
        entity.uiId = value;
      }
    } // for loop
    return entity;
  } // createUiFormField


  /**
   * Convert json to UiFormSection
   * @return populated UiFormSection
   * @param json json info
   */
  static createUiFormSection(json: any): UiFormSection {
    const entity: UiFormSection = new UiFormSection();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'uiTab') {
        entity.uiTab = this.createUiTab(value); // dm
      } else if (key === 'uiTabId') {
        entity.uiTabId = Number(value);
      } else if (key === 'seqNo') {
        entity.seqNo = Number(value);
      } else if (key === 'isCollapsible') {
        entity.isCollapsible = Boolean(value);
      } else if (key === 'isCollapsed') {
        entity.isCollapsed = Boolean(value);
      } else if (key === 'uiFormFieldList') {
        entity.uiFormFieldList = [];
        for (const vom of value) {
          entity.uiFormFieldList.push(this.createUiFormField(vom));
        }
      } else if (key === 'uiId') {
        entity.uiId = value;
      }
    } // for loop
    return entity;
  } // createUiFormSection


  /**
   * Convert json to UiGridField
   * @return populated UiGridField
   * @param json json info
   */
  static createUiGridField(json: any): UiGridField {
    const entity: UiGridField = new UiGridField();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'uiTab') {
        entity.uiTab = this.createUiTab(value); // dm
      } else if (key === 'uiTabId') {
        entity.uiTabId = Number(value);
      } else if (key === 'dataColumn') {
        entity.dataColumn = this.createDataColumn(value); // dm
      } else if (key === 'dataColumnId') {
        entity.dataColumnId = Number(value);
      } else if (key === 'seqNo') {
        entity.seqNo = Number(value);
      } else if (key === 'isDisplay') {
        entity.isDisplay = Boolean(value);
      } else if (key === 'footerInfo') {
        entity.footerInfo = value;
      } else if (key === 'headerInfo') {
        entity.headerInfo = value;
      } else if (key === 'nameFooter') {
        entity.nameFooter = value;
      } else if (key === 'sortUp') {
        entity.sortUp = Boolean(value);
      } else if (key === 'total') {
        entity.total = Number(value);
      } else if (key === 'uiId') {
        entity.uiId = value;
      }
    } // for loop
    return entity;
  } // createUiGridField


  /**
   * Convert json to UiTab
   * @return populated UiTab
   * @param json json info
   */
  static createUiTab(json: any): UiTab {
    const entity: UiTab = new UiTab();
    for (const key of Object.keys(json)) {
      const value: any = json[ key ];
      if (key === 'id') {
        entity.id = Number(value);
      } else if (key === 'name') {
        entity.name = value;
      } else if (key === 'label') {
        entity.label = value;
      } else if (key === 'description') {
        entity.description = value;
      } else if (key === 'isActive') {
        entity.isActive = Boolean(value);
      } else if (key === 'created') {
        entity.created = Number(value);
      } else if (key === 'createdBy') {
        entity.createdBy = this.createTenantUser(value); // dm
      } else if (key === 'createdById') {
        entity.createdById = Number(value);
      } else if (key === 'updated') {
        entity.updated = Number(value);
      } else if (key === 'updatedBy') {
        entity.updatedBy = this.createTenantUser(value); // dm
      } else if (key === 'updatedById') {
        entity.updatedById = Number(value);
      } else if (key === 'externalId') {
        entity.externalId = value;
      } else if (key === 'tenant') {
        entity.tenant = this.createTenant(value); // dm
      } else if (key === 'tenantId') {
        entity.tenantId = Number(value);
      } else if (key === 'owner') {
        entity.owner = this.createTenantUser(value); // dm
      } else if (key === 'ownerId') {
        entity.ownerId = Number(value);
      } else if (key === 'version') {
        entity.version = Number(value);
      } else if (key === 'sfId') {
        entity.sfId = value;
      } else if (key === 'dataTable') {
        entity.dataTable = this.createDataTable(value); // dm
      } else if (key === 'dataTableId') {
        entity.dataTableId = Number(value);
      } else if (key === 'childTabNames') {
        entity.childTabNames = [];
        for (const vll of value) {
          entity.childTabNames.push(vll);
        }
      } else if (key === 'formSectionList') {
        entity.formSectionList = [];
        for (const vom of value) {
          entity.formSectionList.push(this.createUiFormSection(vom));
        }
      } else if (key === 'gridFieldList') {
        entity.gridFieldList = [];
        for (const vom of value) {
          entity.gridFieldList.push(this.createUiGridField(vom));
        }
      } else if (key === 'help') {
        entity.help = value;
      } else if (key === 'iconClass') {
        entity.iconClass = value;
      } else if (key === 'iconHref') {
        entity.iconHref = value;
      } else if (key === 'isChildTab') {
        entity.isChildTab = Boolean(value);
      } else if (key === 'isMainMenu') {
        entity.isMainMenu = Boolean(value);
      } else if (key === 'labelPlural') {
        entity.labelPlural = value;
      } else if (key === 'relatedLinkColumnList') {
        entity.relatedLinkColumnList = [];
        for (const vll of value) {
          entity.relatedLinkColumnList.push(vll);
        }
      } else if (key === 'seqNo') {
        entity.seqNo = Number(value);
      } else if (key === 'uiId') {
        entity.uiId = value;
      }
    } // for loop
    return entity;
  } // createUiTab

} // AccortoUtil
