import { createReducer, on } from '@ngrx/store';
import * as ProjectActions from './project.actions';
export const projectsFeatureKey = 'projects';
export const initialState = {
    allProjectsMap: {},
    currentProject: undefined,
    currentProjectPhases: [],
    currentProjectLines: [],
    currentProjectAllocations: []
};
export function sortByName(one, two) {
    const o = one.label ? one.label : one.name ? one.name : '';
    const t = two.label ? two.label : two.name ? two.name : '';
    return o.localeCompare(t);
}
const ɵ0 = (state, action) => {
    const allProjects = action.projects;
    const allProjectsMap = {};
    allProjects.forEach((project) => {
        allProjectsMap[project.id] = project;
    });
    return Object.assign({}, state, { allProjectsMap });
}, ɵ1 = (state, action) => {
    const allProjectsMap = Object.assign({}, state.allProjectsMap);
    allProjectsMap[action.project.id] = action.project;
    return Object.assign({}, state, { allProjectsMap });
}, ɵ2 = (state, action) => {
    const allProjectsMap = Object.assign({}, state.allProjectsMap);
    allProjectsMap[action.project.id] = action.project;
    return Object.assign({}, state, { allProjectsMap });
}, ɵ3 = (state, action) => {
    const allProjectsMap = Object.assign({}, state.allProjectsMap);
    delete allProjectsMap[action.id];
    return Object.assign({}, state, { allProjectsMap, currentProject: undefined, currentProjectPhases: [], currentProjectLines: [], currentProjectAllocations: [] });
}, ɵ4 = (state, action) => {
    return Object.assign({}, state, { allProjectsMap: {}, currentProject: undefined, currentProjectPhases: [], currentProjectLines: [], currentProjectAllocations: [] });
}, ɵ5 = (state, action) => {
    return Object.assign({}, state, { currentProject: action.project, currentProjectPhases: [], currentProjectLines: [], currentProjectAllocations: [] });
}, ɵ6 = (state, action) => {
    const allProjectsMap = Object.assign({}, state.allProjectsMap);
    allProjectsMap[action.project.id] = action.project;
    return Object.assign({}, state, { currentProject: action.project, allProjectsMap });
}, ɵ7 = (state, action) => {
    const currentProjectLines = [];
    if (action.responseData.records && action.responseData.records.length > 0) {
        const record = action.responseData.records[0];
        let updated = false;
        for (const pl of state.currentProjectLines) {
            if (pl.id === record.id) {
                currentProjectLines.push(record);
                updated = true;
            }
            else {
                currentProjectLines.push(pl);
            }
        }
        console.log('----updated=' + updated, record);
        if (!updated) {
            currentProjectLines.push(record);
        }
    }
    return Object.assign({}, state, { currentProjectLines });
}, ɵ8 = (state, action) => {
    return Object.assign({}, state, { currentProjectPhases: action.projectPhases });
}, ɵ9 = (state, action) => {
    return Object.assign({}, state, { currentProjectLines: action.projectLines });
}, ɵ10 = (state, action) => {
    return Object.assign({}, state, { currentProjectAllocations: action.projectAllocations });
};
const projectReducer0 = createReducer(initialState, 
// All Projects
on(ProjectActions.projectLoadAllResultAction, ɵ0), on(ProjectActions.projectAddAction, ɵ1), on(ProjectActions.projectUpdateAction, ɵ2), on(ProjectActions.projectDeleteAction, ɵ3), on(ProjectActions.projectsClearAction, ɵ4), 
// Selected Project
on(ProjectActions.projectSelectedAction, ɵ5), on(ProjectActions.projectSelectedUpdateAction, ɵ6), on(ProjectActions.projectLineSaveResultAction, ɵ7), on(ProjectActions.projectPhasesLoadResultAction, ɵ8), 
// lines loaded
on(ProjectActions.projectLinesLoadResultAction, ɵ9), 
// allocations loaded
on(ProjectActions.projectAllocationsLoadResultAction, ɵ10));
// Selected Project
export const getCurrentProject = (state) => state.currentProject;
export function projectReducer(state, action) {
    return projectReducer0(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
