/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-section.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../form-element/form-element.component.ngfactory";
import * as i4 from "../form-element/form-element.component";
import * as i5 from "../accorto.service";
import * as i6 from "./form-section.component";
var styles_FormSectionComponent = [i0.styles];
var RenderType_FormSectionComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FormSectionComponent, data: {} });
export { RenderType_FormSectionComponent as RenderType_FormSectionComponent };
function View_FormSectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "slds-button slds-section__title-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSectionClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["aria-hidden", "true"], ["class", "slds-section__title-action-icon slds-button__icon slds-button__icon_left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/icons/utility-sprite/svg/symbols.svg#switch"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labelName; _ck(_v, 3, 0, currVal_0); }); }
function View_FormSectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "slds-truncate slds-p-horizontal_small"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labelName; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.labelName; _ck(_v, 1, 0, currVal_1); }); }
function View_FormSectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h3", [["class", "slds-section__title"]], [[2, "slds-theme_shade", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormSectionComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormSectionComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isCollapsable; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.isNotCollabsable; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMultipleSections; _ck(_v, 0, 0, currVal_0); }); }
function View_FormSectionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "acc-form-element", [["class", "slds-col slds-size_1-of-1 slds-small-size_1-of-2"]], null, null, null, i3.View_FormElementComponent_0, i3.RenderType_FormElementComponent)), i1.ɵdid(1, 704512, null, 0, i4.FormElementComponent, [i5.AccortoService], { fm: [0, "fm"], ff: [1, "ff"], record: [2, "record"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fm; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.record; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_FormSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormSectionComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "section", [["class", "slds-section__content slds-grid slds-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormSectionComponent_4)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isInTab; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.formFieldList; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FormSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "acc-form-section", [], [[2, "slds-is-open", null]], null, null, View_FormSectionComponent_0, RenderType_FormSectionComponent)), i1.ɵdid(1, 573440, null, 0, i6.FormSectionComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isOpen; _ck(_v, 0, 0, currVal_0); }); }
var FormSectionComponentNgFactory = i1.ɵccf("acc-form-section", i6.FormSectionComponent, View_FormSectionComponent_Host_0, { fm: "fm", section: "section", record: "record", isInTab: "isInTab" }, {}, []);
export { FormSectionComponentNgFactory as FormSectionComponentNgFactory };
