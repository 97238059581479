import { CResponse } from './c-response';
/* tslint:disable max-line-length no-inferrable-types */
/**
 * extends: CResponse
 * name: CResponseImport
 */
export class CResponseImport extends CResponse {
    constructor() {
        super(...arguments);
        /**
         * Mapping
         * label: Error Messages
         */
        this.errors = [];
        /**
         * label: Headings
         */
        this.headings = [];
        /**
         * label: Headings with Import Column Mappings
         */
        this.headingColumns = [];
        /**
         * label: column(heading) -> target name
         */
        this.mapping = {};
        /**
         * label: column(heading) -> sf target name
         */
        this.mappingSf = {};
        /**
         * label: List of all Import Columns
         */
        this.importColumns = [];
        /**
         * label: Task Messages
         */
        this.messages = [];
        /**
         * label: Rows
         */
        this.rows = [];
    }
} // CResponseImport
