const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** ResourcePrice Definition */
export const ResourcePriceD = {
    recordTypeName: 'ResourcePrice',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    resource: {
        n: 'resource',
        t: 'ResourceI'
    },
    resourceId: {
        n: 'resourceId',
        t: 'number|string'
    },
    resourceSfId: {
        n: 'resourceSfId',
        t: 'string'
    },
    project: {
        n: 'project',
        t: 'ProjectI'
    },
    projectId: {
        n: 'projectId',
        t: 'number|string'
    },
    projectSfId: {
        n: 'projectSfId',
        t: 'string'
    },
    activityType: {
        n: 'activityType',
        t: 'ActivityTypeI'
    },
    activityTypeId: {
        n: 'activityTypeId',
        t: 'number|string'
    },
    activityTypeSfId: {
        n: 'activityTypeSfId',
        t: 'string'
    },
    effectiveFrom: {
        n: 'effectiveFrom',
        t: 'number'
    },
    costRate: {
        n: 'costRate',
        t: 'number'
    },
    unitPrice: {
        n: 'unitPrice',
        t: 'number'
    },
}; // ResourcePrice
export { ɵ0 };
