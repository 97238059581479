import { DataRecordF, DataRecordI, DateUtil, ProjectLineSharingD, ResourceD } from 'accorto';
import { HeaderInfoDay } from './header-info-day';
import { ResourceInfoDay } from './resource-info-day';
import { PsaUtil } from '../psa-util';

/**
 * Resource - Table Line
 */
export class ResourceInfo {

  public name: string;
  public label: string;
  public title: string;
  //
  public sum: number = 0;
  public total: number = 0;

  public dayList: ResourceInfoDay[] = [];

  allocationMap: { [ key: string ]: DataRecordI } = {};
  isWeekly: boolean = false;
  readonly resourceId: string;
  // capacity
  readonly daysWeek: number;
  readonly capacityDay: number;

  /** Resource Record */
  constructor(private res: DataRecordI) {
    this.name = res.name;
    this.label = DataRecordF.labelName(res);
    this.resourceId = res.id;
    //
    this.capacityDay = DataRecordF.valueNumber(res, ResourceD.capacityDay.n, 8, true);
    this.daysWeek = DataRecordF.valueNumber(res, ResourceD.daysWeek.n, 5, true);
    //
    this.title = DataRecordF.codeLabel(res) + ': ' + this.daysWeek + ' * ' + this.capacityDay + 'h';
  }

  /**
   * Add Allocation
   * @param alloc allocation - add details
   */
  addAllocation(alloc: DataRecordI) {
    if (!alloc.details) {
      const detailString = DataRecordF.value(alloc, ProjectLineSharingD.plannedDetails.n);
      if (detailString && detailString.length > 0) {
        alloc.details = JSON.parse(detailString);
      } else {
        alloc.details = {};
      }
    }
    this.allocationMap[ alloc.id ] = alloc;
  } // addAllocation

  /**
   * Build day list
   * @param isWeekly weekly
   * @param headerInfoList header list
   */
  buildDayList(isWeekly: boolean, headerInfoList: HeaderInfoDay[]) {
    this.isWeekly = isWeekly;
    const target = this.capacityDay * (isWeekly ? this.daysWeek : 1);
    this.sum = 0;
    this.dayList = [];
    for (const hdr of headerInfoList) {
      const value = this.getDayValue(hdr.ms, hdr.isWeekly);
      const day = new ResourceInfoDay(hdr.ms, hdr.isWeekly,
        hdr.isWeekend, hdr.isWeekStartDay,
        value, target, this.label);
      day.projectLines = this.getDayProjectLines(day.ms, day.isWeekly);
      this.dayList.push(day);
      this.sum += value;
    }
  } // buildDayList

  /**
   * Get Total from Details
   * @param weekly weekly
   */
  getTotal(weekly: boolean): number {
    let totalWeek: number = 0;
    let totalDay: number = 0;
    for (const alloc of Object.values(this.allocationMap)) {
      if (alloc.isActive) {
        for (const key of Object.keys(alloc.details)) {
          const value = alloc.details[ key ];
          if (key.startsWith('w')) {
            totalWeek += value;
          } else {
            totalDay += value;
          }
        }
      }
    }
    // console.debug('ProjectAllocationInfo.getTotal', totalDay, totalWeek, this.plannedDetails, );
    return weekly ? totalWeek : totalDay;
  } // getTotal


  /**
   * Update day value of ms + update sum
   * @param ms day
   */
  update(ms: number) {
    console.log('ResourceInfo.update');
    for (const day of this.dayList) {
      if (day.ms === ms) {
        day.value = this.getDayValue(day.ms, day.isWeekly);
        day.projectLines = this.getDayProjectLines(day.ms, day.isWeekly);
        console.log('ResourceInfo.update', day.value, day.projectLines);
      }
    }
    this.updateSum();
  } // update

  /**
   * Update Allocation Sum of days displayed and set detail value
   * @return total
   */
  updateSum(): number {
    this.sum = 0; // date-range
    for (const day of this.dayList) {
      day.value = this.getDayValue(day.ms, day.isWeekly);
      day.projectLines = this.getDayProjectLines(day.ms, day.isWeekly);
      this.sum += day.value;
    }

    // total - all
    this.total = this.getTotal(this.isWeekly);
    // console.debug('ProjectAllocationInfo.updateSum', total);
    return this.total;
  } // updateSum

  /**
   * Get Project Line infos for day/week
   * @param ms ms
   * @param isWeekly weekly
   * @return list of project/line names
   */
  protected getDayProjectLines(ms: number, isWeekly: boolean): string[] {
    if (isWeekly) {
      return this.getDayProjectLinesWeek(ms);
    }
    const projectLineIds: string[] = [];
    const projectInfos: string[] = [];
    const key = 'd' + ms;
    for (const alloc of Object.values(this.allocationMap)) {
      if (alloc.isActive) {
        const hh = alloc.details[ key ];
        if (hh) {
          const id = DataRecordF.value(alloc, ProjectLineSharingD.projectLineSfId.n);
          if (!projectLineIds.includes(id)) {
            projectLineIds.push(id);
            const pj = DataRecordF.value(alloc, PsaUtil.AllocProjectName);
            const pl = DataRecordF.value(alloc, PsaUtil.AllocProjectLineName, alloc.id);
            projectInfos.push(pj + ': ' + pl + ' - ' + hh + 'h');
          }
        }
      }
    }
    return projectInfos;
  } // getDayProjectLines

  /**
   * Get Project Line infos for week
   * @param ms start ms
   * @return list of project/line names
   */
  protected getDayProjectLinesWeek(ms: number): string[] {
    const projectLineIds: string[] = [];
    const projectInfos: string[] = [];
    for (const alloc of Object.values(this.allocationMap)) {
      if (alloc.isActive) {
        for (let dd = 0; dd < 7; dd++) { // days
          const key = 'd' + (ms + (dd * DateUtil.ONEDAY));
          const hh = alloc.details[ key ];
          if (hh) {
            const id = DataRecordF.value(alloc, ProjectLineSharingD.projectLineSfId.n);
            if (!projectLineIds.includes(id)) {
              projectLineIds.push(id);
              const pj = DataRecordF.value(alloc, PsaUtil.AllocProjectName);
              const pl = DataRecordF.value(alloc, PsaUtil.AllocProjectLineName, alloc.id);
              projectInfos.push(pj + ': ' + pl); // hh = first
              break;
            }
          }
        }
      }
    }
    return projectInfos;
  } // getDayProjectLinesWeek

  /**
   * Get Value for day/week
   * @param ms ms
   * @param isWeekly weekly
   */
  protected getDayValue(ms: number, isWeekly: boolean): number {
    if (isWeekly) {
      return this.getDayValueWeek(ms);
    }
    const key = 'd' + ms;
    let value = 0;
    for (const alloc of Object.values(this.allocationMap)) {
      if (alloc.isActive) {
        const vv = alloc.details[ key ];
        if (vv) {
          value += vv;
        }
      }
    }
    return value;
  } // getDayValue

  /**
   * Get Value for week
   * @param ms start ms
   */
  protected getDayValueWeek(ms: number): number {
    let value = 0;
    for (const alloc of Object.values(this.allocationMap)) {
      if (alloc.isActive) {
        for (let dd = 0; dd < 7; dd++) { // days
          const key = 'd' + (ms + (dd * DateUtil.ONEDAY));
          const vv = alloc.details[ key ];
          if (vv) {
            value += vv;
          }
        }
      }
    }
    return value;
  } // getDayValueWeek


} // ResourceInfo
