/**
 * Value Provider
 * interface CalcValue {(colName: string): number;}
 */
type CalcValue = (colName: string) => number;

/**
 * RPM Calculator
 */
export class Calculator {

  /**
   * Calculator
   * @param rpn reverse polish notation array
   * @param rowId optional row id
   */
  constructor(public rpn: string[]) {
  }

  /**
   * Perform Calculation
   * @param cv optional value provider
   * @param debug print token/stack
   */
  calc(cv?: CalcValue, debug: boolean = false): number {
    const stack: number[] = [];
    this.rpn.forEach((token: string) => {
      const noParameter = this.isOperation(token);
      if (noParameter > 0) {
        const number1 = stack.pop();
        const number2 = stack.pop();
        if (noParameter === 2) {
          stack.push(this.performOperation2(token, number2, number1));
        } else if (noParameter === 3) {
          const number3 = stack.pop();
          stack.push(this.performOperation3(token, number3, number2, number1));
        }
      } else {
        if (!token || token === '') {
          stack.push(undefined);
        } else if (cv) {
          const value = cv(token);
          stack.push(value);
        } else {
          const value = Number(token);
          if (Number.isNaN(value)) {
            stack.push(undefined);
          } else {
            stack.push(value);
          }
        }
      }
      if (debug) {
        console.log('token=' + token, stack);
      }
    });
    // console.debug('==', stack);
    return stack.pop();
  } // calc

  /**
   * Token is Operation
   * @param token token
   */
  private isOperation(token: string): number {
    if (token === '+' || token === '-' || token === '*' || token === '/'
      || token === '%' || token === 'OR2') {
      return 2;
    }
    if (token === 'OR3') {
      return 3;
    }
    return 0;
  } // isOperation

  /**
   * Perform Operation with 2 parameters
   * @param operation op
   * @param num1 value 1
   * @param num2 value 2
   */
  private performOperation2(operation: string, num1: number, num2: number): number {
    switch (operation) {
      case '+':
        return num1 + num2;
      case '-':
        return num1 - num2;
      case '*':
        return (num1 * num2);
      case '/':
        return (num1 / num2);
      case '%':
        return num2 === 0 ? 0 : (num2 * 100 / num1);
      case 'OR2':
        return num1 ? num1 : num2;

      default:
        console.error('Unknown operation2', operation);
    }
  } // performOperation2

  /**
   * Perform Operation with 3 parameters
   * @param operation op
   * @param num1 value 1
   * @param num2 value 2
   */
  private performOperation3(operation: string, num1: number, num2: number, num3: number): number {
    switch (operation) {
      case 'OR3': {
        return num1 ? num1 : (num2 ? num2 : num3);
      }
      default:
        console.error('Unknown operation3', operation);
    }
  } // performOperation2

} // Calculator
