import { CResponse } from './c-response';
import { UiTab } from './ui-tab';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CResponse
 * name: CResponseUi
 */
export class CResponseUi extends CResponse {

  /**
   * label: UiTab
   */
  public uiTab: UiTab;

  /**
   * label: UiTab Id
   */
  public uiTabId: number;

  /**
   * label: UiTab Name
   */
  public uiTabName: string;

} // CResponseUi
