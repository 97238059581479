/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-float.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal-float.component";
var styles_ModalFloatComponent = [i0.styles];
var RenderType_ModalFloatComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ModalFloatComponent, data: {} });
export { RenderType_ModalFloatComponent as RenderType_ModalFloatComponent };
function View_ModalFloatComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["aria-hidden", "true"], ["class", "slds-icon slds-icon_small slds-icon-text-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconRef; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalFloatComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "slds-image__icon"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconRef; var currVal_1 = _co.title; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ModalFloatComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "slds-button slds-button_neutral"], ["id", "mf-clear"], ["title", "Clear selection"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClear() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["aria-hidden", "true"], ["class", "slds-button__icon slds-button__icon_left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/icons/utility-sprite/svg/symbols.svg#clear"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Clear"]))], null, null); }
function View_ModalFloatComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "footer", [["class", "slds-modal__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "slds-button-group slds-float_left"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "slds-button slds-button_neutral"], ["id", "mf-close"], ["title", "Close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:svg", [["aria-hidden", "true"], ["class", "slds-button__icon slds-button__icon_left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/icons/utility-sprite/svg/symbols.svg#close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalFloatComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "small", [["class", "slds-m-left_small"], ["style", "line-height: 2rem;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Move the window by dragging the header"])), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showClear; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_ModalFloatComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { dialogElementRef: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["dialog", 1]], null, 16, "section", [["aria-describedby", "float-content"], ["aria-labelledby", "float-heading"], ["aria-modal", "true"], ["class", "slds-modal float-dialog"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "border": 0, "width": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "header", [["class", "slds-modal__header"]], [[4, "cursor", null]], [[null, "mousedown"], [null, "mousemove"], [null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.onHeaderMouseDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("mousemove" === en)) {
        var pd_1 = (_co.onHeaderMouseMove($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseup" === en)) {
        var pd_2 = (_co.onHeaderMouseUp() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "h2", [["class", "slds-text-heading_medium slds-hyphenate"], ["id", "mf-heading"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalFloatComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalFloatComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "slds-m-left_small"], ["id", "mf-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "slds-modal__content slds-p-horizontal_medium"], ["id", "mf-content"], ["style", "min-height: 400px"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalFloatComponent_3)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.borderStyle, _co.width); _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.iconRef.includes("#"); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.iconRef.endsWith(".svg"); _ck(_v, 11, 0, currVal_3); var currVal_5 = _co.showFooter; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.mouseOffset ? "grabbing" : "grab"); _ck(_v, 6, 0, currVal_1); var currVal_4 = _co.title; _ck(_v, 13, 0, currVal_4); }); }
export function View_ModalFloatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "acc-modal-float", [], null, null, null, View_ModalFloatComponent_0, RenderType_ModalFloatComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalFloatComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalFloatComponentNgFactory = i1.ɵccf("acc-modal-float", i3.ModalFloatComponent, View_ModalFloatComponent_Host_0, { title: "title", iconRef: "iconRef", borderStyle: "borderStyle", showFooter: "showFooter", showClear: "showClear", width: "width" }, { closing: "closing" }, ["*", "footerButton"]);
export { ModalFloatComponentNgFactory as ModalFloatComponentNgFactory };
