import { createReducer, on } from '@ngrx/store';
import { DataRecordF } from 'accorto';
import * as ResourceActions from './resource.actions';
export const resourcesFeatureKey = 'resources';
export const initialState = {
    allResourcesMap: {},
    allResourceProjectAllocations: [],
    fromMs: undefined,
    fromDate: undefined,
    toMs: undefined,
    toDate: undefined,
    resourceTypes: [],
    resourceGroups: [],
    resourceLocations: [],
    resourceSkills: [],
    currentResource: undefined,
    currentResourceProjectLines: [],
    currentResourceProjectAllocations: []
};
export function sortByName(one, two) {
    const o = one.label ? one.label : one.name ? one.name : '';
    const t = two.label ? two.label : two.name ? two.name : '';
    return o.localeCompare(t);
}
const ɵ0 = (state, action) => {
    const allResources = action.resources;
    const allResourcesMap = {};
    allResources.forEach((resource) => {
        allResourcesMap[resource.id] = resource;
    });
    const resourceTypes = [...new Set(allResources
            .filter((r) => {
            return DataRecordF.value(r, 'resourceType');
        })
            .map((r) => DataRecordF.value(r, 'resourceType')))];
    const resourceGroups = [...new Set(allResources
            .filter((r) => {
            return DataRecordF.value(r, 'resourceGroup');
        })
            .map((r) => DataRecordF.value(r, 'resourceGroup')))];
    const resourceLocations = [...new Set(allResources
            .filter((r) => {
            return DataRecordF.value(r, 'resourceLocation');
        })
            .map((r) => DataRecordF.value(r, 'resourceLocation')))];
    const resourceSkillSets = [...new Set(allResources
            .filter((r) => {
            return DataRecordF.value(r, 'skillSet');
        })
            .map((r) => DataRecordF.value(r, 'skillSet')))];
    const resourceSkills = []; // resourceSkillSets; // todo
    return Object.assign({}, state, { allResourcesMap,
        resourceTypes,
        resourceGroups,
        resourceLocations,
        resourceSkills });
}, ɵ1 = (state, action) => {
    const allResourcesMap = Object.assign({}, state.allResourcesMap);
    allResourcesMap[action.resource.id] = action.resource;
    return Object.assign({}, state, { allResourcesMap });
}, ɵ2 = (state, action) => {
    const allResourcesMap = Object.assign({}, state.allResourcesMap);
    allResourcesMap[action.resource.id] = action.resource;
    return Object.assign({}, state, { allResourcesMap });
}, ɵ3 = (state, action) => {
    const allResourcesMap = Object.assign({}, state.allResourcesMap);
    delete allResourcesMap[action.id];
    return Object.assign({}, state, { allResourcesMap });
}, ɵ4 = (state, action) => {
    return Object.assign({}, state, { allResourcesMap: {} });
}, ɵ5 = (state, action) => {
    return Object.assign({}, state, { currentResource: action.resource, currentResourceProjectLines: [], currentResourceProjectAllocations: [] });
}, ɵ6 = (state, action) => {
    return Object.assign({}, state, { currentResourceProjectLines: action.projectLines });
}, ɵ7 = (state, action) => {
    return Object.assign({}, state, { currentResourceProjectAllocations: action.projectAllocations });
}, ɵ8 = (state, action) => {
    return Object.assign({}, state, { fromMs: action.fromMs, fromDate: action.fromMs && action.fromMs > 0 ? new Date(action.fromMs) : undefined, toMs: action.toMs, toDate: action.toMs && action.toMs > 0 ? new Date(action.toMs) : undefined });
};
const resourceReducer0 = createReducer(initialState, on(ResourceActions.resourceLoadAllResultAction, ɵ0), on(ResourceActions.resourceAddAction, ɵ1), on(ResourceActions.resourceUpdateAction, ɵ2), on(ResourceActions.resourceDeleteAction, ɵ3), on(ResourceActions.resourcesClearAction, ɵ4), 
// current resource
on(ResourceActions.resourceSelectedAction, ɵ5), 
// current resource - project lines
on(ResourceActions.resourceProjectLineLoadResultAction, ɵ6), 
// current resource - allocations
on(ResourceActions.resourceProjectAllocationLoadResultAction, ɵ7), 
// date range
on(ResourceActions.resourceDateRangeAction, ɵ8));
export const getCurrentResource = (state) => state.currentResource;
export function resourceReducer(state, action) {
    return resourceReducer0(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
