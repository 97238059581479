// feature selector
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { TzState } from './timezone.reducer';
import { TzInfoResponse } from '../model/tz-info-response';
import { TzInfo } from '../model/tz-info';

// export const selectTimezoneState = state => state.timezone;
export const selectTimezoneState = createFeatureSelector<TzState>('timezone');


export const selectTimezones: MemoizedSelector<any, TzInfoResponse> = createSelector(
  selectTimezoneState,
  timezoneState => timezoneState.response
);

export const selectTimezone: MemoizedSelector<any, TzInfo> = createSelector(
  selectTimezoneState,
  timezoneState => timezoneState.tz
);
