import { Trl } from 'accorto';

/**
 * Calendar Day Line
 */
export class CalendarDayLine {

  readonly isCapacity: boolean;
  readonly isOver: boolean;

  constructor(public resourceName: string, public projectName: string, public hours: number, capacityDay: number) {
    this.isCapacity = hours === capacityDay;
    this.isOver = hours > capacityDay;
  }

}

/**
 * Calendar Day
 */
export class CalendarDay {

  /** day, m/d */
  public readonly label: string;
  /** yyyy-mm-dd */
  public readonly dateTime: string;

  lines: CalendarDayLine[] = [];

  constructor(public ms: number, public isWeekend: boolean) {
    this.label = Trl.formatDate(ms, true, { weekday: 'short', month: '2-digit', day: 'numeric' });
    this.dateTime = Trl.formatDateJdbc(new Date(ms), true);
  }

  add(line: CalendarDayLine) {
    this.lines.push(line);
  }

  toString(): string {
    return 'Day ' + this.dateTime;
  }

} // calendarDay
