import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Logger } from '../log/logger';
import { AccortoService } from '../accorto.service';
import { CRequestUi } from '../model/c-request-ui';
import { ServiceBase } from '../utils/service-base';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../accorto.service";
export class UiService extends ServiceBase {
    constructor(http, config) {
        super();
        this.http = http;
        this.config = config;
        this.log = new Logger('UiService');
    }
    /**
     * @param uiName get ui with table name
     */
    get(uiName) {
        const start = new Date();
        const url = this.config.server + '/ui/' + uiName;
        const request = new CRequestUi();
        request.uiTabName = uiName;
        this.config.setCRequest(request);
        this.log.info('get ' + url, request)();
        const body = JSON.stringify(request);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(url, body, httpOptions)
            .pipe(tap(r => {
            this.log.info('get.response', r.message, r.error)();
            this.markSuccess(start);
        }));
    } // get
} // UiService
/** @nocollapse */ UiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiService_Factory() { return new UiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AccortoService)); }, token: UiService, providedIn: "root" });
