import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

import { UiService } from './ui.service';
import { uiErrorAction, uiRequestAction, uiResponseAction } from './ui.actions';

@Injectable()
export class UiEffects {


  uiRequest$ = createEffect(() => this.actions$.pipe(
    ofType(uiRequestAction),
    // tap((action) => {
    //   console.log('uiRequest$', action);
    // }),
    // withLatestFrom((action) => this.store.pipe(select(selectUi(action.payload.uiName)))),
    // filter(([action, uiTab]) => uiTab === undefined),
    mergeMap((action) => this.service.get(action.uiName)
      .pipe(
        catchError(err => {
          console.log('uiRequest$.error', err);
          this.store.dispatch(uiErrorAction({ err }));
          return of(undefined);
        })
      ),
    ),
    map(responseUi => {
      // console.log('uiRequest$ response', responseUi);
      if (responseUi.uiTab) {
        return uiResponseAction({ uiTab: responseUi.uiTab });
      }
      return uiErrorAction({ err: responseUi.error });
    })
    ), {}
  ); // uiRequest$


  uiError$ = createEffect(() => this.actions$.pipe(
    ofType(uiErrorAction),
    tap((action) => {
      console.warn('uiError$', action);
    })
    ), { dispatch: false }
  );


  constructor(private actions$: Actions,
              private service: UiService,
              private store: Store<any>) {
  }

} // UiEffects
