/**
 * Local Storage
 */
export class ClientStorage {

  static readonly REMEMBERME = 'rememberMe';
  static readonly SID = 'sid';
  static readonly SFAUTH = 'sfAuth';
  static readonly UN = 'un';

  // host prefix
  private static thePrefix: string = '';
  // host+Path prefix
  private static thePrefixPath: string = '';

  /**
   * Local Storage host level
   * @param key key
   * @param value value
   */
  static set(key: string, value: string) {
    if (localStorage) {
      if (value) {
        localStorage.setItem(this.prefixHost(key), value);
      } else {
        localStorage.removeItem(this.prefixHost(key));
      }
    }
  } // set

  /**
   * Local Storage host+path level
   * @param key key
   * @param value value
   */
  static setPath(key: string, value: string) {
    if (localStorage) {
      if (value) {
        localStorage.setItem(this.prefixPath(key), value);
      } else {
        localStorage.removeItem(this.prefixPath(key));
      }
    }
  } // set

  /**
   * Local Storage host level
   * @param key key
   * @return value if found
   */
  static get(key: string): string | undefined {
    if (localStorage) {
      const value = localStorage.getItem(this.prefixHost(key));
      if (value !== null) {
        return value;
      }
    }
    return undefined;
  }

  /**
   * Local Storage host level
   * @param key key
   * @param defaultValue default if not found
   * @return "true" value or default
   */
  static getBoolean(key: string, defaultValue: boolean = false): boolean {
    if (localStorage) {
      const value = localStorage.getItem(this.prefixHost(key));
      if (value) { // not (null or undefined)
        return value === 'true';
      }
    }
    return defaultValue;
  }

  /**
   * Local Storage host+path level
   * @param key key
   * @return value if found
   */
  static getPath(key: string): string | undefined {
    if (localStorage) {
      const value = localStorage.getItem(this.prefixPath(key));
      if (value !== null) {
        return value;
      }
    }
    return undefined;
  }


  /**
   * Prefix with host name
   * @param key value to be prefixed
   */
  private static prefixHost(key: string): string {
    if (this.thePrefix === '') {
      this.thePrefix = window.location.hostname + '_';
    }
    return this.thePrefix + key;
  }

  /**
   * Prefix with host+path name
   * @param key value to be prefixed
   */
  private static prefixPath(key: string): string {
    if (this.thePrefixPath === '') {
      this.thePrefixPath = window.location.hostname + window.location.pathname + '_';
    }
    return this.thePrefixPath + key;
  }

} // ClientStorage
