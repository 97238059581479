import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { AccountI, AccountD } from './account-i';
import { DataRecordF } from '../utils/data-record-f';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Account
 */
export class Account {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id: number|string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created: number;

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy: TenantUser;

  public createdById: number;

  /**
   * label: Updated Time
   */
  public updated: number;

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy: TenantUser;

  public updatedById: number;

  /**
   * label: External Id
   */
  public externalId: string;

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant: Tenant;

  public tenantId: number;

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner: TenantUser;

  public ownerId: number;

  /**
   * label: Record Version
   */
  public version: number;

  /**
   * label: Salesforce Id
   */
  public sfId: string;

  /**
   * label: Account Phone
   */
  public phone: string;

  /**
   * label: Account Fax
   */
  public fax: string;

  /**
   * label: Street
   */
  public addrStreet: string;

  /**
   * label: City
   */
  public addrCity: string;

  /**
   * label: Zip/Postal Code
   */
  public addrPostalCode: string;

  /**
   * label: State/Province
   */
  public addrState: string;

  /**
   * label: Country
   */
  public addrCountry: string;

  /**
   * label: Account Number
   */
  public accountNumber: string;

  /**
   * label: Website
   */
  public website: string;

  /**
   * label: SIC Code
   */
  public sic: string;

  /**
   * Account Type
   * label: Type
   */
  public type: string;

  /**
   * label: Employees
   */
  public employees: number;

  /**
   * label: Annual Revenue
   */
  public annualRevenue: number;

  /**
   * label: Last Modified Date
   */
  public sfLastModifiedDate: number;


  /**
   * @return Account record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'Account';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key) => {
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): Account {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        const value = record.valueMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, AccountD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        const value = record.changeMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, AccountD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: AccountI): Account {
    Object.keys(AccountD).forEach((key) => {
      const value = record[ key ];
      if (value) {
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // Account
