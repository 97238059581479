import { CRequest } from './c-request';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CRequest
 * name: CRequestUi
 */
export class CRequestUi extends CRequest {

  /**
   * label: UiTab Id
   */
  public uiTabId: number;

  /**
   * label: UiTab Name
   */
  public uiTabName: string;

} // CRequestUi
