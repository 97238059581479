/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./date-element.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../date-picker/date-picker.component.ngfactory";
import * as i4 from "../date-picker/date-picker.component";
import * as i5 from "./date-element.component";
var styles_DateElementComponent = [i0.styles];
var RenderType_DateElementComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DateElementComponent, data: {} });
export { RenderType_DateElementComponent as RenderType_DateElementComponent };
function View_DateElementComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "abbr", [["class", "slds-required"], ["title", "required"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["* "]))], null, null); }
function View_DateElementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "label", [["class", "slds-form-element__label"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateElementComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isRequired; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.theId; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.label; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.description; _ck(_v, 5, 0, currVal_3); }); }
function View_DateElementComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "acc-date-picker", [], null, [[null, "dateSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dateSelected" === en)) {
        var pd_0 = (_co.onDateSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DatePickerComponent_0, i3.RenderType_DatePickerComponent)), i1.ɵdid(1, 573440, null, 0, i4.DatePickerComponent, [], { mode: [0, "mode"], initialDate: [1, "initialDate"], align: [2, "align"] }, { dateSelected: "dateSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "d"; var currVal_1 = _co.theDate; var currVal_2 = _co.align; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_DateElementComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { inputElement: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateElementComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "slds-form-element__control slds-input-has-icon slds-input-has-icon_right"], ["style", "min-width: 9.5em"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["inp", 1]], null, 0, "input", [["autocomplete", "off"], ["class", "slds-input"], ["type", "text"]], [[8, "id", 0], [8, "name", 0], [8, "readOnly", 0], [8, "required", 0], [8, "title", 0]], [[null, "blur"], [null, "click"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.onBlur($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("keyup" === en)) {
        var pd_2 = (_co.onKeyUp($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["class", "slds-button slds-button_icon slds-input__icon slds-input__icon_right"], ["title", "Clear Date"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClearClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [["aria-hidden", "true"], ["class", "slds-button__icon slds-icon-text-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/icons/utility-sprite/svg/symbols.svg#date_input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "slds-assistive-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Clear Date"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "slds-form-element__help"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DateElementComponent_3)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); var currVal_7 = _co.showDatePicker; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.theId; var currVal_2 = _co.theName; var currVal_3 = _co.isReadOnly; var currVal_4 = _co.isRequired; var currVal_5 = _co.theTitle; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.errorMessage; _ck(_v, 11, 0, currVal_6); }); }
export function View_DateElementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "acc-date-element", [], [[2, "slds-form-element", null], [2, "slds-has-error", null]], null, null, View_DateElementComponent_0, RenderType_DateElementComponent)), i1.ɵdid(1, 704512, null, 0, i5.DateElementComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).formElement; var currVal_1 = i1.ɵnov(_v, 1).hasError; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var DateElementComponentNgFactory = i1.ɵccf("acc-date-element", i5.DateElementComponent, View_DateElementComponent_Host_0, { theId: "theId", theName: "theName", theTitle: "theTitle", label: "label", description: "description", isRequired: "isRequired", isReadOnly: "isReadOnly", theDate: "theDate", formGroup: "formGroup", controlName: "controlName", align: "align" }, { dateSelected: "dateSelected" }, []);
export { DateElementComponentNgFactory as DateElementComponentNgFactory };
