import { DataColumnI } from './data-column-i';
import { UiTabI } from './ui-tab-i';
import { TenantUserI } from './tenant-user-i';
import { TenantI } from './tenant-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: UiGridField
 */
export interface UiGridFieldI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name?: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * parent fk UiTabI
   */
  uiTab?: UiTabI;

  /**
   * parent fk UiTabI key
   */
  uiTabId?: number|string;

  /**
   * fk DataColumnI
   */
  dataColumn?: DataColumnI;

  /**
   * fk DataColumnI key
   */
  dataColumnId?: number|string;

  /**
   * label: Display Sequence
   */
  seqNo?: number;

  /**
   * label: Displayed
   */
  isDisplay?: boolean;

  /**
   * label: client footer info
   */
  footerInfo?: string;

  /**
   * label: client header info
   */
  headerInfo?: string;

  /**
   * label: footer value reference
   */
  nameFooter?: string;

  /**
   * label: client sort up/down
   */
  sortUp?: boolean;

  /**
   * label: client total
   */
  total?: number;

  /**
   * label: client html id
   */
  uiId?: string;

} // UiGridField

/** UiGridField Definition */
export const UiGridFieldD = {
  recordTypeName: 'UiGridField',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  uiTab: {
    n: 'uiTab',
    t: 'UiTabI'
  },
  uiTabId: {
    n: 'uiTabId',
    t: 'number|string'
  },
  dataColumn: {
    n: 'dataColumn',
    t: 'DataColumnI'
  },
  dataColumnId: {
    n: 'dataColumnId',
    t: 'number|string'
  },
  seqNo: {
    n: 'seqNo',
    t: 'number'
  },
  isDisplay: {
    n: 'isDisplay',
    t: 'boolean',
    d: true
  },
  footerInfo: {
    n: 'footerInfo',
    t: 'string'
  },
  headerInfo: {
    n: 'headerInfo',
    t: 'string'
  },
  nameFooter: {
    n: 'nameFooter',
    t: 'string'
  },
  sortUp: {
    n: 'sortUp',
    t: 'boolean'
  },
  total: {
    n: 'total',
    t: 'number'
  },
  uiId: {
    n: 'uiId',
    t: 'string'
  },
}; // UiGridField
