import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Freshdesk, FreshdeskTicket } from './freshdesk-ticket';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FreshdeskSearch } from './freshdesk-search';

@Injectable({
  providedIn: 'root'
})
export class HelpService {


  static readonly urlTickets = 'https://accorto.freshdesk.com/api/v2/tickets';
  static readonly urlSearch = 'https://accorto.freshdesk.com/api/v2/search/solutions?term=';
  static readonly apiKey = 'Z3Bw7hE7N1DwIHxHPUM';

  private httpHeaders: HttpHeaders;

  /**
   * Help Service
   */
  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(HelpService.apiKey + ':X')
    });
  }

  /**
   * @param term search term
   */
  search(term: string, fd: Freshdesk): Observable<FreshdeskSearch[]> {
    const theUrl = HelpService.urlSearch + term;
    return this.http.get<FreshdeskSearch[]>(theUrl,
      { headers: this.httpHeaders })
      .pipe(
        map((result) => {
          result.forEach((sr) => {
            // 'https://support.accorto.com/support/solutions/articles/' + this.id;
            sr.url = fd.url + '/support/solutions/articles/' + sr.id;
          });
          return result.filter((s) => {
            return fd.category === s.category_name // e.g. Accorto
              && s.status === 2 // published
              && !s.folder_name.startsWith('*'); // internal
          });
        })
      );
  } // search

  /**
   * Send Ticket
   * @param ticket freshdesk ticket
   * @return user message
   */
  sendTicket(ticket: FreshdeskTicket): Observable<string> {
    const body = JSON.stringify(ticket);

    return this.http.post(HelpService.urlTickets, body,
      { headers: this.httpHeaders })
      .pipe(
        map((response: any) => {
          console.log('sendTicket response', response);
          return `Ticket ${response.id} created - we will get back to you shortly`;
        }),
        catchError((err) => {
          console.log('sendTicket error', err);
          let msg = 'Error';
          if (err.error && err.error.description) {
            msg += ': ' + err.error.description;
          } else if (err.status) {
            msg += ': ' + err.status;
          }
          return of(msg);
        })
      );
  } // sendTicket

} // HelpService
