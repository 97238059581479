/* tslint:disable max-line-length no-inferrable-types */
export var DataQueryScope;
(function (DataQueryScope) {
    DataQueryScope["ALL"] = "ALL";
    DataQueryScope["ORG"] = "ORG";
    DataQueryScope["USR"] = "USR";
})(DataQueryScope || (DataQueryScope = {}));
const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** DataTable Definition */
export const DataTableD = {
    recordTypeName: 'DataTable',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    isSystemTable: {
        n: 'isSystemTable',
        t: 'boolean'
    },
    isDefinitionReadOnly: {
        n: 'isDefinitionReadOnly',
        t: 'boolean'
    },
    isTransaction: {
        n: 'isTransaction',
        t: 'boolean'
    },
    isRecordsReadOnly: {
        n: 'isRecordsReadOnly',
        t: 'boolean'
    },
    readOnlyLogic: {
        n: 'readOnlyLogic',
        t: 'string'
    },
    parentTable: {
        n: 'parentTable',
        t: 'string'
    },
    parentIdColumn: {
        n: 'parentIdColumn',
        t: 'string'
    },
    parentLinkColumn: {
        n: 'parentLinkColumn',
        t: 'string'
    },
    defaultSort: {
        n: 'defaultSort',
        t: 'string'
    },
    isCsvFirstRowReader: {
        n: 'isCsvFirstRowReader',
        t: 'boolean'
    },
    csvHeaderRow: {
        n: 'csvHeaderRow',
        t: 'string'
    },
    sfOrgId: {
        n: 'sfOrgId',
        t: 'string'
    },
    sfUserId: {
        n: 'sfUserId',
        t: 'string'
    },
    statRowCount: {
        n: 'statRowCount',
        t: 'number'
    },
    statColCount: {
        n: 'statColCount',
        t: 'number'
    },
    statKeyPrefix: {
        n: 'statKeyPrefix',
        t: 'string'
    },
    labelPlural: {
        n: 'labelPlural',
        t: 'string'
    },
    help: {
        n: 'help',
        t: 'string'
    },
    iconRef: {
        n: 'iconRef',
        t: 'string'
    },
    columnList: {
        array: true,
        n: 'columnList',
        t: 'DataColumnI'
    },
    columnListMap: {
        n: 'columnListMap',
        t: '{ [key: string]: DataColumnI }'
    },
    dataQueryScope: {
        n: 'dataQueryScope',
        t: 'DataQueryScope'
    },
}; // DataTable
export { ɵ0 };
