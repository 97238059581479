import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DataRecordI } from 'accorto';
import { resourcesFeatureKey, ResourceState, sortByName } from './resource.reducer';

// the resource state
export const selectResourceState = createFeatureSelector<ResourceState>(resourcesFeatureKey);

// resources dictionary
export const selectResourcesMap = createSelector(
  selectResourceState,
  (state): { [ key: string ]: DataRecordI } => state.allResourcesMap
);

// resources array - DataRecord[]
export const selectResourcesAll = createSelector(
  selectResourcesMap,
  (stateMap): DataRecordI[] => Object.values(stateMap).sort(sortByName)
);

// resource query
export const selectResourceGroups = createSelector(
  selectResourceState,
  (state): string[] => state.resourceGroups
);
export const selectResourceLocations = createSelector(
  selectResourceState,
  (state): string[] => state.resourceLocations
);
export const selectResourceTypes = createSelector(
  selectResourceState,
  (state): string[] => state.resourceTypes
);
export const selectResourceSkills = createSelector(
  selectResourceState,
  (state): string[] => state.resourceSkills
);


// current resource
export const selectCurrentResource = createSelector(
  selectResourceState,
  (state): DataRecordI => state.currentResource
);
// current resource - project lines
export const selectCurrentResourceProjectLines = createSelector(
  selectResourceState,
  (state): DataRecordI[] => state.currentResourceProjectLines
);
// current resource - project allocations
export const selectCurrentResourceProjectAllocations = createSelector(
  selectResourceState,
  (state): DataRecordI[] => state.currentResourceProjectAllocations
);

