import { BehaviorSubject } from 'rxjs';
import { Note } from './note';
import * as i0 from "@angular/core";
/**
 * Notification service
 * (tag added to app.component)
 */
export class NotificationService {
    constructor() {
        // Subject
        this.dataSource = new BehaviorSubject(new Note());
        // Observable
        this.notes$ = this.dataSource.asObservable();
    }
    /**
     * Publish Note
     * @param note notification
     */
    add(note) {
        this.dataSource.next(note);
    }
    /**
     * Show Error
     * @param subject subject
     * @param info optional info
     * @param link optional href
     * @param autoConfirmSecs auto confirm (0)
     */
    addError(subject, info = '', link, autoConfirmSecs = 0) {
        const note = new Note(subject, info, 'e');
        note.autoConfirmSecs = autoConfirmSecs;
        note.link = link;
        this.add(note);
    }
    /**
     * Show Exception
     * @param subject subject
     * @param err the error
     * @param instructions instruction overwrite
     * @param link optional href
     * @param autoConfirmSecs auto confirm (0)
     */
    addException(subject, err, instructions = 'Please try again later', link, autoConfirmSecs = 0) {
        let msg = instructions;
        if (err.message) { // HttpErrorResponse
            msg += ' -- ' + err.message;
        }
        else {
            msg += ' -- ' + JSON.stringify(err);
        }
        this.addError(subject, msg, link, autoConfirmSecs);
    }
    /**
     * Publich Info
     * @param subject heading
     * @param info detail
     * @param link optional href link
     * @param autoConfirmSecs close automatically after seconds (15)
     */
    addInfo(subject, info = '', link, autoConfirmSecs = 15) {
        const note = new Note(subject, info, 'i');
        note.autoConfirmSecs = autoConfirmSecs;
        note.link = link;
        this.add(note);
    }
    /**
     * Publish Client Response
     * @param subject heading
     * @param response response with detail
     */
    addResponse(subject, response) {
        let info = response.message;
        if (response.error) {
            info = response.error;
            if (response.message) {
                info += ' - ' + response.message;
            }
        }
        const note = new Note(subject, info, response.error ? 'e' : 's');
        note.autoConfirmSecs = response.error ? 0 : 15;
        this.add(note);
    } // addResponse
    /**
     * Publish Client Data Response
     * @param subject heading
     * @param response response with detail
     */
    addResponseData(subject, response) {
        let info = response.message;
        if (response.error) {
            info = response.error;
            if (response.message) {
                info += ' - ' + response.message;
            }
        }
        const responses = response.recordErrors;
        if (responses && responses.length > 0) {
            for (let i = 0; i < responses.length; i++) {
                if (responses[i] && responses[i].length > 0) {
                    info += '\n#' + (i + 1) + ': ' + responses[i];
                }
            }
        }
        const note = new Note(subject, info, response.error ? 'e' : 's');
        note.autoConfirmSecs = response.error ? 0 : 15;
        this.add(note);
    } // addResponse
    /**
     * Publish Success
     * @param subject heading
     * @param info detail
     * @param link optional href link
     * @param autoConfirmSecs close automatically after seconds (15)
     */
    addSuccess(subject, info = '', link, autoConfirmSecs = 15) {
        const note = new Note(subject, info, 's');
        note.autoConfirmSecs = autoConfirmSecs;
        note.link = link;
        this.add(note);
    }
    /**
     * Publish Warning
     * @param subject heading
     * @param info detail
     * @param link optional href link
     * @param autoConfirmSecs close automatically after seconds (0)
     */
    addWarning(subject, info = '', link, autoConfirmSecs = 0) {
        const note = new Note(subject, info, 'w');
        note.autoConfirmSecs = autoConfirmSecs;
        note.link = link;
        this.add(note);
    }
} // NotificationService
/** @nocollapse */ NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(); }, token: NotificationService, providedIn: "root" });
