/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../projects/accorto/src/lib/login/login.component.ngfactory";
import * as i3 from "../../../projects/accorto/src/lib/login/login.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../../projects/accorto/src/lib/login/login.service";
import * as i6 from "../../../projects/accorto/src/lib/notification/notification.service";
import * as i7 from "../../../projects/accorto/src/lib/accorto.service";
import * as i8 from "./login.component";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "acc-login", [["loginHeading", "Accorto Login"]], null, null, null, i2.View_LoginComponent_0, i2.RenderType_LoginComponent)), i1.ɵdid(1, 573440, null, 0, i3.LoginComponent, [i4.Store, i5.LoginService, i6.NotificationService, i7.AccortoService], { loginHeading: [0, "loginHeading"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h2", [["class", "slds-text-heading_medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Professional Services Automation"])), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "summary", [["class", "slds-m-vertical_x-small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Accorto PSA Workbench"])), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "img", [["alt", "Accorto PSA Workbench - Copyright Accorto, Inc."], ["src", "/assets/images/logo.svg"], ["style", "float: right; height: 100px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, 0, 4, "ul", [["class", "slds-list_dotted"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage Projects"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage Resources"]))], function (_ck, _v) { var currVal_0 = "Accorto Login"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "psa-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i8.LoginComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("psa-login", i8.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
