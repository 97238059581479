import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Day, Week, WeekDay } from './date-picker-utils';
import { DateUtil } from '../utils/date-util';
import { Logger } from '../log/logger';

/**
 * Date Picker
 */
@Component({
  selector: 'acc-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: [ './date-picker.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent implements OnChanges {

  /** Select individual day */
  static readonly MODE_DAY = 'd';
  /** Select week (first) day */
  static readonly MODE_WEEK = 'w';
  /** Select individual day range */
  static readonly MODE_RANGE = 'r';

  /** Input Mode */
  @Input() mode = DatePickerComponent.MODE_DAY;
  /** Initial Date */
  @Input() initialDate: Date;
  /** Alignment left|right */
  @Input() align: string = 'slds-dropdown_right';

  /** Date Selected */
  @Output() dateSelected = new EventEmitter<Date>();

  /** All years (select options) */
  years: number[];
  /** Current Year (select value) */
  year: number;
  yearSelect: string;

  /** Current Month 0..11 */
  month: number;
  /** Display Month */
  monthName: string;
  /** Current Day */
  day: number;

  /** Display Header */
  weekDays: WeekDay[];
  /** Display Calendar weeks with 7 days */
  weeks: Week[] = [];

  /** Calendar Start (first) */
  calendarStart: Date;
  /** Calendar End (last) */
  calendarEnd: Date;
  /** Selected End Date */
  private dateEnd: Date;
  private log: Logger = new Logger('Datepicker');
  /** Selected Start Date */
  private theDate: Date = DateUtil.today();

  constructor() {
    // years
    this.years = [];
    for (let yy = 1990; yy < 2023; yy++) {
      this.years.push(yy);
    }
    //
    // this.weekStartDay = loginService.weekStartDay;
    this.createWeekdays();
    // this.createDays();
  }

  /**
   * Selected Date (from)
   * @return the date in utc
   */
  get date(): Date {
    return this.theDate;
  }

  /**
   * Sel Selected Date (from)
   * @param value (converted to utc)
   */
  set date(value: Date) {
    if (value) {
      this.theDate = DateUtil.day(value);
    } else {
      this.theDate = DateUtil.today();
    }
    if (this.mode === DatePickerComponent.MODE_WEEK) {
      // date to first of week
      this.theDate = DateUtil.toStartOfWeek(this.theDate);
      // dateEnd to end of week
      this.dateEnd = DateUtil.toEndOfWeek(this.theDate);
      // console.log('start=' + this.theDate.toUTCString(), 'end=' + this.theDateEnd.toUTCString())
    } else if (this.mode === DatePickerComponent.MODE_RANGE) {
    }

    this.createDays();
    this.log.log('date', this.theDate.toISOString())();
  }

  /**
   * Create Calendar
   */
  createDays() {
    this.year = this.theDate.getUTCFullYear();
    this.yearSelect = String(this.year);
    this.month = this.theDate.getUTCMonth();
    this.monthName = DateUtil.monthName(this.month);
    this.day = this.theDate.getUTCDate();

    // week start
    let date = DateUtil.toStartOfWeek(this.theDate); // UTC
    // calendar start
    while (date.getUTCMonth() === this.month) {
      date = new Date(date.getTime() - DateUtil.ONEWEEK);
    }
    this.calendarStart = date;
    // this.log.log('createDays start', this.calendarStart.toISOString())();
    // until yyyyMM
    const untilYearMonth = this.year * 100 + this.month;
    let cmpYearMonth = date.getUTCFullYear() * 100 + date.getUTCMonth();
    // weeks
    const multi: boolean = this.mode !== DatePickerComponent.MODE_DAY;
    this.weeks = [];
    while (cmpYearMonth <= untilYearMonth) {
      const theWeek = new Week(multi);
      for (let dd = 0; dd < 7; dd++) {
        const theDay = new Day(date, this.month, this.theDate, this.dateEnd, multi);
        theWeek.addDay(theDay);
        // this.log.debug('day', theDay.toString())();
        date = new Date(date.getTime() + DateUtil.ONEDAY);
      }
      this.weeks.push(theWeek);
      cmpYearMonth = date.getUTCFullYear() * 100 + date.getUTCMonth();
    }
    this.calendarEnd = new Date(date.getTime() - DateUtil.ONEDAY);
    // this.log.debug('createDays', 'y=' + this.year, 'm=' + this.month, 'd=' + this.day)();
  } // createDays

  /**
   * Header
   */
  createWeekdays() {
    delete this.weekDays;
    this.weekDays = [];
    this.weekDays.push(new WeekDay(0, 'Sunday', 'Sun'));
    this.weekDays.push(new WeekDay(1, 'Monday', 'Mon'));
    this.weekDays.push(new WeekDay(2, 'Tuesday', 'Tue'));
    this.weekDays.push(new WeekDay(3, 'Wednesday', 'Wed'));
    this.weekDays.push(new WeekDay(4, 'Thursday', 'Thu'));
    this.weekDays.push(new WeekDay(5, 'Friday', 'Fri'));
    this.weekDays.push(new WeekDay(6, 'Saturday', 'Sat'));

    const weekStartDay = DateUtil.defaultWeekStartDay;
    for (let rounds = weekStartDay; rounds > 0; rounds--) {
      const first = this.weekDays.shift(); // from the start
      this.weekDays.push(first); // at the end
    }
  } // createWeekdays

  /**
   * Input changes
   */
  ngOnChanges(changes: SimpleChanges) {
    let isCreateDays = false;
    for (const propName of Object.keys(changes)) { // in changes
      if (propName === 'mode') {

      } else if (propName === 'initialDate') {
        //  this.log.debug('ngOnChanges date=' + this.initialDate.toISOString())();
        this.theDate = DateUtil.day(this.initialDate);
        isCreateDays = true;
      }
    }
    if (isCreateDays) {
      this.date = this.theDate;
    }
  } // ngOnChanges

  /**
   * Emit indefined
   */
  onClear() {
    this.log.debug('onClear')();
    this.dateSelected.emit(undefined);
  }

  /**
   * Clicked on Day
   */
  onDayClick(event, day: Day) {
    // this.log.debug('onDayClick ' + day, event)();
    if (day) {
      if (this.mode === DatePickerComponent.MODE_WEEK) {
        const first = DateUtil.toStartOfWeek(day.date);
        this.dateSelected.emit(first);
      } else if (this.mode === DatePickerComponent.MODE_RANGE) {
        this.dateSelected.emit(day.date);
      } else {
        this.dateSelected.emit(day.date);
      }
    }
  }

  /**
   * @param event next month
   */
  onNextMonth(event: MouseEvent) {
    event.preventDefault(); // otherwise submits form
    let newYear = this.theDate.getUTCFullYear();
    let newMonth = this.theDate.getUTCMonth() + 1;
    if (newMonth > 11) {
      newYear++;
      newMonth = 0;
    }
    this.log.debug('onNextMonth ' + newYear + '-' + (newMonth + 1))();
    this.date = new Date(Date.UTC(newYear, newMonth, this.theDate.getUTCDate()));
  }

  /**
   * @param event previous month
   */
  onPreviousMonth(event: MouseEvent) {
    event.preventDefault(); // otherwise submits form
    let newYear = this.theDate.getUTCFullYear();
    let newMonth = this.theDate.getUTCMonth() - 1;
    if (newMonth < 0) {
      newYear--;
      newMonth = 11;
    }
    this.log.debug('onPreviousMonth ' + newYear + '-' + (newMonth + 1))();
    this.date = new Date(Date.UTC(newYear, newMonth, this.theDate.getUTCDate()));
  }

  /**
   * Show Today
   */
  onToday() {
    this.log.debug('onToday')();
    this.date = null;
  }

  /**
   * Year change
   * @param yy new year
   */
  onYearChange(yy: any) {
    const newYear = Number(yy);
    this.log.debug('onYear', this.year, yy, newYear)();
    if (typeof newYear === 'number') {
      this.date = new Date(Date.UTC(newYear, this.theDate.getUTCMonth(), this.theDate.getUTCDate()));
    }
  }


} // DatePickerComponent
