/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-editor.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../projects/accorto/src/lib/modal-float/modal-float.component.ngfactory";
import * as i3 from "../../../projects/accorto/src/lib/modal-float/modal-float.component";
import * as i4 from "../../../projects/accorto/src/lib/form/form.component.ngfactory";
import * as i5 from "../../../projects/accorto/src/lib/form/form.component";
import * as i6 from "@angular/forms";
import * as i7 from "@ngrx/store";
import * as i8 from "../../../projects/accorto/src/lib/accorto.service";
import * as i9 from "./project-editor.component";
var styles_ProjectEditorComponent = [i0.styles];
var RenderType_ProjectEditorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ProjectEditorComponent, data: {} });
export { RenderType_ProjectEditorComponent as RenderType_ProjectEditorComponent };
export function View_ProjectEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "acc-modal-float", [["borderStyle", "solid 2px darkgreen"], ["iconRef", "/assets/icons/utility-sprite/svg/symbols.svg#edit"]], null, [[null, "closing"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closing" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ModalFloatComponent_0, i2.RenderType_ModalFloatComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalFloatComponent, [], { title: [0, "title"], iconRef: [1, "iconRef"], borderStyle: [2, "borderStyle"], showFooter: [3, "showFooter"] }, { closing: "closing" }), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "acc-form", [], null, [[null, "saveRecord"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveRecord" === en)) {
        var pd_0 = (_co.onSaveRecord($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FormComponent_0, i4.RenderType_FormComponent)), i1.ɵdid(3, 704512, null, 0, i5.FormComponent, [i6.FormBuilder, i7.Store, i8.AccortoService], { ui: [0, "ui"], record: [1, "record"], showClose: [2, "showClose"], initialTab: [3, "initialTab"] }, { saveRecord: "saveRecord" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = "/assets/icons/utility-sprite/svg/symbols.svg#edit"; var currVal_2 = "solid 2px darkgreen"; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.ui; var currVal_5 = _co.record; var currVal_6 = true; var currVal_7 = _co.initialTab; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ProjectEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "psa-project-editor", [], null, null, null, View_ProjectEditorComponent_0, RenderType_ProjectEditorComponent)), i1.ɵdid(1, 573440, null, 0, i9.ProjectEditorComponent, [i7.Store], null, null)], null, null); }
var ProjectEditorComponentNgFactory = i1.ɵccf("psa-project-editor", i9.ProjectEditorComponent, View_ProjectEditorComponent_Host_0, { recordI: "recordI", record: "record", initialTab: "initialTab" }, { editorClosed: "editorClosed" }, []);
export { ProjectEditorComponentNgFactory as ProjectEditorComponentNgFactory };
