import { NavigationEnd, Router } from '@angular/router';
import { AccortoService } from '../accorto.service';
import { isPlatformBrowser } from '@angular/common';
import { Logger } from '../log/logger';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../accorto.service";
/**
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
 * https://github.com/googleanalytics/autotrack
 *
 * https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/google.analytics/index.d.ts
 *
 * Track4d - (UA-32129178-12)
 * Pick4d - (UA-32129178-15)
 * Quando - (UA-32129178-5)
 * Accorto - (UA-32129178-2)
 * Check4Sf - (UA-32129178-14)
 */
export class GoogleAnalyticsService {
    /**
     * Setup
     * - Add script to index.html
     * - Add to constructor of app.component
     */
    constructor(router, config, platform) {
        this.log = new Logger('GA');
        const uaCode = config.gaCode ? config.gaCode : 'UA-32129178-2';
        if (isPlatformBrowser(platform)) {
            try {
                if (ga) {
                    this.log.debug(uaCode)();
                    ga('create', uaCode, 'auto');
                    ga('send', 'pageview');
                    router.events.subscribe((evt) => {
                        if (evt instanceof NavigationEnd) {
                            this.pageView(evt.urlAfterRedirects);
                        }
                    });
                }
                else {
                    this.log.warn('NotFound', uaCode)();
                }
            }
            catch (ex) {
                this.log.warn(uaCode, ex)();
            }
        }
    } // constructor
    /**
     * Set Session Info
     * @param userId user id
     * @param dim1 dimension 1 - org name
     */
    setSession(userId, dim1) {
        if (userId) {
            ga('set', 'userId', userId);
        }
        if (dim1) { // session org name
            ga('set', 'dimension1', dim1);
        }
    } // setSession
    /**
     * @param url current url
     */
    pageView(url) {
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/pages
        ga('set', 'page', url);
        ga('send', 'pageview');
        this.log.debug('pageView', url)();
        if (window.performance && !this.timeSincePageLoad) {
            this.timeSincePageLoad = Math.round(performance.now());
            this.timing('initial', 'load', this.timeSincePageLoad);
        }
    } // pageView
    timing(category, name, value) {
        ga('send', 'timing', category, name, value); // label
        this.log.debug('timing', name + '=' + value)();
    } // timing
    /**
     * @param action event action e.g. play
     * @param category event category e.g. videos
     * @param label event label e.g. video title
     * @param value integer value
     */
    event(category, action, label, value) {
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
        ga('send', 'event', category, action, label, value);
        this.log.debug('event', action)();
    } // event
    exception(description, fatal = false) {
        ga('send', 'exception', {
            exDescription: description,
            exFatal: fatal
        });
    } // exception
} // GoogleAnalyticsService
/** @nocollapse */ GoogleAnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AccortoService), i0.ɵɵinject(i0.PLATFORM_ID)); }, token: GoogleAnalyticsService, providedIn: "root" });
