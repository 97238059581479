import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UiState } from './ui.reducer';
import { UiTabI } from '../model/ui-tab-i';
import { UiTab } from '../model/ui-tab';
import { ModelUtil } from '../utils/model-util';


// export const selectUiState = state => state.ui;
export const selectUiState = createFeatureSelector<UiState>('ui');

// UiTabI map
export const selectUis = createSelector(
  selectUiState,
  (uiState): { [ key: string ]: UiTabI } => uiState.uis
);

// UiTab (clone)
export const selectUi = (uiName: string) => createSelector(
  selectUiState,
  (uiState): UiTab => ModelUtil.processUiTab(uiState.uis[ uiName ])
);

// https://blog.angularindepth.com/ngrx-parameterized-selector-e3f610529f8
