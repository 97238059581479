
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: CResponseImportRow
 */
export class CResponseImportRow {

  /**
   * label: csv cell values
   */
  public cells: string[] = [];

  /**
   * prefix: ERROR= INFO= SUCCESS=
   * label: Row Messages
   */
  public messages: string[] = [];

  /**
   * label: row no
   */
  public rowNo: number;

  /**
   * objectName.fieldName -> sf value
   * label: Salesforce Values
   */
  public valueMap: {[key: string]: string} = {};

} // CResponseImportRow
