import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import {
  AccortoService,
  CRequestDataI,
  CResponseDataI,
  DataOperation,
  DataRecordI,
  Logger,
  NotificationService,
  ServiceBase,
  Trl
} from 'accorto';
import { PsaUtil } from '../psa-util';

@Injectable({
  providedIn: 'root'
})
export class ResourceService extends ServiceBase {

  private log: Logger = new Logger('ResourceService');

  constructor(private http: HttpClient,
              private config: AccortoService,
              private note: NotificationService) {
    super();
  }

  /**
   * Load All Resources
   */
  loadResources(): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'Resource',
      operation: DataOperation.QUERY
    };
    return this.send(request);
  } // loadResources

  /**
   * Load Resources for specific resource id (active or inactive)
   * - called from AllocResource, effects: resourceSelectedAction
   * @param resourceId optional resource id
   * @param fromMs optional from time
   * @param toMs optional to Time
   */
  loadResourceAllocations(resourceId: string, fromMs: number, toMs: number): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'ProjectLineSharing',
      operation: DataOperation.QUERY,
      dataView: {
        selectAddlColumns: PsaUtil.selectAddlColumns,
        whereList: [],
        orderBys: [
          'name DESC'
        ]
      }
    };
    if (resourceId) {
      request.dataView.whereList.push(
        {
          columnName: 'resourceId',
          value: resourceId
        }
      );
    }
    if (fromMs && fromMs > 0) {
      // not: ended before            S--E--f--l --> E<=f = E>f
      //  AND Project_Line__r.End_Plan__c > :firstDate
      const dd = Trl.formatDateJdbc(fromMs); // YYYY-MM-DD
      request.dataView.whereList.push(
        {
          whereDirect: 'accorto__Project_Line__r.accorto__End_Plan__c > ' + dd
        }
      );
    }
    if (toMs && toMs > 0) {
      // not: started after           f--l--S--E --> l<S = S<l
      //  AND Project_Line__r.Start_Plan__c < :lastDate
      const dd = Trl.formatDateJdbc(toMs); // YYYY-MM-DD
      request.dataView.whereList.push(
        {
          whereDirect: 'accorto__Project_Line__r.accorto__Start_Plan__c < ' + dd
        }
      );
    }
    return this.send(request);
  } // loadResourceProjectAllocations

  loadResourceProjectLines(resourceId: string, dateFrom: number): Observable<DataRecordI[]> {
    const request: CRequestDataI = {
      tableName: 'ProjectLine',
      operation: DataOperation.QUERY,
      dataView: {
        whereList: [
          {
            columnName: 'resourceId',
            value: resourceId
          }
        ]
      }
    };
    return this.send(request);
  } // loadResourceProjectLines


  /**
   * Save Records
   * @param records records
   */
  saveList(records: DataRecordI[]): Observable<CResponseDataI> {
    const request: CRequestDataI = {
      tableName: records[ 0 ].recordType,
      operation: DataOperation.SAVE,
      records
    };
    return this.submit(request, 'Save List');
  } // saveList


  /**
   * Send/Query - return DataRecords
   * @param request request to send
   */
  send(request: CRequestDataI): Observable<DataRecordI[]> {
    return this
      .submit(request, 'Query')
      .pipe(
        map(response => response.records ? response.records : [])
      );
  } // sendI

  /**
   * Submit Request - return response
   * @param request request
   */
  submit(request: CRequestDataI, type: string): Observable<CResponseDataI> {
    const start = new Date();
    const url = this.config.server + '/data';
    this.log.info('submit ' + url + ' ' + request.tableName, request)();
    this.config.setCRequestI(request);
    const body = JSON.stringify(request);
    //
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<CResponseDataI>(url, body, httpOptions)
      .pipe(
        tap(response => {
          const msg = this.markI(start, response, response.tableName);
          if (response.error) {
            this.note.addResponseData('Resource ' + type + ' failed', response);
            this.log.warn('submit.response', response)();
            this.markError(start);
          } else {
            this.log.info('submit.response ' + msg)();
            this.markSuccess(start);
          }
        }),
        catchError((err) => {
          this.log.error('submit', err)();
          const error: CResponseDataI = {
            error: 'Connection Error'
          };
          if (err instanceof HttpErrorResponse) {
            error.error = err.message;
          }
          this.note.addError('Resource ' + type + ' error', error.error);
          return of(error);
        })
      );
  } // submit

} // ResourceService
