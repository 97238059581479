/**
 * Margin
 */
export class Margin {

  /**
   * Margin
   * @param t top
   * @param r right
   * @param b bottom
   * @param l left
   */
  constructor(public t: number, public r?: number, public b?: number, public l?: number) {
    if (r === undefined) {
      this.r = this.t;
    }
    if (b === undefined) {
      this.b = this.t;
    }
    if (l === undefined) {
      this.l = this.r;
    }
  }

  /** Vertical (width) r+l */
  get v(): number {
    return this.r + this.l;
  }

  /** Horizontal (height) t+b */
  get h(): number {
    return this.t + this.b;
  }

  toString(): string {
    return '[' + this.t + ',' + this.r + ',' + this.b + ',' + this.l + ']';
  }


} // Margin
