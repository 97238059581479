import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TzInfoResponse } from '../model/tz-info-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isPlatformServer } from '@angular/common';
import { Logger } from '../log/logger';
import { AccortoService } from '../accorto.service';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  private log: Logger = new Logger('TimezoneService');

  constructor(private http: HttpClient,
              private config: AccortoService,
              @Inject(PLATFORM_ID) private platform) {
  }

  get(): Observable<TzInfoResponse> {
    if (isPlatformServer(this.platform)) {
      this.log.info('get(server)')();
      return of(new TzInfoResponse());
    }

    const dd = new Date();
    const timeString = dd.toLocaleDateString('en', { timeZoneName: 'short' });
    const timezoneName = AccortoService.timezoneId;
    const localeName = AccortoService.locale;

    const url = this.config.server
      + '/tz?id=' + encodeURIComponent(timezoneName)
      + '&offset=' + dd.getTimezoneOffset()
      + '&locale=' + encodeURIComponent(localeName)
      + '&time=' + encodeURIComponent(timeString);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.log.info('get ' + url)();

    return this.http.get<TzInfoResponse>(url, httpOptions);
  }

}
