import { Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * Help Button to start the Help Component
 * for content, add classes like: help-p, slds-list_dotted
 */
@Component({
  selector: 'acc-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: [ './help-button.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class HelpButtonComponent {

  @Input() title: string = 'Help';
  @Input() message: string;
  @Input() vimeoId: string;
  @Input() youtubeId: string;

  showHelp: boolean = false;

  constructor() {
  }

  onClick() {
    this.showHelp = !this.showHelp;
  }

  onClose() {
    this.showHelp = false;
  }

}
