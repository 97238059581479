import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Logger } from '../log/logger';
import { DataColumn } from '../model/data-column';
import { Trl } from '../utils/trl';
import { DateUtil } from '../utils/date-util';
import { UiFormField } from '../model/ui-form-field';
import { UiGridField } from '../model/ui-grid-field';
import { FormManager } from '../form/form-manager';
import { FormFocus } from '../form/form-focus';

@Component({
  selector: 'acc-form-date',
  templateUrl: './form-date.component.html',
  styleUrls: [ './form-date.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class FormDateComponent implements OnChanges, OnDestroy, FormFocus {

  /** Grid Field */
  @Input() gf: UiGridField;
  /** Form Field */
  @Input() ff: UiFormField;
  /** Form Manager */
  @Input() fm: FormManager;

  /** Base Id */
  @Input() theId: string;
  /** ReadOnly Overwrite */
  @Input() readonly: boolean;

  /** The String Number Value */
  value: string | undefined;
  /** The Date Value */
  theDate: Date;

  /** Property Name */
  propertyName: string;
  /** Field Label */
  label: string;
  /** Data Column */
  dataColumn: DataColumn;

  /** Form Element Control */
  control: FormControl;

  @ViewChild('inp', { static: true })
  inputElement: ElementRef;

  errorMessage: string;

  /** Show Date Dialog */
  showDatePicker: boolean = false;

  private subscription: Subscription;
  private log: Logger = new Logger('FormDate');

  constructor() {
  }

  get inputValue(): string {
    if (this.inputElement) {
      return this.inputElement.nativeElement.value;
    }
    return '';
  }

  get valid(): boolean {
    if (this.errorMessage) {
      return false;
    }
    if (this.control) {
      // this.log.log('valid ' + this.control.valid, this.control.errors)();
      if (this.control.valid) {
        return true;
      } else {
        return this.control.pristine; // true if untouched
      }
    }
    return false;
  }

  /**
   * Required
   */
  get isUiRequired(): boolean {
    if (this.ff) {
      return this.ff.isRequired();
    }
    if (this.gf && this.gf.dataColumn) {
      return this.gf.dataColumn.isUiRequired;
    }
    return false;
  }

  // Read only
  get isReadOnly(): boolean {
    if (this.readonly !== undefined && this.readonly) {
      return true; // override
    }
    if (this.fm && this.fm.record.isReadOnly) {
      return true; // record
    }
    if (this.dataColumn) {
      return this.dataColumn.isReadOnly; // column
    }
    return false;
  }

  /**
   * Initialize
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.ff) {
      this.propertyName = this.ff.name;
      this.label = this.ff.label;
      this.dataColumn = this.ff.dataColumn;
    } else if (this.gf) {
      this.propertyName = this.gf.name;
      this.label = this.gf.label;
      this.dataColumn = this.gf.dataColumn;
    }
    if (!this.control) { // setup
      this.log.setSubName(this.propertyName);
      if (this.fm.registerFocus(this.propertyName, this)) {
        setTimeout(() => {
          if (this.inputElement) {
            this.log.debug('ngOnChanges.focus')();
            this.inputElement.nativeElement.focus();
          }
        }, 500);
      }
      this.control = this.fm.formGroup.controls[ this.propertyName ] as FormControl;
      this.subscription = this.control.valueChanges.subscribe((value) => {
        this.log.debug('ValueChanges error=' + this.errorMessage,
          (this.control.dirty ? 'dirty ' : '') + (this.control.valid ? '' : 'NotValid ') + 'value=' + value)();
        this.setValue(value);
      });
    }
    if (this.control) {
      const value = this.control.value;
      this.log.debug('ngOnChanges', changes,
        (this.control.dirty ? 'dirty ' : '') + (this.control.valid ? '' : 'NotValid ') + 'value=' + value)();
      this.setValue(this.control.value);
    } else {
      this.log.info('ngOnChanges NoControl ' + this.propertyName, this.fm.formGroup)();
    }
  } // ngOnChanges

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * parse and set value if no DatePicker displayed
   */
  onBlur(event: FocusEvent) {
    if (!this.showDatePicker) {
      const newDate = DateUtil.parseDate(this.inputValue);
      this.log.debug('onBlur', this.inputValue, newDate)();
      this.setDate(newDate);
    } else {
      this.log.debug('onBlur showing', this.inputValue)();
    }
  }

  onClearClick() {
    this.log.debug('onClearClick ' + this.showDatePicker)();
    this.fm.onFocus(this.propertyName);
    this.setDate(undefined);
    this.showDatePicker = true;
  }

  onClick(event: Event) {
    if (!this.showDatePicker) {
      // theDate used by DatePicker
      this.theDate = DateUtil.parseDate(this.inputValue);
    }
    this.showDatePicker = !this.showDatePicker; // calls dateSelected
  } // onClicked

  /**
   * Called from DatePicker
   */
  onDateSelected(utcDate: Date) {
    this.log.debug('onDateSelected', utcDate)();
    this.setDate(utcDate);
  }

  onFocus() {
    this.fm.onFocus(this.propertyName);
  }

  onFocusChangedTo(propertyName: string) {
    if (this.propertyName !== propertyName) {
      this.showDatePicker = false;
    }
  }

  /**
   * KeyUp - parse for errors - update via blur
   */
  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Clear') {
      this.setValue(undefined);
      this.errorMessage = this.isUiRequired ? 'required' : undefined;
    } else {
      const newDate = DateUtil.parseDate(this.inputValue);
      this.log.debug('onKeyUp ' + event.key, this.inputValue, newDate)();
      if (this.inputValue === '') {
        this.errorMessage = undefined;
      } else if (newDate) {
        this.errorMessage = undefined;
        this.showDatePicker = false;
      } else {
        this.errorMessage = 'Invalid';
        this.showDatePicker = true;
      }
      if (event.key === 'Enter' && !this.errorMessage) {
        this.setDate(newDate);
        this.inputElement.nativeElement.blur();
      }
    }
  } // onKeyUp

  /**
   * Set Date - update control (and display)
   * - called when blur, DatePicker (inside)
   */
  private setDate(newDate: Date) {
    this.log.debug('setDate', newDate)();
    this.showDatePicker = false;
    this.theDate = newDate;
    this.errorMessage = undefined;
    if (newDate) {
      this.control.setValue(String(newDate.getTime())); // *** calls setValue via ValueChanges
      this.control.markAsDirty();
    } else {
      if (this.isUiRequired) {
        this.errorMessage = 'required';
      }
      this.control.setValue(null);
      this.control.markAsDirty();
    }
  } // setDate

  /**
   * Set number value - update display
   * - called when control changes (outside)
   */
  private setValue(numberValue: string) {
    this.showDatePicker = false;
    if (numberValue && numberValue.length > 0) {
      this.value = numberValue;
      const time = Number(numberValue);
      if (Number.isInteger(time)) {
        this.theDate = new Date(time);
        this.inputElement.nativeElement.value = Trl.formatDateUtc(this.theDate);
        this.log.debug('setValue', this.value, this.control.value, this.control.dirty, this.theDate)();
        return;
      }
    }
    // none, invalid
    this.value = undefined;
    this.theDate = undefined;
    this.inputElement.nativeElement.value = ''; // string
  }

} // FormDateComponent
