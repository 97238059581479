import { SessionI, SessionD } from './session-i';
import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Session
 */
export class Session {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id: number|string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created: number;

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy: TenantUser;

  public createdById: number;

  /**
   * label: Updated Time
   */
  public updated: number;

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy: TenantUser;

  public updatedById: number;

  /**
   * label: External Id
   */
  public externalId: string;

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant: Tenant;

  public tenantId: number;

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner: TenantUser;

  public ownerId: number;

  /**
   * label: Record Version
   */
  public version: number;

  /**
   * label: Salesforce Id
   */
  public sfId: string;

  /**
   * label: Client Id
   */
  public cid: string;

  /**
   * label: User Locale
   */
  public locale: string;

  /**
   * label: Timezone Offset (ms)
   */
  public utcOffset: number;

  /**
   * label: Timezone
   */
  public timezone: string;

  /**
   * label: User Language
   */
  public language: string;

  /**
   * fk TenantUser
   * label: User
   */
  public tenantUser: TenantUser;

  public tenantUserId: number;

  /**
   * label: Salesforce Org Id
   */
  public sfOrgId: string;

  /**
   * label: Salesforce User Id
   */
  public sfUserId: string;

  /**
   * label: Salesforce User Name
   */
  public sfUserName: string;

  /**
   * label: Salesforce Resource Id
   */
  public sfResourceId: string;

  /**
   * label: Salesforce Instance
   */
  public sfInstanceUrl: string;

  /**
   * label: Email Address
   */
  public email: string;

  /**
   * label: Full User Name
   */
  public userName: string;

  /**
   * label: User Initials
   */
  public userInitials: string;

  /**
   * label: Photo Url
   */
  public userPhotoUrl: string;

  /**
   * 1..7 = Sun..Sat
   * label: First Day of Week
   */
  public firstDayOfWeek: number;

  /**
   * label: Settings
   */
  public settings: {[key: string]: string} = {};


  /**
   * @return Session record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'Session';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key) => {
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): Session {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        const value = record.valueMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, SessionD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        const value = record.changeMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, SessionD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: SessionI): Session {
    Object.keys(SessionD).forEach((key) => {
      const value = record[ key ];
      if (value) {
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'tenantUser') { // fk
          this.tenantUser = new TenantUser();
          this.tenantUser.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // Session
