import { CRequest } from './c-request';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * extends: CRequest
 * name: CRequestFk
 */
export class CRequestFk extends CRequest {

  /**
   * label: Fk Table Name
   */
  public fkTable: string;

  /**
   * label: Optional Id
   */
  public fkId: string;

  /**
   * label: Optional List of Ids
   */
  public fkIds: string[] = [];

  /**
   * label: Optional Limit
   */
  public limit: number;

  /**
   * label: Optional Search Term
   */
  public searchTerm: string;

  /**
   * label: Optional Parent Map - propertyName->value
   */
  public parentMap: {[key: string]: string} = {};

} // CRequestFk
