import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TimezoneService } from './timezone.service';
import { NotificationService } from '../notification/notification.service';
import { timezoneErrorAction, timezoneReponseAction, timezoneRequestAction, timezoneSetAction } from './timezone.actions';

@Injectable()
export class TimezoneEffects {

  timezoneRequest$ = createEffect(() => this.actions$.pipe(
    ofType(timezoneRequestAction),
    tap((action) => {
      //  console.log('timezoneRequest$', action);
    }),
    mergeMap((action) => this.service.get()
      .pipe(
        catchError(err => {
          // console.warn('timezoneRequest$.error', err); // TypeError: Cannot read property 'callType' of null
          this.store.dispatch(timezoneErrorAction({ err }));
          return EMPTY;
        })
      )),
    map(response => timezoneReponseAction({ response }))
    ), {}
  );

  timezoneError$ = createEffect(() => this.actions$.pipe(
    ofType(timezoneErrorAction),
    tap((action) => {
      const err = action.err;
      console.warn('timezoneError$', err);
      this.notify.addException('Connection Error (TZ)', err);
    })
    ), { dispatch: false }
  );


  timezoneSet$ = createEffect(() => this.actions$.pipe(
    ofType(timezoneSetAction),
    tap((action) => {
      //  console.log('timezoneSet$', action);
    })
    ), { dispatch: false }
  );

  /* @Effect()
  init$ = defer(() => {
    return of(new TimezoneRequest());
  }); */

  constructor(private actions$: Actions,
              private service: TimezoneService,
              private store: Store<any>,
              private notify: NotificationService) {
  }
}
