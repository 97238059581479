import { TenantUserI } from './tenant-user-i';
import { TenantI } from './tenant-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Session
 */
export interface SessionI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name?: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * label: Client Id
   */
  cid?: string;

  /**
   * label: User Locale
   */
  locale?: string;

  /**
   * label: Timezone Offset (ms)
   */
  utcOffset?: number;

  /**
   * label: Timezone
   */
  timezone?: string;

  /**
   * label: User Language
   */
  language?: string;

  /**
   * fk TenantUserI
   * label: User
   */
  tenantUser?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: User
   */
  tenantUserId?: number|string;

  /**
   * label: Salesforce Org Id
   */
  sfOrgId?: string;

  /**
   * label: Salesforce User Id
   */
  sfUserId?: string;

  /**
   * label: Salesforce User Name
   */
  sfUserName?: string;

  /**
   * label: Salesforce Resource Id
   */
  sfResourceId?: string;

  /**
   * label: Salesforce Instance
   */
  sfInstanceUrl?: string;

  /**
   * label: Email Address
   */
  email?: string;

  /**
   * label: Full User Name
   */
  userName?: string;

  /**
   * label: User Initials
   */
  userInitials?: string;

  /**
   * label: Photo Url
   */
  userPhotoUrl?: string;

  /**
   * 1..7 = Sun..Sat
   * label: First Day of Week
   */
  firstDayOfWeek?: number;

  /**
   * label: Settings
   */
  settings?: { [key: string]: string };

} // Session

/** Session Definition */
export const SessionD = {
  recordTypeName: 'Session',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  cid: {
    n: 'cid',
    t: 'string'
  },
  locale: {
    n: 'locale',
    t: 'string'
  },
  utcOffset: {
    n: 'utcOffset',
    t: 'number'
  },
  timezone: {
    n: 'timezone',
    t: 'string'
  },
  language: {
    n: 'language',
    t: 'string'
  },
  tenantUser: {
    n: 'tenantUser',
    t: 'TenantUserI'
  },
  tenantUserId: {
    n: 'tenantUserId',
    t: 'number|string'
  },
  sfOrgId: {
    n: 'sfOrgId',
    t: 'string'
  },
  sfUserId: {
    n: 'sfUserId',
    t: 'string'
  },
  sfUserName: {
    n: 'sfUserName',
    t: 'string'
  },
  sfResourceId: {
    n: 'sfResourceId',
    t: 'string'
  },
  sfInstanceUrl: {
    n: 'sfInstanceUrl',
    t: 'string'
  },
  email: {
    n: 'email',
    t: 'string'
  },
  userName: {
    n: 'userName',
    t: 'string'
  },
  userInitials: {
    n: 'userInitials',
    t: 'string'
  },
  userPhotoUrl: {
    n: 'userPhotoUrl',
    t: 'string'
  },
  firstDayOfWeek: {
    n: 'firstDayOfWeek',
    t: 'number'
  },
  settings: {
    n: 'settings',
    t: '{ [key: string]: string }'
  },
}; // Session
