import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AccortoService, DataOperation, Logger, ServiceBase } from 'accorto';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../projects/accorto/src/lib/accorto.service";
export class InvoiceService extends ServiceBase {
    constructor(http, config) {
        super();
        this.http = http;
        this.config = config;
        this.log = new Logger('InvoiceService');
    }
    loadInvoiceLines(projectId) {
        const request = {
            tableName: 'InvoiceLine',
            operation: DataOperation.QUERY,
            dataView: {
                whereList: [
                    {
                        columnName: 'projectId',
                        value: projectId
                    }
                ]
            }
        };
        return this.send(request);
    } // loadInvoice
    loadInvoices(projectId) {
        const request = {
            tableName: 'Invoice',
            operation: DataOperation.QUERY,
            dataView: {
                whereList: [
                    {
                        columnName: 'projectId',
                        value: projectId
                    }
                ]
            }
        };
        return this.send(request);
    } // loadInvoice
    /**
     * Send/Query - return DataRecords
     * @param request request to send
     */
    send(request) {
        return this.submit(request).pipe(map(response => response.records ? response.records : []));
    } // sendI
    /**
     * Submit Request - return response
     * @param request request
     */
    submit(request) {
        const start = new Date();
        const url = this.config.server + '/data';
        this.log.info('submit ' + url + ' ' + request.tableName, request)();
        this.config.setCRequestI(request);
        const body = JSON.stringify(request);
        //
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(url, body, httpOptions)
            .pipe(tap(response => {
            const msg = this.markI(start, response, response.tableName);
            this.log.info('submit.response ' + msg)();
            this.markSuccess(start);
        }), catchError((err) => {
            this.log.error('submit', err)();
            const error = {
                error: 'Connection Error'
            };
            if (err instanceof HttpErrorResponse) {
                error.error = err.message;
            }
            return of(error);
        }));
    } // submit
} // InvoiceService
/** @nocollapse */ InvoiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvoiceService_Factory() { return new InvoiceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AccortoService)); }, token: InvoiceService, providedIn: "root" });
