import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { AccortoService, appStatus, DataRecordF, DataRecordI, Dimensions, ProjectLineD } from 'accorto';
import { AppState } from '../reducers';
import { selectCurrentProjectLines } from '../project/project.selectors';
import { selectProjectTeItems } from '../te-item/te-item.selectors';
import { projectSelectedAction } from '../project/project.actions';
import { selectInvoiceLines } from '../invoice/invoice.selectors';
import { PsaBase } from '../psa-base';
import { StackedBarChart } from '../graphs/stacked-bar-chart/stacked-bar-chart';
import { BarSourceF, BarSourceI } from '../graphs/stacked-bar-chart/bar-source';
import { TimeLine } from '../graphs/time-line/time-line';
import { TimeSourceF, TimeSourceI } from '../graphs/time-line/time-source';

import { TEItemD } from '../../../projects/track4d/src/app/model/t-e-item-i';
import { InvoiceLineD } from '../../../projects/accorto/src/lib/model/invoice-line-i';
import { ProjectService } from '../project/project.service';

/**
 * Project Dashboard
 */
@Component({
  selector: 'psa-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent extends PsaBase
  implements OnInit, OnDestroy {


  /** hours stacked */
  hoursDefinition: StackedBarChart = {
    prefix: '',
    decimals: 2,
    suffix: 'h',
    types: [ BarSourceF.PLAN_T, BarSourceF.PLAN_B,
      BarSourceF.CURRENT_T, BarSourceF.CURRENT_B, BarSourceF.INVOICED_T ]
  };
  hoursDimensions: Dimensions = new Dimensions(500, 300, 20, 40);
  hoursSources: BarSourceI[] = [];

  /** Time Lines */
  tlDefinitions: TimeLine[] = [
    {
      type: TimeSourceF.HOURS_T,
      label: 'Total Hours',
      yAxisRight: false,
      decimals: 0,
      prefix: '',
      suffix: 'h',
      color: 'lightsalmon'
    },
    {
      type: TimeSourceF.HOURS_B,
      label: 'Billable Hours',
      yAxisRight: false,
      decimals: 0,
      prefix: '',
      suffix: 'h',
      color: 'lightgreen'
    },
    {
      type: TimeSourceF.COSTS,
      label: 'Cost Amount',
      yAxisRight: true,
      decimals: 0,
      prefix: '',
      suffix: '',
      color: 'red'
    },
    {
      type: TimeSourceF.REVENUE,
      label: 'Billable Amount',
      yAxisRight: true,
      decimals: 0,
      prefix: '',
      suffix: '',
      color: 'green'
    }
  ];
  tlDimensions: Dimensions = new Dimensions(800, 300, 20, 40);
  tlSources: TimeSourceI[] = [];

  /** Project Line Data */
  private hoursSourceMap: { [ key: string ]: BarSourceI } = {};
  private hoursSourceKeyList: string[] = [];

  /** current data */
  private rawProjectLines: DataRecordI[] = [];
  private rawItems: DataRecordI[] = [];
  private rawInvoiceLines: DataRecordI[] = [];

  /**
   * Dashboard
   */
  constructor(store: Store<AppState>,
              conf: AccortoService) {
    super('Dashboard', 'P', '', 'Dashboard',
      undefined, undefined, store, conf);
    // conf.inIFrameConfirmed = false;
  }

  get iframeConfirmed(): boolean {
    return this.conf.inIFrameConfirmed;
  }

  get isEditProject(): boolean {
    return this.project && this.project.id != null && this.project.id.length > 0;
  }

  ngOnDestroy(): void {
    super.ngDestroy();
  } // ngDestroy

  ngOnInit() {
    // get potential canvas ids
    const parameterIds: string[] = [];
    if (this.conf.session && this.conf.session.settings) {
      // parameter
      const pId = this.conf.session.settings.p_recordId;
      if (pId) {
        const pOn = this.conf.session.settings.p_objectName;
        parameterIds.push(pId);
      }
      const recordId = this.conf.session.settings.recordId;
      if (recordId) {
        const recordType = this.conf.session.settings.recordType;
        parameterIds.push(recordId);
      }
    }
    if (this.conf.env.id) {
      parameterIds.push(this.conf.env.id);
    }
    // parameterIds.push('a0F1E00000oJyfLUAS'); // NorthStart
    // parameterIds.push('a0F8000000nhAnGEAU'); // demo data Lewis 7352
    this.log.info('ngOnInit params', parameterIds)();
    if (parameterIds.length > 0) {
      this.pProjectId = parameterIds[ 0 ];
    }
    // --load--
    super.ngInit(); // projectAll currentProject resourceMap resourceAll currentResource

    // lines of selected project - initiated by project select
    this.busyPlus('pl');
    this.subscriptions.push(this.store.pipe(select(selectCurrentProjectLines))
      .subscribe((projectLines) => {
        this.loadProjectLines(projectLines); // updateNodes + layout
        this.busyMinus('pl');
      }));

    // items of the selected project - initiated by project select
    this.busyPlus('items');
    this.subscriptions.push(this.store.pipe(select(selectProjectTeItems))
      .subscribe((items) => {
        this.loadTeItems(items);
        this.busyMinus('items');
      }));

    // invoice lines of the selected project - initiated by project select
    this.busyPlus('il');
    this.subscriptions.push(this.store.pipe(select(selectInvoiceLines))
      .subscribe((lines) => {
        this.loadInvoiceLines(lines);
        this.busyMinus('il');
      }));

    this.store.dispatch(appStatus({ status: 'dashboard' }));
  } // ngOnInit

  /**
   * Show Project Editor
   */
  onEditProject() {
    this.log.debug('onEditProject')();
    this.showProjectEdit = true;
  }

  /**
   * Hide Project Editor
   */
  onEditProjectClose() {
    this.log.debug('onEditProjectClose')();
    this.showProjectEdit = false;
  }

  /**
   * iframe confirm
   */
  onLoadProject() {
    this.log.debug('onLoadProject')();
    this.conf.inIFrameConfirmed = true;
    if (this.project) {
      this.store.dispatch(projectSelectedAction({ project: DataRecordF.clone(this.project) }));
    } else {
      this.onSelectProject();
    }
  } // onLoadProject

  /**
   * Select Project
   * @param project project to load [close(true)=undefined clear(false)=null]
   */
  onProjectSelected(project: DataRecordI) {
    super.onProjectSelected(project, false);
    if (project !== undefined) {
      this.store.dispatch(projectSelectedAction({ project: DataRecordF.clone(project) }));
    }
  }

  onRefresh() {
    if (this.project) {
      this.store.dispatch(projectSelectedAction({ project: DataRecordF.clone(this.project) }));
    } else {
      this.showProjectSelect = true;
    }
  }

  /**
   * Show Project Selection
   */
  onSelectProject() {
    this.log.debug('onSelectProject')();
    this.showProjectSelect = true;
    this.conf.inIFrameConfirmed = true;
  }

  /**
   * Load Invoice Lines (3)
   * @param invoiceLines lines
   */
  protected loadInvoiceLines(invoiceLines: DataRecordI[]) {
    this.rawInvoiceLines = invoiceLines;
    this.buildPage('invoice');
  } // loadProjectLines

  /**
   * Load Project Lines (1)
   * @param projectLines project lines
   */
  protected loadProjectLines(projectLines: DataRecordI[]) {
    this.rawProjectLines = projectLines;
    this.buildPage('projectLines');
  } // loadProjectLines

  /**
   * Load TE Items (2)
   * @param items items
   */
  protected loadTeItems(items: DataRecordI[]) {
    this.rawItems = items;
    this.buildPage('items');
  } // loadTeItems

  /**
   * Set Projects
   * - don't load project if IFrame not confirmed
   * @param projects projects
   */
  protected setProjects(projects: DataRecordI[]) {
    if (projects.length > 0
      && this.pProjectId
      && !this.conf.inIFrameConfirmed) {
      const temp = this.pProjectId;
      this.pProjectId = undefined; // prevent loading
      super.setProjects(projects); // clone
      this.pProjectId = temp;
      const candidates = projects.filter(pj => pj.id === this.pProjectId);
      if (candidates.length > 0) {
        this.project = candidates[ 0 ];
      }
    } else {
      super.setProjects(projects); // clone
    }
  } // setProjects

  /**
   * Build Page Data
   * @param why new data
   */
  private buildPage(why: string) {
    this.log.debug('buildPage', why,
      'pl=' + this.rawProjectLines.length,
      'items=' + this.rawItems.length,
      'inv=' + this.rawInvoiceLines.length)();

    // -- project lines --
    if (why === 'projectLines') {
      this.hoursSourceMap = {}; // reset
      this.hoursSourceKeyList = [];
      //
      this.rawProjectLines.forEach((pl: DataRecordI) => {
        if (pl.name !== ProjectService.PROJECT) {
          const projectLineId = pl.id;
          this.hoursSourceKeyList.push(projectLineId);
          const label = DataRecordF.codeLabel(pl);
          const total = DataRecordF.valueNumber(pl, ProjectLineD.plannedEffort.n, 0);
          const billable = DataRecordF.valueNumber(pl, ProjectLineD.plannedEffortBillable.n, 0);
          //
          const barSource: BarSourceI = {
            name: projectLineId,
            label,
            values: {}
          };
          BarSourceF.add(barSource, BarSourceF.PLAN_T, total);
          BarSourceF.add(barSource, BarSourceF.PLAN_B, billable);
          this.hoursSourceMap[ projectLineId ] = barSource;
        }
      });
      this.store.dispatch(appStatus({ status: 'dashboard-lines-' + this.rawProjectLines.length }));
      this.hoursSources = this.hoursSourceKeyList.map((key) => this.hoursSourceMap[ key ]);
    } // projectLines

    // -- Items -- reset
    Object.values(this.hoursSourceMap).forEach((bs) => {
      BarSourceF.reset(bs, [ BarSourceF.CURRENT_T, BarSourceF.CURRENT_B ]);
    });
    this.tlSources = [];
    // time line
    this.rawItems.forEach((item: DataRecordI) => {
      const projectLineId = DataRecordF.value(item, TEItemD.projectLineSfId.n);
      const barSource: BarSourceI = this.getBarSource(projectLineId);
      //
      const hours = DataRecordF.valueNumber(item, TEItemD.hours.n, 0);
      BarSourceF.add(barSource, BarSourceF.CURRENT_T, hours);
      const invoiced = DataRecordF.valueBoolean(item, TEItemD.invoiced.n);
      if (invoiced) {
        BarSourceF.add(barSource, BarSourceF.CURRENT_B, hours);
      }
      // time
      const date = DataRecordF.valueDate(item, TEItemD.teDate.n);
      const ts = TimeSourceF.create(date);
      TimeSourceF.add(ts, TimeSourceF.HOURS_T, hours, TimeSourceF.HOURS_B, invoiced);
      const costRate = DataRecordF.valueNumber(item, TEItemD.costRate.n, 0);
      if (costRate) {
        const costs = costRate * hours;
        TimeSourceF.add(ts, TimeSourceF.COSTS, costs);
      }
      const billingRate = DataRecordF.valueNumber(item, TEItemD.billingRate.n, 0);
      if (billingRate && invoiced) {
        const revenue = billingRate * hours;
        TimeSourceF.add(ts, TimeSourceF.REVENUE, revenue);
      }
      this.tlSources.push(ts);
    });
    this.hoursSources = this.hoursSourceKeyList.map((key) => this.hoursSourceMap[ key ]);

    // -- Invoices -- reset
    Object.values(this.hoursSourceMap).forEach((bs) => {
      BarSourceF.reset(bs, [ BarSourceF.INVOICED_T ]);
    });
    this.rawInvoiceLines.forEach((line: DataRecordI) => {
      const projectLineId = DataRecordF.value(line, InvoiceLineD.projectLineSfId.n);
      const barSource: BarSourceI = this.getBarSource(projectLineId);
      //
      const hours = DataRecordF.valueNumber(line, InvoiceLineD.hours.n, 0);
      BarSourceF.add(barSource, BarSourceF.INVOICED_T, hours);
      // line
      const unitPrice = DataRecordF.valueNumber(line, InvoiceLineD.unitPrice.n, 0);
    });
    this.hoursSources = this.hoursSourceKeyList.map((key) => this.hoursSourceMap[ key ]);

  } // buildPage

  /**
   * Get Bar Source or create Other
   * @param projectLineId project line id
   */
  private getBarSource(projectLineId: string): BarSourceI {
    let barSource: BarSourceI = this.hoursSourceMap[ projectLineId ];
    if (!barSource) {
      barSource = this.hoursSourceMap.other;
      if (!barSource) {
        barSource = {
          name: 'other',
          label: '-other-',
          values: {}
        };
        this.hoursSourceKeyList.push('other');
        this.hoursSourceMap.other = barSource;
      }
    }
    return barSource;
  } // getBarSource

} // Dashboard
