import { DataRecordF, DataRecordI } from 'accorto';

/**
 * Project Line Information
 */
export class ProjectAllocation {

  /** projectLineId + resourceId */
  id: string;

  projectLineId: string;
  isShareAll: boolean;
  resourceId: string;

  projectLineName: string;

  /**
   *
   * @param record project line
   * @param resourceList resources
   */
  constructor(public record: DataRecordI, public resourceList: DataRecordI[]) {
    // const projectId = DataRecordF.value(record, 'projectSfId');
    // const projectPhaseId = DataRecordF.value(record, 'projectPhaseSfId');
    this.projectLineId = record.id;
    //
    this.projectLineName = DataRecordF.codeLabel(record);
    //
    this.resourceId = DataRecordF.value(record, 'resourceSfId');
    this.isShareAll = DataRecordF.valueBoolean(record, 'isShareAll');

    this.id = this.projectLineId + '_' + this.resourceId;
    // console.debug('projectLineId=' + this.projectLineId, 'isShareAll=' + this.isShareAll, 'resourceId' + this.resourceId);
  } // constructor


} // ProjectAllocation
