/**
 * Logger
 *    with
 *      ng build --prod --aot --named-chunks --source-map
 *    console lists correct ts line numbers
 *
 *
 * private log: Logger = new Logger('XX');
 *
 * this.log.error("error")(); // red
 * this.log.warn("warn")();   // yellow
 * this.log.info("info")();   // blue
 * this.log.log("log")();     // green
 * this.log.debug("debug")(); // gray (verbose)
 *
 * console.error("error");    // red icon
 * console.warn("warn");      // yellow icon
 * console.info("info");      // black - no-console
 * console.log("log");        // black
 * console.debug("debug");    // black
 * console.trace("trace");    // with stack trace
 * console.assert(tt === undefined, 'plain assert true');
 *
 */
import { AccortoService } from '../accorto.service';

export class Logger {

  /** shows definition:line
   *
   *  const origLog = console.log;
   *  console.log = function() {
   *    const args = [];
   *    args.push('prefix');
   *    for ( let i = 0; i < arguments.length; i++ ) {
   *      args.push( arguments[i] );
   *    }
   *    origLog.apply(console, args); // this lno logged
   *  };
   *  console.log('one');
   *  console.log('one', 'two');
   *
   *  const ll = function() {
   *    const args = Array.prototype.slice.call(arguments);
   *    args.unshift('thePrefix');
   *    console.log.apply(console, args);
   *  };
   *  ll('one');
   *  ll('one', 'two');
   */

  /*
  info0 = console.info.bind(null, '%c' + this.prefix, 'color:blue');
  log0 = console.log.bind(null, '%c' + this.prefix, 'color:green');
  debug0 = console.debug.bind(null, '%c' + this.prefix, 'color:gray');
  warn0 = console.warn.bind(null, '%c' + this.prefix);
  error0 = console.error.bind(null, '%c' + this.prefix);
  */


  /** Log Prefix */
  private prefix: string;

  /**
   * Logger
   * @param loggerName name (prefix)
   */
  constructor(private loggerName: string) {
    this.prefix = loggerName;
  }

  /**
   * @param name logger sub name
   */
  setSubName(name: string) {
    this.prefix = this.loggerName;
    if (name && name.length > 0) {
      this.prefix += '(' + name + ')';
    }
  }

  /* tslint:disable:no-console */

  /**
   * Log info function (blue) - this.log.info('m', 'msg')();
   * @param methodName method name
   * @param message log message
   * @param optionalParams optional parameters
   */
  info(methodName: string, message?: any, ...optionalParams: any[]): any {
    // const theLog: Log = new Log();
    // theLog.loggerName = this.loggerName;
    // theLog.methodName = method;

    if (AccortoService.isChrome) {
      if (optionalParams.length > 0) {
        //  console.info(`%c${this.prefix}.${method}`, 'color:blue', message, ...optionalParams);
        return console.info.bind(null, `%c${this.prefix}.${methodName}`, 'color:blue', message, ...optionalParams);
      } else if (message) {
        //  console.info(`%c${this.prefix}.${method}`, 'color:blue', message);
        return console.info.bind(null, `%c${this.prefix}.${methodName}`, 'color:blue', message);
      } else {
        // console.info(`%c${this.prefix}`, 'color:blue');
        return console.info.bind(null, `%c${this.prefix}.${methodName}`, 'color:blue');
      }
    } else {
      if (optionalParams.length > 0) {
        return console.info.bind(null, `${this.prefix}.${methodName}`, message, ...optionalParams);
      } else if (message) {
        return console.info.bind(null, `${this.prefix}.${methodName}`, message);
      } else {
        return console.info.bind(null, `${this.prefix}.${methodName}`);
      }
    }
  } // info

  /**
   * Log function (green) - this.log.debug('m', 'msg')();
   * @param methodName method name
   * @param message log message
   * @param optionalParams optional parameters
   */
  log(methodName: string, message?: any, ...optionalParams: any[]): any {
    if (AccortoService.isChrome) {
      if (optionalParams.length > 0) {
        // console.log(`%c${this.prefix}.${method}`, 'color:green', message, ...optionalParams);
        return console.log.bind(null, `%c${this.prefix}.${methodName}`, 'color:green', message, ...optionalParams);
      } else if (message) {
        // console.log(`%c${this.prefix}.${method}`, 'color:green', message);
        return console.log.bind(null, `%c${this.prefix}.${methodName}`, 'color:green', message);
      } else {
        // console.log(`%c${this.prefix}.${method}`, 'color:green');
        return console.log.bind(null, `%c${this.prefix}.${methodName}`, 'color:green');
      }
    } else {
      if (optionalParams.length > 0) {
        return console.log.bind(null, `${this.prefix}.${methodName}`, message, ...optionalParams);
      } else if (message) {
        return console.log.bind(null, `${this.prefix}.${methodName}`, message);
      } else {
        return console.log.bind(null, `${this.prefix}.${methodName}`);
      }
    }
  } // log


  /**
   * Log debug function (gray) - this.log.debug('m', 'msg')();
   * @param methodName method name
   * @param message log message
   * @param optionalParams optional parameters
   */
  debug(methodName: string, message?: any, ...optionalParams: any[]): any {
    if (AccortoService.isChrome) {
      if (optionalParams.length > 0) {
        // console.debug(` %c${this.prefix}.${method}`, 'color:gray', message, ...optionalParams);
        return console.debug.bind(null, ` %c${this.prefix}.${methodName}`, 'color:gray', message, ...optionalParams);
      } else if (message) {
        // console.debug(` %c${this.prefix}.${method}`, 'color:gray', message);
        return console.debug.bind(null, ` %c${this.prefix}.${methodName}`, 'color:gray', message);
      } else {
        // console.debug(` %c${this.prefix}.${method}`, 'color:gray');
        return console.debug.bind(null, ` %c${this.prefix}.${methodName}`, 'color:gray');
      }
    } else {
      if (optionalParams.length > 0) {
        return console.debug.bind(null, `${this.prefix}.${methodName}`, message, ...optionalParams);
      } else if (message) {
        return console.debug.bind(null, `${this.prefix}.${methodName}`, message);
      } else {
        return console.debug.bind(null, `${this.prefix}.${methodName}`);
      }
    }
  } // debug

  /**
   * Log warn function - this.log.warn('m', 'msg')();
   * @param methodName method name
   * @param message log message
   * @param optionalParams optional parameters
   */
  warn(methodName: string, message?: any, ...optionalParams: any[]): any {
    if (optionalParams.length > 0) {
      // console.warn(`${this.prefix}.${method}`, message, ...optionalParams);
      return console.warn.bind(null, `${this.prefix}.${methodName}`, message, ...optionalParams);
    } else if (message) {
      // console.warn(`${this.prefix}.${method}`, message);
      return console.warn.bind(null, `${this.prefix}.${methodName}`, message);
    } else {
      // console.warn(`${this.prefix}.${method}`);
      return console.warn.bind(null, `${this.prefix}.${methodName}`);
    }
  } // warn

  /**
   * Log error function - this.log.error('m', 'msg')();
   * @param methodName method name
   * @param message log message
   * @param optionalParams optional parameters
   */
  error(methodName: string, message?: any, ...optionalParams: any[]): any {
    if (optionalParams.length > 0) {
      // console.error(`${this.prefix}.${method}`, message, ...optionalParams);
      return console.error.bind(null, `${this.prefix}.${methodName}`, message, ...optionalParams);
    } else if (message) {
      // console.error(`${this.prefix}.${method}`, message);
      return console.error.bind(null, `${this.prefix}.${methodName}`, message);
    } else {
      // console.error(`${this.prefix}.${method}`);
      return console.error.bind(null, `${this.prefix}.${methodName}`);
    }
  } // error

  /**
   * Clear console
   */
  clear() {
    console.clear();
  }

  /**
   * JSON.stringify with circular handling
   * @param obj object to convert
   * @return string representation
   */
  public stringify(obj: any): string {
    try {
      return JSON.stringify(obj);
    } catch (e) {
    }
    return 'circular' + JSON.stringify(obj, this.getCircularReplacer());
  } // stringify

  private ll(method: string, message?: any, ...optionalParams: any[]): string {
    let msg = `${this.prefix}.${method} `;
    if (message) {
      msg += this.stringify(message);
    }
    if (optionalParams) {
      let prefix = ' [';
      optionalParams.forEach((param: any) => {
        msg += prefix + this.stringify(param);
        prefix = ', ';
      });
      if (prefix !== ' [') {
        msg += ']';
      }
    }
    return msg;
  } // log

  /**
   * JSON.stringify Circular Replacer
   */
  private getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  } // getCircularReplacer

} // Logger
