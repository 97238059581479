import { BoxLine } from '../draw/box-line';
import { Box, DataRecordF, DataRecordI, DateUtil, Logger, Trl } from 'accorto';
import { PalletLayout } from '../draw/pallet-layout';
import { Pallet } from '../draw/pallet';

/**
 * Container for Headers, Pallets,
 */
export class Workbench {

  public pallets: Pallet[] = [];
  public headers: BoxLine[] = [];

  name: string;
  private log: Logger = new Logger('Workbench');

  constructor() {
  }

  getSubName(): string {
    return this.name;
  }

  setSubName(name: string) {
    this.name = name;
    this.log.setSubName(name);
  }

  /**
   * Create Headers
   */
  createHeaders(pLayout: PalletLayout, maxHeight: number) {
    const weekStartDay = 0; // pLayout.config.weekStartDay; // Sun..Sat 0..6

    const maxWidth = pLayout.durationDays * pLayout.xDayFactor;
    const showDay = pLayout.zoomLevel === PalletLayout.ZoomLevelDay;
    const showWeek = pLayout.zoomLevel === PalletLayout.ZoomLevelWeek;
    // console.log('createHeaders w=' + maxWidth + ' W=' + pLayout.pageWidth);

    this.headers = [];
    const monthLine = new BoxLine();
    const weekLine = new BoxLine();
    const dayLine = new BoxLine();

    let monthX = 1;
    let weekX = 1;
    let dayX = 1;
    const monthY = 1;
    const weekY = monthY + pLayout.lineHeight;
    const dayY = (showWeek ? weekY : monthY) + pLayout.lineHeight;
    let widthWeeks = 0;
    let widthMonths = 0;

    // this.log.debug('createHeaders', 'showDay=' + showDay, 'showWeek=' + showWeek,
    //  'start=' + pLayout.startMs, 'end=' + pLayout.endMs)();
    for (let ms = pLayout.startMs; ms <= pLayout.endMs; ms += DateUtil.ONEDAY) {
      const date = new Date(ms);
      const utcDay = date.getUTCDay(); // Sun..Sat 0..6
      // day
      if (showDay) {
        const day = new Box(dayX, dayY, pLayout.xDayFactor, pLayout.lineHeight);
        day.label = String(date.getUTCDate());
        day.stroke = 'gray';
        //
        const weekend = DateUtil.isWeekendDay(utcDay);
        if (weekend) {
          day.fill = 'lightgray';
          day.h = maxHeight - dayY - 2;
        }
        //
        dayLine.add(day);
        dayX += day.w;
      } // showDay

      if (showWeek) {
        if (weekLine.boxes.length === 0 || utcDay === weekStartDay) {
          const week = new Box(weekX, weekY, pLayout.xWeekFactor, pLayout.lineHeight);
          const daysInWeek = 7 - utcDay + weekStartDay;
          week.w = daysInWeek * pLayout.xDayFactor;
          widthWeeks += week.w;
          if (widthWeeks > maxWidth) {
            week.w -= (widthWeeks - maxWidth);
          }
          /*
          console.debug('week ' + date, 'day=' + utcDay, 'start=' + weekStartDay, '=' + daysInWeek
            + ' w=' + week.w + ' ' + widthWeeks + ' W' + pLayout.svgWidth);
          /**/
          week.label = Trl.formatWeekUtc(date);
          week.stroke = 'gray';
          //
          weekLine.add(week);
          weekX += week.w;
        }
      } // showWeek

      // month
      if (monthLine.boxes.length === 0 || date.getUTCDate() === 1) {
        const month = new Box(monthX, monthY, 0, pLayout.lineHeight);
        // month width
        const dayOfMonth = date.getUTCDate(); // next month 0=previous day
        const lastInMonthUtc = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0);
        const daysInMonth = lastInMonthUtc.getUTCDate();
        month.w = (daysInMonth - dayOfMonth + 1) * pLayout.xDayFactor;
        widthMonths += month.w;
        if (widthMonths > maxWidth) {
          month.w -= (widthMonths - maxWidth);
        }
        month.label = Trl.formatMonthUtcWidth(date, month.w);
        month.stroke = 'gray';
        /*
        console.debug('month ' + date, 'daysInMonth=' + daysInMonth, 'dom=' + dayOfMonth,
         '=' + (daysInMonth - dayOfMonth + 1), 'width=' + month.w + ' t=' + widthMonths);
        /**/
        monthLine.add(month);
        monthX += month.w;
      }
    }
    this.headers.push(monthLine);
    if (showWeek) {
      this.headers.push(weekLine);
    }
    if (showDay) {
      this.headers.push(dayLine);
    }
    this.log.debug('createHeaders', 'months=' + monthLine.boxes.length,
      'weeks=' + weekLine.boxes.length, 'days=' + dayLine.boxes.length)();
  } // createHeaders

  /**
   * Find/Create Pallet for Project
   * @param id project id
   */
  findCreateProjectPallet(id: string, project: DataRecordI): Pallet {
    const theId = id ? id : undefined;
    let pp = this.pallets.find((p) => {
      return p.type === 'PJ' && p.id === theId;
    });
    if (!pp) {
      pp = new Pallet().setTypeId('PJ', theId);
      this.pallets.push(pp);
      // console.warn('findCreateProjectPallet create=' + id + ' # ' + this.pallets.length);
    }
    if (project && !pp.record) {
      pp.record = project;
      pp.name = project.name;
      pp.label = DataRecordF.codeLabel(project);
    }
    return pp;
  } // findCreateProjectPallet

  /**
   * Find/Create Pallet for Project
   * @param id project id
   */
  findCreateResourcePallet(id: string, resource: DataRecordI): Pallet {
    const theId = id ? id : undefined;
    let rr = this.pallets.find((p) => {
      return p.type === 'R' && p.id === theId;
    });
    if (!rr) {
      rr = new Pallet().setTypeId('R', theId);
      this.pallets.push(rr);
      // console.warn('findCreateProjectPallet create=' + id + ' # ' + this.pallets.length);
    }
    if (resource && !rr.record) {
      rr.record = resource;
      rr.name = resource.name;
      rr.label = DataRecordF.codeLabel(resource);
    }
    return rr;
  } // findCreateResourcePallet

  /** Reset workbench */
  reset() {
    this.pallets = [];
    this.headers = [];
  }

} // Workbench
