import { DataRecordF, DataRecordI, ResourceD } from 'accorto';

/**
 * Search Restriction
 */
export class ResourceSearchRestrictions {

  public group: string;
  public name: string;
  public location: string;
  public type: string;
  public skillSet: string;

  constructor() {
  }

  /**
   * Any restrictions defined?
   */
  get isRestricted(): boolean {
    return (this.name && this.name.length > 0)
      || (this.group && this.group.length > 0)
      || (this.location && this.location.length > 0)
      || (this.skillSet && this.skillSet.length > 0);
  }

  /**
   * Matches selection (i.e. not restricted)
   * @param resource resource record
   * @return true if ok to include
   */
  matches(resource: DataRecordI): boolean {
    if (!resource) {
      return false; // do not include
    }
    if (this.name && this.name.length > 0) {
      const value = DataRecordF.value(resource, ResourceD.name.n, '');
      if (!value.toLocaleLowerCase().includes(this.name.toLocaleLowerCase())) {
        return false;
      }
    }
    if (this.group && this.group.length > 0) {
      const value = DataRecordF.value(resource, ResourceD.resourceGroup.n, '');
      if (value !== this.group) {
        return false;
      }
    }
    if (this.location && this.location.length > 0) {
      const value = DataRecordF.value(resource, ResourceD.resourceLocation.n, '');
      if (value !== this.location) {
        return false;
      }
    }
    if (this.skillSet && this.skillSet.length > 0) {
      const value = DataRecordF.value(resource, ResourceD.skillSet.n, '');
      if (!value.includes(this.skillSet)) {
        return false;
      }
    }
    return true;
  } // natches

} // ResourceSearchRestrictions
