import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {
  DataRecord,
  DataRecordF,
  DataRecordI,
  FormManager,
  Logger,
  ModelUtil,
  ProjectLineD,
  selectUi,
  uiRequestAction,
  UiTab
} from 'accorto';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { Subscription } from 'rxjs';
import { projectLineSaveRequestAction } from '../project/project.actions';

/**
 * Project Line Editor
 */
@Component({
  selector: 'psa-project-line-editor',
  templateUrl: './project-line-editor.component.html',
  styleUrls: [ './project-line-editor.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ProjectLineEditorComponent implements OnChanges {

  @Input() recordI: DataRecordI;
  @Input() record: DataRecord = new DataRecord();
  /** Initial Editor Tab Name */
  @Input() initialTab: string;

  /** Editor Closed */
  @Output() editorClosed = new EventEmitter();

  fm: FormManager;
  ui: UiTab = new UiTab();

  title: string = 'Edit';

  private log: Logger = new Logger('ProjectLineEditor');
  private subscriptions: Subscription[] = [];

  constructor(private store: Store<AppState>) {
    const uiName = 'ProjectLine';
    this.subscriptions.push(this.store.pipe(select(selectUi(uiName)))
      .subscribe((ui: UiTab) => {
        if (!ui) {
          this.store.dispatch(uiRequestAction({ uiName }));
        } else {
          this.buildUi(ui);
        }
      })
    );
  } // constructor


  public ngOnChanges(changes: SimpleChanges): void {
    if (this.recordI) {
      this.record = DataRecordF.asRecord(this.recordI);
    }
    this.log.debug('ngOnChanges', this.record)();
    this.checkRecord();
  } // ngOnChanges


  onClose() {
    this.editorClosed.emit(true);
  } // onClose

  /**
   * Record saved if changed - close
   * @param projectLine record
   */
  onSaveRecord(projectLine: DataRecord) {
    if (projectLine) { // not just close
      this.log.info('onSaveRecord', 'changed=' + projectLine.isChanged(), projectLine)();
      if (projectLine.isChanged()) {
        this.store.dispatch(projectLineSaveRequestAction({ projectLine }));
      }
    }
    this.editorClosed.emit();
  }

  private buildUi(ui: UiTab) {
    this.log.debug('buildUi', ui)();
    this.ui = ui;
    this.checkRecord();
  } // buildUi

  /**
   * Check/new record
   */
  private checkRecord() {
    const projectId = this.record.changeMap[ ProjectLineD.projectSfId.n ];
    if (this.ui.id && (!this.record || Object.keys(this.record.valueMap).length === 0)) {
      this.record = ModelUtil.newDataRecord(this.ui);
      this.record.isSf = true;
      this.record.changeMap[ ProjectLineD.projectSfId.n ] = projectId;
      this.log.debug('checkRecord', this.record)();
    }
    this.title = this.record.codeLabel();
  }

} // ProjectLineEditorComponent
