import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { ResourceI, ResourceD } from './resource-i';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Resource
 */
export class Resource {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id: number|string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created: number;

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy: TenantUser;

  public createdById: number;

  /**
   * label: Updated Time
   */
  public updated: number;

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy: TenantUser;

  public updatedById: number;

  /**
   * label: External Id
   */
  public externalId: string;

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant: Tenant;

  public tenantId: number;

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner: TenantUser;

  public ownerId: number;

  /**
   * label: Record Version
   */
  public version: number;

  /**
   * label: Salesforce Id
   */
  public sfId: string;

  /**
   * fk TenantUser
   * label: User
   */
  public user: TenantUser;

  public userId: number;

  /**
   * label: User Sf Id
   */
  public userSfId: string;

  /**
   * label: Manager
   */
  public isManager: boolean;

  /**
   * label: Resource Type
   */
  public resourceType: string;

  /**
   * label: Code
   */
  public code: string;

  /**
   * label: Resource Group
   */
  public resourceGroup: string;

  /**
   * label: Resource Location
   */
  public resourceLocation: string;

  /**
   * label: Skill Set
   */
  public skillSet: string[] = [];

  /**
   * label: Access all Project Lines
   */
  public isAccessAllProjectLines: boolean;

  /**
   * label: Weekend Days
   */
  public weekendDays: string[] = [];

  /**
   * label: Days/week
   */
  public daysWeek: number;

  /**
   * label: Capacity/day
   */
  public capacityDay: number;


  /**
   * @return Resource record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'Resource';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key) => {
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): Resource {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        const value = record.valueMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, ResourceD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        const value = record.changeMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, ResourceD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: ResourceI): Resource {
    Object.keys(ResourceD).forEach((key) => {
      const value = record[ key ];
      if (value) {
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'user') { // fk
          this.user = new TenantUser();
          this.user.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // Resource
