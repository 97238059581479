import { createFeatureSelector, createSelector } from '@ngrx/store';
import { loginFeatureKey, LoginState } from './login.reducer';
import { Session } from '../model/session';
import { MenuItem } from '../model/menu-item';


export const selectLoginState = createFeatureSelector<LoginState>(loginFeatureKey);

/** App Status */
export const selectAppStatus = createSelector(
  selectLoginState,
  (loginState): string => loginState.status
);
/** App Heading */
export const selectAppHeading = createSelector(
  selectLoginState,
  (loginState): string => loginState.heading
);


export const selectLoginLoggedIn = createSelector(
  selectLoginState,
  (loginState): boolean => !!loginState.session
);

export const selectLoginSalesforceOAuth = createSelector(
  selectLoginState,
  (loginState): string => loginState.salesforceOAuth
);

export const selectLoginGoogleOauth = createSelector(
  selectLoginState,
  (loginState): string => loginState.googleOauth
);

export const selectLoginSession = createSelector(
  selectLoginState, // test fails
  (loginState): Session => loginState ? loginState.session : undefined
);

export const selectLoginMenuItems = createSelector(
  selectLoginState,
  (loginState): MenuItem[] => loginState.menuItems
);
