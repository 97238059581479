const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** InvoiceLine Definition */
export const InvoiceLineD = {
    recordTypeName: 'InvoiceLine',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    invoice: {
        n: 'invoice',
        t: 'InvoiceI'
    },
    invoiceId: {
        n: 'invoiceId',
        t: 'number|string'
    },
    invoiceSfId: {
        n: 'invoiceSfId',
        t: 'string'
    },
    project: {
        n: 'project',
        t: 'ProjectI'
    },
    projectId: {
        n: 'projectId',
        t: 'number|string'
    },
    projectSfId: {
        n: 'projectSfId',
        t: 'string'
    },
    projectPhase: {
        n: 'projectPhase',
        t: 'ProjectPhaseI'
    },
    projectPhaseId: {
        n: 'projectPhaseId',
        t: 'number|string'
    },
    projectPhaseSfId: {
        n: 'projectPhaseSfId',
        t: 'string'
    },
    projectLine: {
        n: 'projectLine',
        t: 'ProjectLineI'
    },
    projectLineId: {
        n: 'projectLineId',
        t: 'number|string'
    },
    projectLineSfId: {
        n: 'projectLineSfId',
        t: 'string'
    },
    resource: {
        n: 'resource',
        t: 'ResourceI'
    },
    resourceId: {
        n: 'resourceId',
        t: 'number|string'
    },
    resourceSfId: {
        n: 'resourceSfId',
        t: 'string'
    },
    activityType: {
        n: 'activityType',
        t: 'ActivityTypeI'
    },
    activityTypeId: {
        n: 'activityTypeId',
        t: 'number|string'
    },
    activityTypeSfId: {
        n: 'activityTypeSfId',
        t: 'string'
    },
    quantity: {
        n: 'quantity',
        t: 'number'
    },
    hours: {
        n: 'hours',
        t: 'number'
    },
    listPrice: {
        n: 'listPrice',
        t: 'number'
    },
    unitPrice: {
        n: 'unitPrice',
        t: 'number'
    },
}; // InvoiceLine
export { ɵ0 };
