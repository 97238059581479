import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TimeLineStackedSource } from './time-line-stacked-source';
import { AccortoService, DateUtil, Logger, Trl } from 'accorto';
import { TimeLineStacked } from './time-line-stacked';
import { GraphBase } from '../graph-base';
import { TimeLineStackedBarSegment } from './time-line-stacked-bar-segment';
import { TimeLineLegend } from '../time-line/time-line-legend';

import * as d3Scale from 'd3-scale';
import * as d3Axis from 'd3-axis';
import * as d3Shape from 'd3-shape';
import * as d3Chromatic from 'd3-scale-chromatic';
import * as d3 from 'd3-selection';


@Component({
  selector: 'psa-time-line-stacked',
  templateUrl: './time-line-stacked.component.html',
  styleUrls: [ './time-line-stacked.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class TimeLineStackedComponent
  extends GraphBase implements OnChanges {

  readonly NONE = '-none-';

  @Input() id: string;
  @Input() definition: TimeLineStacked;
  @Input() sources: TimeLineStackedSource[] = [];
  @Input() dayWeekMonth: string = 'day';

  info: string;
  currentSources: TimeLineStackedBarSegment[] = [];
  legends: TimeLineLegend[] = [];

  /** Bar Segments */
  private segments: TimeLineStackedBarSegment[] = [];
  private cumulatedMap: { [ key: string ]: number } = {};

  private log: Logger = new Logger('TimeLineStacked');


  constructor(private config: AccortoService) {
    super();
  }

  get isDebug(): boolean {
    return this.config.isDebug;
  }

  /**
   * Create Segments from sources
   * @param changes ignored
   */
  public ngOnChanges(changes: SimpleChanges): void {
    this.log.setSubName(this.definition.domainAttribute);
    this.log.debug('ngOnChanges ' + this.dayWeekMonth + ' #' + this.sources.length)();
    //  this.definition)(); // , this.sources)();
    this.message = this.sources.length === 0 ? 'No Data' : undefined;

    // add missing domain/labels + cumulated
    for (const source of this.sources) {
      const domainValue = source.domains[ this.definition.domainAttribute ];
      const theLabel = this.definition.domainLabelMap[ domainValue ];
      if (!theLabel) {
        if (!domainValue) {
          if (!this.definition.domainLabelMap[ this.NONE ]) {
            this.definition.domainLabelMap[ this.NONE ] = this.NONE;
            this.log.debug('ngOnChange adding other', source)();
          }
        } else {
          this.definition.domainLabelMap[ domainValue ] = '<' + domainValue + '>';
          this.log.debug('ngOnChange adding missing: ' + domainValue)();
        }
      }
    } // addMissing

    // create segments, calculate max date, cumulated values
    this.segments = [];
    let maxTime: number = 0;
    this.cumulatedMap = {};
    for (const source of this.sources) {
      // calculate date
      const segTime = this.getSegmentTime(source.date);
      if (maxTime < segTime) {
        maxTime = segTime;
      }
      //
      const domainValue = source.domains[ this.definition.domainAttribute ];
      // add all segments for date
      const domainValue2 = domainValue ? domainValue : this.NONE;
      const segment = this.getAddSegment(this.segments, segTime, domainValue2, this.definition.domainLabelMap);
      const value = source.values[ this.definition.valueAttribute ];
      if (value) {
        segment.value += value;
        const cum = this.cumulatedMap[ domainValue2 ];
        this.cumulatedMap[ domainValue2 ] = (cum ? cum : 0) + value;
      }
    } // sources
    if (maxTime > 0) { // add last value
      const maxDate = new Date(maxTime);
      let nextTime = Date.UTC(maxDate.getUTCFullYear(), maxDate.getUTCMonth() + 1, 1, 0, 0, 0, 0);
      if (this.dayWeekMonth === 'week') {
        nextTime = Date.UTC(maxDate.getUTCFullYear(), maxDate.getUTCMonth(), maxDate.getUTCDate() + 7, 0, 0, 0, 0);
      }
      this.getAddSegment(this.segments, nextTime, undefined, this.definition.domainLabelMap);
    }

    // sort segments by time and domain label
    this.segments.sort((one, two) => {
      const cmp = one.segTime - two.segTime;
      if (cmp === 0) {
        return one.domainLabel.localeCompare(two.domainLabel);
      }
      return cmp;
    });
    // this.log.debug('ngOnChanges cum', this.cumulatedMap)(); // this.segments, domainSegments)();
    this.init();
  } // ngOnChanges

  /**
   * Draw SVG
   */
  protected init() {
    const selectorBackground = '.tls-background';
    if (super.initialize()) {
      this.svgMain
        .append('rect') // background for legend
        .attr('class', selectorBackground.substring(1))
        .attr('x', '0')
        .attr('y', '0')
        .attr('width', this.dimensions.boundedWidth)
        .attr('height', this.dimensions.boundedHeight);
    }
    this.log.debug('init (svg)', this.dimensions.toString())();

    // value start/end
    let yMaxDomain: number = 0;
    const domainSegments: { [ domain: string ]: TimeLineStackedBarSegment[] } = {};
    let curTime: number = 0;
    let cum: number = 0;
    let no = 1;
    // build segments
    for (const seg of this.segments) {
      seg.no = no++;
      if (curTime !== seg.segTime) {
        curTime = seg.segTime;
        cum = 0;
      }
      seg.valueStart = cum;
      seg.valueEnd = seg.value + cum;
      cum += seg.value;
      //
      seg.tooltip = seg.domainLabel
        + '<br><small>' + Trl.formatDate(seg.segTime) + ':</small>  '
        + Trl.formatNumber(seg.value, 0);
      //
      if (yMaxDomain < seg.valueEnd) {
        yMaxDomain = seg.valueEnd;
      }
      // console.debug('- ' + seg.domainName + ' ' + seg.date.toISOString().substring(0, 10)
      //   + ' v=' + seg.value + ' - ' + seg.valueStart + ' ' + seg.valueEnd + ' #' + seg.no);
      // add to domain list
      const doms = domainSegments[ seg.domainName ];
      if (doms) {
        doms.push(seg);
      } else {
        domainSegments[ seg.domainName ] = [ seg ];
      }
    }
    this.log.debug('init segments=' + this.segments.length, 'yMax=' + yMaxDomain)(); // this.segments, domainSegments)();

    // --- X ---
    const timeMin: number = this.segments.length > 0 ? this.segments[ 0 ].segTime : undefined;
    const timeMax: number = this.segments.length > 0 ? this.segments[ this.segments.length - 1 ].segTime : undefined;
    const xScale = d3Scale.scaleTime()
      .range([ 0, this.dimensions.boundedWidth ])
      .domain([ timeMin, timeMax ])
      .nice();
    super.createXAxis(xScale);

    // --- Y ---
    const yScale = d3Scale.scaleLinear()
      .rangeRound([ this.dimensions.boundedHeight, 0 ])
      .domain([ 0, yMaxDomain ])
      .nice();
    const yAxis = d3Axis.axisLeft(yScale);
    const selectorYaxis = '.y-axis';
    if (this.svgMain.select(selectorYaxis).empty()) {
      this.svgMain
        .append('g')
        .attr('class', selectorYaxis.substring(1))
        .call(yAxis);
    } else {
      this.svgMain.select(selectorYaxis)
        .call(yAxis);
    }

    // --- color ---
    const domainKeys: string[] = Object.keys(domainSegments);
    const zScale = d3Scale.scaleOrdinal(d3Chromatic.schemeSet2)
      .domain(domainKeys); // https://github.com/d3/d3-scale-chromatic

    // -- Area --
    const areaGenerator = d3Shape.area<TimeLineStackedBarSegment>()
      .curve(d3Shape.curveStepAfter)
      .x((d) => xScale(d.segTime))
      .y1((d) => yScale(d.valueEnd))
      .y0((d) => yScale(d.valueStart));

    const selectorAreaSeg = '.tls-area';
    const svgArea = this.svgMain
      .selectAll<SVGPathElement, TimeLineStackedBarSegment[][]>('path' + selectorAreaSeg)
      .data(Object.values(domainSegments));

    svgArea.enter()
      .append('path')
      .attr('class', selectorAreaSeg.substring(1))
      .merge(svgArea)
      .attr('d', areaGenerator)
      .attr('fill', (d) => zScale(d[ 0 ].domainName))

      .on('mouseover', (d) => {
        this.tooltipDiv.style('display', 'block');
      })
      .on('mouseout', (d) => {
        this.tooltipDiv.style('display', 'none');
      })
      .on('mousemove', (d) => {
        const xP = d3.event.pageX;
        const yP = d3.event.pageY;
        this.tooltipDiv.style('top', yP + 'px');
        this.tooltipDiv.style('left', (xP + 15) + 'px');
        //
        const xPoint = d3.event.offsetX - this.dimensions.marginLeft; // 1..719
        const xDomain: Date = xScale.invert(xPoint);
        const yPoint = d3.event.offsetY - this.dimensions.marginTop;
        const yDomain = yScale.invert(yPoint);
        this.info = 'a (' + xPoint + ') ' + xDomain.toISOString() + ' - (' + yPoint + ') ' + yDomain;
        this.tooltipDiv.html(this.tooltip(xDomain, yDomain));
        // legend
        this.legend(xDomain, zScale);
      });
    svgArea.exit().remove();

    // legend
    if (yMaxDomain) { // not empty
      this.svg.select(selectorBackground)
        .on('mousemove', () => {
          // const xP = d3.event.pageX;
          const xPoint = d3.event.offsetX - this.dimensions.marginLeft; // 1..719
          const xDomain: Date = xScale.invert(xPoint);
          this.info = 'b (' + xPoint + ') ' + xDomain.toISOString();
          this.legend(xDomain, zScale);
        });
    }
    // show legend
    this.legend(timeMax ? new Date(timeMax) : undefined, zScale);
  } // init

  /**
   * Get + if not found add all segments for date
   * @param segments the list of bar segments
   * @param segTime the segment time
   * @param domainName the domain
   * @param domainLabelMap domain name->label used to add all
   */
  private getAddSegment(segments: TimeLineStackedBarSegment[],
                        segTime: number,
                        domainName: string,
                        domainLabelMap: { [ domainName: string ]: string }): TimeLineStackedBarSegment {
    for (const seg of segments) {
      if (seg.segTime === segTime && seg.domainName === domainName) {
        return seg;
      }
    }
    let value: TimeLineStackedBarSegment;
    for (const name of Object.keys(domainLabelMap)) {
      const seg: TimeLineStackedBarSegment = {
        segTime,
        segDateLabel: new Date(segTime).toISOString().substring(0, 10),
        domainName: name,
        domainLabel: domainLabelMap[ name ],
        value: 0
      };
      segments.push(seg); // add
      if (domainName === name) {
        value = seg;
      }
    }
    if (domainName && !value) {
      this.log.debug('getAddSegment NotMapped ' + domainName, domainLabelMap)();
      value = {
        segTime,
        segDateLabel: new Date(segTime).toISOString().substring(0, 10),
        domainName,
        domainLabel: '?' + domainName + '?',
        value: 0
      };
    }
    return value;
  } // getAddSegment

  private getSegmentDate(theDate: Date): Date {
    return new Date(this.getSegmentTime(theDate));
  } // getSegmentDate

  private getSegmentTime(theDate: Date): number {
    if (this.dayWeekMonth === 'week') {
      // console.debug('weekStart ' + this.config.weekStartDay, theDate.toISOString().substring(0, 10),
      //  DateUtil.toStartOfWeek(theDate, this.config.weekStartDay).toISOString().substring(0, 10));
      return DateUtil.toStartOfWeek(theDate).getTime();
    }
    return Date.UTC(theDate.getUTCFullYear(), theDate.getUTCMonth(), 1, 0, 0, 0, 0);
  } // getSegmentDate

  /**
   * Build legend
   * @param mouseDate the hover date
   * @param zScale color scale
   */
  private legend(mouseDate: Date, zScale: d3Scale.ScaleOrdinal<string, string>) {
    this.legends = [];
    if (mouseDate != null) {
      this.legendSource(mouseDate); // sets this.currentSources
      // legend top
      const value1 = Trl.formatDate(mouseDate);
      this.legends.push({
        label: 'Date',
        color: '',
        value: value1,
        valueCumulated: ''
      });
      // legend lines
      let topValue: number = 0;
      let bottomValue: number = 0;
      for (const source of this.currentSources) {
        const value = Trl.formatNumber(source.value, 0);
        const valueCum = this.cumulatedMap[ source.domainName ];
        if (value && valueCum) {
          const valueCumulated = Trl.formatNumber(valueCum, 0);
          this.legends.push({
            label: source.domainLabel,
            color: zScale(source.domainName),
            value,
            valueCumulated
          });
        }
        if (this.definition.marginName) {
          if (source.value && source.domainName === this.definition.marginValueTop) {
            topValue += source.value;
          }
          if (source.value && source.domainName === this.definition.marginValueBottom) {
            bottomValue += source.value;
          }
        }
      }
      // margin
      if (this.definition.marginName) {
        const sum = topValue + bottomValue;
        const pct = sum === 0 ? 0 : topValue * 100.0 / sum;
        //
        const topValueC: number = this.cumulatedMap[ this.definition.marginValueTop ];
        const bottomValueC: number = this.cumulatedMap[ this.definition.marginValueBottom ];
        const sumC = (topValueC ? topValueC : 0) + (bottomValueC ? bottomValueC : 0);
        const pctC = sumC === 0 ? 0 : (topValueC ? topValueC : 0) * 100.0 / sumC;
        this.legends.push({
          label: this.definition.marginName,
          color: '',
          value: Trl.formatNumber(pct, 0) + '%',
          valueCumulated: Trl.formatNumber(pctC, 0) + '%',
          background: ''
        });
      }
    }
  } // legend

  /**
   * Get Time Source for mouse date
   * - sets currentSources
   * @param mouseDate mouse date
   * @returns segment date
   */
  private legendSource(mouseDate: Date): number | undefined {
    const mouseTime = mouseDate.getTime();
    //
    let segmentTime: number;
    if (this.segments.length > 0) {
      let previous: number;
      for (const src of this.segments) { // loop
        if (mouseTime < src.segTime) {
          segmentTime = previous ? previous : src.segTime; // first
          break;
        }
        previous = src.segTime;
      }
      if (!segmentTime) { // last
        segmentTime = this.segments[ this.segments.length - 1 ].segTime;
      }
    }
    // get all matching
    this.currentSources = [];
    if (segmentTime) { // undefined if no segments
      this.info += ' l-- mouse=' + mouseDate.toISOString().substring(0, 10)
        + ' segment=' + new Date(segmentTime).toISOString().substring(0, 10);
      if (this.segments.length > 0) {
        for (const src of this.segments) { // loop
          if (segmentTime === src.segTime) {
            this.currentSources.push(src);
          }
        }
      } // legendSource
    }
    return segmentTime;
  } // legendSource

  /**
   * Tooltip for area Segment
   * @param xDomain time value
   * @param yDomain number value
   */
  private tooltip(xDomain: Date, yDomain: number): string {
    const segDate = this.getSegmentDate(DateUtil.day(xDomain));
    const segTime = segDate.getTime();
    this.info += ' t-- ' + segDate.toISOString().substring(1, 10);
    // console.log('t---', xDomain.toISOString(), segDate.toISOString(), yDomain, this.segments);

    for (const segment of this.segments) {
      if (segment.valueStart < yDomain && segment.valueEnd > yDomain) {
        if (segTime === segment.segTime) {
          // console.debug('t=', new Date(segment.segTime).toISOString() + ' - ' + segment.domainLabel + ' #' + segment.no);
          return segment.domainLabel
            + '<br><small>' + Trl.formatDate(segment.segTime) + ':</small>  '
            + Trl.formatNumber(segment.value, 0);
          /* } else {
            console.debug('t-T', new Date(segment.segTime).toISOString()
              + ' - ' + segment.domainLabel + ' #' + segment.no); */
        }
        /* } else {
          console.info('t-SE', new Date(segment.segTime).toISOString().substring(0, 10)
            + ' [' + segment.valueStart + ' ' + segment.valueEnd + '] ' + segment.value
            + ' - ' + segment.domainLabel + ' #' + segment.no); */
      }
    }
    return '';
  } // tooltip


} // TimeLineStackedComponent
