/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./time-line.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./time-line.component";
import * as i4 from "../../../../projects/accorto/src/lib/accorto.service";
var styles_TimeLineComponent = [i0.styles];
var RenderType_TimeLineComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TimeLineComponent, data: {} });
export { RenderType_TimeLineComponent as RenderType_TimeLineComponent };
function View_TimeLineComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "tl-legend"]], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "tl-legend-label"]], [[4, "color", null]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "tl-legend-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "tl-legend-value-c"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.background; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.color; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.label; _ck(_v, 2, 0, currVal_2); var currVal_3 = _v.context.$implicit.value; _ck(_v, 4, 0, currVal_3); var currVal_4 = _v.context.$implicit.valueCumulated; _ck(_v, 6, 0, currVal_4); }); }
function View_TimeLineComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(0, i2.JsonPipe, []), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Definitions: "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "small", [["class", "tl-debug"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(0, i2.JsonPipe, []), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sources: "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "small", [["class", "tl-debug"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(0, i2.JsonPipe, []), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Plus: "])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "small", [["class", "tl-debug"]], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, ["", ""])), i1.ɵpid(0, i2.JsonPipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.currentSource)); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.definitions)); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.sources)); _ck(_v, 14, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform(_co.sourcesPlus)); _ck(_v, 19, 0, currVal_4); }); }
export function View_TimeLineComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { chartContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "slds-grid slds-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["chart", 1]], null, 3, "div", [["class", "slds-col"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "tl-tooltip"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 13, "div", [["class", "slds-col slds-m-top_small tl-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "slds-form-element slds-m-vertical_small"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 9, "label", [["class", "slds-checkbox_toggle slds-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "slds-form-element__label slds-m-bottom_none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Display Values"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "input", [["aria-describedby", "cumulated-toggle"], ["checked", ""], ["name", "cumulated-toggle"], ["type", "checkbox"], ["value", "cumulated-toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCumulated($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "span", [["aria-live", "assertive"], ["class", "slds-checkbox_faux_container"], ["id", "cumulated-toggle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "span", [["class", "slds-checkbox_faux"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "slds-checkbox_on"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cumulated"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "slds-checkbox_off"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Direct"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimeLineComponent_1)), i1.ɵdid(19, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimeLineComponent_2)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.legends; _ck(_v, 19, 0, currVal_3); var currVal_4 = _co.isDebug; _ck(_v, 21, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.tooltipId; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 5, 0, currVal_2); }); }
export function View_TimeLineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "psa-time-line", [], null, null, null, View_TimeLineComponent_0, RenderType_TimeLineComponent)), i1.ɵdid(1, 573440, null, 0, i3.TimeLineComponent, [i4.AccortoService], null, null)], null, null); }
var TimeLineComponentNgFactory = i1.ɵccf("psa-time-line", i3.TimeLineComponent, View_TimeLineComponent_Host_0, { id: "id", dimensions: "dimensions", sources: "sources", definitions: "definitions" }, {}, []);
export { TimeLineComponentNgFactory as TimeLineComponentNgFactory };
