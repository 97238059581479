/* tslint:disable max-line-length no-inferrable-types */
export var DataType;
(function (DataType) {
    DataType["BLOB"] = "BLOB";
    DataType["BOOLEAN"] = "BOOLEAN";
    DataType["CLOB"] = "CLOB";
    DataType["DATE"] = "DATE";
    DataType["DECIMAL"] = "DECIMAL";
    DataType["DOUBLE"] = "DOUBLE";
    DataType["EMAIL"] = "EMAIL";
    DataType["FK"] = "FK";
    DataType["FKID"] = "FKID";
    DataType["HOURS"] = "HOURS";
    DataType["ID"] = "ID";
    DataType["IMAGE"] = "IMAGE";
    DataType["INTEGER"] = "INTEGER";
    DataType["LIST"] = "LIST";
    DataType["LONG"] = "LONG";
    DataType["MAP"] = "MAP";
    DataType["MINUTES"] = "MINUTES";
    DataType["NULL"] = "NULL";
    DataType["OBJECT"] = "OBJECT";
    DataType["PASSWORD"] = "PASSWORD";
    DataType["PHONE"] = "PHONE";
    DataType["PICK"] = "PICK";
    DataType["PICKMULTI"] = "PICKMULTI";
    DataType["SFID"] = "SFID";
    DataType["STRING"] = "STRING";
    DataType["TIME"] = "TIME";
    DataType["TIMESTAMP"] = "TIMESTAMP";
    DataType["URL"] = "URL";
})(DataType || (DataType = {}));
export var DataDisplay;
(function (DataDisplay) {
    DataDisplay["BOTH"] = "BOTH";
    DataDisplay["FORM"] = "FORM";
    DataDisplay["GRID"] = "GRID";
    DataDisplay["NONE"] = "NONE";
})(DataDisplay || (DataDisplay = {}));
const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** DataColumn Definition */
export const DataColumnD = {
    recordTypeName: 'DataColumn',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    dataTable: {
        n: 'dataTable',
        t: 'DataTableI'
    },
    dataTableId: {
        n: 'dataTableId',
        t: 'number|string'
    },
    dataType: {
        n: 'dataType',
        t: 'DataType'
    },
    isNullable: {
        n: 'isNullable',
        t: 'boolean'
    },
    length: {
        n: 'length',
        t: 'number'
    },
    lengthMin: {
        n: 'lengthMin',
        t: 'number'
    },
    precision: {
        n: 'precision',
        t: 'number'
    },
    scale: {
        n: 'scale',
        t: 'number'
    },
    defaultValue: {
        n: 'defaultValue',
        t: 'string'
    },
    isPk: {
        n: 'isPk',
        t: 'boolean'
    },
    isReadOnly: {
        n: 'isReadOnly',
        t: 'boolean'
    },
    isUnique: {
        n: 'isUnique',
        t: 'boolean'
    },
    isAutoIncrement: {
        n: 'isAutoIncrement',
        t: 'boolean'
    },
    isFk: {
        n: 'isFk',
        t: 'boolean'
    },
    fkTable: {
        n: 'fkTable',
        t: 'string'
    },
    fkIdColumn: {
        n: 'fkIdColumn',
        t: 'string'
    },
    fkNameColumn: {
        n: 'fkNameColumn',
        t: 'string'
    },
    fkLabelColumn: {
        n: 'fkLabelColumn',
        t: 'string'
    },
    isFkParent: {
        n: 'isFkParent',
        t: 'boolean'
    },
    fkMultiReference: {
        n: 'fkMultiReference',
        t: 'string'
    },
    fkRestrictColumnNames: {
        n: 'fkRestrictColumnNames',
        t: 'string'
    },
    fkRestrictColumnValues: {
        n: 'fkRestrictColumnValues',
        t: 'string'
    },
    isPick: {
        n: 'isPick',
        t: 'boolean'
    },
    pickSource: {
        n: 'pickSource',
        t: 'string'
    },
    uniqueSequenceId: {
        n: 'uniqueSequenceId',
        t: 'number'
    },
    isExternalId: {
        n: 'isExternalId',
        t: 'boolean'
    },
    seqNo: {
        n: 'seqNo',
        t: 'number'
    },
    dataDisplay: {
        n: 'dataDisplay',
        t: 'DataDisplay'
    },
    isUiRequired: {
        n: 'isUiRequired',
        t: 'boolean'
    },
    displayLogic: {
        n: 'displayLogic',
        t: 'string'
    },
    readOnlyLogic: {
        n: 'readOnlyLogic',
        t: 'string'
    },
    help: {
        n: 'help',
        t: 'string'
    },
    autocomplete: {
        n: 'autocomplete',
        t: 'string'
    },
    controlType: {
        n: 'controlType',
        t: 'string'
    },
    inputType: {
        n: 'inputType',
        t: 'string'
    },
    inputRegex: {
        n: 'inputRegex',
        t: 'string'
    },
    isServerOnly: {
        n: 'isServerOnly',
        t: 'boolean'
    },
    tabSection: {
        n: 'tabSection',
        t: 'string'
    },
    displayAfter: {
        n: 'displayAfter',
        t: 'string'
    },
    columnLogic: {
        n: 'columnLogic',
        t: 'string'
    },
    dependentPropertyList: {
        array: true,
        n: 'dependentPropertyList',
        t: 'string'
    },
    layout: {
        n: 'layout',
        t: 'string'
    },
    pickOptionList: {
        array: true,
        n: 'pickOptionList',
        t: 'DataPickOptionI'
    },
    pickOptionListMap: {
        n: 'pickOptionListMap',
        t: '{ [key: string]: DataPickOptionI }'
    },
}; // DataColumn
export { ɵ0 };
