import { CRequest } from './c-request';
/* tslint:disable max-line-length no-inferrable-types */
export var DataOperation;
(function (DataOperation) {
    DataOperation["DELETE"] = "DELETE";
    DataOperation["QUERY"] = "QUERY";
    DataOperation["SAVE"] = "SAVE";
})(DataOperation || (DataOperation = {}));
/**
 * extends: CRequest
 * name: CRequestData
 */
export class CRequestData extends CRequest {
    constructor() {
        super(...arguments);
        /**
         * query, delete
         * label: Record Ids
         */
        this.recordIds = [];
        /**
         * save, delete
         * label: Data Records
         */
        this.records = [];
    }
} // CRequestData
