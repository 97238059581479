const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** ProjectLine Definition */
export const ProjectLineD = {
    recordTypeName: 'ProjectLine',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    project: {
        n: 'project',
        t: 'ProjectI'
    },
    projectId: {
        n: 'projectId',
        t: 'number|string'
    },
    projectSfId: {
        n: 'projectSfId',
        t: 'string'
    },
    projectPhase: {
        n: 'projectPhase',
        t: 'ProjectPhaseI'
    },
    projectPhaseId: {
        n: 'projectPhaseId',
        t: 'number|string'
    },
    projectPhaseSfId: {
        n: 'projectPhaseSfId',
        t: 'string'
    },
    wbs: {
        n: 'wbs',
        t: 'string'
    },
    resource: {
        n: 'resource',
        t: 'ResourceI'
    },
    resourceId: {
        n: 'resourceId',
        t: 'number|string'
    },
    resourceSfId: {
        n: 'resourceSfId',
        t: 'string'
    },
    lineType: {
        n: 'lineType',
        t: 'string'
    },
    priority: {
        n: 'priority',
        t: 'string'
    },
    invoiced: {
        n: 'invoiced',
        t: 'string'
    },
    isShareAll: {
        n: 'isShareAll',
        t: 'boolean'
    },
    plannedEffort: {
        n: 'plannedEffort',
        t: 'number'
    },
    plannedStart: {
        n: 'plannedStart',
        t: 'number'
    },
    plannedEffortBillable: {
        n: 'plannedEffortBillable',
        t: 'number'
    },
    plannedEnd: {
        n: 'plannedEnd',
        t: 'number'
    },
    unitPrice: {
        n: 'unitPrice',
        t: 'number'
    },
    fixedAmount: {
        n: 'fixedAmount',
        t: 'number'
    },
    plannedCostRate: {
        n: 'plannedCostRate',
        t: 'number'
    },
    plannedCostBudget: {
        n: 'plannedCostBudget',
        t: 'number'
    },
    plannedRate: {
        n: 'plannedRate',
        t: 'number'
    },
    budgetAmount: {
        n: 'budgetAmount',
        t: 'number'
    },
    status: {
        n: 'status',
        t: 'string'
    },
    percentComplete: {
        n: 'percentComplete',
        t: 'number'
    },
    progress: {
        n: 'progress',
        t: 'string'
    },
    prerequisite: {
        n: 'prerequisite',
        t: 'ProjectLineI'
    },
    prerequisiteId: {
        n: 'prerequisiteId',
        t: 'number|string'
    },
    prerequisiteSfId: {
        n: 'prerequisiteSfId',
        t: 'string'
    },
    prerequisiteIdList: {
        n: 'prerequisiteIdList',
        t: 'string'
    },
    isAllComplete: {
        n: 'isAllComplete',
        t: 'boolean'
    },
}; // ProjectLine
export { ɵ0 };
