import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { Resource } from './resource';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';
import { ProjectLine } from './project-line';
import { ProjectLineSharingI, ProjectLineSharingD } from './project-line-sharing-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: ProjectLineSharing
 */
export class ProjectLineSharing {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id: number|string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created: number;

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy: TenantUser;

  public createdById: number;

  /**
   * label: Updated Time
   */
  public updated: number;

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy: TenantUser;

  public updatedById: number;

  /**
   * label: External Id
   */
  public externalId: string;

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant: Tenant;

  public tenantId: number;

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner: TenantUser;

  public ownerId: number;

  /**
   * label: Record Version
   */
  public version: number;

  /**
   * label: Salesforce Id
   */
  public sfId: string;

  /**
   * parent fk ProjectLine
   * label: Project Line
   */
  public projectLine: ProjectLine;

  public projectLineId: number;

  /**
   * label: Project Line
   */
  public projectLineSfId: string;

  /**
   * fk Resource
   * label: Resource
   */
  public resource: Resource;

  public resourceId: number;

  /**
   * label: Resource
   */
  public resourceSfId: string;

  /**
   * 15 of 18 characters
   * label: Project
   */
  public projectSfId: string;

  /**
   * label: Mandatory
   */
  public isMandatory: boolean;

  /**
   * label: Max Effort (h) for this Resource
   */
  public maxEffort: number;

  /**
   * label: Percent for this Resource
   */
  public percent: number;

  /**
   * label: Resource Priority relative to other Resources
   */
  public resourcePriority: number;

  /**
   * in hours
   * label: Resource Planned Effort
   */
  public plannedEffort: number;

  /**
   * label: Resource Planned Effort Details
   */
  public plannedDetails: string;


  /**
   * @return ProjectLineSharing record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'ProjectLineSharing';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key) => {
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): ProjectLineSharing {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        const value = record.valueMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, ProjectLineSharingD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        const value = record.changeMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, ProjectLineSharingD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: ProjectLineSharingI): ProjectLineSharing {
    Object.keys(ProjectLineSharingD).forEach((key) => {
      const value = record[ key ];
      if (value) {
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'resource') { // fk
          this.resource = new Resource();
          this.resource.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // ProjectLineSharing
