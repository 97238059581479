import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UiFormField } from '../model/ui-form-field';
import { DataRecord } from '../model/data-record';
import { DataColumn, DataType } from '../model/data-column';
import { Logger } from '../log/logger';
import { AUtil } from '../utils/autil';
import { AccortoService } from '../accorto.service';
import { FormManager } from '../form/form-manager';
import { FormFocus } from '../form/form-focus';

@Component({
  selector: 'acc-form-element',
  templateUrl: './form-element.component.html',
  styleUrls: [ './form-element.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class FormElementComponent
  implements OnChanges, OnDestroy, FormFocus {

  @Input() fm: FormManager;
  /** Form Field Meta */
  @Input() ff: UiFormField = new UiFormField();
  /** Data record */
  @Input() record: DataRecord = new DataRecord();
  /** Input Element */
  @ViewChildren('inEl') inputElements;

  /** Actual Form Element Control */
  control: FormControl = new FormControl();

  /** Is it displayed */
  isDisplay = true;
  showFkDialog = false;
  showDateDialog = false;

  private log: Logger = new Logger('FormElement');
  private statusSubscription: Subscription;
  private changeSubscription: Subscription;

  /**
   * Form Element
   */
  constructor(private conf: AccortoService) {
    this.ff.dataColumn = new DataColumn();
  }

  get id(): string {
    return 'f_' + this.ff.uiId;
  }

  get title(): string {
    if (this.debug) {
      //  return JSON.stringify(this.field);
    }
    let tt = this.ff.label;
    if (this.ff.description) {
      tt += ': \n' + this.ff.description;
    }
    tt += '\n(' + this.ff.name + ')';
    return tt;
  }

  get debug(): boolean {
    return this.conf.isDebug;
  }

  get valid(): boolean {
    if (this.control) {
      // this.log.log('valid ' + this.control.valid, this.control.errors)();
      if (this.control.valid) {
        return true;
      } else {
        return this.control.pristine; // true if untouched
      }
    }
    return false;
  }

  get errorMessage(): string {
    if (this.control) {
      return AUtil.getValidationErrors(this.control.errors, this.ff.dataColumn.propertyName, this.ff.dataColumn);
    }
    return '';
  } // errorMessage

  /**
   * Fk display value - see RecordValue.displayValueFk
   * @return Observable<string>
   */
  get displayLabelFk(): Observable<string> {
    const pn: string = this.ff.name;
    // let recordValue: string = this.record.valueMap[ pn ]; // this.control.value;
    const recordValue: string = this.control.value;
    if (recordValue) {
      // this.log.debug('displayValueFk ' + pn, recordValue)();
      // return this.queryService.getLabelFk(this.column.fkTable, recordValue); // recordType, Id
    }
    return of('');
  }

  /**
   * @return read - only
   */
  get isReadOnly(): boolean {
    const column = this.ff.dataColumn;
    if (column) {
      return this.record.isReadOnly       // record data
        || !this.record.isActive          // active
        || column.isReadOnly;             // field
    }
    return true;
  }

  /**
   * @return input type for device text|number
   */
  get hoursInputType(): string {
    if (AUtil.isMobile()) {
      return 'number';
    }
    const column = this.ff.dataColumn;
    return column.inputType;
  }

  /**
   * @return new record
   */
  get isNew(): boolean {
    return (!this.record.id || this.record.id.length === 0)
      && (!this.record.link || this.record.link.length === 0);
  }

  get propertyName(): string {
    return this.ff.name;
  }

  /**
   * FK link - see RecordValue.fkLink
   * @return [recordHomeUrl, recordId, { ui: ui.name }]
   */
  get fkLink() {
    const propertyName: string = this.ff.name;
    let recordValue: string;
    if (this.record.changeMap) {
      recordValue = this.record.changeMap[ propertyName ]; // this.control.value;
    }
    if (!recordValue && this.record.valueMap) {
      recordValue = this.record.valueMap[ propertyName ]; // this.control.value;
    }
    //
    const recordHoneUrl = ''; // AUtil.getUrlForTabName(this.column.fkTable);
    const tabName = this.ff.dataColumn.fkTable;
    if (recordValue) {
      return [ recordHoneUrl, recordValue, { tab: tabName } ];
    }
    // list
    return [ recordHoneUrl, { tab: tabName } ];
  } // fkLink

  get isHidden(): boolean {
    return !this.isDisplay;
  }

  asString(value: any): string {
    if (value) {
      return '' + value;
    }
    return '';
  }

  dateSelected(date: Date) {
    this.log.log('dateSelected', date)();
    this.showDateDialog = false;
  }

  /**
   * Evaluate Display Logic
   * - current value: record.attributeName
   * - original value: original.attributeName
   * @return display y/n
   */
  evaluateIsDisplay(): boolean {
    if (this.ff.dataColumn.displayLogic) {
      const logic: string = this.ff.dataColumn.displayLogic;
      // context (record,original,parent,env)
      const original = this.record.valueMap;
      const row = this.fm.formGroup.value; // current context
      const env = this.conf.env;
      try {
        /* tslint:disable-next-line */
        const vv = eval(logic);
        this.isDisplay = vv === true;
        if (this.conf.isDebug) { // debug info
          let index = logic.indexOf('!=');
          if (index === -1) {
            index = logic.indexOf('=');
          }
          const xLogic: string = logic.substr(0, index).trim();
          let x: any;
          try {
            /* tslint:disable-next-line */
            x = eval(xLogic);
          } catch (xx) {
          }
          this.log.log('evaluateIsDisplay', logic + ' => ' + vv + ' (' + (vv === true) + ') ' + xLogic + '~' + x)();
        } else {
          this.log.log('evaluateIsDisplay', logic + ' => ' + vv + ' (' + (vv === true) + ')')();
        }
      } catch (ex) {
        this.log.warn('evaluateIsDisplay: ' + logic, ex)();
      }
    } else {
      this.isDisplay = true;
    }
    // this.log.log('FormElement.evaluateIsDisplay ' + this.isDisplay)();
    return this.isDisplay;
  } // evaluateIsDisplay

  /**
   * Fk Dialog -> fk selected
   * @param fkValue - might be empty
   */
  fkSelected(fkValue: string) {
    this.log.log('fkSelected', fkValue)();
    this.showFkDialog = false;
    if (fkValue) {
      this.control.setValue(fkValue);
      this.control.markAsDirty();
      this.control.markAsTouched();
    }
  }

  /**
   * Input changes
   * @param changes changes
   */
  ngOnChanges(changes: SimpleChanges) {
    const propertyName = this.ff.name;
    this.log.setSubName(propertyName);
    if (this.ff.dataColumn === undefined) {
      return;
    }
    const dataType = this.ff.dataColumn.dataType;
    if (dataType === DataType.DATE
      || dataType === DataType.HOURS || dataType === DataType.MINUTES
      || dataType === DataType.FKID || dataType === DataType.SFID
      || dataType === DataType.BLOB) { // own handling of value changes, etc.
      return;
    }

    if (this.fm.registerFocus(propertyName, this)) {
      setTimeout(() => {
        if (this.inputElements && this.inputElements.first) {
          this.log.debug('ngOnChanges.focus', this.inputElements)();
          this.inputElements.first.nativeElement.focus();
        }
      }, 500);
    }
    if (propertyName && this.fm.formGroup.controls[ propertyName ]) {
      this.control = this.fm.formGroup.controls[ propertyName ] as FormControl;
      // this.log.info('ngOnChanges Found name=' + name + ' -' + info)();
    } else {
      this.control = new FormControl();
      if (propertyName) { // undefined for empty cell
        this.log.warn('ngOnChanges NotFound name=' + propertyName, this.ff, this.fm.formGroup.controls)();
      }
      return;
    }

    this.evaluateIsDisplay();
    // this.log.debug('ngOnChanges', name + '=' + this.control.value, info);
    if (this.isReadOnly) {
      return;
    }

    /* Status */
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
    this.statusSubscription = this.control.statusChanges
      .subscribe((v) => {
        // this.log.debug('StatusChange valid=' + this.errorMessage, v)();
        // this.valid; // this.updateFormElementClasses();
      });

    /* Changes */
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
    this.changeSubscription = this.control.valueChanges
      .subscribe((value) => {
        this.log.debug('ValueChange error=' + this.errorMessage,
          (this.control.dirty ? 'dirty ' : '') + (this.control.valid ? '' : 'NotValid ') + 'value=' + value)();
        this.evaluateIsDisplay();
      });
    /* */
  } // ngOnChanges

  /**
   * File Uploaded
   */
  fileUploaded(dataUrl: string) {
    this.fm.fileUploaded();
  }

  ngOnDestroy() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
    this.statusSubscription = null;
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
    this.changeSubscription = null;
  } // ngOnDestroy

  /**
   * Field Clear/Reset
   * @param event ClickEvent
   */
  onClear(event: Event) {
    this.log.log('onClear', event)();
    event.preventDefault();
    event.stopPropagation();

    const propertyName = this.ff.name;
    const recordValue = this.record.valueMap[ propertyName ];
    if (recordValue) {
      this.control.setValue('');
    } else {
      this.control.reset();
    }
    this.control.markAsPristine();
    this.control.markAsUntouched();
  } // onClear

  /**
   * Search Clicked - open Fk Dialog
   * @param event click event
   */
  onFkSearchClick(event: Event) {
    this.log.log('onFkSearchClick', event)();
    //  event.preventDefault();
    this.showFkDialog = true;
  }

  // (keyup)="onFkSearchKey($event)"
  onFkSearchKey(event: KeyboardEvent) {
    this.log.log('onFkSearchKey', event.code)();
    //  event.preventDefault();
    if (event.code === 'Enter') { // event.keyCode === 13
      this.showFkDialog = true;
    }
    // input #box (keyup.enter)="update(box.value)"
  }

  onFocus() {
    this.fm.onFocus(this.propertyName);
  }

  onFocusChangedTo(propertyName: string) {
  }

} // FormElement
