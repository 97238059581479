import { Box } from './box';
import { ProjectLineD } from '../model/project-line-i';
import { DataRecordF } from '../utils/data-record-f';
import { DateUtil } from '../utils/date-util';
/**
 * Graph Node - Edge
 */
export class GraphEdge extends Box {
    constructor() {
        super(...arguments);
        this.dependentIds = [];
        this.prerequisiteIds = [];
        this.prerequisiteSet = new Set();
        this.dependentSet = new Set();
    }
    addDependent(to) {
        if (!this.dependentSet.has(to.id)) {
            this.dependentSet.add(to.id);
            this.dependentIds.push(to.id);
        }
        this.updateDependent();
    }
    addPrerequisite(from) {
        if (!this.prerequisiteSet.has(from.id)) {
            this.prerequisiteSet.add(from.id);
            this.prerequisiteIds.push(from.id);
        }
        this.updatePrerequisite();
    }
    deleteDependent(to) {
        this.dependentSet.delete(to.id);
        const index = this.dependentIds.indexOf(to.id);
        if (index >= 0) {
            this.dependentIds.splice(index, 1);
        }
        this.updateDependent();
    }
    deletePrerequisite(from) {
        this.prerequisiteSet.delete(from.id);
        const index = this.prerequisiteIds.indexOf(from.id);
        if (index >= 0) {
            this.prerequisiteIds.splice(index, 1);
        }
        this.updatePrerequisite();
    }
    getDependentId() {
        // n.record.value('dependentSfId'); // ChecklistItem+ only
        return undefined;
    }
    getDurationDays() {
        // record.valueNumber('dateOffsetDays'); // ChecklistItem
        return undefined;
    }
    getPrerequisiteId() {
        if (this.namePrerequisiteId()) {
            return DataRecordF.value(this.record, this.namePrerequisiteId());
        }
        return undefined;
    }
    getPrerequisiteIdList() {
        if (this.namePrerequisiteIdList()) {
            return DataRecordF.value(this.record, this.namePrerequisiteIdList());
        }
        return undefined;
    }
    /**
     * Record Property Name
     */
    nameDependentId() {
        // n.record.value('dependentSfId'); // ChecklistItem+ only
        return undefined;
    }
    /**
     * Record Property Name
     */
    namePrerequisiteId() {
        // n.record.value('prerequisiteSfId'); // ChecklistItem+ProjectLine
        return undefined;
    }
    /**
     * Record Property Name
     */
    namePrerequisiteIdList() {
        // n.record.value('prerequisiteIdList'); // ChecklistItem+ProjectLine
        return undefined;
    }
    toStringX() {
        return this.label + ' start=' + DateUtil.formatIso(this.startMs)
            + '(plan=' + DateUtil.formatIso(DataRecordF.valueNumber(this.record, ProjectLineD.plannedStart.n))
            + ') end=' + DateUtil.formatIso(this.startMs)
            + '(plan=' + DateUtil.formatIso(DataRecordF.valueNumber(this.record, ProjectLineD.plannedEnd.n))
            + ') effort=' + DataRecordF.value(this.record, ProjectLineD.plannedEffort.n)
            + ' pre=[' + this.prerequisiteIds
            + '] - ' + JSON.stringify(this.record.changeMap);
    }
    /**
     * Update Record Dependency
     */
    updateDependent() {
        if (this.nameDependentId()) {
            const first = this.dependentIds.length > 0 ? this.dependentIds[0] : null;
            DataRecordF.setValue(this.record, this.nameDependentId(), first);
        }
    }
    /**
     * Update Record Prerequisites
     */
    updatePrerequisite() {
        if (this.namePrerequisiteId()) {
            const first = this.prerequisiteIds.length > 0 ? this.prerequisiteIds[0] : null;
            DataRecordF.setValue(this.record, this.namePrerequisiteId(), first);
        }
        if (this.namePrerequisiteIdList()) {
            const value = this.prerequisiteIds.length > 0 ? this.prerequisiteIds.join(',') : null;
            DataRecordF.setValue(this.record, this.namePrerequisiteIdList(), value);
        }
    }
} // GraphEdge
