import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'psa-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
