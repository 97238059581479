import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Logger } from 'accorto';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { Subscription } from 'rxjs';
import { selectResourceGroups, selectResourceLocations, selectResourceSkills, selectResourceTypes } from '../resource/resource.selector';
import { ResourceSearchRestrictions } from './resource-search-restrictions';

/**
 * Search/Match Resources by Group, Type, Location, Skill
 */
@Component({
  selector: 'psa-resource-search',
  templateUrl: './resource-search.component.html',
  styleUrls: [ './resource-search.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ResourceSearchComponent implements OnInit, OnChanges, OnDestroy {


  @Output() resourceRestriction = new EventEmitter<ResourceSearchRestrictions>();

  restrictions: ResourceSearchRestrictions = new ResourceSearchRestrictions();

  groupOptions: string[] = [];
  locationOptions: string[] = [];
  typeOptions: string[] = [];
  skillSetOptions: string[] = [];

  showGroup: boolean = true;
  showLocation: boolean = true;
  showType: boolean = true;
  showSkillSet: boolean = true;

  private subscriptions: Subscription[] = [];
  private log: Logger = new Logger('ResourceSearch');

  constructor(private store: Store<AppState>) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
  } // ngOnChanges

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  public ngOnInit(): void {
    this.subscriptions.push(this.store.pipe(select(selectResourceGroups))
      .subscribe((records: string[]) => {
        this.groupOptions = records;
        this.showGroup = records.length > 0;
      }));
    this.subscriptions.push(this.store.pipe(select(selectResourceTypes))
      .subscribe((records: string[]) => {
        this.typeOptions = records;
        this.showType = records.length > 0;
      }));
    this.subscriptions.push(this.store.pipe(select(selectResourceLocations))
      .subscribe((records: string[]) => {
        this.locationOptions = records;
        this.showLocation = records.length > 0;
      }));
    this.subscriptions.push(this.store.pipe(select(selectResourceSkills))
      .subscribe((records: string[]) => {
        this.skillSetOptions = records;
        this.showSkillSet = records.length > 0;
      }));
  } // ngOnInit

  onRestrictGroup(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.restrictions.group = target.value;
    this.log.debug('onRestrictGroup', this.restrictions)();
    this.resourceRestriction.emit(this.restrictions);
  }

  onRestrictLocation(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.restrictions.location = target.value;
    this.log.debug('onRestrictLocation', this.restrictions)();
    this.resourceRestriction.emit(this.restrictions);
  }

  onRestrictName(event) {
    const target = event.target as HTMLInputElement;
    this.restrictions.name = target.value;
    this.log.debug('onResourceRestrictName', this.restrictions)();
    this.resourceRestriction.emit(this.restrictions);
  }

  onRestrictSkillSet(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.restrictions.skillSet = target.value;
    this.log.debug('onRestrictSkillSet', this.restrictions)();
    this.resourceRestriction.emit(this.restrictions);
  }

  onRestrictType(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.restrictions.type = target.value;
    this.log.debug('onRestrictType', this.restrictions)();
    this.resourceRestriction.emit(this.restrictions);
  }

}
