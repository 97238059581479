import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Workbench } from '../workbench';
import { DataRecordF, DateUtil, GraphEdge, Logger, ProjectLineD } from 'accorto';
import { Pallet } from '../../draw/pallet';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'psa-project-reschedule',
  templateUrl: './project-reschedule.component.html',
  styleUrls: [ './project-reschedule.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ProjectRescheduleComponent implements OnChanges {

  @Input() wb: Workbench = new Workbench();

  /** first node */
  nodeStartList: GraphEdge[] = [];
  nodeStart: GraphEdge;
  startDate: Date;

  error: string;
  message: string;

  nodeList: GraphEdge[] = [];
  formList: FormGroup[] = [];

  /** The Project */
  project: Pallet;
  //
  private createMissing: boolean = false;
  private reSequence: boolean = false;
  //
  private log: Logger = new Logger('ProjectReschedule');

  constructor() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.log.info('ngOnChanges', this.wb)();
    this.initProject();
  } // ngOnChanges

  onChangeStartNode(event: Event) {
    const select = event.target as HTMLSelectElement;
    const id = select.value;
    const node = this.project.nodeMap[ id ];
    this.log.info('onChangeStartNode', id, node)();
    if (node) {
      this.setStartNode(node);
    }
  } // onChangeStartNode

  onClickEarlier(event: MouseEvent, form: FormGroup) {
    const shift: boolean = event.shiftKey;
    this.log.debug('onClickEarlier', form.value, 'shift=' + shift)();
    let valueMs: number = form.value.plannedStart;
    if (!valueMs) {
      valueMs = DateUtil.today().getTime();
    } else {
      valueMs -= shift ? DateUtil.ONEWEEK : DateUtil.ONEDAY;
    }
    form.patchValue({ plannedStart: valueMs });
  }

  onClickLater(event: MouseEvent, form: FormGroup) {
    const shift: boolean = event.shiftKey;
    this.log.debug('onClickLater', form.value, 'shift=' + shift)();
    let valueMs: number = form.value.plannedStart;
    if (!valueMs) {
      valueMs = DateUtil.today().getTime();
    } else {
      valueMs += shift ? DateUtil.ONEWEEK : DateUtil.ONEDAY;
    }
    form.patchValue({ plannedStart: valueMs });
  }

  onClickLonger(event: MouseEvent, form: FormGroup) {
    const shift: boolean = event.shiftKey;
    this.log.debug('onClickLonger', form.value, 'shift=' + shift)();
    let value: number = form.value.plannedEffort;
    if (!value) {
      value = 0;
    }
    value += shift ? 40 : 8;
    form.patchValue({ plannedEffort: value });
  }

  onClickShorter(event: MouseEvent, form: FormGroup) {
    const shift: boolean = event.shiftKey;
    this.log.debug('onClickShorter', form.value, 'shift=' + shift)();
    let value: number = form.value.plannedEffort;
    if (value) {
      value -= shift ? 40 : 8;
    }
    if (!value || value < 0) {
      value = 0;
    }
    form.patchValue({ plannedEffort: value });
  }

  onInputCreateMissing(event: Event) {
    const ele = event.target as HTMLInputElement;
    this.createMissing = ele.checked;
    this.log.debug('onInputCreateMissing', this.createMissing)();
  }

  onInputReSequence(event) {
    const ele = event.target as HTMLInputElement;
    this.reSequence = ele.checked;
    this.log.debug('onInputReSequence', this.reSequence)();
  }

  /**
   * Reschedule
   */
  onReschedule() {
    this.log.info('onReschedule')();

  } // onReschedule

  onStartDate(date: Date) {
    if (date) {
      this.startDate = date;
    }
  }

  private initProject() {
    this.project = undefined;
    this.nodeStartList = [];
    this.nodeStart = undefined;
    this.nodeList = [];
    this.formList = [];

    if (this.wb.pallets && this.wb.pallets.length > 0) {
      this.project = this.wb.pallets[ 0 ];
      //
      if (this.project.nodeList.length === 0) {
        this.error = 'No Project Lines';
      } else {
        // build form
        for (const node of this.project.nodeList) {
          const form = new FormGroup({});
          this.nodeList.push(node);
          this.formList.push(form);
          //
          const plId = node.id;
          const wbs: string = DataRecordF.value(node.record, ProjectLineD.wbs.n);
          const name: string = DataRecordF.value(node.record, ProjectLineD.name.n);
          const plannedStart: number = DataRecordF.valueNumber(node.record, ProjectLineD.plannedStart.n);
          const plannedEffort: number = DataRecordF.valueNumber(node.record, ProjectLineD.plannedEffort.n);
          form.addControl('wbs', new FormControl(wbs));
          form.addControl('name', new FormControl(name));
          form.addControl('fix', new FormControl(false));
          form.addControl('plannedStart', new FormControl(plannedStart));
          form.addControl('plannedEffort', new FormControl(plannedEffort));
          //
          const pres = node.getPrerequisiteIdList();
          if (!pres || pres === '') {
            this.nodeStartList.push(node);
          }
        }
        if (this.nodeStartList.length > 0) {
          this.setStartNode(this.nodeStartList[ 0 ]);
        }
      } // projectLines
    } else {
      this.error = 'No Project';
    }
  } // initProject

  private setStartNode(node: GraphEdge) {
    this.message = 'nodes: ' + this.project.nodeList.length + '; links: ' + this.project.linkList.length;
    this.error = undefined;
    this.nodeStart = node;
    //
    for (const nn of this.project.nodeList) {
      this.log.debug('-n', nn.toStringX())();
      if (nn.id === this.nodeStart.id) {
        this.startDate = DataRecordF.valueDate(nn.record, ProjectLineD.plannedStart.n);
      }
    }
    if (!this.startDate) {
      this.startDate = DateUtil.today();
    }
    this.log.debug('setStartNode', this.message, this.startDate)();

    // check if valid
    for (const ll of this.project.linkList) {
      this.log.debug('-l', ll.label)();
      if (ll.to.id === this.nodeStart.id) {
        this.error = 'Start node has a prerequisite: ' + this.project.nodeMap[ ll.from.label ];
        return;
      }
    }
    //
    this.error = this.project.calculate();
    //


    // const usedNodes;
    // const sortedNodeList: Carton[] = [];

    // check for cycles

    // create missing links
    // if node has no prerequisite, use previous node


  } // setStartNode

} // ProjectRescheduleComponent

