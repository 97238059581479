/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./time-line-stacked.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./time-line-stacked.component";
import * as i4 from "../../../../projects/accorto/src/lib/accorto.service";
var styles_TimeLineStackedComponent = [i0.styles];
var RenderType_TimeLineStackedComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TimeLineStackedComponent, data: {} });
export { RenderType_TimeLineStackedComponent as RenderType_TimeLineStackedComponent };
function View_TimeLineStackedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "tls-legend"]], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "tls-legend-label"]], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "tls-legend-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "tls-legend-value-c"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.background; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.color; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.label; _ck(_v, 2, 0, currVal_2); var currVal_3 = _v.context.$implicit.value; _ck(_v, 4, 0, currVal_3); var currVal_4 = _v.context.$implicit.valueCumulated; _ck(_v, 6, 0, currVal_4); }); }
function View_TimeLineStackedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 2, 0, currVal_0); }); }
export function View_TimeLineStackedComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { chartContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "slds-grid slds-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["chart", 1]], null, 3, "div", [["class", "slds-col"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "tls-tooltip"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "slds-col slds-m-top_small tls-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimeLineStackedComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimeLineStackedComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.legends; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.isDebug; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.tooltipId; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 5, 0, currVal_2); }); }
export function View_TimeLineStackedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "psa-time-line-stacked", [], null, null, null, View_TimeLineStackedComponent_0, RenderType_TimeLineStackedComponent)), i1.ɵdid(1, 573440, null, 0, i3.TimeLineStackedComponent, [i4.AccortoService], null, null)], null, null); }
var TimeLineStackedComponentNgFactory = i1.ɵccf("psa-time-line-stacked", i3.TimeLineStackedComponent, View_TimeLineStackedComponent_Host_0, { id: "id", dimensions: "dimensions", definition: "definition", sources: "sources", dayWeekMonth: "dayWeekMonth" }, {}, []);
export { TimeLineStackedComponentNgFactory as TimeLineStackedComponentNgFactory };
