import { DataRecordF, DataRecordI, Trl } from 'accorto';
import { AllocationInfo } from '../allocation-info';
import { PsaUtil } from '../../psa-util';

/**
 * Resource Project Line Allocation
 */
export class ResourceAllocationInfo extends AllocationInfo {

  public projectName: string;
  public projectLineName: string;

  /**
   * Resource Allocation
   * @param allocation project line allocation
   * @param res resource
   */
  constructor(allocation: DataRecordI, res: DataRecordI) {
    super(allocation, res);
    this.projectName = DataRecordF.value(allocation, PsaUtil.AllocProjectName);
    this.projectLineName = DataRecordF.value(allocation, PsaUtil.AllocProjectLineName);
    this.label = this.projectName + ': ' + this.projectLineName;

    // project line
    // const plannedEffort = DataRecordF.valueNumber(allocation, ProjectLineSharingD.plannedEffort.n);
    this.plannedStart = DataRecordF.valueNumber(allocation, PsaUtil.AllocProjectLineStartPlan);
    this.plannedEnd = DataRecordF.valueNumber(allocation, PsaUtil.AllocProjectLineEndPlan);
    //
    this.dayPrefix = this.label
      + ' ' + Trl.formatDateUtcMs(this.plannedStart) + ' - ' + Trl.formatDateUtcMs(this.plannedEnd);
    this.title = this.dayPrefix;
  }

} // ResourceAllocationInfo
