import { Project } from './project';
import { ProjectPhase } from './project-phase';
import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { ProjectLineI, ProjectLineD } from './project-line-i';
import { Resource } from './resource';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: ProjectLine
 */
export class ProjectLine {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id: number|string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created: number;

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy: TenantUser;

  public createdById: number;

  /**
   * label: Updated Time
   */
  public updated: number;

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy: TenantUser;

  public updatedById: number;

  /**
   * label: External Id
   */
  public externalId: string;

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant: Tenant;

  public tenantId: number;

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner: TenantUser;

  public ownerId: number;

  /**
   * label: Record Version
   */
  public version: number;

  /**
   * label: Salesforce Id
   */
  public sfId: string;

  /**
   * parent fk Project
   * label: Project
   */
  public project: Project;

  public projectId: number;

  /**
   * label: Project
   */
  public projectSfId: string;

  /**
   * parent fk ProjectPhase
   * label: Project Phase
   */
  public projectPhase: ProjectPhase;

  public projectPhaseId: number;

  /**
   * label: Project Phase
   */
  public projectPhaseSfId: string;

  /**
   * Project Line WBS Code
   * label: WBS
   */
  public wbs: string;

  /**
   * fk Resource
   * label: Resource
   */
  public resource: Resource;

  public resourceId: number;

  /**
   * label: Resource
   */
  public resourceSfId: string;

  /**
   * label: Line Type
   */
  public lineType: string;

  /**
   * label: Priority
   */
  public priority: string;

  /**
   * label: Invoiced
   */
  public invoiced: string;

  /**
   * Give access to this Line for all who have access to the Project (no assignments necessary)
   * label: Share all
   */
  public isShareAll: boolean;

  /**
   * in hours
   * label: Planned Effort
   */
  public plannedEffort: number;

  /**
   * label: Planned Start
   */
  public plannedStart: number;

  /**
   * in hours
   * label: Planned Effort Billable
   */
  public plannedEffortBillable: number;

  /**
   * label: Planned End
   */
  public plannedEnd: number;

  /**
   * Overwrites Resource Price
   * label: Unit Price (Billing)
   */
  public unitPrice: number;

  /**
   * Amount charged regardless of effort
   * label: Fixed Amount
   */
  public fixedAmount: number;

  /**
   * used with estimated effort
   * label: Planned Cost Rate
   */
  public plannedCostRate: number;

  /**
   * Amount budgeted regardless of effort
   * label: Budget Cost Amount
   */
  public plannedCostBudget: number;

  /**
   * used with estimated effort
   * label: Planned Rate (Average Price)
   */
  public plannedRate: number;

  /**
   * Amount budgeted regardless of effort
   * label: Budget Revenue Amount
   */
  public budgetAmount: number;

  /**
   * label: Status
   */
  public status: string;

  /**
   * label: Percent Complete
   */
  public percentComplete: number;

  /**
   * label: Progress
   */
  public progress: string;

  /**
   * fk ProjectLine
   * label: Prerequisite
   */
  public prerequisite: ProjectLine;

  public prerequisiteId: number;

  /**
   * Prerequisite line to be completed before starting this
   * label: Prerequisite
   */
  public prerequisiteSfId: string;

  /**
   * Internal list of prerequisite item Ids
   * label: Prerequisite Id List
   */
  public prerequisiteIdList: string;

  /**
   * If selected, to start the Line, ALL prior Lines need to be completed (the lines this line depends on).
   * label: All Complete
   */
  public isAllComplete: boolean;


  /**
   * @return ProjectLine record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'ProjectLine';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key) => {
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): ProjectLine {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        const value = record.valueMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, ProjectLineD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        const value = record.changeMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, ProjectLineD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: ProjectLineI): ProjectLine {
    Object.keys(ProjectLineD).forEach((key) => {
      const value = record[ key ];
      if (value) {
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'resource') { // fk
          this.resource = new Resource();
          this.resource.setFromI(value);
        } else if (key === 'prerequisite') { // fk
          this.prerequisite = new ProjectLine();
          this.prerequisite.setFromI(value);
        }
      }
    });
    return this;
  } // setFromI

} // ProjectLine
