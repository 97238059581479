import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { selectAppStatus, selectLoginGoogleOauth, selectLoginSalesforceOAuth } from './login.selectors';
import { ClientStorage } from '../utils/client-storage';
import { AccortoService } from '../accorto.service';
import { NotificationService } from '../notification/notification.service';
import { SafeHtml } from '@angular/platform-browser';
import { LoginService } from './login.service';

@Component({
  selector: 'acc-login',
  templateUrl: './login.component.html',
  styleUrls: [ './login.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnChanges {

  @Input() showSalesforce: boolean = true;
  @Input() showGoogle: boolean = true;
  @Input() showManual: boolean = true;

  @Input() loginHeading: SafeHtml = 'Login';

  /** Salesforce Form */
  sfForm: FormGroup;
  /** Salesforce OAuth Url */
  sfUrl: string;

  /** Google OAuth Url */
  googleUrl: string;

  /** Manual Form */
  manualForm: FormGroup;

  /** Active Tab */
  tabActive: string = 'sf';
  status: string;

  private sfAuth: string;
  private sfUrlPath: string = '';
  private sfReAuthorize: boolean = false;


  /**
   * Init Form Groups
   */
  constructor(private store: Store<any>,
              private service: LoginService,
              notify: NotificationService,
              config: AccortoService) {
    const rememberMe = ClientStorage.getBoolean(ClientStorage.REMEMBERME, true);
    const errorMsg = config.getEnv('error');
    if (errorMsg) {
      this.status = errorMsg; // short lived
      notify.addError('Login Error', errorMsg);
    }

    // salesforce
    const sfGroup: any = {};
    const sfAuth = config.getEnvOrStorage(ClientStorage.SFAUTH);
    const reAuthorize = config.getEnvBoolean('reAuthorize');

    sfGroup[ ClientStorage.SFAUTH ] = new FormControl(sfAuth);
    sfGroup[ ClientStorage.REMEMBERME ] = new FormControl(rememberMe);
    sfGroup.reAuthorize = new FormControl(reAuthorize);
    this.sfForm = new FormGroup(sfGroup);
    this.sfForm.valueChanges.subscribe((val: { [ key: string ]: any }) => {
      if (this.tabActive === 'sf') {
        console.log('LoginComponent.sf', val);
        this.sfAuth = val[ ClientStorage.SFAUTH ];
        this.sfReAuthorize = val.reAuthorize;
        this.setSfUrl();
        this.setRememberSf(val[ ClientStorage.REMEMBERME ]);
      }
    });

    // Manual
    const mGroup: any = {};
    const un: string = config.getEnvOrStorage(ClientStorage.UN);
    mGroup[ ClientStorage.UN ] = new FormControl(un, Validators.required);
    mGroup.pw = new FormControl('', Validators.required);
    mGroup[ ClientStorage.REMEMBERME ] = new FormControl(rememberMe);
    this.manualForm = new FormGroup(mGroup);
    this.manualForm.valueChanges.subscribe((val: { [ key: string ]: any }) => {
      if (this.tabActive === 'manual') {
        // console.log('LoginComponent.manual', val);
        const un1 = val[ ClientStorage.UN ];
        this.setRememberManual(val[ ClientStorage.REMEMBERME ], un1);
      }
    });

    // listeners
    this.store.pipe(select(selectAppStatus))
      .subscribe((status) => {
        // console.log('Login.selectStatus=' + status);
        this.status = status;
      });
    this.store.pipe(select(selectLoginSalesforceOAuth))
      .subscribe((sfOAuth) => {
        this.sfUrlPath = sfOAuth;
        this.setSfUrl();
      });
    this.store.pipe(select(selectLoginGoogleOauth))
      .subscribe((googleOAuth) => {
        this.googleUrl = googleOAuth;
      });
  } // constructor

  public ngOnChanges(changes: SimpleChanges): void {
    // tab init
    if (this.showSalesforce) {
      this.onClickSalesforce();
    } else if (this.showGoogle) {
      this.onClickGoogle();
    } else if (this.showManual) {
      this.onClickManual();
    }
  }


  onClickSalesforce() {
    this.tabActive = 'sf';
  }
  onClickGoogle() {
    this.tabActive = 'google';
  }
  onClickManual() {
    this.tabActive = 'manual';
  }

  /**
   * Manual Login
   */
  onSubmitManual() {
    const un = this.manualForm.value.un;
    const pw = this.manualForm.value.pw;
    const rememberMe = this.manualForm.value.rememberMe;
    // console.log('onSubmitManual', un, pw, rememberMe);
    this.service.loginUnPw(un, pw).subscribe((r) => {
      console.log('onSubmitManual', r);
      this.status = r.message;
    });
  }

  private setSfUrl() {
    // https://developer.salesforce.com/docs/atlas.en-us.api_rest.meta/api_rest/intro_understanding_web_server_oauth_flow.htm
    this.sfUrl = 'https://login.salesforce.com';
    if (this.sfAuth) {
      if (this.sfAuth === 'test' || this.sfAuth === 'sandbox') {
        this.sfUrl = 'https://test.salesforce.com';
      } else if (this.sfAuth.includes('.')) {
        this.sfUrl = this.sfAuth;
        if (!this.sfUrl.startsWith('http')) {
          this.sfUrl = 'https://' + this.sfUrl;
        }
      }
    }
    if (this.sfUrlPath) {
      this.sfUrl += this.sfUrlPath;
    } else { // /services/oauth2/authorize?response_type=code&client_id=3M..qI&redirect_uri=https%3A%2F%2Fapp.track4d.us%2FsfCallback
      this.sfUrl += '?';
    }
    //
    this.sfUrl += '&display=touch'; // default: page - popup, mobile, touch
    this.sfUrl += '&immediate=false'; // default
    if (this.sfReAuthorize) {
      this.sfUrl += '&prompt=login%20consent';
    }
    let state = window.location.hostname;
    if (state === 'localhost') {
      state += '%3A' + window.location.port; // :port
    }
    this.sfUrl += '&state=' + state;
    // https://login.salesforce.com/services/oauth2/authorize
    // ?response_type=code
    // &client_id=3MVG9CVKiXR7Ri5qB1isHEeFjVqu.JeimKCahRw7W2S9WO1w3fCPg_iahfLU21HL.vsb8obtBx6AMdPBBXvqI
    // &redirect_uri=https%3A%2F%2Fapp.track4d.us%2FsfCallback
    // &display=touch&immediate=false&state=localhost%3A4200
    // console.log('Login.setSfUrl=' + this.sfUrl);
  } // setSfUrl


  private setRememberSf(rememberMe: boolean) {
    ClientStorage.set(ClientStorage.REMEMBERME, rememberMe ? 'true' : 'false');
    if (rememberMe) {
      ClientStorage.set(ClientStorage.SFAUTH, this.sfAuth);
    } else {
      ClientStorage.set(ClientStorage.SID, undefined);
      ClientStorage.set(ClientStorage.SFAUTH, undefined);
    }
  }

  private setRememberManual(rememberMe: boolean, un: string) {
    ClientStorage.set(ClientStorage.REMEMBERME, rememberMe ? 'true' : 'false');
    if (rememberMe) {
      ClientStorage.set(ClientStorage.UN, un);
    } else {
      ClientStorage.set(ClientStorage.UN, undefined);
      ClientStorage.set(ClientStorage.SID, undefined);
    }
  }

} // LoginComponent
