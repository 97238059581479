import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'acc-popover',
  templateUrl: './popover.component.html',
  styleUrls: [ './popover.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class PopoverComponent implements OnInit, OnChanges {

  /** Display Heading */
  @Input() heading: string = 'heading';
  /** Display Text */
  @Input() bodyText: string = 'body text';

  /** Id suffix for button, heading, body */
  @Input() idSuffix: string = 'id';

  @Input() event: MouseEvent;
  @Input() parent: HTMLElement;

  /** type: error|warning|info (icon) */
  @Input() type: string = 'info_alt';

  @ViewChild('element', { static: false }) section: ElementRef;
  showing: boolean = true;

  stylePopup = {};
  classPopup: string[];
  classButton: string;
  classContainer: string;
  classIcon: string;
  iconHref: string = '';

  private lastInfo: string;

  constructor() {
  }

  get closeId(): string {
    return 'popover-close-' + this.idSuffix;
  }

  get headingId(): string {
    return 'popover-heading-' + this.idSuffix;
  }

  get bodyId(): string {
    return 'popover-body-' + this.idSuffix;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.initialize();
  } // ngOnChanges

  public ngOnInit(): void {
    this.initialize();
  }

  onClose() {
    this.showing = false;
  } // close

  /**
   * Initialize
   */
  private initialize() {
    this.showing = !!this.event;
    if (!this.event) {
      return; // no event
    }
    const lastInfo = this.type + this.heading + this.bodyText;
    if (this.lastInfo === lastInfo) {
      this.showing = false;
      return; // clicked twice on same
    }
    this.lastInfo = lastInfo;

    // type
    if (this.type === 'error') {
      this.classPopup = [ 'slds-popover_error' ];
      this.classButton = 'slds-button_icon-inverse';
      this.classContainer = 'slds-icon-utility-error';
      this.classIcon = undefined;
      this.iconHref = '/assets/icons/utility-sprite/svg/symbols.svg#error';
    } else if (this.type === 'warning') {
      this.classPopup = [ 'slds-popover_warning' ];
      this.classButton = undefined;
      this.classContainer = 'slds-icon-utility-warning';
      this.classIcon = undefined;
      this.iconHref = '/assets/icons/utility-sprite/svg/symbols.svg#warning';
    } else {
      this.classPopup = [];
      this.classButton = undefined;
      this.classContainer = undefined;
      this.classIcon = 'slds-icon-text-default';
      this.iconHref = '/assets/icons/utility-sprite/svg/symbols.svg#' + this.type;
    }

    // where we clicked - absolute coordinates
    const clickY = this.event.pageY;
    const clickX = this.event.pageX;
    // const target = this.event.target as HTMLElement;

    // parent restriction
    const parentWidth = this.parent.clientWidth;
    const parentHeight = this.parent.clientHeight;
    // popup section
    let sectionWidth = 320;
    let sectionHeight = 55; // one line (72 for two)
    if (this.section != null) { // last display
      const thisSection = this.section.nativeElement as HTMLElement;
      sectionWidth = thisSection.clientWidth + 2; // border
      sectionHeight = thisSection.clientHeight + 2;
    }
    // top/left - absolute
    let top = clickY;
    let left = clickX;
    let nubbinClass = 'slds-nubbin';
    if (clickY < (parentHeight - sectionHeight)) { // top
      nubbinClass += '_top';
      top += 20;
    } else {
      nubbinClass += '_bottom';
      top -= sectionHeight + 20;
    }
    if (clickX < (parentWidth - sectionWidth)) { // left
      nubbinClass += '-left';
      left -= 20;
    } else {
      nubbinClass += '-right';
      left -= sectionWidth - 20;
    }
    //
    // console.log('click=' + clickX + '/' + clickY, 'screen=' + this.event.screenX + '/' + this.event.screenY,
    //   'parent=' + parentWidth + '/' + parentHeight, 'section=' + sectionWidth + '/' + sectionHeight);
    this.classPopup.push(nubbinClass);
    this.stylePopup = {
      position: 'absolute',
      top: top + 'px',
      left: left + 'px'
    };
  } // initialize

} // Popover
