/**
 * Service Statistics
 */
import { CResponse } from '../model/c-response';
import { CResponseI } from '../model/c-response-i';

export class ServiceBase {

  private successCount: number = 0;
  private successMs: number = 0;
  private successLast: Date;
  private errorCount: number = 0;
  private errorMs: number = 0;
  private errorLast: Date;

  /** Mark success/error */
  mark(start: Date, response: CResponse, msg: string = ''): string {
    if (response.error) {
      return msg + ' ' + this.markError(start) + 'ms = ' + response.error;
    } else {
      return msg + ' ' + this.markSuccess(start) + 'ms - ' + response.message;
    }
  }

  /** Mark success/error */
  markI(start: Date, response: CResponseI, msg: string = ''): string {
    if (response.error) {
      return msg + ' ' + this.markError(start) + 'ms = ' + response.error;
    } else {
      return msg + ' ' + this.markSuccess(start) + 'ms - ' + response.message;
    }
  }

  /** Mark error */
  markError(start: Date): number {
    this.errorCount++;
    const ms = Date.now() - start.getTime();
    this.errorMs += ms;
    this.errorLast = start;
    return ms;
  }

  /** Mark success */
  markSuccess(start: Date): number {
    this.successCount++;
    const ms = Date.now() - start.getTime();
    this.successMs += ms;
    this.successLast = start;
    return ms;
  }

  /** success/error info */
  public summary(): string {
    let sum = this.summarySuccess();
    if (this.errorCount > 0) {
      sum += '; ' + this.summaryError();
    }
    return sum;
  }

  /** error info */
  public summaryError(): string {
    let sum = 'errors #' + this.errorCount;
    if (this.errorCount > 0) {
      sum += ' ' + (this.errorMs / this.errorCount).toFixed(0) + 'ms';
      sum += ' last=' + new Date(this.errorLast).toISOString();
    }
    return sum;
  }

  /** success info */
  public summarySuccess(): string {
    let sum = 'success #' + this.successCount;
    if (this.successCount > 0) {
      sum += ' ' + (this.successMs / this.successCount).toFixed(0) + 'ms';
      sum += ' last=' + new Date(this.successLast).toISOString();
    }
    return sum;
  }

} // ServiceBase
