import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { selectProjectsAll } from './project.selectors';
import { projectLoadAllRequestAction } from './project.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ProjectResolver {
    constructor(store) {
        this.store = store;
        this.loading = false;
    }
    /**
     * Load All Projects
     */
    resolve(route, state) {
        return this.store
            .pipe(select(selectProjectsAll), tap((projects) => {
            // console.log('ProjectResolver ' + this.loading, projects);
            if (!this.loading && (!projects || projects.length === 0)) {
                this.loading = true;
                // console.log('ProjectResolver request');
                this.store.dispatch(projectLoadAllRequestAction());
            }
        }), filter(projects => !!projects), first(), finalize(() => {
            // console.log('ProjectResolver fini');
            this.loading = false;
        }));
    }
} // ProjectResolver
/** @nocollapse */ ProjectResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectResolver_Factory() { return new ProjectResolver(i0.ɵɵinject(i1.Store)); }, token: ProjectResolver, providedIn: "root" });
