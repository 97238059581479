import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';

import {
  AccortoService,
  appStatus,
  DataRecordF,
  DataRecordI,
  DateUtil,
  GraphVertex,
  ModelUtil,
  Preference,
  ProjectLineD,
  ProjectLineSharingD
} from 'accorto';
import { Workbench } from '../workbench';
import { WorkbenchComponent } from '../workbench.component';
import { AppState } from '../../reducers';
import { PsaBase } from '../../psa-base';
import { selectProjectTeItems } from '../../te-item/te-item.selectors';
import { Pallet } from '../../draw/pallet';
import { PalletLayout } from '../../draw/pallet-layout';
import { BoxLine } from '../../draw/box-line';
import { Carton } from '../../draw/carton';
import { selectCurrentProjectAllocations, selectCurrentProjectLines, selectCurrentProjectPhases } from '../../project/project.selectors';
import { TEItemD } from '../../../../projects/track4d/src/app/model/t-e-item-i';
import { DataPickOption } from '../../../../projects/accorto/src/lib/model/data-pick-option';
import { ProjectService } from '../../project/project.service';

@Component({
  selector: 'psa-project-workbench',
  templateUrl: './project-workbench.component.html',
  styleUrls: [ './project-workbench.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class ProjectWorkbenchComponent
  extends PsaBase implements OnInit, OnDestroy {

  static prefShowResources = new Preference('wb-showResources', 'Show Resources',
    'false', { true: 'Yes', false: 'No' });
  static prefShowPlanCurrent = new Preference('wb-showPlanCurrent', 'Show Plan/Current/Both',
    'P', { P: 'Plan', C: 'Current', B: 'Both' });

  version: number = 0;

  projectEffortInfo: string;
  projectPlanInfo: string;
  projectCurrentInfo: string;
  projectCountInfo: string;
  projectCountInfoAll: string;

  projectLine: DataRecordI | undefined = undefined;
  projectLineTab: string;
  showProjectLineEditor: boolean = false;

  /** Graph Containers */
  wb: Workbench = new Workbench();
  layout: PalletLayout = new PalletLayout(0);

  /** Plan, Current, Both */
  showPlanCurrent: string = 'P';
  /** Resource Details */
  showResources: boolean = false;

  /** GraphVertex Selected */
  selectedLink: GraphVertex;
  /** Project Reschedule */
  showProjectReschedule: boolean = false;

  // Workbench
  @ViewChild(WorkbenchComponent, { static: true }) workbench !: WorkbenchComponent;
  svgForm: FormGroup = new FormGroup({ zoomLevel: new FormControl() });
  /** Zoom Options */
  svgZoomOptions: DataPickOption[] = [];

  private projectLineMap: { [ key: string ]: DataRecordI } = {};
  private projectPhaseMap: { [ key: string ]: DataRecordI } = {};
  /** current raw data */
  private rawAllocations: DataRecordI[] = [];
  private rawProjectLines: DataRecordI[] = [];
  private rawItems: DataRecordI[] = [];

  //
  constructor(route: ActivatedRoute,
              router: Router,
              store: Store<AppState>,
              conf: AccortoService,
              private elementRef: ElementRef) {
    super('ProjectWorkbench', 'P', '/project-wb', 'Project Workbench',
      route, router, store, conf);
    /** Zoom Options */
    this.svgZoomOptions.push(ModelUtil.createOption('A', '-all-'));
    this.svgZoomOptions.push(ModelUtil.createOption('M', 'Month'));
    this.svgZoomOptions.push(ModelUtil.createOption('W', 'Week'));
    this.svgZoomOptions.push(ModelUtil.createOption('D', 'Day'));
    //
    this.showPlanCurrent = ProjectWorkbenchComponent.prefShowPlanCurrent.value;
    this.showResources = ProjectWorkbenchComponent.prefShowResources.isValue;
  } // constructor

  ngOnDestroy(): void {
    super.ngDestroy();
  }

  ngOnInit(): void {
    super.ngInit(); // projectAll currentProject resourceMap resourceAll currentResource

    // lines of selected project
    this.busyPlus('pl');
    this.subscriptions.push(this.store.pipe(select(selectCurrentProjectLines))
      .subscribe((projectLines) => {
        this.setProjectLines(projectLines); // updateNodes + layout
        this.busyMinus('pl');
      }));
    // phases of selected project
    this.busyPlus('pp');
    this.subscriptions.push(this.store.pipe(select(selectCurrentProjectPhases))
      .subscribe((projectPhases) => {
        this.setProjectPhases(projectPhases);
        this.busyMinus('pp');
      }));

    // planned allocations of selected project
    this.busyPlus('alloc');
    this.subscriptions.push(this.store.pipe(select(selectCurrentProjectAllocations))
      .subscribe((allocations) => {
        this.setAllocations(allocations);
        this.busyMinus('alloc');
      }));

    // actual items of the selected project
    this.busyPlus('items');
    this.subscriptions.push(this.store.pipe(select(selectProjectTeItems))
      .subscribe((items) => {
        this.setItems(items); // updateNodes + layout
        this.busyMinus('items');
      }));

    this.store.dispatch(appStatus({ status: 'project-wb' }));
  } // ngOnInit

  /**
   * Toggle GraphVertex - select/deselect - show delete button
   * - called from workbench
   * @param link link
   */
  onLinkClick(link: GraphVertex) {
    this.selectedLink = link;
  }

  /**
   * Delete GraphVertex
   */
  onLinkDelete() {
    this.log.debug('onLinkDelete', this.selectedLink)();
    if (this.selectedLink) {
      const pallet = this.selectedLink.parent as Pallet;
      if (pallet) {
        pallet.deleteLink(this.selectedLink);
        // TODO save delete
        this.selectedLink = undefined;
      }
    }
  } // onLinkDelete

  /** Change Plan-Current-Both */
  onPlanCurrentClick(value: string) {
    this.log.debug('onPlanCurrentClick', value)();
    this.showPlanCurrent = value;
    ProjectWorkbenchComponent.prefShowPlanCurrent.save(value);
    this.updateLayout();
  }

  /**
   * Click on Project Line - open PL editor
   * @param carton project line
   */
  onProjectLineClick(carton: Carton) {
    this.log.debug('onProjectLineClick', carton.type + ' ' + carton.id)();
    this.projectLine = this.projectLineMap[ carton.id ];
    this.projectLineTab = 'Plan';
    this.showProjectLineEditor = this.projectLine != null;
  }

  /**
   * Close PL editor
   */
  onProjectLineEditComplete() {
    this.showProjectLineEditor = false;
    if (this.showPlanCurrent === 'C') {
      this.showPlanCurrent = 'B'; // from Current to Both
    }
  }

  /**
   * New Project Line - open PL editor
   */
  onProjectLineNew() {
    this.log.debug('onProjectLineNew', this.project.id)();
    this.projectLine = DataRecordF.newDataRecord(ProjectLineD);
    this.projectLineTab = undefined;
    DataRecordF.setValue(this.projectLine, ProjectLineD.projectSfId.n, this.project.id);
    this.showProjectLineEditor = true;
  }

  onRefresh() {
    this.log.debug('onRefresh')();
    this.wb.reset();
    super.onProjectSelected(this.project, false);
  }

  onReschedule() {
    this.log.debug('onReschedule')();
    if (this.project) {
      this.showProjectReschedule = true;
    }
  }

  onRescheduleClose() {
    this.log.debug('onReschedule')();
    this.showProjectReschedule = false;
  }

  /** Toggle Show Resource */
  onShowResourceClick(event: MouseEvent) {
    const target = event.target as HTMLInputElement;
    this.log.debug('onShowResourceClick', target.value, target.checked)();
    this.showResources = target.checked;
    ProjectWorkbenchComponent.prefShowResources.saveBoolean(this.showResources);
    this.updateLayout();
  }

  /**
   * @param direction L|U|D|R
   */
  onSvgPan(direction: string) {
    // this.log.info('onSvgPan ' + direction)();
    this.workbench.doPan(direction);
  }

  /**
   * @param Zoom in/out I|O
   */
  onSvgZoomFactor(inOut: string) {
    this.workbench.doZoomFactor(inOut);
  } // onSvgZoom

  /**
   * Change Zoom Level A|D|W|M
   */
  onSvgZoomLevel(event: Event) {
    const target = event.target as HTMLSelectElement;
    const svgZoomValue = target.value;
    // this.log.info('onSvgZoom ' + svgZoomValue, this.svgForm.controls.zoomLevel.value)();
    this.workbench.setZoomLevel(svgZoomValue);
    // this.svgForm.controls.zoomLevel.setValue(this.layout.zoomLevel);
  } // onSvgZoom

  // called when project changed
  reset() {
    super.reset();
    this.wb.reset();
    //
    this.projectEffortInfo = undefined;
    this.projectPlanInfo = undefined;
    this.projectCurrentInfo = undefined;
    this.projectCountInfo = undefined;
  } // reset

  /**
   * Set Project Allocations
   * @param allocations allocation list (ro)
   */
  setAllocations(allocations: DataRecordI[]) {
    this.rawAllocations = allocations;
    this.buildPage('allocations');
  } // setAllocations

  /**
   * Set Project Items (2)
   * @param items item list
   */
  setItems(items: DataRecordI[]) {
    this.rawItems = items;
    this.buildPage('items');
  } // setItems

  /**
   * Set Project
   * @param project new project called via effect - selectCurrentProject
   */
  protected setProject(project: DataRecordI) {
    this.reset();
    super.setProject(project, true);
    this.log.setSubName(project ? project.name : undefined);
    this.wb.setSubName(project ? project.name : undefined);
    // update path parameter
    if (project && project.id) { // { queryParams: { id: project.id } }
      // Pallet...Box
      this.wb.findCreateProjectPallet(project.id, project);
    }
    this.buildPage('project');
  } // setProject

  /**
   * Set Project Lines (1)
   * @param projectLines new project lines
   */
  protected setProjectLines(projectLines: DataRecordI[]) {
    this.rawProjectLines = projectLines;
    this.buildPage('projectLines');
  } // setProjectLines

  /**
   * Set Project Phases
   * @param projectPhases phases
   */
  protected setProjectPhases(projectPhases: DataRecordI[]) {
    this.log.debug('setProjectPhases', projectPhases)();
    this.projectPhaseMap = {};
    if (projectPhases) {
      for (const record of projectPhases) {
        this.projectPhaseMap[ record.id ] = record;
      }
    }
  } // setProjectPhases


  /**
   * Build Page
   * @param why new data
   */
  private buildPage(why: string) {
    this.wb.reset();

    const countPL = this.buildPage1ProjectLine();
    const countAl = this.buildPage2Allocations();
    const countIt = this.buildPage3Items();

    this.log.debug('buildPage', why,
      'pl=' + this.rawProjectLines.length + '/' + countPL,
      'allocations=' + this.rawAllocations.length + '/' + countAl,
      'items=' + this.rawItems.length + '/' + countIt)();

    this.updateLayout();
  } // buildPage

  private buildPage1ProjectLine(): number {
    const today = DateUtil.today();
    this.projectLineMap = {};
    let count = 0;
    for (const record of this.rawProjectLines) {
      if (record.name !== ProjectService.PROJECT) {
        const projectLineId = record.id;
        this.projectLineMap[ projectLineId ] = record;
        // refs
        const resourceId = DataRecordF.value(record, ProjectLineD.resourceSfId.n);
        const projectId = DataRecordF.value(record, ProjectLineD.projectSfId.n);
        const projectPhaseId = DataRecordF.value(record, ProjectLineD.projectPhaseSfId.n);
        const phase: DataRecordI = this.projectPhaseMap[ projectPhaseId ];
        //
        const value = DataRecordF.valueNumber(record, ProjectLineD.plannedEffort.n);
        const plannedStart = DataRecordF.valueNumber(record, ProjectLineD.plannedStart.n);
        const plannedEnd = DataRecordF.valueNumber(record, ProjectLineD.plannedEnd.n);

        // Pallet..Box
        const pjPallet = this.wb.findCreateProjectPallet(projectId, this.project);
        const ppCrate = pjPallet.findCreateCrate('PP', projectPhaseId, phase);
        const plCarton = ppCrate.findCreateCarton('PL', projectLineId, record);
        //
        plCarton.subLabel = '-';
        if (resourceId) {
          const resource = this.resourceMap[ resourceId ];
          if (resource) {
            plCarton.subLabel = '(' + DataRecordF.codeLabel(resource) + ')';
          } else {
            plCarton.subLabel = '<' + resourceId + '>';
          }
        }
        // PlanLine
        const planLine: BoxLine = plCarton.findCreateLine(PalletLayout.LineTypePlan, undefined);
        planLine.startMs = plannedStart ? plannedStart : today.getTime();
        planLine.endMs = plannedEnd ? plannedEnd : planLine.calculateEndMs(planLine.startMs, value);
        planLine.value = value;
        //
        pjPallet.addSummaryValue(value, planLine.startMs, PalletLayout.LineTypePlan);
        // this.log.debug('setProjectLines-', plCarton, planLine)();
        count++;
      }
    } // projectLine
    for (const pj of this.wb.pallets) {
      pj.loadLinks();
    }
    return count;
  } // buildPage1ProjectLine

  private buildPage2Allocations(): number {
    let count = 0;
    for (const alloc of DataRecordF.cloneArray(this.rawAllocations)) { // r/w
      if (!alloc.isActive) {
        continue;
      }
      const projectLineId = DataRecordF.value(alloc, ProjectLineSharingD.projectLineSfId.n);
      const projectLine = projectLineId ? this.projectLineMap[ projectLineId ] : undefined;
      let projectId = DataRecordF.value(alloc, ProjectLineSharingD.projectSfId.n); // 15 of 18 a0F1E00000ovT7x
      let projectPhaseId;
      if (projectLine) { // use project/phase from project line
        // console.log('allocation pj=' + projectId, DataRecordF.value(projectLine, ProjectLineD.projectSfId.n),
        //   'project', this.project, 'projectLine', projectLine, 'allocation', alloc);
        projectId = DataRecordF.value(projectLine, ProjectLineD.projectSfId.n); // 18
        projectPhaseId = DataRecordF.value(projectLine, ProjectLineD.projectPhaseSfId.n);
      }
      if (projectId !== this.project.id) { // if no pj, compares 15 to 18 id
        if (projectId.length !== this.project.id.length) {
          if (projectId.substr(0, 15) !== this.project.id.substr(0, 15)) {
            continue;
          }
        } else {
          // console.log('allocation ' + alloc.name, projectLine, 'pj=' + projectId + ' <>' +  this.project.id);
          continue;
        }
      }
      // detail allocations
      if (!alloc.details) {
        const detailString = DataRecordF.value(alloc, ProjectLineSharingD.plannedDetails.n);
        if (detailString && detailString.length > 0) {
          alloc.details = JSON.parse(detailString);
        } else {
          alloc.details = {};
        }
      }
      const detailList = Object.keys(alloc.details);
      if (detailList.length === 0) {
        // console.log('allocation', alloc.details);
        continue;
      }
      count++;
      // refs
      const resourceId = DataRecordF.value(alloc, ProjectLineSharingD.resourceSfId.n);
      const resource = resourceId ? this.resourceMap[ resourceId ] : undefined;
      //
      const pjPallet = this.wb.findCreateProjectPallet(projectId, this.project);
      const ppCrate = pjPallet.findCreateCrate('PP', projectPhaseId, undefined);
      const plCarton = ppCrate.findCreateCarton('PL', projectLineId, projectLine);
      const planLine = plCarton.findCreateLine(PalletLayout.LineTypePlan, resourceId); // line for resource
      if (resource) {
        planLine.label = DataRecordF.codeLabel(resource);
      } else {
        planLine.label = '<' + resourceId + '>';
      }
      // plan line data
      for (const key of detailList) {
        if (key.startsWith('d')) {
          const ms = Number(key.substr(1));
          const hh = alloc.details[ key ];
          planLine.addValueMs(hh, ms);
        }
      }
    } // allocations
    return count;
  } // buildPage2Allocations

  private buildPage3Items(): number {
    let count = 0;
    for (const item of this.rawItems) {
      // refs
      const projectId = DataRecordF.value(item, TEItemD.projectSfId.n);
      if (projectId !== this.project.id) {
        continue;
      }
      const projectLineId = DataRecordF.value(item, TEItemD.projectLineSfId.n);
      let projectPhaseId = DataRecordF.value(item, TEItemD.projectPhaseSfId.n);
      const projectLine = projectLineId ? this.projectLineMap[ projectLineId ] : undefined;
      if (projectLine) { // use phase from project line
        projectPhaseId = DataRecordF.value(projectLine, ProjectLineD.projectPhaseSfId.n);
      }
      const resourceId = DataRecordF.value(item, TEItemD.resourceSfId.n);
      const resource = resourceId ? this.resourceMap[ resourceId ] : undefined;

      const teDate: number = DataRecordF.valueNumber(item, TEItemD.teDate.n);
      const hours: number = DataRecordF.valueNumber(item, TEItemD.hours.n);
      if (!hours) {
        continue;
      }
      count++;
      // graph
      const pjPallet = this.wb.findCreateProjectPallet(projectId, this.project);
      const ppCrate = pjPallet.findCreateCrate('PP', projectPhaseId, undefined);
      const plCarton = ppCrate.findCreateCarton('PL', projectLineId, projectLine);
      const planLine = plCarton.findCreateLine(PalletLayout.LineTypeCurrent, resourceId); // line for resource
      if (resource) {
        planLine.label = DataRecordF.codeLabel(resource);
      } else {
        planLine.label = '<' + resourceId + '>';
      }

      // individual days
      let xHours: number = 0;
      for (let i = 0; i < 7; i++) {
        const xh = DataRecordF.valueNumber(item, 'x' + (i + 1));
        if (xh) {
          const dd: number = teDate + (i * DateUtil.ONEDAY);
          planLine.addValueMs(xh, dd);
          pjPallet.addSummaryValue(xh, dd, PalletLayout.LineTypeCurrent);
          xHours += xh;
        }
      }
      // not normalized
      const otherHours = hours - xHours;
      if (otherHours) {
        planLine.addValueMs(otherHours, teDate);
        pjPallet.addSummaryValue(otherHours, teDate, PalletLayout.LineTypeCurrent);
      }
      if (item.id) { // might be summarized
        planLine.addDetailId(item.id);
      }
    } // item
    return count;
  } // buildPage3Items

  /**
   * Set graph.startMs, endMs -  node.info, startMs, endMs
   * + layout
   */
  private updateLayout() {
    this.version++;
    //
    const ref = this.elementRef.nativeElement as HTMLElement;
    const pageSize: ClientRect = ref.getBoundingClientRect();
    //
    this.layout = new PalletLayout(pageSize.width);
    this.layout.initialize(this.showPlanCurrent, this.showResources, undefined);
    this.layout.layout(this.wb);
    this.svgForm.controls.zoomLevel.setValue(this.layout.zoomLevel);

    // Counts
    this.projectCountInfoAll = 'Projects: ' + this.projectRecords.length
      + ' Resources: ' + Object.keys(this.resourceMap).length;
    //
    let countProjects = 0;
    let countProjectPhases = 0;
    let countProjectLines = 0;
    let countItems = 0;
    for (const pallet of this.wb.pallets) {
      if (pallet.type === 'PJ') {
        countProjects++;
        for (const crate of pallet.crates) {
          if (crate.type === 'PP') {
            if (crate.id) {
              countProjectPhases++;
            }
            for (const carton of crate.cartons) {
              if (carton.type === 'PL') {
                if (carton.id) {
                  countProjectLines++;
                }
                for (const line of carton.lines) {
                  if (line.type === PalletLayout.LineTypeCurrent) {
                    countItems += line.detailIds.length;
                  }
                } // line
              } // PL
            } // cartons
          } // PP
        } // crates
      } // PJ
    } // pallet
    this.projectCountInfo = (countProjectPhases > 0 ? 'Ph: ' + countProjectPhases + ', ' : '')
      + 'Lines: ' + countProjectLines + '; Items: ' + countItems;

    // single project info
    this.projectEffortInfo = undefined;
    this.projectPlanInfo = undefined;
    this.projectCurrentInfo = undefined;
    if (this.project) { // single project
      const pp: Pallet = this.wb.findCreateProjectPallet(this.project.id, this.project);
      if (pp) {
        this.projectEffortInfo = pp.effortInfo;
        this.projectPlanInfo = pp.getDateInfo(PalletLayout.ShowPlan);
        this.projectCurrentInfo = pp.getDateInfo(PalletLayout.ShowCurrent);
      }
    }
    if (this.isDebug()) {
      this.log.debug('updateLayout ' + this.version, this.layout, this.wb.pallets)();
    }
  } // updateLayout

} // ProjectWorkbenchComponent
