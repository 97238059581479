import { CResponse } from './c-response';
/* tslint:disable max-line-length no-inferrable-types */
/**
 * extends: CResponse
 * name: CResponseFk
 */
export class CResponseFk extends CResponse {
    constructor() {
        super(...arguments);
        /**
         * label: Fk Infos
         */
        this.fkInfos = [];
        /**
         * label: Optional Parent Map (AND) used - propertyName->value
         */
        this.parentMap = {};
    }
} // CResponseFk
