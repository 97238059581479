import * as d3 from 'd3-selection';
import { ElementRef, Input, ViewChild } from '@angular/core';
import { Dimensions } from 'accorto';
import * as d3Axis from 'd3-axis';

/**
 * Graphics Base Functionality
 */
export class GraphBase {


  @Input() id: string;
  /** Chart Dimensions */
  @Input() dimensions: Dimensions = new Dimensions(700, 300, 20, 40);

  /** Div Element */
  @ViewChild('chart', { static: true }) chartContainer: ElementRef;

  message: string;

  /** Main svg */
  protected svg: d3.Selection<any, any, any, any>;
  /** Main g */
  protected svgMain: d3.Selection<SVGGElement, any, any, any>;
  /** Tooltip */
  protected tooltipDiv: d3.Selection<HTMLDivElement, any, any, any>;


  get tooltipId(): string {
    return this.id + '-tooltip';
  }

  /**
   * Create X Axis
   * @param xScale X Scale
   */
  protected createXAxis(xScale) {
    const xAxis = d3Axis.axisBottom(xScale);
    const selectorXaxis = '.x-axis';
    if (this.svgMain.select(selectorXaxis).empty()) {
      this.svgMain
        .append('g')
        .attr('class', selectorXaxis.substring(1))
        .attr('transform', 'translate(0,' + this.dimensions.boundedHeight + ')')
        .call(xAxis);
      // this.log.debug('init (xAxis new)')();
    } else {
      this.svgMain.select(selectorXaxis)
        .attr('transform', 'translate(0,' + this.dimensions.boundedHeight + ')')
        .call(xAxis);
      // this.log.debug('init (xAxis update)')();
    }
  } // createXAxis

  /**
   * Create Left Y Axis
   * @param yScale Y Sale
   */
  protected createYAxis(yScale) {
    const yAxis = d3Axis.axisLeft(yScale);
    const selectorYaxis = '.y-axis';
    if (this.svgMain.select(selectorYaxis).empty()) {
      this.svgMain
        .append('g')
        .attr('class', selectorYaxis.substring(1))
        .call(yAxis);
    } else {
      this.svgMain.select(selectorYaxis)
        .call(yAxis);
    }
  }

  /**
   * Initialize svg/g and tooltip
   * @returns true if initial
   */
  protected initialize(): boolean {
    const element = this.chartContainer.nativeElement as HTMLDivElement;
    this.tooltipDiv = d3.select<HTMLDivElement, any>(element).select('#' + this.tooltipId);
    if (!this.svgMain) {
      this.svg = d3.select<HTMLDivElement, any>(element)
        .append('svg')
        .attr('width', this.dimensions.width)
        .attr('height', this.dimensions.height);
      this.svgMain = this.svg
        .append('g')
        .attr('transform', 'translate(' + this.dimensions.marginLeft + ',' + this.dimensions.marginTop + ')')
        .attr('class', 'content');
      //
      return true;
    }
    return false;
  } // initialize


} // graphBase
