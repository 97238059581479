import { FkInfo } from '../model/fk-info';
import { CResponseFk } from '../model/c-response-fk';
import { Logger } from '../log/logger';

/**
 * Cache info for FkTable
 */
export class FkCache {


  /** all - fkId -> FkInfo */
  fkInfoMap: { [ key: string ]: FkInfo } = {};
  /** all complete */
  isComplete: boolean = false;
  /** Parent Map - parentId -> FkCache */
  parentMap: { [ key: string ]: FkCache } = {};
  /** Total count */
  totalCount: number = -1;
  /** Unrestricted Query Count */
  queryCount: number = 0;

  private log: Logger = new Logger('FkCache');

  /** Fk Cache for table and optional id */
  constructor(public fkTable: string, public forId: string) {
    this.log.setSubName(fkTable);
    if (forId) {
      this.log.setSubName(fkTable + '.' + forId);
    }
  }

  /** Count/length of fk infos */
  get count(): number {
    return Object.keys(this.fkInfoMap).length;
  }

  get hasParents(): boolean {
    return Object.keys(this.parentMap).length > 0;
  }

  /**
   * Add Items
   */
  add(response: CResponseFk) {
    // console.debug('FkCache.add', response);
    response.fkInfos.forEach((fki) => {
      if (fki.validForSelection === undefined) {
        fki.validForSelection = true;
      }
      // general
      this.fkInfoMap[ fki.fkId ] = fki; // might be locked

      // add to parent
      if (fki.validForSelection) {
        if (fki.parentId) {
          const pc: FkCache = this.parentMap[ fki.parentId ];
          if (pc === undefined) {
            this.parentMap[ fki.parentId ] = new FkCache(this.fkTable, fki.fkId);
            this.parentMap[ fki.parentId ].fkInfoMap[ fki.fkId ] = fki;
          } else {
            pc.fkInfoMap[ fki.fkId ] = fki;
          }
        }
        if (fki.parent2Id) {
          const pc: FkCache = this.parentMap[ fki.parent2Id ];
          if (pc === undefined) {
            this.parentMap[ fki.parent2Id ] = new FkCache(this.fkTable, fki.fkId);
            this.parentMap[ fki.parent2Id ].fkInfoMap[ fki.fkId ] = fki;
          } else {
            pc.fkInfoMap[ fki.fkId ] = fki;
          }
        }
      }
    });

    // totals
    this.totalCount = response.totalCount;
    if (response.isComplete && !response.searchTerm) {
      this.isComplete = response.isComplete;
    } else if (this.totalCount === this.count) {
      this.isComplete = true;
    }
    this.queryCount++;

    // parent handling
    if (response.parentMap) { // parent query
      Object.values(response.parentMap).forEach((parentId: string) => {
        let pc = this.parentMap[ parentId ]; // mark parents complete
        if (!pc) {
          pc = new FkCache(this.fkTable, parentId);
          this.parentMap[ parentId ] = pc;
        }
        pc.isComplete = true; // assumption: if dedicated parent query, it is complete
        // FkCache.log.debug('add ' + this.fkTable + ' parent ' + parentId, pc)();
      });
    } else if (!response.searchTerm) { // not a parent query and no search
      this.isComplete = response.isComplete;
    }
    /* parent might be Pj or Pp
    let total = 0;
    Object.values(this.parentMap).forEach((fkc) => {
      total += Object.keys(fkc.fkInfoMap).length;
    }); */

    // this.log.debug('add ' + this.info(), response.parentMap)();
    // this.log.debug('add ' + this.info(), response)();
  } // add

  /**
   * Deep Clone
   */
  clone(): FkCache {
    const cc = new FkCache(this.fkTable, this.forId);
    cc.isComplete = this.isComplete;
    cc.totalCount = this.totalCount;
    cc.queryCount = this.queryCount;
    Object.keys(this.fkInfoMap).forEach(key => {
      const fki = this.fkInfoMap[ key ];
      cc.fkInfoMap[ key ] = fki.clone(!!fki.isSelected);
    });
    Object.keys(this.parentMap).forEach(key => {
      const fkc = this.parentMap[ key ];
      cc.parentMap[ key ] = fkc.clone();
    });
    return cc;
  } // clone

  /**
   * return id -> label
   */
  idLabelMap(): Map<string, string> {
    const idMap: Map<string, string> = new Map<string, string>();
    Object.keys(this.fkInfoMap).forEach(key => {
      const fki = this.fkInfoMap[ key ];
      idMap[ key ] = fki.label;
    });
    return idMap;
  }

  /**
   * @param parentMap propertyName -> value
   * @return sorted options from general cache
   */
  options(parentMap: { [ key: string ]: string }): FkInfo[] {
    const options: FkInfo[] = [];
    // add of valid + matches parent map
    this.values().forEach((fki) => {
      if (fki.validForSelection) {
        let add = true;
        Object.keys(parentMap).forEach((pName) => {
          const pValue = parentMap[ pName ];
          const value = fki.valueMap[ pName ];
          if (value !== pValue) {
            add = false;
          }
        });
        if (add) {
          options.push(fki); // not cloned
        }
      }
    });
    options.sort(FkInfo.compare);
    return options;
  } // options

  /**
   * Parent Info
   */
  parentInfo(): string {
    let totalParents = 0;
    let completeParents = 0;
    Object.values(this.parentMap).forEach((fkc: FkCache) => {
      totalParents++;
      if (fkc.isComplete) {
        completeParents++;
      }
    });
    if (totalParents) {
      return '#' + totalParents + ' complete=' + completeParents;
    }
    return '';
  }

  /**
   * partial summary
   */
  info(): string {
    return '#' + this.count
      + (this.isComplete ? ' complete' : ' notComplete')
      + ' of ' + this.totalCount
      + ' parents=' + Object.keys(this.parentMap).length
      + ' queries=' + this.queryCount;
  }

  /**
   * @return sorted options from parent cache
   */
  parentOptions(parentId: string): FkInfo[] | undefined {
    const values = this.parentValues(parentId);
    if (values) {
      const options: FkInfo[] = [];
      values.forEach((fki) => {
        if (fki.validForSelection) {
          options.push(fki); // not cloned
        }
      });
      options.sort(FkInfo.compare);
      return options;
    }
    return values;
  } // parentOptions

  /**
   * @return array of Fk Infos for parentId
   */
  parentValues(parentId: string): FkInfo[] | undefined {
    if (parentId) {
      const pc = this.parentMap[ parentId ];
      if (pc) {
        return Object.values(pc.fkInfoMap);
      }
    }
    return undefined;
  }

  /**
   * @param parentId parent id
   * @return true if parentId is complete
   */
  parentIsComplete(parentId: string): boolean {
    if (parentId) {
      const pc = this.parentMap[ parentId ];
      if (pc) {
        if (!pc.isComplete) {
          if (pc.queryCount > 10) {
            this.log.warn('parentIsComplete NO ' + pc.toString(), pc)();
            return true;
            // } else {
            //   this.log.debug('parentIsComplete ' + pc.info())();
          }
        }
        return pc.isComplete;
      }
      // this.log.debug('parentIsComplete ' + parentId + ' notFound')();
    }
    return false;
  } // parentIsComplete

  /**
   * complete summary
   */
  toString(): string {
    return '['
      + this.fkTable
      + (this.forId ? '.' + this.forId : '')
      + ' ' + this.info()
      + ']';
  }

  /**
   * @return info for fkId if found
   */
  value(fkId: string): FkInfo | undefined {
    return this.fkInfoMap[ fkId ];
  }

  /**
   * @return array of all Fk Infos
   */
  values(): FkInfo[] {
    return Object.values(this.fkInfoMap);
  }

} // FkCache
