import { Routes } from '@angular/router';
import { DataResolver, LoginGuard, ObjectHomeComponent, RecordHomeComponent, StatusComponent, UiResolver } from 'accorto';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectImportComponent } from './project-import/project-import.component';
import { ProjectResolver } from './project/project.resolver';
import { ResourceResolver } from './resource/resource.resolver';
import { ProjectAllocationComponent } from './project-allocation/project-allocation.component';
import { ProjectSheetComponent } from './project-sheet/project-sheet.component';
import { ResourceOverviewComponent } from './resource-overview/resource-overview.component';
import { AllocResourceComponent } from './alloc/alloc-resource/alloc-resource.component';
import { AllocProjectComponent } from './alloc/alloc-project/alloc-project.component';
import { ProjectWorkbenchComponent } from './workbench/project-workbench/project-workbench.component';
import { ResourceWorkbenchComponent } from './workbench/resource-workbench/resource-workbench.component';
import { CalendarComponent } from './calendar/calendar.component';
const ɵ0 = {
    title: 'Login',
    description: 'Please provide Credentials for Accorto PSA Workbench',
    keywords: 'Accorto PSA, Professional Services Application'
}, ɵ1 = {
    title: 'Welcome',
    description: 'Accorto PSA Workbench',
    keywords: 'Accorto PSA, Professional Services Application'
}, ɵ2 = {
    title: 'Welcome',
    description: 'Accorto PSA Workbench',
    keywords: 'Accorto PSA, Professional Services Application'
}, ɵ3 = {
    title: 'Project Sheet',
    description: 'Accorto Project Overview',
    keywords: 'Project Sheet'
}, ɵ4 = {
    title: 'Project Sheet',
    description: 'Accorto Project Overview',
    keywords: 'Project Sheet'
}, ɵ5 = {
    title: 'Project Workbench',
    description: 'Accorto Project Workbench',
    keywords: 'Project Workbench'
}, ɵ6 = {
    title: 'Project Workbench',
    description: 'Accorto Project Workbench',
    keywords: 'Project Workbench'
}, ɵ7 = {
    title: 'Project Allocation',
    description: 'Accorto Project Allocation',
    keywords: 'Project Allocation'
}, ɵ8 = {
    title: 'Project Allocation',
    description: 'Accorto Project Allocation',
    keywords: 'Project Allocation'
}, ɵ9 = {
    title: 'Project Detail Allocation',
    description: 'Accorto Project Detail Allocation',
    keywords: 'Project Allocation'
}, ɵ10 = {
    title: 'Project Detail Allocation',
    description: 'Accorto Project Detail Allocation',
    keywords: 'Project Allocation'
}, ɵ11 = {
    title: 'Resource Detail Allocation',
    description: 'Accorto Resource Detail Allocation',
    keywords: 'Resource Allocation'
}, ɵ12 = {
    title: 'Resource Detail Allocation',
    description: 'Accorto Resource Detail Allocation',
    keywords: 'Resource Allocation'
}, ɵ13 = {
    title: 'Project Import',
    description: 'Accorto Project Import',
    keywords: 'Project Import'
}, ɵ14 = {
    title: 'Resource Overview',
    description: 'Accorto Resource Overview',
    keywords: 'Resource Overview'
}, ɵ15 = {
    title: 'Resource Calendar',
    description: 'Accorto Resource Calendar',
    keywords: 'Resource Calendar'
}, ɵ16 = {
    title: 'Resource Workbench',
    description: 'Accorto Resource Workbench',
    keywords: 'Resource Workbench'
}, ɵ17 = {
    title: 'Resource Workbench',
    description: 'Accorto Resource Workbench',
    keywords: 'Resource Workbench'
}, ɵ18 = {
    title: 'Status',
    description: 'Client Status and settings',
    keywords: ''
};
const routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ0
    },
    {
        path: '',
        component: DashboardComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ1
    },
    {
        path: 'dashboard/:id',
        component: DashboardComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ2
    },
    {
        path: 'project/:id',
        component: ProjectSheetComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ3
    },
    {
        path: 'project',
        component: ProjectSheetComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ4
    },
    {
        path: 'project-wb/:id',
        component: ProjectWorkbenchComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ5
    },
    {
        path: 'project-wb',
        component: ProjectWorkbenchComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ6
    },
    {
        path: 'project-alloc/:id',
        component: ProjectAllocationComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ7
    },
    {
        path: 'project-alloc',
        component: ProjectAllocationComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ8
    },
    {
        path: 'alloc-project/:id',
        component: AllocProjectComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ9
    },
    {
        path: 'alloc-project',
        component: AllocProjectComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ10
    },
    {
        path: 'alloc-resource/:id',
        component: AllocResourceComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ11
    },
    {
        path: 'alloc-resource',
        component: AllocResourceComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ12
    },
    {
        path: 'project-import',
        component: ProjectImportComponent,
        canActivate: [LoginGuard],
        data: ɵ13
    },
    {
        path: 'resources',
        component: ResourceOverviewComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ14
    },
    {
        path: 'calendar',
        component: CalendarComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ15
    },
    {
        path: 'resource-wb/:id',
        component: ResourceWorkbenchComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ16
    },
    {
        path: 'resource-wb',
        component: ResourceWorkbenchComponent,
        canActivate: [LoginGuard],
        resolve: {
            projects: ProjectResolver,
            resources: ResourceResolver
        },
        data: ɵ17
    },
    {
        path: 'ui/:uiTab',
        component: ObjectHomeComponent,
        canActivate: [LoginGuard],
        resolve: {
            uiTab: UiResolver
        }
    },
    {
        path: 'ui/:uiTab/:id',
        component: RecordHomeComponent,
        canActivate: [LoginGuard],
        resolve: {
            uiTab: UiResolver,
            record: DataResolver
        }
    },
    {
        path: 'status',
        component: StatusComponent,
        data: ɵ18
    },
    {
        path: '**',
        redirectTo: '/'
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18 };
