import { CResponse } from './c-response';
/* tslint:disable max-line-length no-inferrable-types */
/**
 * extends: CResponse
 * name: CResponseData
 */
export class CResponseData extends CResponse {
    constructor() {
        super(...arguments);
        /**
         * label: Error results (list save/delete)
         */
        this.recordErrors = [];
        /**
         * label: Data Records
         */
        this.records = [];
    }
} // CResponseData
