import { AccountI } from './account-i';
import { TenantUserI } from './tenant-user-i';
import { ResourceI } from './resource-i';
import { TenantI } from './tenant-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: Project
 */
export interface ProjectI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name?: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * fk ResourceI
   * label: Resource/Manager
   */
  resource?: ResourceI;

  /**
   * fk ResourceI key
   * label: Resource/Manager
   */
  resourceId?: number|string;

  /**
   * User
   * label: Manager Sf Id
   */
  managerSfId?: string;

  /**
   * Project WBS Code
   * label: Code
   */
  code?: string;

  /**
   * label: Invoiced
   */
  invoiced?: string;

  /**
   * fk AccountI
   * label: Account
   */
  account?: AccountI;

  /**
   * fk AccountI key
   * label: Account
   */
  accountId?: number|string;

  /**
   * label: Account Sf Id
   */
  accountSfId?: string;

  color?: string;

  /**
   * label: Share All Lines
   */
  isShareAllLines?: boolean;

  /**
   * label: Status
   */
  status?: string;

  /**
   * label: Percent Complete
   */
  percentComplete?: number;

  /**
   * label: Progress
   */
  progress?: string;

} // Project

/** Project Definition */
export const ProjectD = {
  recordTypeName: 'Project',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  resource: {
    n: 'resource',
    t: 'ResourceI'
  },
  resourceId: {
    n: 'resourceId',
    t: 'number|string'
  },
  managerSfId: {
    n: 'managerSfId',
    t: 'string'
  },
  code: {
    n: 'code',
    t: 'string'
  },
  invoiced: {
    n: 'invoiced',
    t: 'string'
  },
  account: {
    n: 'account',
    t: 'AccountI'
  },
  accountId: {
    n: 'accountId',
    t: 'number|string'
  },
  accountSfId: {
    n: 'accountSfId',
    t: 'string'
  },
  color: {
    n: 'color',
    t: 'string'
  },
  isShareAllLines: {
    n: 'isShareAllLines',
    t: 'boolean'
  },
  status: {
    n: 'status',
    t: 'string'
  },
  percentComplete: {
    n: 'percentComplete',
    t: 'number'
  },
  progress: {
    n: 'progress',
    t: 'string'
  },
}; // Project
