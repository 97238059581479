import { select, Store } from '@ngrx/store';
import { pipe } from 'rxjs';
import { take } from 'rxjs/operators';

import { UiTab } from '../model/ui-tab';
import { DataRecord } from '../model/data-record';
import { selectFkCache } from './fk.selectors';
import { fkIdRequestAction } from './fk.actions';

/**
 * FK Utilities
 */
export class FkUtil {

  /**
   * Get Ids from records
   * @param properties list of property names in record
   * @param records list of records
   * @return undefined of there are no properties or no records
   */
  static getFkValues(properties: string[], records: DataRecord[]): string[] | undefined {
    if (records.length === 0) {
      return undefined; // nothing to do
    }
    if (properties.length === 0) {
      return undefined;
    }
    const ids: string[] = [];
    records.forEach((r) => {
      properties.forEach((pn) => {
        const value = r.value(pn);
        if (value) {
          if (!ids.includes(value)) {
            ids.push(value);
          }
        }
      });
    });
    return ids;
  } // getFkValues

  static preloadFormFKs(store: Store<any>, ui: UiTab, record: DataRecord) {
    const fkTables = [];
    for (const section of ui.formSectionList) {
      for (const ff of section.uiFormFieldList) {
        if (ff.dataColumn && ff.dataColumn.fkTable) {
          const fkTable = ff.dataColumn.fkTable;
          if (fkTables.includes(fkTable)) {
            continue;
          }
          const properties: string[] = [];
          ui.dataTable.columnList.forEach((c) => { // find all referencing table
            if (c.fkTable === fkTable) {
              properties.push(c.propertyName); // both - projectId, projectSfId
            }
          });
          fkTables.push(fkTable);
          store.pipe(
            select(selectFkCache(fkTable)),
            pipe(take(1)))
            .subscribe(v => {
              if (v === undefined) { // nothing there so request
                const fkId = undefined;
                const fkIds = FkUtil.getFkValues(properties, [ record ]);
                // console.warn('FkUtil.preloadFormFks', fkTable, fkId, fkIds);
                store.dispatch(fkIdRequestAction({ fkTable, fkId, fkIds }));
              }
            });
        } // fkColumn
      } // formField
    } // sections
  } // preloadFormFks

  /**
   * Preload FKs contained in records
   * @param store app store
   * @param ui UI to get Grid columns referencing table
   * @param records list of records
   */
  static preloadGridFKs(store: Store<any>, ui: UiTab, records: DataRecord[]) {
    const fkTables = [];
    for (const gc of ui.gridFieldList) {
      if (gc.dataColumn && gc.dataColumn.fkTable) {
        const fkTable = gc.dataColumn.fkTable;
        if (fkTables.includes(fkTable)) {
          continue;
        }
        const properties: string[] = [];
        ui.dataTable.columnList.forEach((c) => { // find all referencing table
          if (c.fkTable === fkTable) {
            properties.push(c.propertyName); // both - projectId, projectSfId
          }
        });
        fkTables.push(fkTable);
        store.pipe(
          select(selectFkCache(fkTable)),
          pipe(take(1)))
          .subscribe(v => {
            if (v === undefined) { // nothing there so request
              const fkId = undefined;
              const fkIds = FkUtil.getFkValues(properties, records);
              // console.warn('FkUtil.preloadGridFks', fkTable, fkId, fkIds);
              store.dispatch(fkIdRequestAction({ fkTable, fkId, fkIds }));
            }
          });
      } // // fkColumn
    } // gridField
  } // preloadGridFks


} // FkUtil
