const ɵ0 = {
    n: 'id',
    t: 'number|string'
};
/** UiTab Definition */
export const UiTabD = {
    recordTypeName: 'UiTab',
    id: ɵ0,
    name: {
        n: 'name',
        t: 'string'
    },
    label: {
        n: 'label',
        t: 'string'
    },
    description: {
        n: 'description',
        t: 'string'
    },
    isActive: {
        n: 'isActive',
        t: 'boolean',
        d: true
    },
    created: {
        n: 'created',
        t: 'number'
    },
    createdBy: {
        n: 'createdBy',
        t: 'TenantUserI'
    },
    createdById: {
        n: 'createdById',
        t: 'number|string'
    },
    updated: {
        n: 'updated',
        t: 'number'
    },
    updatedBy: {
        n: 'updatedBy',
        t: 'TenantUserI'
    },
    updatedById: {
        n: 'updatedById',
        t: 'number|string'
    },
    externalId: {
        n: 'externalId',
        t: 'string'
    },
    tenant: {
        n: 'tenant',
        t: 'TenantI'
    },
    tenantId: {
        n: 'tenantId',
        t: 'number|string'
    },
    owner: {
        n: 'owner',
        t: 'TenantUserI'
    },
    ownerId: {
        n: 'ownerId',
        t: 'number|string'
    },
    version: {
        n: 'version',
        t: 'number'
    },
    sfId: {
        n: 'sfId',
        t: 'string'
    },
    dataTable: {
        n: 'dataTable',
        t: 'DataTableI'
    },
    dataTableId: {
        n: 'dataTableId',
        t: 'number|string'
    },
    childTabNames: {
        array: true,
        n: 'childTabNames',
        t: 'string'
    },
    formSectionList: {
        array: true,
        n: 'formSectionList',
        t: 'UiFormSectionI'
    },
    gridFieldList: {
        array: true,
        n: 'gridFieldList',
        t: 'UiGridFieldI'
    },
    help: {
        n: 'help',
        t: 'string'
    },
    iconClass: {
        n: 'iconClass',
        t: 'string'
    },
    iconHref: {
        n: 'iconHref',
        t: 'string'
    },
    isChildTab: {
        n: 'isChildTab',
        t: 'boolean'
    },
    isMainMenu: {
        n: 'isMainMenu',
        t: 'boolean'
    },
    labelPlural: {
        n: 'labelPlural',
        t: 'string'
    },
    relatedLinkColumnList: {
        array: true,
        n: 'relatedLinkColumnList',
        t: 'string'
    },
    seqNo: {
        n: 'seqNo',
        t: 'number'
    },
    uiId: {
        n: 'uiId',
        t: 'string'
    },
}; // UiTab
export { ɵ0 };
