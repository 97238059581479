import { DataRecord } from './data-record';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: GraphNode
 */
export class GraphNode {

  /**
   * label: Dependent List
   */
  public dependentIds: string[] = [];

  /**
   * client only
   * label: Client Set of dependent ids
   */
  public dependentSet: Set<string>;

  /**
   * local changes
   * label: Duration Days (width)
   */
  public durationDays: number;

  /**
   * client only absolute end
   * label: Client End Ms
   */
  public endMs: number;

  /**
   * client only
   * label: Client Height
   */
  public height: number = 0;

  public info: string;

  /**
   * label: Info Map
   */
  public infoMap: {[key: string]: string} = {};

  /**
   * client only
   * label: Client Selected
   */
  public isSelected: boolean = false;

  /**
   * local changes
   * label: Prerequisites (SfIds)
   */
  public prerequisiteIds: string[] = [];

  /**
   * client only
   * label: Client Set of prerequisite ids
   */
  public prerequisiteSet: Set<string>;

  /**
   * changes
   * label: Data Record
   */
  public record: DataRecord;

  /**
   * label: Record SfId
   */
  public sfId: string;

  /**
   * client only absolute start
   * label: Client Start Ms
   */
  public startMs: number;

  /**
   * client only relative start
   * label: Client Start Offset Days
   */
  public startOffsetDays: number = 0;

  /**
   * label: Client Debug Title
   */
  public title: string;

  /**
   * e.g. Record Owner
   * label: User/Resource Info
   */
  public userInfo: string;

  /**
   * client only
   * label: Client Width
   */
  public width: number = 0;

  /**
   * client only
   * label: Client X pos
   */
  public x: number = 0;

  /**
   * client only
   * label: Client Y pos
   */
  public y: number = 0;


  /**
   * code/wbs
   */
  public getCode(): string {
    if (this.record) {
      let cc = this.record.value('code');
      if (!cc) {
        cc = this.record.value('wbs');
      }
      return cc;
    }
    return undefined;
  } // getCode


  /**
   * name
   */
  public getName(): string {
    if (this.record) {
      const nn = this.record.value('name');
      if (nn) {
        return nn;
      }
      return this.record.name;
    }
    return '-';
  } // getName


  /**
   * code: name
   */
  public prefix(): string {
    const code = this.getCode();
    if (code) {
      return code + ': ' + this.getName();
    }
    return this.getName();
  } // prefix


  /**
   * code: name
   */
  public toString(): string {
    const code = this.getCode();
    if (code) {
      return code + ': ' + this.getName();
    }
    return this.getName();
  } // toString


  /**
   * code: name start=.. duration=..
   */
  public toStringX(): string {
    let info = this.sfId
      + ' ' + this.prefix()
      + ' start=' + this.startOffsetDays + ' dur=' + this.durationDays;
    if (this.prerequisiteIds) {
      for (const pid of this.prerequisiteIds) {
        info += ' pre:' + pid;
      }
    }
    if (this.dependentIds) {
      for (const did of this.dependentIds) {
        info += ' dep:' + did;
      }
    }
    return info;
  } // toStringX

} // GraphNode
