import { CRequest } from './c-request';
/* tslint:disable max-line-length no-inferrable-types */
/**
 * extends: CRequest
 * name: CRequestImport
 */
export class CRequestImport extends CRequest {
    constructor() {
        super(...arguments);
        /**
         * label: Headings with Import Column Mappings
         */
        this.headingColumns = [];
    }
} // CRequestImport
