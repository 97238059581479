import { DataRecordI } from '../model/data-record-i';
import { UiGridFieldI } from '../model/ui-grid-field-i';
import { DataRecord } from '../model/data-record';

/**
 * Data Record Functions
 */
export class DataRecordF {

  /**
   * Clone Record
   * @param record the record
   * @param trim trim info/meta/who - make valueMap editable
   */
  static clone(record: DataRecordI, trim: boolean = false): DataRecordI {
    if (record) {
      const clon = Object.assign({}, record); // leave value map frozen
      if (record.changeMap) {
        clon.changeMap = Object.assign({}, record.changeMap);
      } else {
        clon.changeMap = {};
      }
      clon.isSelected = false; // get 'object is not extensible'
      if (trim) {
        clon.valueMap = Object.assign({}, record.valueMap);
        delete clon.info;
        delete clon.meta;
        delete clon.who;
        delete clon.details;
      }
      return clon;
    }
    return undefined;
  } // clone

  /**
   * Convert DataRecord value
   * @param key key
   * @param value value
   * @param def definition
   */
  static recordValue(key: string, value: string, def: { [ key: string ]: string }): any {
    if (!def || def.t === 'string') {
      return value;
    }
    if (def.t === 'boolean') {
      return value === 'true';
    }
    if (def.t === 'number') {
      try {
        const f = Number(value); // Number.parseInt('1a1') === 1
        if (!Number.isNaN(f)) {
          return f;
        }
      } catch (e) {
        console.log('DataRecordF.recordValue Error ' + key + '=' + value, def, e);
      }
    }
    if (def.t !== 'number|string') { // class+interface id
      console.log('DataRecordF.recordValue Invalid ' + key + '=' + value, def);
    }
    return value;
  } // recordValue

  /**
   * Clone Record Array
   * @param records records
   * @param trim trim valueMap of records
   */
  static cloneArray(records: DataRecordI[], trim: boolean = false): DataRecordI[] {
    if (records && records.length > 0) {
      return records.map((record) => DataRecordF.clone(record, trim));
    }
    return [];
  } // clone

  /**
   * code|wbs: label|name
   * @param record the record
   */
  static codeLabel(record: DataRecordI): string {
    if (record) {
      const labelName = (record.label == null ? record.name : record.label);
      const code = DataRecordF.value(record, 'code');
      if (code) {
        return code + ': ' + labelName;
      }
      const wbs = DataRecordF.value(record, 'wbs');
      if (wbs) {
        return wbs + ': ' + labelName;
      }
      return labelName;
    }
    return '';
  } // codeLabel

  /**
   * Get list of property names of Changes
   * @param record the record (updated!)
   */
  static getChanges(record: DataRecordI): string[] {
    const changes: string[] = [];
    if (record && record.changeMap) {
      const notChanged: string[] = [];
      for (const key of Object.keys(record.changeMap)) {
        let value = record.changeMap[ key ];
        if (value === 'undefined' || value === '' || value == null) {
          value = undefined;
          record.changeMap[ key ] = value;
        } else { // ensure it is a string
          record.changeMap[ key ] = String(value);
        }
        let oldValue = record.valueMap[ key ];
        if (oldValue === '' || oldValue == null) {
          oldValue = undefined;
        }
        if (Object.is(value, oldValue)) {
          notChanged.push(key);
        } else {
          changes.push(key);
        }
      }
      for (const key of notChanged) {
        delete record.changeMap[ key ];
      }
    }
    return changes;
  } // getChanges

  /**
   * Is Changed
   * @param record the record
   */
  static isChanged(record: DataRecordI): boolean {
    if (!record || !record.changeMap) {
      return false;
    }
    return Object.keys(record.changeMap).length > 0;
  }

  /**
   * Label or Name
   * @param record the record
   */
  static labelName(record: DataRecordI): string {
    if (record) {
      return record.label ? record.label : record.name;
    }
    return '';
  }

  /**
   * New Data Record
   * @param def record definition
   */
  static newDataRecord(def: { [ key: string ]: string | { n: string, t: string, d?: any } }): DataRecordI {
    const dr: DataRecordI = {};
    dr.recordType = def.recordTypeName as string;
    dr.isActive = true;
    dr.valueMap = {};
    dr.changeMap = {};
    for (const key of Object.keys(def)) {
      const defValue = def[ key ];
      if (defValue instanceof Object) {
        if (defValue.d) {
          dr.changeMap [ key ] = String(defValue.d);
        }
      }
    }
    return dr;
  } // newDataRecord


  /**
   * Reset Changes
   * @param record the record (updated)
   */
  static reset(record: DataRecordI) {
    if (record) {
      record.changeMap = {};
    }
  }

  /**
   * Set Value - return true if changed
   * @param record the record (updated)
   * @param propertyName property name
   * @param value new value
   */
  static setValue(record: DataRecordI, propertyName: string, value: string): boolean {
    if (record && propertyName) {
      const oldValue = DataRecordF.value(record, propertyName);
      if (!record.valueMap) { // new record
        record.valueMap = {};
      }
      if (!record.changeMap) { // unchanged record
        record.changeMap = {};
      }
      record.changeMap[ propertyName ] = (value === 'undefined' || value === 'null' || value === '' || value == null)
        ? null : value; // update empty to null
      if (record.changeMap[ propertyName ] === record.valueMap[ propertyName ]) {
        delete record.changeMap[ propertyName ];
      }
      return !Object.is(oldValue, DataRecordF.value(record, propertyName));
    }
    return false;
  } // setValue

  static title(record: DataRecordI, gf: UiGridFieldI, index: number): string {
    if (record && gf) {
      const value = DataRecordF.value(record, gf.name);
      if (value) {
        return '#' + (index + 1) + ' ' + gf.dataColumn.label + ': ' + value;
      }
      return '-';
    }
    return 'n/a';
  } // title

  /**
   * Get the current String Value of property name
   * @param record the record
   * @param propertyName property name
   * @param defaultValue default if not found
   */
  static value(record: DataRecordI, propertyName: string, defaultValue?: string): string | undefined {
    if (record && propertyName) {
      if (record.changeMap && record.changeMap.hasOwnProperty(propertyName)) {
        return record.changeMap[ propertyName ];
      }
      if (record.valueMap && record.valueMap.hasOwnProperty(propertyName)) {
        return record.valueMap[ propertyName ];
      }
    }
    return defaultValue;
  } // value

  /**
   * Get the current boolean Value of property name
   * @param record the record
   * @param propertyName property name
   * @param defaultValue default if not found or invalid (false)
   */
  static valueBoolean(record: DataRecordI, propertyName: string, defaultValue: boolean = false): boolean {
    const value: string = DataRecordF.value(record, propertyName);
    if (value != null && value.length > 0) {
      return value === 'true' || value === 'Yes';
    }
    return defaultValue;
  }

  /**
   * get the current date Value of property name
   * @param record the record
   * @param propertyName property name
   */
  static valueDate(record: DataRecordI, propertyName: string): Date | undefined {
    const value: number = DataRecordF.valueNumber(record, propertyName, 0);
    if (value != null && value !== 0) {
      return new Date(value);
    }
    return undefined;
  } // valueDate

  /**
   * Get the current number Value of property name
   * @param record the record
   * @param propertyName property name
   * @param defaultValue default if not found or invalid (undefined)
   * @param isZeroToDefault use default if value is 0
   */
  static valueNumber(record: DataRecordI, propertyName: string, defaultValue?: number, isZeroToDefault: boolean = false): number {
    const value: string = DataRecordF.value(record, propertyName);
    if (value != null && value.length > 0) {
      const nn = Number(value);
      if (!Number.isNaN(nn)) {
        if (isZeroToDefault) {
          return nn === 0 ? defaultValue : nn;
        }
        return nn;
      }
    }
    return defaultValue;
  } // valueNumber


  static asRecord(record: DataRecordI): DataRecord {
    const rr = new DataRecord();
    Object.assign(rr, record); // leave value map frozen
    if (record.changeMap) {
      rr.changeMap = Object.assign({}, record.changeMap);
    } else {
      rr.changeMap = {};
    }
    rr.isSelected = false;
    return rr;
  }

} // DataRecordF
