import { Colors, DateUtil, Logger, Margin } from 'accorto';
import { Workbench } from '../workbench/workbench';

/**
 * Pallet Layout
 */
export class PalletLayout {

  static readonly ZoomLevelAll = 'A';
  static readonly ZoomLevelDay = 'D';
  static readonly ZoomLevelWeek = 'W';
  static readonly ZoomLevelMonth = 'M';

  static readonly LineTypePlan = 'P';
  static readonly LineTypeCurrent = 'C';

  static readonly BoxTypeTotal = 'T';
  static readonly BoxTypeMs = 'ms';
  static readonly BoxTypeWeek = 'w';
  static readonly BoxTypeMonth = 'm';

  static readonly ShowBoth = 'B';
  static readonly ShowPlan = 'P';
  static readonly ShowCurrent = 'C';

  /** xDayWidth */
  static readonly MinDayWidth = 20;
  static readonly MinWeekWidth = 7; // 49
  static readonly MinMonthWidth = 3.3; // 99

  /** Show Plan|Current|Both */
  public showPlanCurrent: string = 'B';
  /** Show ResourceLines */
  public showResourceLines: boolean = true;
  /** ZoomLevel = A|D|W|M */
  public zoomLevel: string;
  /** Duration Days */
  public durationDays: number;

  /** x factor per day */
  public xDayFactor: number = 0;
  /** factor per day for NoZoom */
  public dayFactor: number;

  /** Header Lines */
  public headerLines: number = 2;

  public startMs: number;
  public endMs: number;

  svgHeight: number = 0;
  svgWidth: number = 0;

  minValue: number;
  maxValue: number;

  svgTransform: string = '';
  svgViewBox: string = '0 0 0 0';
  readonly colorWhite = [ 255, 255, 255 ];
  readonly colorBlue = Colors.hex2rgb('#0070d2');
  readonly colorGreen = Colors.hex2rgb('#4bca81');
  /** a b | c d | tx, ty */
  private svgTransformMatrix: number[] = [ 1, 0, 0, 1, 0, 0 ];
  /** min-x, min-y, width, height */
  private svgViewpointVector: number[] = [ 0, 0, 0, 0 ];

  private log: Logger = new Logger('PalletLayout');

  /**
   * Pallet Layout
   * @param pageWidth page width in px
   * @param lineHeight line height
   * @param lineGap line gap
   * @param marginCarton carton margin
   * @param marginLine box line margin
   */
  constructor(public pageWidth: number,
              public lineHeight: number = 20,
              public lineGap: number = 4,
              public marginCarton: Margin = new Margin(1),
              public marginLine: Margin = new Margin(1, 2)) {
    this.reset();
  }

  /** Week Factor */
  get xWeekFactor(): number {
    return this.xDayFactor * 7;
  }

  /**
   * add value of type
   * called from initialize of BoxLine
   */
  addValue(type: string, value: number) {
    // console.debug('addValue', type + ' ' + value);
    if (value && type !== PalletLayout.BoxTypeTotal) { // ignore T(totals)
      if (this.minValue === undefined || this.minValue > value) {
        this.minValue = value;
      }
      if (this.maxValue === undefined || this.maxValue < value) {
        this.maxValue = value;
      }
    } // value
  } // addValue

  /**
   * Get Color (called from box-line)
   * @param lineType C or P
   * @param boxType T or ms
   * @param value the value
   */
  colorBox(lineType: string, boxType: string, value: number): string {
    const color = lineType === PalletLayout.LineTypeCurrent ? this.colorBlue : this.colorGreen;
    if (boxType === PalletLayout.BoxTypeTotal) {
      return Colors.rgb2hex(color);
    }
    if (value) {
      const range = (this.maxValue - this.minValue);
      if (range > 0) {
        let factor = (value - this.minValue) / (this.maxValue - this.minValue);
        // console.debug('colorBox', 'factor=' + factor, 'min=' + this.minValue + ' max=' + this.maxValue + ' v=' + value);
        factor += 0.05; // a bit darker
        if (factor > 1) {
          factor = 1; // max
        }
        const rgb = Colors.interpolateRgb(this.colorWhite, color, factor);
        // console.debug('colorBox', rgb, factor);
        return Colors.rgb2hex(rgb);
      } else { // max=min
        return Colors.rgb2hex(color);
      }
    }
    return 'white';
  } // colorBox

  /** Color of main carton */
  colorCarton(): string {
    if (this.showPlanCurrent === PalletLayout.ShowPlan) {
      return Colors.rgb2hex(this.colorGreen);
    }
    if (this.showPlanCurrent === PalletLayout.ShowCurrent) {
      return Colors.rgb2hex(this.colorBlue);
    }
    return 'black';
  }

  /**
   * Initialize / reset
   * @param showPlanCurrent show P|C|B
   * @param showResourceLines show resource box lines
   * @param zoomLevel zoom level
   */
  initialize(showPlanCurrent: string, showResourceLines: boolean, zoomLevel: string) {
    this.showPlanCurrent = showPlanCurrent;
    this.showResourceLines = showResourceLines;
    this.zoomLevel = zoomLevel;
    //
  } // initialize

  /**
   * Layout
   * @param workbench with all pallets
   */
  layout(wb: Workbench) {
    this.reset();
    this.startMs = undefined;
    this.endMs = undefined;

    if (this.pageWidth === 0) {
      return; // no page width yet
    }

    // --- startMs, endMs, duration ---
    wb.pallets.forEach((pallet) => {
      pallet.initStartEnd(this.showPlanCurrent);
      if (pallet.startMs) {
        if (this.startMs === undefined || this.startMs > pallet.startMs) {
          this.startMs = pallet.startMs;
        }
      }
      if (pallet.endMs) {
        if (this.endMs === undefined || this.endMs < pallet.endMs) {
          this.endMs = pallet.endMs;
        }
      }
      // console.debug('layout-initStartEnd(' + this.showPlanCurrent + ') ' + pallet);
    });
    // total duration
    this.durationDays = (this.endMs - this.startMs) / DateUtil.ONEDAY;
    if (Number.isNaN(this.durationDays) || this.durationDays < 0) {
      this.durationDays = 1;
    } else {
      this.durationDays += 1;
    }
    // this.log.debug('layout', 'start=' + this.startMs + ' end=' + this.endMs + ' duration=' + this.durationDays)();

    // zoom
    this.dayFactor = Math.trunc((this.pageWidth - this.marginCarton.v) / this.durationDays * 10) / 10;
    let selectedZoomLevel = this.zoomLevel;
    if (this.zoomLevel === PalletLayout.ZoomLevelDay) {
      this.xDayFactor = PalletLayout.MinDayWidth;
    } else if (this.zoomLevel === PalletLayout.ZoomLevelWeek) {
      this.xDayFactor = PalletLayout.MinWeekWidth;
    } else if (this.zoomLevel === PalletLayout.ZoomLevelMonth) {
      this.xDayFactor = PalletLayout.MinMonthWidth;
    } else { // all
      selectedZoomLevel = PalletLayout.ZoomLevelAll;
      this.zoomLevel = PalletLayout.ZoomLevelDay;
      this.xDayFactor = this.dayFactor;
      if (this.xDayFactor < PalletLayout.MinDayWidth) {
        this.zoomLevel = PalletLayout.ZoomLevelWeek;
        if (this.xDayFactor < PalletLayout.MinWeekWidth) { // 70
          this.zoomLevel = PalletLayout.ZoomLevelMonth;
        }
      }
    }
    this.log.debug('layout ' + this.showPlanCurrent,
      'width=' + this.pageWidth + ' days=' + this.durationDays + ' factor=' + this.dayFactor,
      'zoom=' + this.zoomLevel + ' xDayFactor=' + this.xDayFactor,
      'showDay=' + this.showDay() + ' showWeek=' + this.showWeek())();
    // width
    this.svgWidth = this.pageWidth;
    this.svgViewpointVector[ 2 ] = this.pageWidth;

    // --- initialize week/month ---
    this.maxValue = undefined;
    this.minValue = undefined;
    wb.pallets.forEach((pallet) => {
      pallet.initialize(this);
    });

    // --- layout ---
    this.headerLines = 2;
    if (this.zoomLevel === PalletLayout.ZoomLevelMonth) {
      this.headerLines = 1;
    }
    let y = (this.headerLines * this.lineHeight) + (2 * this.lineGap);
    wb.pallets.forEach((pallet) => {
      y = pallet.layout(y, this);
    });
    const maxHeight = y + 20;
    wb.createHeaders(this, maxHeight);

    // height
    this.svgHeight = y + 20;
    this.svgViewpointVector[ 3 ] = maxHeight;
    //
    this.svgViewBox = this.svgViewpointVector.join(' ');
    this.svgTransform = 'matrix(' + this.svgTransformMatrix.join(' ') + ')';
    // fix
    this.zoomLevel = selectedZoomLevel;
  } // layout

  /** Reset transformation / view */
  reset() {
    /** a b | c d | tx, ty */
    this.svgTransformMatrix = [ 1, 0, 0, 1, 0, 0 ];
    /** min-x, min-y, width, height */
    this.svgViewpointVector = [ 0, 0, 0, 0 ];
    //
    this.svgViewBox = this.svgViewpointVector.join(' ');
    this.svgTransform = 'matrix(' + this.svgTransformMatrix.join(' ') + ')';
  } // reset

  // show days
  showDay(): boolean {
    return this.xDayFactor > PalletLayout.MinDayWidth;
  }

  // show weeks
  showWeek(): boolean {
    return !this.showDay && this.xDayFactor > PalletLayout.MinWeekWidth;
  }

  /**
   * SVG Pan
   * @param dx x delta
   * @param dy y delta
   */
  svgPan(dx: number, dy: number) {
    /*
    <path class="button" onclick="pan(0, 25)" d="M25 5 l6 10 a20 35 0 0 0 -12 0z" />
    <path class="button" onclick="pan(25, 0)" d="M5 25 l10 -6 a35 20 0 0 0 0 12z" />
    <path class="button" onclick="pan(0,-25)" d="M25 45 l6 -10 a20, 35 0 0,1 -12,0z" />
    <path class="button" onclick="pan(-25, 0)" d="M45 25 l-10 -6 a35 20 0 0 1 0 12z" />
    */
    this.svgTransformMatrix[ 4 ] += dx; // e
    this.svgTransformMatrix[ 5 ] += dy; // f
    this.svgTransform = 'matrix(' + this.svgTransformMatrix.join(' ') + ')';
  } // svgPan

  /**
   * SVG Zoom
   * @param scale 1.0 = 100%
   */
  svgZoom(scale: number) {
    /*
    <circle class="button" cx="25" cy="20.5" r="4" onclick="zoom(0.8)"/>
    <circle class="button" cx="25" cy="29.5" r="4" onclick="zoom(1.25)"/>
    */
    /*
     * a b | c d | tx, ty
     *  | a c tx || x | -- xNew = a(x)+c(y)+e
     *  | b d ty || y | -- yNew = b(x)+d(y)+f
     *  | 0 0 0  || 1 |
     */
    for (let i = 0; i < 4; i++) {
      this.svgTransformMatrix[ i ] *= scale;
    }
    const centerX = this.svgViewpointVector[ 2 ] / 2; // w
    const centerY = this.svgViewpointVector[ 3 ] / 2; // h

    // tx, ty
    this.svgTransformMatrix[ 4 ] += (1 - scale) * centerX;
    this.svgTransformMatrix[ 5 ] += (1 - scale) * centerY;
    this.svgTransform = 'matrix(' + this.svgTransformMatrix.join(' ') + ')';
  } // svgZoom

} // PalletLayout
