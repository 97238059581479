import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';

import { loginErrorAction, loginRequestAction, loginResponseAction, logoutRequestAction } from './login.actions';
import { LoginService } from './login.service';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AccortoService } from '../accorto.service';
import { ClientStorage } from '../utils/client-storage';
import { DateUtil } from '../utils/date-util';


@Injectable()
export class LoginEffects {


  loginRequest$ = createEffect(() => this.actions$.pipe(
    ofType(loginRequestAction),
    // concatMap(() => EMPTY)
    mergeMap((action) => this.service.login(action.request)
      .pipe(
        catchError(err => {
          console.warn('loginRequest$.error', err); // TypeError: Cannot read property 'callType' of null
          this.store.dispatch(loginErrorAction({ err }));
          return of(undefined);
        })
      )),
    map(response => loginResponseAction({ response }))
    ), {}
  );


  loginResponse$ = createEffect(() => this.actions$.pipe(
    ofType(loginResponseAction),
    filter(action => action.response.session !== undefined),
    tap((action) => {
      const session = action.response.session;
      if (ClientStorage.getBoolean(ClientStorage.REMEMBERME, true)) {
        const sid = session.name;
        ClientStorage.set(ClientStorage.SID, sid);
        // console.log('loginResponse$ sid=' + sid, action);
      }
      DateUtil.setDefaultWeekInfo(session.firstDayOfWeek,
        session.settings ? session.settings.weekendDays : undefined);
      //
      const redirect = this.config.getEnvOrStorage('redirect');
      // console.log('loginResponse$ redirect=' + redirect, action);
      this.router.navigateByUrl(redirect ? redirect : '/');
    })
    ), { dispatch: false }
  );


  logoutRequest$ = createEffect(() => this.actions$.pipe(
    ofType(logoutRequestAction),
    tap((action) => {
      this.service.logout();
      this.router.navigateByUrl('/login');
    })
    ), { dispatch: false }
  );

  constructor(private actions$: Actions,
              private service: LoginService,
              private store: Store<any>,
              private router: Router,
              private config: AccortoService) {
  }

}
