
/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: TzInfo
 */
export class TzInfo {

  /**
   * e.g. British Time - Central European Time - Pacific Time
   * label: Timezone Name
   */
  public fullName: string;

  /**
   * e.g. Europe/London - Europe/Brussels - America/Los_Angeles
   * label: Timezone Id
   */
  public id: string;

  /**
   * e.g. Z - +01:00 - -08:00
   * label: Offset Id
   */
  public offsetId: string;

  /**
   * e.g. 0 - 36000 - -28800
   * label: Offset in Seconds
   */
  public offsetSeconds: number;

  /**
   * e.g. BT - CET - PT
   * label: Short Name
   */
  public shortName: string;

  /**
   * time in ms
   * label: Timezone change
   */
  public tzChange: number;

  /**
   * e.g. 0 - 36000 - -28800
   * label: Offset in Seconds after Change
   */
  public tzChangeSeconds: number;

} // TzInfo
