import { ProjectI } from './project-i';
import { ActivityTypeI } from './activity-type-i';
import { TenantUserI } from './tenant-user-i';
import { ResourceI } from './resource-i';
import { TenantI } from './tenant-i';

/* tslint:disable max-line-length no-inferrable-types */

/**
 * name: ResourcePrice
 */
export interface ResourcePriceI {

  /**
   * Primary Key (Id)
   * label: Record
   */
  id?: number|string;

  /**
   * label: Name
   */
  name?: string;

  /**
   * label: Label
   */
  label?: string;

  /**
   * label: Description
   */
  description?: string;

  /**
   * label: Active
   */
  isActive?: boolean;

  /**
   * label: Created Time
   */
  created?: number;

  /**
   * fk TenantUserI
   * label: Created By
   */
  createdBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Created By
   */
  createdById?: number|string;

  /**
   * label: Updated Time
   */
  updated?: number;

  /**
   * fk TenantUserI
   * label: Updated By
   */
  updatedBy?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Updated By
   */
  updatedById?: number|string;

  /**
   * label: External Id
   */
  externalId?: string;

  /**
   * parent fk TenantI
   * label: Tenant
   */
  tenant?: TenantI;

  /**
   * parent fk TenantI key
   * label: Tenant
   */
  tenantId?: number|string;

  /**
   * fk TenantUserI
   * label: Record Owner
   */
  owner?: TenantUserI;

  /**
   * fk TenantUserI key
   * label: Record Owner
   */
  ownerId?: number|string;

  /**
   * label: Record Version
   */
  version?: number;

  /**
   * label: Salesforce Id
   */
  sfId?: string;

  /**
   * parent fk ResourceI
   * label: Resource
   */
  resource?: ResourceI;

  /**
   * parent fk ResourceI key
   * label: Resource
   */
  resourceId?: number|string;

  /**
   * label: Resource
   */
  resourceSfId?: string;

  /**
   * fk ProjectI
   * label: Project
   */
  project?: ProjectI;

  /**
   * fk ProjectI key
   * label: Project
   */
  projectId?: number|string;

  /**
   * label: Project
   */
  projectSfId?: string;

  /**
   * fk ActivityTypeI
   * label: Activity Type
   */
  activityType?: ActivityTypeI;

  /**
   * fk ActivityTypeI key
   * label: Activity Type
   */
  activityTypeId?: number|string;

  /**
   * label: Activity Type
   */
  activityTypeSfId?: string;

  /**
   * label: Effective from
   */
  effectiveFrom?: number;

  /**
   * label: Cost Rate
   */
  costRate?: number;

  /**
   * label: Billing Rate
   */
  unitPrice?: number;

} // ResourcePrice

/** ResourcePrice Definition */
export const ResourcePriceD = {
  recordTypeName: 'ResourcePrice',
  id: {
    n: 'id',
    t: 'number|string'
  },
  name: {
    n: 'name',
    t: 'string'
  },
  label: {
    n: 'label',
    t: 'string'
  },
  description: {
    n: 'description',
    t: 'string'
  },
  isActive: {
    n: 'isActive',
    t: 'boolean',
    d: true
  },
  created: {
    n: 'created',
    t: 'number'
  },
  createdBy: {
    n: 'createdBy',
    t: 'TenantUserI'
  },
  createdById: {
    n: 'createdById',
    t: 'number|string'
  },
  updated: {
    n: 'updated',
    t: 'number'
  },
  updatedBy: {
    n: 'updatedBy',
    t: 'TenantUserI'
  },
  updatedById: {
    n: 'updatedById',
    t: 'number|string'
  },
  externalId: {
    n: 'externalId',
    t: 'string'
  },
  tenant: {
    n: 'tenant',
    t: 'TenantI'
  },
  tenantId: {
    n: 'tenantId',
    t: 'number|string'
  },
  owner: {
    n: 'owner',
    t: 'TenantUserI'
  },
  ownerId: {
    n: 'ownerId',
    t: 'number|string'
  },
  version: {
    n: 'version',
    t: 'number'
  },
  sfId: {
    n: 'sfId',
    t: 'string'
  },
  resource: {
    n: 'resource',
    t: 'ResourceI'
  },
  resourceId: {
    n: 'resourceId',
    t: 'number|string'
  },
  resourceSfId: {
    n: 'resourceSfId',
    t: 'string'
  },
  project: {
    n: 'project',
    t: 'ProjectI'
  },
  projectId: {
    n: 'projectId',
    t: 'number|string'
  },
  projectSfId: {
    n: 'projectSfId',
    t: 'string'
  },
  activityType: {
    n: 'activityType',
    t: 'ActivityTypeI'
  },
  activityTypeId: {
    n: 'activityTypeId',
    t: 'number|string'
  },
  activityTypeSfId: {
    n: 'activityTypeSfId',
    t: 'string'
  },
  effectiveFrom: {
    n: 'effectiveFrom',
    t: 'number'
  },
  costRate: {
    n: 'costRate',
    t: 'number'
  },
  unitPrice: {
    n: 'unitPrice',
    t: 'number'
  },
}; // ResourcePrice
