/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stacked-bar-chart.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./stacked-bar-chart.component";
import * as i4 from "../../../../projects/accorto/src/lib/accorto.service";
var styles_StackedBarChartComponent = [i0.styles];
var RenderType_StackedBarChartComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_StackedBarChartComponent, data: {} });
export { RenderType_StackedBarChartComponent as RenderType_StackedBarChartComponent };
function View_StackedBarChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Definition: "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "small", [["class", "sbc-debug"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(0, i2.JsonPipe, []), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sources: "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "small", [["class", "sbc-debug"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(0, i2.JsonPipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.definition)); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.sources)); _ck(_v, 10, 0, currVal_1); }); }
export function View_StackedBarChartComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { chartContainer: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["chart", 1]], null, 3, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "sbc-tooltip"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StackedBarChartComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isDebug; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.tooltipId; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 4, 0, currVal_2); }); }
export function View_StackedBarChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "psa-stacked-bar-chart", [], null, null, null, View_StackedBarChartComponent_0, RenderType_StackedBarChartComponent)), i1.ɵdid(1, 573440, null, 0, i3.StackedBarChartComponent, [i4.AccortoService], null, null)], null, null); }
var StackedBarChartComponentNgFactory = i1.ɵccf("psa-stacked-bar-chart", i3.StackedBarChartComponent, View_StackedBarChartComponent_Host_0, { id: "id", dimensions: "dimensions", definition: "definition", sources: "sources" }, {}, []);
export { StackedBarChartComponentNgFactory as StackedBarChartComponentNgFactory };
