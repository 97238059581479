import { TenantUser } from './tenant-user';
import { Tenant } from './tenant';
import { DataColumn } from './data-column';
import { DataTableI, DataTableD } from './data-table-i';
import { DataRecordI } from './data-record-i';
import { DataRecord } from './data-record';
import { DataRecordF } from '../utils/data-record-f';

/* tslint:disable max-line-length no-inferrable-types */

export enum DataQueryScope {
  ALL = 'ALL',
  ORG = 'ORG',
  USR = 'USR'
}

/**
 * name: DataTable
 */
export class DataTable {

  /**
   * Primary Key (Id)
   * label: Record
   */
  public id: number|string;

  /**
   * label: Name
   */
  public name: string;

  /**
   * label: Label
   */
  public label: string;

  /**
   * label: Description
   */
  public description: string;

  /**
   * label: Active
   */
  public isActive: boolean = true;

  /**
   * label: Created Time
   */
  public created: number;

  /**
   * fk TenantUser
   * label: Created By
   */
  public createdBy: TenantUser;

  public createdById: number;

  /**
   * label: Updated Time
   */
  public updated: number;

  /**
   * fk TenantUser
   * label: Updated By
   */
  public updatedBy: TenantUser;

  public updatedById: number;

  /**
   * label: External Id
   */
  public externalId: string;

  /**
   * parent fk Tenant
   * label: Tenant
   */
  public tenant: Tenant;

  public tenantId: number;

  /**
   * fk TenantUser
   * label: Record Owner
   */
  public owner: TenantUser;

  public ownerId: number;

  /**
   * label: Record Version
   */
  public version: number;

  /**
   * label: Salesforce Id
   */
  public sfId: string;

  public isSystemTable: boolean;

  public isDefinitionReadOnly: boolean;

  public isTransaction: boolean;

  public isRecordsReadOnly: boolean;

  /**
   * Record Level
   * label: Read Only Logic
   */
  public readOnlyLogic: string;

  public parentTable: string;

  public parentIdColumn: string;

  public parentLinkColumn: string;

  public defaultSort: string;

  public isCsvFirstRowReader: boolean;

  public csvHeaderRow: string;

  public sfOrgId: string;

  public sfUserId: string;

  /**
   * label: Row Count
   */
  public statRowCount: number;

  /**
   * label: Column Count
   */
  public statColCount: number;

  public statKeyPrefix: string;

  public labelPlural: string;

  public help: string;

  public iconRef: string;

  public columnList: DataColumn[] = [];

  /**
   * Transient Column Map
   * label: Map: name(LowerCase) -> DataColumn
   */
  public columnListMap: {[key: string]: DataColumn} = {};

  public dataQueryScope: DataQueryScope;


  /**
   * @return DataTable record as DataRecord (original value)
   */
  public asDataRecord(): DataRecord {
    const dr = new DataRecord();
    dr.recordType = 'DataTable';
    if (this.id) {
      dr.id = String(this.id);
    } else {
      dr.rowNo = -1;
    }
    if (this.name) {
      dr.name = this.name;
    }
    if (this.label) {
      dr.label = this.label;
    }
    Object.keys(this).forEach((key) => {
      const value = this[key];
      if (value !== undefined && value !== null && !Array.isArray(value) && typeof value !== 'object') {
        dr.valueMap[key] = String(value);
      }
    });
    return dr;
  } // asDataRecord


  /**
   * @param id id of the DataColumn
   * @return DataColumn entity or undefined
   */
  public getColumnById(id: number): DataColumn | undefined {
    if (this.columnList) {
      for (const entity of this.columnList) {
        if (entity.id === id) {
          return entity;
        }
      }
    }
    return undefined;
  } // getColumnById


  /**
   * @param record data record object literal
   */
  public setFrom(record: DataRecordI): DataTable {
    if (record.valueMap) {
      Object.keys(record.valueMap).forEach((key) => {
        const value = record.valueMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, DataTableD[ key ]);
      });
    }
    if (record.changeMap) {
      Object.keys(record.changeMap).forEach((key) => {
        const value = record.changeMap[ key ];
        this[ key ] = DataRecordF.recordValue(key, value, DataTableD[ key ]);
      });
    }
    return this;
  } // setFrom


  /**
   * @param record object literal
   */
  public setFromI(record: DataTableI): DataTable {
    Object.keys(DataTableD).forEach((key) => {
      const value = record[ key ];
      if (value) {
        this[ key ] = value;
        if (key === 'createdBy') { // fk
          this.createdBy = new TenantUser();
          this.createdBy.setFromI(value);
        } else if (key === 'updatedBy') { // fk
          this.updatedBy = new TenantUser();
          this.updatedBy.setFromI(value);
        } else if (key === 'owner') { // fk
          this.owner = new TenantUser();
          this.owner.setFromI(value);
        } else if (key === 'columnList') { // child
          this.columnList = [];
          this.columnListMap = {};
          for (const vv of value) {
            const cc = new DataColumn();
            cc.setFromI(vv);
            cc.dataTable = this; // parent
            this.columnList.push(cc); // list
            this.columnListMap[ cc.name.toLowerCase() ] = cc; // map
          }
        }
      }
    });
    return this;
  } // setFromI

} // DataTable
