/**
 * Point x/y
 * aim fo compatibility to
 * https://developer.mozilla.org/en-US/docs/Mozilla/JavaScript_code_modules/Geometry.jsm/Point
 * https://dxr.mozilla.org/mozilla-beta/source/toolkit/modules/Geometry.jsm
 */
export class Point2d {

  constructor(public x: number,
              public y: number) {
  }

  /**
   * @param other another point to add (delta)
   * @return new point (this.x + other.x), (this.y + other.y)
   */
  public add(other: Point2d): Point2d {
    return new Point2d(this.x + other.x, this.y + other.y);
  }

  /**
   * @param delta number to add to X -
   */
  public addX(delta: number) {
    this.x += delta;
  }

  /**
   * @param delta number to add to Y |
   */
  public addY(delta: number) {
    this.y += delta;
  }

  /**
   * Point is the same to other (x/y)
   * @param other point
   * @return true if x/y is the same
   */
  public isSame(other: Point2d): boolean {
    if (other) {
      return this.x === other.x && this.y === other.y;
    }
    return false;
  }

  /**
   * @param other another point to subtract (delta)
   * @return new point (this.x - other.x), (this.y - other.y)
   */
  public minus(other: Point2d): Point2d {
    return new Point2d(this.x - other.x, this.y - other.y);
  }

  toString(): string {
    return this.x + ',' + this.y;
  }

} // Point2D
