import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AccortoService } from '../accorto.service';
import { Logger } from '../log/logger';
import { CRequestData, DataOperation } from '../model/c-request-data';
import { AccortoCUtil } from '../model/accorto-c-util';
import { ServiceBase } from '../utils/service-base';
import { logoutRequestAction } from '../login/login.actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../accorto.service";
import * as i3 from "@ngrx/store";
export class DataService extends ServiceBase {
    constructor(http, config, store) {
        super();
        this.http = http;
        this.config = config;
        this.store = store;
        this.log = new Logger('DataService');
    }
    /**
     * Delete single record
     * @param record the record
     */
    deleteRecord(record) {
        const start = new Date();
        const request = new CRequestData();
        this.config.setCRequest(request);
        request.record = record;
        request.operation = DataOperation.DELETE;
        const url = this.config.server + '/data';
        this.log.info('delete ' + url + ' ' + request.tableName, request)();
        const body = JSON.stringify(request);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(url, body, httpOptions)
            .pipe(map(response => AccortoCUtil.createCResponseData(response)), // class
        tap((r) => {
            this.log.info('delete.response ' + r.tableName, r)();
            this.markSuccess(start);
        }));
    } // deleteRecord
    /**
     * Query List
     * @param tableName table name
     * @param dataView query criteria
     */
    query(tableName, dataView) {
        const start = new Date();
        const request = new CRequestData();
        this.config.setCRequest(request);
        request.tableName = tableName;
        request.dataView = dataView;
        request.operation = DataOperation.QUERY;
        const url = this.config.server + '/data';
        this.log.info('query ' + url + ' ' + request.tableName, request)();
        const body = JSON.stringify(request);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(url, body, httpOptions)
            .pipe(map(response => AccortoCUtil.createCResponseData(response)), // class
        tap((r) => {
            this.log.info('query.response ' + r.tableName, r)();
            if (r.isLoggedOut) {
                this.store.dispatch(logoutRequestAction());
            }
            this.markSuccess(start);
        }));
    } // query
    /**
     * Query Record
     * @param tableName table name
     * @param recordId record id
     */
    queryId(tableName, recordId) {
        const start = new Date();
        const request = new CRequestData();
        this.config.setCRequest(request);
        request.tableName = tableName;
        request.recordId = recordId;
        request.operation = DataOperation.QUERY;
        const url = this.config.server + '/data';
        this.log.info('queryId ' + url + ' ' + request.tableName, request)();
        const body = JSON.stringify(request);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(url, body, httpOptions)
            .pipe(tap(r => {
            this.log.info('queryId.response ' + request.tableName, r)();
        }), map(response => AccortoCUtil.createCResponseData(response)), // class
        map(response => response.records[0]), tap((r) => {
            this.markSuccess(start);
        }));
    } // queryId
    /**
     * Save single record
     * @param record the record
     */
    saveRecord(record) {
        const start = new Date();
        const request = new CRequestData();
        this.config.setCRequest(request);
        request.record = record;
        request.operation = DataOperation.SAVE;
        const url = this.config.server + '/data';
        this.log.info('save ' + url + ' ' + request.tableName, request)();
        const body = JSON.stringify(request);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(url, body, httpOptions)
            .pipe(map(response => AccortoCUtil.createCResponseData(response)), // class
        tap((r) => {
            this.log.info('save.response ' + r.tableName, r)();
            this.markSuccess(start);
        }));
    } // saveRecord
} // DataService
/** @nocollapse */ DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AccortoService), i0.ɵɵinject(i3.Store)); }, token: DataService, providedIn: "root" });
